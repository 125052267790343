@use "../../Foundation/mixin/typography";

.p-productMunicipality {
  .p-productMunicipality__descriptionWrap {
    @include typography.body;
    white-space: pre-line;
  }

  .p-productMunicipality__nextBtn {
    margin-top: auto;
  }
}
