/* stylelint-disable */
// 本当は
// @include typography.label-bold;
// を使用したいけど、
// 商品カードは情報量が多すぎて 12px の文字は無理があるので、
// 一旦ベタ書きで 10px を指定します（weightも同様）

@mixin productTilingCardLabel {
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 20px;
  width: fit-content;
  margin: 0 4px 4px 0;
  position: relative;
}

.c-furusatoLabel {
  @include productTilingCardLabel;
  background: $color-primary4;
}

.c-quickShipLabel {
  @include productTilingCardLabel;
  background: $color-primary4;
}
