@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-averageRatingsWithCount {
  display: flex;
  background: color.$color-gray0;
  flex-direction: column;
  border-bottom: 1px solid color.$color-gray4;
  padding-bottom: 16px;

  @include screen("tablet-and-pc") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  @include screen("smartphone") {
    gap: 12px;
  }

  .p-averageRatingsWithCount__ratings {
    align-items: center;
    display: flex;
    justify-content: center;

    @include screen("tablet-and-pc") {
      flex-direction: column;
      border-left: 1px solid color.$color-gray4;
    }
  }

  .p-averageRatingsWithCount__ratingsBlock {
    @include screen("smartphone") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  .p-averageRatingsWithCount__count {
    text-align: center;
    @include typography.caption;
  }

  .p-averageRatingsWithCount__zeroCount {
    color: color.$color-gray2;
  }
}
