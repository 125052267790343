/*
# .c-formInputRadioButtonWithEdit
# 概要
- 住所の選択と編集を行うために使われることが多い
- 
*/

.c-formInputRadioButtonWithEdit {
  display: flex;

  & + .c-formInputRadioButtonWithEdit {
    margin-top: 8px;
  }

  .c-formInputRadioButton + & {
    margin-top: 8px;
  }

  &[aria-disabled="true"] {
    .c-formInputRadioButtonWithEdit__label {
      color: $color-gray3;
      background-color: $color-gray5;
      &::before {
        border: 2px solid $color-gray4;
        background-color: $color-gray4;
      }
    }
  }

  .c-formInputRadioButtonWithEdit__input {
    // MEMO : display: none; にすると input 要素のフォーカスの操作ができなくなるので、見せないようにしています。
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    border: 0;

    &[type="radio"]:checked + .c-formInputRadioButtonWithEdit__label {
      background-color: $color-primary3;
      border: 1px solid $color-primary2;
      border-radius: 4px;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: #bda35a;
        border-radius: 50%;
        grid-area: 1/1;
        margin: 0 auto;
      }
    }

    &:disabled {
      & + .c-formInputRadioButtonWithEdit__label {
        color: $color-gray3;
        background-color: $color-gray5;
        &::before {
          border: 2px solid $color-gray4;
          background-color: $color-gray4;
        }
      }
    }
  }

  .c-formInputRadioButtonWithEdit__label {
    width: 100%;

    display: grid;
    grid-template-columns: 18px 1fr;
    gap: 8px;
    align-items: center;

    background-color: $color-gray0;
    border: 1px solid $color-gray4;
    border-radius: 4px;

    padding: 16px;

    &::before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border: 2px solid #bda35a;
      background-color: $color-gray0;
      border-radius: 50%;
      grid-area: 1/1;
      box-sizing: border-box;
    }
  }

  .c-formInputRadioButtonWithEdit__labelText {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    margin: 0;
    padding: 0;
  }

  .c-formInputRadioButtonWithEdit__labelTextWithButton {
    min-width: calc(2em + (14px * 2));
  }

  // 名前と住所の記載
  .c-formInputRadioButtonWithEdit__nameAndAddress {
    display: grid;
    gap: 8px 4px;

    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }

  .c-formInputRadioButtonWithEdit__nameAndAddressTerm {
    // width: 2em;
  }

  .c-formInputRadioButtonWithEdit__nameAndAddressDescription {
    // width: calc(100% - (2em + 8px));
  }

  .c-formInputRadioButtonWithEdit__button {
    color: $color-primary2;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.5px;

    border: 1px solid $color-primary2;
    border-radius: 4px;
    padding: 14px;
    background-color: $color-gray0;
  }
}
