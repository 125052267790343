@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/arrow";
@use "../../Foundation/mixin/line-clamp";

.p-producerPickupProductCard {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border: 1px solid $color-gray5;
  border-radius: 4px;
  background: $color-gray0;

  .p-producerPickupProductCard__name {
    @include typography.subTitle;
    @include line-clamp.line-clamp(3);
    margin-bottom: 4px;
  }

  .p-producerPickupProductCard__price {
    @include typography.price;
  }

  .p-producerPickupProductCard__arrow {
    position: relative;

    &:before {
      @include arrow($color-primary2, 2px, $arrowDirectionRight);
      display: block;
    }
  }
}
