.p-fixedTermSubscription__detailProductItem {
  overflow: hidden;
  border: 1px solid $color-gray5;
  margin-bottom: $base-margin-4;
  padding: $base-padding-4;
  border-radius: 10px;
}

.p-fixedTermSubscription__itemWrapper {
  padding: $base-padding-4;
}

.p-fixedTermSubscription__coolTypeLabel {
  position: relative;
  display: inline-block;
  margin: 0 0 5px;
  padding: 4px 8px;
  font-size: $font-size9;
  font-weight: $font-weight-bold;
  border: 3px solid $color-primary4;
  border-radius: 20px;
}

.p-fixedTermSubscription__detailProductItemContent {
  display: flex;
  padding-bottom: $base-padding-4;
  text-align: left;
}

.p-fixedTermSubscription__detailProductThumbnail {
  flex-basis: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;

  @include screen("smartphone") {
    flex-basis: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-fixedTermSubscription__detailProductInfo {
  flex-basis: calc(100% - 100px);
  margin-left: 12px;

  @include screen("smartphone") {
    flex-basis: calc(100% - 50px);
  }
}

.p-fixedTermSubscription__detailProductProducerName {
  font-size: $font-size7;
}

.p-fixedTermSubscription__detailProductName {
  font-size: $font-size5;
  font-weight: $font-weight-bold;

  @include screen("smartphone") {
    font-size: $font-size7;
  }
}

.p-fixedTermSubscription__shipmentWrapper {
  padding: $base-padding-4 0;
  border-top: 1px solid $color-gray5;
  font-size: $font-size5;

  @include screen("smartphone") {
    font-size: $font-size7;
  }
}

.p-fixedTermSubscription__estimatedDeliveryDate {
  margin-bottom: $base-margin;
  display: flex;
  justify-content: space-between;
}

.p-fixedTermSubscription__shipmentLabel {
  width: 40%;
}

.p-fixedTermSubscription__producerHolidayAttention {
  font-size: $font-size8;
  margin-bottom: $base-margin-2;
}

.p-fixedTermSubscription__shipmentCool {
  display: flex;
  justify-content: flex-end;
}

.p-fixedTermSubscription__shipmentCoolText {
  font-size: $font-size6;
}

.p-fixedTermSubscription__paymentPriceWrapper {
  padding: $base-padding-4 0;
  border-top: 1px solid $color-gray5;
  font-size: $font-size5;

  @include screen("smartphone") {
    font-size: $font-size7;
  }
}

.p-fixedTermSubscription__paymentPriceLabel {
  float: left;
}

.p-fixedTermSubscription__paymentPriceValue {
  text-align: right;
  margin-bottom: $base-margin-2;
}

.p-fixedTermSubscription__totalAmountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $base-padding-8 $base-padding-3 $base-padding-8;
}

.p-fixedTermSubscription__totalAmountLabel {
  font-size: $font-size5;
}

.p-fixedTermSubscription__totalAmountValue {
  font-size: $font-size3;
}

.p-fixedTermSubscription__notice {
  margin-bottom: $base-margin-4;
}

.p-fixedTermSubscription__confirmShipmentContainer {
  overflow: hidden;
  border: 1px solid $color-gray5;
  margin-bottom: $base-margin-5;
  border-radius: 10px;
}

.p-fixedTermSubscription__confirmShipmentHeader {
  font-size: $font-size6;
  font-weight: $font-weight-bold;
  border-bottom: 1px solid $color-gray5;
  padding: $base-padding-4;
}

.p-fixedTermSubscription__confirmShipment {
  padding: $base-padding-4;
}

.p-fixedTermSubscription__confirmProduct {
  display: flex;
  margin-bottom: $base-margin-4;
}

.p-fixedTermSubscription__confirmProductThumbnail {
  flex-basis: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;

  @include screen("smartphone") {
    flex-basis: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-fixedTermSubscription__confirmProductInfo {
  flex-basis: calc(100% - 100px);
  margin-left: 12px;

  @include screen("smartphone") {
    flex-basis: calc(100% - 50px);
  }
}

.p-fixedTermSubscription__confirmProductProducerName {
  font-size: $font-size7;
}

.p-fixedTermSubscription__confirmProductName {
  font-size: $font-size5;
  font-weight: $font-weight-bold;

  @include screen("smartphone") {
    font-size: $font-size7;
  }
}

.p-fixedTermSubscription__confirmPriceContainer {
  border-top: 1px solid $color-gray5;
  padding-top: $base-padding-4;
  font-size: $font-size6;
}

.p-fixedTermSubscription__confirmPriceLabel {
  float: left;
}

.p-fixedTermSubscription__confirmPriceValue {
  text-align: right;
  margin-bottom: $base-margin-2;
}

.p-fixedTermSubscription__confirmReservedPayment {
  display: flex;
  justify-content: flex-end;
  font-size: $font-size8;
}
