/* stylelint-disable selector-class-pattern */
.c-btnSocial {
  display: flex;
  height: 20px;
  align-items: center;
  padding-left: 23px;
  background-repeat: no-repeat;
  background-position: 6px center;
  border-radius: 3px;
  color: $color-gray0;
  font-family: Helvetica, Arial, "hiragino kaku gothic pro", meiryo, "ms pgothic", sans-serif;
  font-size: 11px;
  font-weight: 700;
}

a.c-btnSocial {
  color: $color-gray0;
}

.c-btnSocial-facebook {
  width: 68px;
  background-color: $color-facebook;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBYGJDeRLlUUAAAAjElEQVQY01WPMQ6CUBAFZ4l0djSUJF7EA3gDC0PxD+KxDCS0v6b2Fkg9FpCvvGqy85KXRcQwmV1czCZDBLF19D+jrWCU88vOkzd1NDCV5kXEq6oJcxEhvnfO4cKZLQG486diBeBJ7PIOwFoxc0wHwIx92UAK9xhl/ieygdg4HcRks32OtQ8HEQcf1iJfGaivTNZzdpcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDQtMjJUMTM6MzY6NTUtMDc6MDCLicTQAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTA0LTIyVDEzOjM2OjU1LTA3OjAw+tR8bAAAAABJRU5ErkJggg==);
  background-size: 12px;
}
.c-btnSocial-twitter {
  width: 76px;
  background-color: $color-twitter;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
  background-size: 14px;
}
.c-btnSocial-x {
  width: 76px;
  background-color: $color-x;
  background-image: url("/app/assets/images/icon-sns-x.svg");
  background-size: 14px;
}
/* stylelint-enable selector-class-pattern */
