.p-priceOffCampaigNnotice {
  color: $color-gray0;
  font-size: $font-size1;
  font-weight: $font-weight-bold;
  text-align: center;

  background-color: $color-primary2;

  padding: 6px;
  margin: 16px 0;
}
