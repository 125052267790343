@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

.p-productReviewRatings {
  display: flex;
  justify-content: space-around;

  .p-productReviewRatings__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
    flex: 1;

    &:nth-child(n + 2) {
      border-left: 1px solid #e9e9e9;
    }
  }

  .p-productReviewRatings__label {
    @include typography.caption;
  }

  .p-productReviewRatings__score {
    color: color.$color-primary1;
    margin: 0 4px;
    font-family: Arial, sans-serif;

    @include typography.title-2;
  }
}
