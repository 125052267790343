@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/screen";

.p-titleShowHeader {
  --_padding-bottom: 16px;
  --_info-padding-bottom: 12px;
  --_info-flex-direction: column;
  --_infoStatus-flex-basis: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-bottom: 1px solid color.$color-gray5;

  padding-bottom: var(--_padding-bottom);

  @include screen.screen("tablet-and-pc") {
    --_padding-bottom: 24px;
    --_info-padding-bottom: 24px;
    --_info-flex-direction: row;
    --_infoStatus-flex-basis: 390px;
  }

  .p-titleShowHeader__title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .p-titleShowHeader__titleImage {
    flex-basis: 64px;
  }

  .p-titleShowHeader__titleThumbnail {
    --_bg-image: url("/app/assets/images/missing.png");

    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-image: var(--_bg-image);
    background-size: cover;
  }

  .p-titleShowHeader__titleInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .p-titleShowHeader__titleName {
    @include typography.title-1;
  }

  .p-titleShowHeader__condition {
    @include typography.body-small;
  }

  .p-titleShowHeader__info {
    width: 100%;

    display: flex;
    flex-direction: var(--_info-flex-direction);
    gap: var(--_info-padding-bottom);
  }

  .p-titleShowHeader__infoStatus {
    flex-basis: var(--_infoStatus-flex-basis);
  }

  .p-titleShowHeader__infoStatusList {
    max-width: 390px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    border-left: 1px solid color.$color-gray5;
    border-right: 1px solid color.$color-gray5;
  }

  .p-titleShowHeader__infoStatusListItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    & + .p-titleShowHeader__infoStatusListItem {
      border-left: 1px solid color.$color-gray5;
    }
  }

  .p-titleShowHeader__infoStatusTypeName {
    @include typography.caption-bold;
  }

  .p-titleShowHeader__infoStatusTypeValue {
    font-size: font.$font-size7;
    line-height: 1.6;
    letter-spacing: 0.2px;
    text-align: center;
  }
}
