@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";

.p-titleHolderInProductDetail {
  padding-bottom: 16px;
  border-bottom: 1px solid color.$color-gray4;

  .p-titleHolderInProductDetail__tilte {
    @include typography.body-small-bold;
  }

  .p-titleHolderInProductDetail__list {
    margin-top: 4px;
    display: flex;
    gap: 0;
  }

  .p-titleHolderInProductDetail__item {
    & + .p-titleHolderInProductDetail__item {
      margin-left: -6px;
    }
  }

  .p-titleHolderInProductDetail__thumbnail {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid color.$color-gray0;
  }
}
