.p-regularSubscription {
  .p-regularSubscription__cancelContainer {
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;

    border-top: 1px solid $color-gray3;
    margin-top: 40px;
    padding-top: 40px;

    .p-regularSubscription__cancel--subAttention {
      margin: 4px 0;
      font-size: $font-size9;
      font-weight: $font-weight-normal;
    }
  }
}

.p-regularSubscriptionModal {
  width: calc(100% - 20px);
  /* override app/styles/user/Object/Component/c-modal.scss */
  --c-modal-max-width: 600px;
  --c-modal-max-height: 220px;
  padding-bottom: 16px;

  @include screen("smartphone") {
    --dialog-height: 250px;
  }

  .p-regularSubscriptionModal__title {
    font-size: $font-size5;
    font-weight: $font-weight-bold;
    text-align: center;
    background: $color-gray5;
    padding: 10px 0;
  }

  .p-regularSubscriptionModal__body {
    margin: 20px 10px;
  }

  .p-regularSubscriptionModal__content {
    font-size: $font-size5;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  .p-regularSubscriptionModal__content--subAttention {
    margin: 4px 0;
    font-size: $font-size5;
    font-weight: $font-weight-normal;
  }

  .p-regularSubscriptionModal__buttonArea {
    display: flex;
    justify-content: center;
  }

  .p-regularSubscriptionModal__button {
    width: 150px;
    margin: 10px 10px 0;

    @include screen("tablet-and-pc") {
      width: 200px;
    }
  }

  .p-regularSubscriptionModal__hiddenButton {
    display: none;
  }
}
