@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-producerAward {
  display: grid;
  gap: 8px;

  border-top: 1px solid color.$color-gray4;
  border-bottom: 1px solid color.$color-gray4;

  padding: 16px 24px;

  @include screen.screen("tablet-and-pc") {
    border-top: 1px solid color.$color-primary2;
    border-bottom: 1px solid color.$color-primary2;
  }

  .p-producerAward__header {
    display: grid;

    grid-template-areas:
      "title title"
      "count hall_of_famer";
    grid-template-columns: auto 1fr;
    justify-content: start;
    align-items: center;
    gap: 8px;

    @include screen.screen("tablet-and-pc") {
      grid-template-areas: "title count hall_of_famer";
      grid-template-columns: repeat(3, auto);
      justify-content: start;
      align-items: center;
      gap: 16px;
    }
  }

  .p-producerAward__title {
    grid-area: title;
    @include typography.subTitle-small;
  }

  .p-producerAward__count {
    grid-area: count;
    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします
    @include typography.body-small;
  }

  .p-producerAward__hallOfFamer {
    grid-area: hall_of_famer;
    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします
  }

  .p-producerAward__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
  }

  .p-producerAward__item {
    flex-basis: 46px;
    text-align: center;
  }

  .p-producerAward__iconLabel {
    color: color.$color-primary1;

    font-size: font.$font-size9;
    font-weight: font.$font-weight-bold;
    line-height: 1;

    /* stylelint-disable selector-class-pattern -- ランキングの数値のため */
    &.p-producerAward__iconLabel--1 {
      color: color.$color-primary1;
    }

    &.p-producerAward__iconLabel--2 {
      color: color.$color-gray2;
    }

    &.p-producerAward__iconLabel--3 {
      color: color.$color-bronze;
    }
    /* stylelint-enable selector-class-pattern */
  }
}
