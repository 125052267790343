.coupon {
  width: 100%;
  max-width: 320px;
  height: 85px;
  background-image: url("../../app/assets/images/mypage/coupon-background.svg");
  background-size: cover;
  margin: 0 auto;
  position: relative;
  margin-bottom: 16px;

  &.coupon--small {
    height: 75px;
    transform: scale(0.8);
  }

  &.left_nav {
    background-image: url("../../app/assets/images/mypage/coupon-background-pc.svg");
    max-width: 270px;
    height: 80px;
    @include screen("tablet-only") {
      background-image: url("../../app/assets/images/mypage/coupon-background-tablet.svg");
      max-width: 175px;
      height: 70px;
    }
  }

  &.coupon-limit::after {
    background-color: $color-progress;
    border-radius: 28px;
    padding: 8px;
    position: absolute;
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
    right: 0px;
    top: -12px;
    letter-spacing: 0;
  }

  &.coupon-limit-0::after {
    content: "\672C\65E5\9650\308A\FF01"; // 本日限り！
  }

  &.coupon-limit-1::after {
    content: "\660E\65E5\307E\3067\FF01"; // 明日まで！
  }

  &.coupon-limit-2::after {
    content: "\3042\3068\FF12\65E5\FF01"; // あと２日！
  }

  &.coupon-limit-3::after {
    content: "\3042\3068\FF13\65E5\FF01"; // あと３日！
  }

  .coupon-off {
    color: #fff;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;
    font-size: 24px;
    text-align: center;
    @include screen("smartphone") {
      font-size: 20px;
    }
    @include screen("tablet-only") {
      font-size: 18px;
    }

    span {
      font-size: 40px;
      @include screen("smartphone") {
        font-size: 38px;
      }
      @include screen("tablet-only") {
        font-size: 28px;
      }
    }

    &.small {
      font-size: $font-size5;

      span {
        font-size: $font-size-large4;
      }
    }
  }

  .coupon-day {
    color: #cd0526;
    background-color: #fff;
    border-radius: 8px;
    font-size: 11px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;
    padding: 0px 12px;
    max-width: 156px;
    margin: 0 auto;
    @include max-breakpoint($dimen-sp) {
      font-size: 10px;
    }
  }
}
