@use "../../Foundation/mixin/typography";

.p-producerCommitment {
  display: grid;
  gap: 40px 20px;
  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
  }

  .p-producerCommitment__inner {
    display: flex;
    flex-direction: column;

    &.p-producerCommitment__inner--spHide {
      @include screen("smartphone") {
        display: none;
      }
    }
  }

  .p-producerCommitment__img {
    margin-bottom: 16px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .p-producerCommitment__moreImg {
    height: 50px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .p-producerCommitment__title {
    margin-bottom: 8px;
    color: $color-primary1;
    @include typography.title-2;
  }

  .p-producerCommitment__text {
    margin-bottom: 16px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include typography.body;

    @include screen("tablet-and-pc") {
      -webkit-line-clamp: 4;
    }

    @include screen("smartphone") {
      -webkit-line-clamp: 3;
    }
  }

  .p-producerCommitment__btn {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-producerCommitment__moreBtn {
  position: relative;
  left: 0;
  bottom: 66px;
  height: 52px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, $color-gray0 100%);
  margin-bottom: -50px;
}

.p-producerCommitmentModal {
  @include screen("tablet-and-pc") {
    --dialog-width: min(90vw, 1200px);
    --dialog-height: min(70vh, 800px);
    overflow: hidden;
  }

  .p-producerCommitmentModal__img {
    flex: 2;
    @include screen("smartphone") {
      flex: 1.2;
      padding: 16px;
    }

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .p-producerCommitmentModal__content {
    display: flex;
    flex-flow: column;

    @include screen("tablet-and-pc") {
      flex-flow: row;
    }

    > * {
      width: 100%;
    }
  }

  .p-producerCommitmentModal__detail {
    padding: 24px 16px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include screen("smartphone") {
      height: 100%;
      padding: 0 16px;
      overflow-y: auto;
      gap: 8px;
      @include scrollbar-hide;
    }
  }

  .p-producerCommitmentModal__title {
    color: $color-primary1;
    @include typography.title-2;
  }

  .p-producerCommitmentModal__text {
    @include typography.body;
    word-break: break-all;
  }
}
