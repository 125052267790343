@use "sass:color";
@use "../../Foundation/variable";
@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/arrow";

/* 

現在使用していないcssです
過去に特集記事・ショーケース内で使用してしまっているため、削除ができません
特集記事・ショーケースのcssにimportしています

*/

.c-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 16px;
  @include typography.button;
  transition: 300ms;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;

  &.c-btn-small {
    @include typography.button-small;
    padding: 12px 10px;
  }

  &.c-btn-gold {
    color: variable.$color-gray0;
    background: variable.$color-primary2;

    &:hover {
      background: color.adjust(variable.$color-primary2, $lightness: -6%);
    }
  }

  &.c-btn-gold-outline {
    color: variable.$color-primary2;
    border: 1px solid variable.$color-primary2;
    background: variable.$color-gray0;

    &:hover {
      background: rgba(variable.$color-primary2, 0.1);
    }
  }

  &.c-btn-red {
    color: variable.$color-gray0;
    background: variable.$color-order1;

    &:hover {
      background: color.adjust(variable.$color-order1, $lightness: -4%);
    }
  }

  &.c-btn-red-outline {
    color: variable.$color-order1;
    border: 1px solid variable.$color-order1;
    background: variable.$color-gray0;

    &:hover {
      background: #fcf3f4;
      border: 1px solid color.adjust(variable.$color-order1, $lightness: -4%);
      color: color.adjust(variable.$color-order1, $lightness: -4%);
    }
  }

  &.c-btn-gray {
    color: variable.$color-gray1;
    background: variable.$color-gray4;

    &:hover {
      background: color.adjust(variable.$color-gray4, $lightness: -4%);
    }
  }

  &.c-btn-black {
    color: variable.$color-gray0;
    border: 1px solid variable.$color-gray1;
    background: variable.$color-gray1;

    &::before {
      position: absolute;
      top: calc(50% - 4px);
      right: 20px;
      @include arrow.arrow(variable.$color-gray0, 1px, arrow.$arrowDirectionRight);
    }

    &:hover {
      background: rgba(variable.$color-gray1, 0.7);
    }
  }

  &.c-btn-white {
    color: variable.$color-gray1;
    border: 1px solid variable.$color-gray1;
    background: variable.$color-gray0;

    &::before {
      position: absolute;
      top: calc(50% - 4px);
      right: 20px;
      @include arrow.arrow(variable.$color-gray1, 1px, arrow.$arrowDirectionRight);
    }

    &:hover {
      background: rgba(variable.$color-gray1, 0.1);
    }
  }
}
