@use "sass:color";
@use "../../Foundation/mixin/typography";

.c-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 16px;
  @include typography.button;
  transition: 300ms;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;

  &.c-btn--small {
    @include typography.button-small;
    padding: 12px 10px;
  }

  &-disabled {
    color: #999 !important;
    background: #efefef;
    border: 1px solid #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[aria-disabled="true"] {
    opacity: 0.4;
  }

  &.c-btn--primary {
    color: $color-gray0;
    background: $color-primary2;

    &:hover {
      background: color.adjust($color-primary2, $lightness: -6%);
    }
  }

  &.c-btn--order {
    color: $color-gray0;
    background: $color-order1;

    &:hover {
      background: color.adjust($color-order1, $lightness: -4%);
    }
  }

  &.c-btn--subOrder {
    color: $color-order1;
    border: 1px solid $color-order1;
    background: $color-gray0;

    &:hover {
      background: #fcf3f4; //rgba($color-order1, 0.1);
      border: 1px solid color.adjust($color-order1, $lightness: -4%);
      color: color.adjust($color-order1, $lightness: -4%);
    }
  }

  &.c-btn--basic {
    color: $color-gray1;
    background: $color-gray4;

    &:hover {
      background: color.adjust($color-gray4, $lightness: -4%);
    }
  }

  // MEMO : color での !important を避けます
  &.c-btn--line {
    color: $color-gray0;
    background-color: $color-line;

    &:hover {
      background: color.adjust($color-line, $lightness: -4%);
    }
  }

  &.c-btn--annotation {
    color: $color-gray0;
    background: $color-annotation;

    &:hover {
      background: color.adjust($color-annotation, $lightness: -4%);
    }
  }

  &.c-btn--fullSize {
    max-width: none;
  }
}

.btn_wrap {
  .c-btn {
    + .c-btn {
      margin-top: 1rem;
    }
  }
}
