.contact {
  padding: 30px 0;
  @include screen("smartphone") {
    display: none;
  }

  .row {
    overflow: hidden;
    text-align: center;

    .contact-box {
      display: inline-block;
      max-width: 430px;
      width: 430px;
      @include screen("smartphone") {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
      }

      &.to-dev {
        margin-right: 20px;
        @include screen("smartphone") {
          margin-right: auto;
        }
      }

      &.to-cus {
        @include max-breakpoint($dimen-pc) {
          display: none;
        }
      }

      a {
        display: block;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    } /* contact-box */
  } /* row */
} /* contact */
