@use "../Foundation/mixin/screen" as mixin;

.l-recipeContents {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include mixin.screen("smartphone") {
    gap: 16px;
    grid-template-columns: none;
  }
}
