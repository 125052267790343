@use "../../Foundation/mixin/screen";

@include screen.screen("tablet-and-pc") {
  // prettier-ignore
  .u-showonlySP,
  .show-only-sp { // stylelint-disable-line selector-class-pattern -- TODO: Deprecated.
    display: none !important;
  }
}

@include screen.screen("smartphone") {
  // prettier-ignore
  .u-showonlyPC,
  .show-only-pc { // stylelint-disable-line selector-class-pattern -- TODO: Deprecated.
    display: none !important;
  }
}
