@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";

.c-takedTitleItem {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 12px 0;

  & + .c-takedTitleItem {
    border-top: 1px solid color.$color-gray5;
  }

  .c-takedTitleItem__detail {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 0 8px;
  }

  .c-takedTitleItem__level {
    font-size: font.$font-size4;
  }

  .c-takedTitleItem__info {
    flex: 1;
  }

  .c-takedTitleItem__titleName {
    @include typography.subTitle-small;
  }

  .c-takedTitleItem__count {
    @include typography.caption;
  }

  .c-takedTitleItem__history {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }

  .c-takedTitleItem__historyItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    background-color: color.$color-gray6;
    border-radius: 4px;

    padding: 8px;
  }

  .c-takedTitleItem__acquisitionDate {
    color: color.$color-gray1;
    font-size: font.$font-size9;
    line-height: 1.6;
    letter-spacing: 0.25px;
  }
}
