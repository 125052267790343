@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/typography";

.p-producerPromotions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  .p-producerPromotions__anottation {
    @include typography.caption;
  }

  .p-producerPromotions__list {
    border-top: 1px solid color.$color-gray5;
    border-bottom: 1px solid color.$color-gray5;
  }

  .p-producerPromotions__listItem {
    padding: 8px 12px;

    & + .p-producerPromotions__listItem {
      border-top: 1px solid color.$color-gray5;
    }
  }
}
