@use "../../Foundation/mixin/typography";

.p-productPosts {
  background: $color-gray6;
  margin-top: 1px;

  @include screen("tablet-and-pc") {
    margin: 20px 0 40px;
  }

  .p-productPosts__header {
    display: flex;
    background: $color-primary4;
    gap: 8px;
    padding: 12px 16px;
  }

  .p-productPosts__body {
    @include screen("tablet-and-pc") {
      padding: 0 $base-padding-4 $base-padding-4;

      &:empty {
        padding-bottom: 0;
      }
    }

    > * + * {
      @include screen("tablet-and-pc") {
        margin-top: $base-margin-4;
      }
    }
  }

  .p-productPosts__footer {
    margin-top: 0;
    padding: $base-padding-5 0;
    text-align: center;

    @include screen("tablet-and-pc") {
      padding-bottom: $base-padding-10;
    }

    .p-productPosts__footerDescription {
      margin-bottom: $base-margin-2;
      white-space: pre-wrap;
    }

    * + .p-productPosts__footerDescription {
      margin-top: $base-margin-6;
    }

    strong {
      @include typography.body-bold;
    }

    small {
      @include typography.body;
    }
  }

  .p-productPosts__linkBlock {
    background: $color-gray0;
    padding: 16px;
  }

  .p-productPosts__link {
    width: min(100%, 600px);
    margin: 0 auto;
  }

  .p-productPosts__paginate {
    margin: 4px 0 -16px 0;
  }
}
