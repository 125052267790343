@use "../../Foundation/mixin/typography";

.subscriptionsContractCard {
  &:nth-child(n + 1) {
    margin-top: 16px;
  }

  .subscriptionsContractCard__link {
    display: flex;
    gap: 12px;
  }

  .subscriptionsContractCard__detail {
    width: 100%;
  }

  .subscriptionsContractCard__status {
    @include typography.caption;
  }

  .subscriptionsContractCard__statusLabel {
    &:before {
      width: 8px;
      height: 8px;
      content: "";
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      margin-right: 4px;
      background-color: $color-gray3;
    }

    &.subscriptionsContractCard__statusLabel--uncontracted {
      &:before {
        background-color: #f53290;
      }
    }

    &.subscriptionsContractCard__statusLabel--contract {
      &:before {
        background-color: #23d160;
      }
    }
  }

  .subscriptionsContractCard__name {
    @include typography.subTitle;
  }

  .subscriptionsContractCard__button {
    margin-top: 12px;
  }
}
