.c-producerNavigation {
  @include screen("tablet-and-pc") {
    display: none;
  }

  .c-producerNavigation__item {
    & + .c-producerNavigation__item {
      border-top: 1px solid #ffffff;
    }

    &:first-child {
      .c-producerNavigation__link {
        border-radius: 8px 8px 0 0;
      }
    }

    &:last-child {
      .c-producerNavigation__link {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .c-producerNavigation__link {
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: $font-size5;
    font-weight: $font-weight-bold;
    transition: 300ms;
    background: #f5f3e9;

    &:hover {
      opacity: 0.5;
      background: #ffffff;
    }

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 3.5px);
      right: 12px;
      height: 7px;
      width: 7px;
      transform: rotate(45deg);
      border-top: 2px solid #ddd;
      border-right: 2px solid #ddd;
    }

    &.is-current {
      background-color: #f0e8d5;
      &::before {
        display: none;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
