/**
 * スクロールバーを隠します。標準の方法がまだ存在しないので、ベンダープレフィックスを使っています。
 *
 * @see https://caniuse.com/css-scrollbar
 * @see https://github.com/reslear/tailwind-scrollbar-hide
 */
@mixin scrollbar-hide {
  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;

  // Safari and Chrome
  &::-webkit-scrollbar {
    display: none;
  }
}
