.p-userPreferenceSample__layout {
  margin: 32px auto 0;
  text-align: center;
}

.p-userPreferenceSample__layoutTag {
  color: $color-gray0;
  background: $color-primary2;
  padding: 4px 8px;
  border-radius: 18px;
  font-size: $font-size9;
  font-weight: $font-weight-bold;
}

.p-userPreferenceSample__layoutLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -26px;
    top: 57%;
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
    border-top: 2px solid $color-primary2;
    border-right: 2px solid $color-primary2;
  }
}

.p-userPreferenceSample__preferenceList {
  margin-top: 10px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 4px;
  background: $color-primary4;
  padding: 8px;
  border-radius: 4px;
}

.p-userPreferenceSample__layoutRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-userPreferenceSample__recommendationList {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 4px;
  background: $color-primary4;
  padding: 8px;
  border-radius: 4px;
}

.p-userPreferenceSample__recommendationItem {
  background: $color-gray0;
  padding: 8px;
  font-size: $font-size9;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-userPreferenceSample__layoutItem {
  background: $color-gray0;
  padding: 4px;
  font-size: $font-size9;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}
