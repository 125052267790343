@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/variables/aspect-ratio";
@use "../../../../Foundation/mixin/typography";

.c-followButton {
  --_icon: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2225%22%20height%3D%2224%22%20viewBox%3D%220%200%2025%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20.8798%2013.5161C20.9612%2013.6665%2020.9689%2013.8608%2020.8996%2014.0206C20.8297%2014.1797%2020.6943%2014.2799%2020.5473%2014.2799H10.0215V3.87988H20.5473C20.6943%203.87988%2020.8297%203.98011%2020.8996%204.13919C20.9689%204.299%2020.9612%204.49331%2020.8798%204.64363L18.6231%208.8077C18.5339%208.97224%2018.5339%209.18756%2018.6231%209.3521L20.8798%2013.5161Z%22%20stroke%3D%22%23B8B8B8%22%20stroke-width%3D%221.2%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M7.68096%203.87988H5.33984V20.6799H7.68096V3.87988Z%22%20stroke%3D%22%23B8B8B8%22%20stroke-width%3D%221.2%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  --_background-color: #{color.$color-gray3};
  min-width: 150px;

  display: flex;
  align-items: center;
  gap: 8px;

  color: color.$color-gray1;

  border-radius: 4px;
  border: 2px solid color.$color-gray4;
  background: color.$color-gray0;

  padding: 16px;

  @include typography.button;

  &:hover {
    opacity: 0.7;
  }

  &[aria-pressed="true"] {
    --_background-color: #{color.$color-primary1};

    color: color.$color-primary1;
    border: 2px solid color.$color-primary3;
    background: color.$color-primary4;
  }

  &.c-followButton--small {
    height: 48px;
    padding: 12px 16px;
  }

  &.c-followButton--width100 {
    width: 100%;
  }

  .c-followButton__icon {
    min-width: 25px;
    height: 25px;
    aspect-ratio: aspect-ratio.$aspect-square;
    background-repeat: no-repeat;
    display: inline-block;
    mask-image: var(--_icon);
    inline-size: 1em;
    background-color: var(--_background-color, color.$color-gray3);
  }
}
