// 新 UI 用

.communication-feed-new {
  background-color: $color-gray6;
  padding: 0 0 20px;

  .communication-feed__post-new {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: $color-white;
    letter-spacing: 0 !important;
    box-shadow: none !important;

    .rank-list {
      justify-content: left;

      .score {
        width: 20px;

        &::before {
          width: 18px;
          height: 18px;
        }
      }
    }

    .feed-content {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      width: 100%;

      .name {
        position: relative;
        font-weight: bold;
        display: inline;

        &:not(:first-child) {
          padding-left: 28px;

          &::before {
            @include arrow($gold200, 1px, $arrowDirectionRight);
            position: absolute;
            top: 3px;
            left: 8px;
          }
        }
      }

      .user-thumbnail {
        width: 45px;
        height: 45px;
        margin-right: 10px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .post-time {
        font-size: 12px;
        color: $text-gray;
      }

      .content-left,
      .content-right {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .content-left {
        margin-right: 8px;
      }

      .content-center {
        flex: 1;
        text-align: left;
        width: calc(100% - 45px - 10px - 70px);

        .post-type {
          color: #aaa;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .content {
        white-space: pre-wrap;
        word-break: break-all;
      }

      .communication-label {
        font-size: 10px;
        font-weight: bold;
        background: $color-primary4;
        border-radius: 4px;
        padding: 4px;
      }
    }

    .mainText {
      position: relative;
      margin-bottom: 1rem;
      overflow: hidden;
      word-break: break-all;

      &-trigger {
        position: absolute;
        right: 0;
        top: 66px;
        z-index: 1;
        color: $color-primary2;
        font-weight: bold;
        background: #fff;
        padding: 0 4px 0 20px;
        line-height: 1;
        cursor: pointer;
      }

      &-content {
        position: relative;
        overflow: hidden;
        max-height: 80px; /*隠した状態の高さ*/
      }

      &.is-open {
        .mainText-trigger {
          display: none;
        }

        .mainText-content {
          height: auto; /*opneしたら、高さを戻す*/
          max-height: none;

          a {
            text-decoration: underline;
          }
          a:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .feed-content-title {
      font-size: 1rem;
      font-weight: 600;
    }

    .p-communicationArticle__image {
      margin: 1rem 0;
      img {
        width: 100%;
      }
    }

    .images {
      position: relative;
      padding-top: 56.25%;
      margin-bottom: 1rem;
    }

    .image-gallery-new {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-rows: repeat(2, minmax(0, 0.5fr));
      grid-template-columns: repeat(2, minmax(0, 0.5fr));
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 1rem;
      @include screen("tablet-and-pc") {
        height: 300px;
      }

      .image {
        border: 1px solid #fff;
        position: relative;

        img {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }

        .image__gallery-thumbnail {
          &.-count-3-0 {
            height: 100%;
          }
        }

        .view-all {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;

          .view-all-text {
            position: relative;
            color: #fff;
            font-size: 2rem;
            font-weight: bold;
          }
        }
      }
    }

    .action-menu {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $color-gray5;
      margin-top: 1rem;
      padding: 0.5rem 0;

      .btn-action-edit-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.6rem;
      }

      .btn-action-edit {
        display: flex;
        align-items: center;
        background: $color-gray5;
        padding: 4px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #999;

        &:not(:last-child) {
          margin-right: 4px;
        }
      }

      .p-communicationArticle__like {
        display: flex;
        justify-content: flex-start;
        padding-top: 4px;
        font-size: $font-size7;
        color: #aaaaaa;
      }

      .p-communicationArticle__comment {
        display: flex;
        justify-content: flex-start;
        padding-top: 4px;
        font-size: $font-size7;
        color: #aaaaaa;
      }

      .p-communicationArticle__shareList {
        display: flex;
        justify-content: flex-end;
        .list-share {
          display: flex;
          gap: 8px;
          padding-top: 4px;
        }
      }
    }

    .btn-icon {
      color: $color-grey;
      padding: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }

      &.-pointer-auto {
        cursor: auto;
      }
    }

    .post-replies {
      border-top: 1px solid #e6e6e5;
      padding-top: 1rem;
    }

    .comment {
      position: relative;
      padding: 0.5rem 1rem;
      background: $color-gray5;
      border-radius: 8px;

      &:before {
        content: "";
        position: absolute;
        top: 1rem;
        left: -12px;
        width: 0;
        height: 0;
        border-width: 7px 20px 7px 0;
        border-style: solid;
        border-color: transparent $color-gray5 transparent;
        transform: rotate(30deg);
      }

      .name {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }

    .comment-footer {
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    .comment-forms {
      display: flex;
      align-items: center;

      textarea {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 18px;
        flex-grow: 2;

        &:focus {
          outline: none;
          border: 2px solid #ccc;
          border-radius: 18px;
        }
      }

      .comment-submit-button {
        display: flex;
        margin-left: 0.5rem;

        .is-icon {
          width: 1.5rem;
          height: 1.5rem;
          margin-top: 0.4rem;
        }
      }
    }
  }

  // 新旧のUIが返信ではでるので隣接セレクターで回避します
  .communication-feed__post-new + .communication-feed__post {
    margin-top: -1rem;
  }
}

.post-replies-actions-wrapper {
  width: 100%;
}

.post-replies-counter {
  font-size: 12px;
  color: #aaaaaa;
  padding: 0.4rem;

  .post-replies-counter__result {
    margin-left: 0.25rem;
  }
}

.post-replies-actions {
  display: flex;
  font-size: 12px;
  border-top: 1px solid #e6e6e5;

  .post-replies-actions__link {
    width: 50%;
    padding: 0.4rem 0 0.2rem;
  }

  .post-replies-actions__action {
    font-weight: 600;
    color: #999999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
