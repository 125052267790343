.footer-banner {
  padding: 20px 15px 40px;
  @include screen("tablet-and-pc") {
    padding: 2rem 0 4rem;
  }

  a {
    display: block;

    img {
      width: 100%;
    }
  }
}
