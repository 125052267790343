@use "../../Foundation/mixin/typography";

@mixin c-link {
  display: flex;

  > a,
  > button {
    @include typography.button;
    color: $color-primary1;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
    }
  }
}

// 右向き矢印
.c-link--next {
  justify-content: flex-end;
  @include c-link;

  > a,
  > button {
    padding-right: 20px;

    &::before {
      right: 5px;
      @include arrow($color-primary1, 2px, $arrowDirectionRight);
    }
  }
}

// 左向き矢印
.c-link--back {
  justify-content: flex-start;
  @include c-link;

  > a,
  > button {
    padding-left: 20px;

    &::before {
      left: 5px;
      @include arrow($color-primary1, 2px, $arrowDirectionLeft);
    }
  }
}

// 下向き矢印
.c-link--seeMore {
  justify-content: center;
  @include c-link;

  > a,
  > button {
    padding-left: 25px;

    &::before {
      left: 5px;
      top: calc(50% - 7px);
      @include arrow($color-primary1, 2px, $arrowDirectionBottom);
    }
  }
}

// 上向き矢印
.c-link--seeClose {
  justify-content: center;
  @include c-link;

  > a,
  > button {
    padding-left: 25px;

    &::before {
      left: 5px;
      top: calc(50% - 2px);
      @include arrow($color-primary1, 2px, $arrowDirectionTop);
    }
  }
}
