/*
# p-formSection

## 概要

- .p-formGroup をいくつかまとめます
- section要素を想定しています。そのため、.p-formSection__title は h要素の想定です
  - div要素で使っても問題はありません。その時は、h要素がない時にしてください

## 使用例

- .p-formSection
- .p-formSection__title
- .p-formSection__lead
- .p-formSection__body
- .p-formSection__attention

### 並び順の想定

```
.p-formSection
  .p-formSection__title
  .p-formSection__lead
  .p-formSection__body
    .p-formGroup
  .p-formSection__attention

.p-formSection
  .p-formSection__title
  .p-formSection__lead
  .p-formSection__body
    .p-formGroup
  .p-formSection__attention
```
*/

.p-formSection {
  color: $color-gray1;
  font-size: $font-size5;
  padding: 0;

  & + .p-formSection {
    margin-top: 32px;
  }

  .p-formSection__title {
    width: 100%;
    color: $color-gray1;
    font-size: $font-size4;
    font-weight: $font-weight-semibold;
    line-height: 1.4;
    letter-spacing: 0.1px;

    /* stylelint-disable declaration-block-no-shorthand-property-overrides */
    background-color: $color-gray5;
    background: $color-gray5; // FIXME : .payment-content h2 対策
    /* stylelint-enable declaration-block-no-shorthand-property-overrides */
    border: none; // FIXME : .payment-content h2 対策
    padding: 8px 16px;
  }

  .p-formSection__lead {
    padding: 0 16px 0;
    margin: 16px 0 0;
  }

  .p-formSection__body {
    padding: 16px 16px 0;
    margin: 0;

    // MEMO: title も lead もない時があるための対策です
    &.p-formSection__body--zeroPaddingTop {
      padding-top: 0;
    }
  }

  .p-formSection__attention {
    font-size: $font-size7;
    line-height: 1.5;
    letter-spacing: 0.4px;

    margin: 16px 0 0;
  }

  .p-formSection__link {
    color: $color-gray1;
    text-decoration: underline;
  }

  .p-formSection__atoneDescription {
    margin-bottom: 16px;
  }

  .p-formSection__atoneBanner {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .p-formSection__atoneBanner img {
    max-width: 300px;
    height: auto;

    @media screen and (max-width: 768px) {
      max-width: 200px;
    }
  }
}
