.p-productPostImageModal {
  top: 0;
  @include screen("tablet-and-pc") {
    --dialog-width: min(90vw, 1200px);
    --dialog-height: min(90vh, 800px);
  }

  .p-productPostImageModal__content {
    display: flex;
    flex-flow: column;

    @include screen("tablet-and-pc") {
      flex-flow: row;
    }

    > * {
      width: 100%;
    }
  }

  .p-productPostImageModal__post {
    flex: 1;
    overflow-y: auto;
    @include scrollbar-hide;
  }

  .swiper {
    flex: 1.2;

    @include screen("tablet-and-pc") {
      flex: 2;
    }
  }

  .swiper-slide img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
