.p-orderFormCompletesSection {
  background-color: $color-gray0;
  padding: 24px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @include screen("tablet-and-pc") {
    padding: 32px 24px 40px;
  }

  & + .p-orderFormCompletesSection {
    margin-top: 20px;
  }

  .p-orderFormCompletesSection__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
  }

  .p-orderFormCompletesSection__lead {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    width: fit-content;
  }

  .p-orderFormCompletesSection__body {
    /*---*/
  }

  .p-orderFormCompletesSection__image {
    width: 100%;
    height: auto;
  }

  .p-orderFormCompletesSection__button {
    width: 100%;
    margin-top: 12px;
  }
}
