// FIXME : .c-producerCard に置き換えるのであとで消します

.producer-card {
  display: block;
  transition: 300ms;
  width: 100%;
  letter-spacing: 0;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  @include screen("smartphone") {
    border-radius: 4px;
  }

  &:hover {
    @media screen and (min-width: $dimen-tablet+px) {
      opacity: 0.8;
    }
  }

  .producer-img {
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    border-radius: 12px;
    @include screen("smartphone") {
      border-radius: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .producer-info-block {
    padding: 0 8px 10px;

    .producer-area {
      font-size: 11px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-bottom: 4px;
    }

    .producer-name {
      font-size: 16px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 48px;
      overflow: hidden;
      margin-bottom: 7px;

      @include screen("smartphone") {
        font-size: 14px;
        height: 42px;
        margin-bottom: 8px;
      }
    }

    .producer-description {
      font-size: 13px;
      height: 95px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
  }

  .follow-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(70, 70, 70, 0.9);

    img {
      width: 100%;
      height: auto;
    }
  }
}
