@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/screen";

.p-categoryFilter {
  padding: 24px 8px;

  .p-categoryFilter__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;

    @include screen.screen("pc-large-only") {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .p-categoryFilter__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 81px;
    cursor: pointer;
  }

  .p-categoryFilter__item--selected {
    background-color: variable.$color-primary4;
    border-radius: 4px;
  }

  .p-categoryFilter__image {
    border-radius: 50%;
    min-height: 42px;
    object-fit: cover;
    width: 42px;
  }

  .p-categoryFilter__name {
    font-size: variable.$font-size7;
    font-weight: variable.$font-weight-semibold;
    margin-top: 8px;
    text-align: center;
    white-space: nowrap;
  }
}
