@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";
@use "../../Foundation/mixin/typography";

/*

# c-coupon2

- クーポンに関する情報を表示します。
- 使えない時、`aria-disabled="true"` を指定してください。
- 上部の ticket のみで、使用することも可能です。可能ですが、条件など書いたほうが良い場合が多いと思うので記載を推奨します。
- 複数のクーポンが並ぶ時、2つめのクーポンから margin が設定されます。
- c-coupon は app/styles/user/Object/Component/c-coupon.scss に記載されています。

*/

.c-coupon2 {
  --coupon2-ticket-color: #{color.$color-order1};
  --coupon2-ticket-body-color: #{color.$color-order2};

  overflow: hidden;
  width: 300px;

  background-color: color.$color-gray0;

  &[aria-disabled="true"] {
    --coupon2-ticket-color: #d07681;
    --coupon2-ticket-body-color: #dc808b;
  }

  & + .c-coupon2 {
    margin-top: 40px;
  }

  // FIXME： c-coupon に実装を合わせた。単体で使うときにセンター寄せにしている前提で作っくているところがあるので、変更したらなくせる。
  &.c-coupon2--marginAuto {
    margin-left: auto;
    margin-right: auto;
  }

  .c-coupon2__ticket {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: color.$color-gray0;

    background-color: var(--coupon2-ticket-color);
    padding: 4px;
  }

  .c-coupon2__ticketBody {
    width: 100%;
    position: relative;
    color: color.$color-gray0;

    background-image: url("/app/assets/images/c-coupon2__background.png");
    background-color: var(--coupon2-ticket-body-color);
    border-radius: 8px;
    padding: 3px;
  }

  .c-coupon2__ticketBody::before {
    content: "";
    position: absolute;
    top: calc(50% - 22.5px);
    left: -22.5px;

    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--coupon2-ticket-color);
  }

  .c-coupon2__ticketBody::after {
    content: "";
    position: absolute;
    top: calc(50% - 22.5px);
    right: -22.5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--coupon2-ticket-color);
  }

  .c-coupon2__ticketLimitLabels {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  }

  .c-coupon2__ticketLimitLabel {
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @include typography.caption-bold;
    color: color.$color-gray1;
    text-align: right;

    border-radius: 50px;
    background-color: $color-gray0;
  }

  .c-coupon2__ticketDiscount {
    text-align: center;
  }

  .c-coupon2__ticketDiscountPrice {
    color: color.$color-gray0;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: font.$font-weight-bold;
    line-height: 1;
  }

  .c-coupon2__ticketPriceUnit {
    color: color.$color-gray0;
    font-family: Arial, sans-serif;
    font-size: font.$font-size2;
    font-style: normal;
    font-weight: font.$font-weight-bold;
    text-align: center;
    line-height: 1.4;
  }

  .c-coupon2__ticketDueDateInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .c-coupon2__ticketDueDateLabel {
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @include typography.body-small-bold;
    color: color.$color-gray1;
    text-align: center;

    border-radius: 50px;
    background: color.$color-progress;

    // FIXME： c-coupon に実装を合わせた。React と ERB でどちらでも jsx / View だけで使えるようにするため。本当は、クーポンのdecoration として処理するようになるとここを削除できる
    &.c-coupon2__ticketDueDateLabel--0days::after {
      content: "\672C\65E5\9650\308A"; // 本日限り
    }

    &.c-coupon2__ticketDueDateLabel--1days::after {
      content: "\660E\65E5\307E\3067"; // 明日まで
    }

    &.c-coupon2__ticketDueDateLabel--2days::after {
      content: "\3042\3068\FF12\65E5"; // あと２日
    }

    &.c-coupon2__ticketDueDateLabel--3days::after {
      content: "\3042\3068\FF13\65E5"; // あと３日
    }
  }

  .c-coupon2__ticketDueDate {
    @include typography.body-small-bold;
    color: color.$color-primary0;
    text-align: center;
  }

  .c-coupon2__termsOfUse {
    color: color.$color-gray1;

    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid color.$color-gray4;
    border-left: 1px solid color.$color-gray4;
    border-bottom: 1px solid color.$color-gray4;

    padding: 8px;
  }

  .c-coupon2__termsOfUseTitle {
    @include typography.caption-bold;
  }

  .c-coupon2__termsOfUseItem {
    display: list-item;
    list-style: square;

    @include typography.caption;

    margin-left: 1em;
  }

  .c-coupon2__termsOfUseItemLink {
    text-decoration: underline;
  }

  .c-coupon2__collectionLink {
    text-align: right;
    font-size: font.$font-size7;
    margin-top: 4px;
  }

  .c-coupon2__collectionLinkTo {
    text-decoration: underline;
  }
}
