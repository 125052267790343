.badge {
  background-color: #c30d23;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  position: absolute;
  left: 55%;

  &-nocount {
    width: 8px;
    height: 8px;
  }
}
