.login {
  background-color: #fff;

  .text_mail {
    margin-bottom: 10px;
    color: #333;
    font-size: 13px;
    font-weight: bold;
  }

  .forget_pw {
    a {
      color: #1b1b1b;
      font-size: 12px;
      text-decoration: underline;
    }
  }

  /* forget_pw */

  .border_top {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #555;
  }

  /* border_top */

  .account-attention-head-message {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .account-head-message {
    display: block;
    font-size: 20px;
    font-weight: bold;
  }

  .account-attention-head-tiny-message {
    font-size: 12px;
    color: #1b1b1b;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  .account-box-container {
    border: 2px solid #f5f1eb;
    border-radius: 4px;
    padding-bottom: 20px;
    margin: 20px auto;
    text-align: center;
    @include screen("pc-only") {
      width: 500px;
    }

    // NOTE: 固定ヘッダ分調整する
    &.account-box-container--anchor {
      scroll-margin-top: 150px;
    }

    h3 {
      margin-bottom: 20px;
      background-color: #f7f6f4;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
    }

    .small-attention {
      font-size: 14px;
      margin-bottom: 20px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .attention {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .txt_or {
    display: flex;
    max-width: 250px;
    margin: 0 auto 12px;
    color: #555;
    justify-content: center;
    align-items: center;

    .line {
      width: 100%;
      height: 1px;
      background: #555;
    }

    /* line  */

    .txt {
      margin: 0 20px;
    }
  }

  /* txt_or */

  .btn-sns {
    margin: 0 auto 15px;
    background-repeat: no-repeat;
    border-radius: 4px;
    font-size: 14px;

    &.btn-line {
      color: #fff;
      background: {
        color: $color-line;
        image: url(" ../../app/assets/images/icon-sns-line.svg");
        position: 6px center;
        size: 35px;
      }
    }

    /* btn_line */

    &.btn-fb {
      color: #fff;
      background: {
        color: $color-facebook;
        image: url(" ../../app/assets/images/icon-sns-fb.svg");
        position: 6px center;
        size: 35px;
      }
    }

    /* btn_fb */

    &.btn-yahoo {
      color: #fff;
      background: {
        color: #fc0233;
        image: url(" ../../app/assets/images/icon-yahoo.png");
        position: 10px center;
        size: 25px;
      }
    }

    /* btn_yahoo */

    &.btn-mail {
      margin-bottom: 20px;
      color: #555;
      background: {
        color: #f7f4e2;
        image: url(" ../../app/assets/images/icon-mail2.png");
        position: 9px center;
        size: 25px;
      }
    }

    /* btn_mail */

    &.btn-apple {
      color: #fff;
      background: {
        color: #000000;
        image: url(" ../../app/assets/images/icon-apple.png");
        size: auto;
        position: -3px -4px;
      }
    }
  }

  /* btn_apple */
}

/* login */
