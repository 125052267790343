@use "../../Foundation/mixin/typography";

.p-selectSubscription {
  .p-selectSubscription__cancelContainer {
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    border-top: 1px solid $color-gray5;
    margin-top: 40px;
    padding-top: 40px;

    .p-selectSubscription__cancel--subAttention {
      @include typography.caption;
      margin-top: 8px;

      .p-selectSubscription__cancel--subAttentionLink {
        color: $color-primary1;
        text-decoration: underline;
      }
    }
  }
}
