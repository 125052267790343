.p-searchCategoryNavigation {
  .p-searchCategoryNavigationTitleLine {
    background: $color-gray6;
    padding: 4px 0 4px 8px;

    .p-searchCategoryNavigationTitleLine__title {
      font-size: $font-size4;
      font-weight: $font-weight-bold;
      line-height: 1.4;

      display: inline;
    }
    .p-searchCategoryNavigationTitleLine__updateDate {
      display: inline;
    }
  }

  .p-searchCategoryNavigation__title {
    font-size: $font-size4;
    font-weight: $font-weight-bold;
    line-height: 1.4;

    background: $color-gray6;
    padding: 4px 0 4px 8px;
  }

  .p-searchCategoryNavigation__body {
    margin-top: 12px;
  }

  & + .p-searchCategoryNavigation {
    margin-top: 20px;
  }

  .p-searchCategoryNavigation__more {
    margin-top: 12px;
  }
}
