.p-searchCategoryNavigations {
  margin-bottom: 24px;

  @include screen("tablet-and-pc") {
    margin-bottom: 48px;
  }

  .p-searchCategoryNavigations__backLink {
    margin-top: 40px;
  }
}
