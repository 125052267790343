.p-cautionList {
  margin: 50px 0 30px 0;
  font-size: 12px;
  color: $color-gray3;

  @include screen("smartphone") {
    margin: 30px 0;
    padding: 0 1rem;
  }

  .p-cautionList__title {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .p-cautionList__item {
    > li {
      list-style: disc;
      margin-left: 20px;
    }
  }
}
