.group-backToShowcase {
  margin: $base-margin-4 auto $base-margin-8;

  @include screen("tablet-and-pc") {
    width: 500px;
  }

  .group-backToShowcase__title {
    font-size: $font-size4;
    font-weight: $font-weight-bold;
    margin-bottom: $base-margin-2;
  }

  .group-backToShowcase__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 300px;
  }
}
