@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";

.c-productCardChange {
  display: flex;
  gap: 16px;
  padding: 16px 0;
  align-items: center;
  border-top: 1px solid color.$color-gray4;
  margin-top: 16px;

  .c-productCardChange__label {
    display: flex;
    gap: 4px;
    align-items: center;

    @include typography.label;
  }

  .c-productCardChange__icons {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
