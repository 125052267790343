@use "../../Foundation/variable";
@use "./search_filters/p-searchFiltersCard";
@use "./search_filters/p-categoryFilter";
@use "./search_filters/p-prefectureFilter";
@use "./search_filters/p-priceFilter";
@use "./search_filters/p-farmingFilter";
@use "./search_filters/p-goodsQuantityFilter";
@use "./search_filters/p-labelFilter";
@use "./search_filters/p-searchCategoryFilter";

.p-leftFilterNav {
  float: left;
  width: 22.5%;

  @include screen("smartphone") {
    display: none;
  }
}

.p-searchFilters {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include screen("smartphone") {
    display: none;
  }
}
