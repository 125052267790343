$image-on: "/app/assets/images/icon-star-on.svg";
$image-off: "/app/assets/images/icon-star-off.svg";
$image-half: "/app/assets/images/icon-star-half.svg";
$image-post: "/app/assets/images/icon-post.svg";

/**
 * @example
 * <!-- 単体 -->
 * <i class="c-rating c-rating-on"></i>
 * <i class="c-rating c-rating-off"></i>
 * <i class="c-rating c-rating-half"></i>
 *
 * <!-- 複数 -->
 * <i class="c-rating c-rating-0"></i>
 * <i class="c-rating c-rating-0.5"></i>
 * <i class="c-rating c-rating-4.5"></i>
 * <i class="c-rating c-rating-5"></i>
 */
.c-rating {
  --rating-size: 16px;

  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: var(--rating-size);
  width: var(--rating-size);

  &-on {
    background-image: url($image-on);
  }

  &-off {
    background-image: url($image-off);
  }

  &-half {
    background-image: url($image-half);
  }

  &-0,
  &-0\.5,
  &-1,
  &-1\.5,
  &-2,
  &-2\.5,
  &-3,
  &-3\.5,
  &-4,
  &-4\.5,
  &-5 {
    --rating-gap: calc(var(--rating-size) * 0.25);
    --rating-size-and-gap: calc(var(--rating-size) + var(--rating-gap));

    width: calc(var(--rating-size-and-gap) * 5);

    /* prettier-ignore */
    background-position-x:
      0,
      calc(var(--rating-size-and-gap) * 1),
      calc(var(--rating-size-and-gap) * 2),
      calc(var(--rating-size-and-gap) * 3),
      calc(var(--rating-size-and-gap) * 4);
  }

  &-0 {
    background-image: url($image-off), url($image-off), url($image-off), url($image-off), url($image-off);
  }

  &-0\.5 {
    background-image: url($image-half), url($image-off), url($image-off), url($image-off), url($image-off);
  }

  &-1 {
    background-image: url($image-on), url($image-off), url($image-off), url($image-off), url($image-off);
  }

  &-1\.5 {
    background-image: url($image-on), url($image-half), url($image-off), url($image-off), url($image-off);
  }

  &-2 {
    background-image: url($image-on), url($image-on), url($image-off), url($image-off), url($image-off);
  }

  &-2\.5 {
    background-image: url($image-on), url($image-on), url($image-half), url($image-off), url($image-off);
  }

  &-3 {
    background-image: url($image-on), url($image-on), url($image-on), url($image-off), url($image-off);
  }

  &-3\.5 {
    background-image: url($image-on), url($image-on), url($image-on), url($image-half), url($image-off);
  }

  &-4 {
    background-image: url($image-on), url($image-on), url($image-on), url($image-on), url($image-off);
  }

  &-4\.5 {
    background-image: url($image-on), url($image-on), url($image-on), url($image-on), url($image-half);
  }

  &-5 {
    background-image: url($image-on), url($image-on), url($image-on), url($image-on), url($image-on);
  }

  &-post {
    background-image: url($image-post);
  }
}

/**
 * @example
 * <div class="c-ratingInput">
 *   <button class="c-ratingInput_button">
 *     <i class="c-ratingInput_icon"></i>
 *     <span>Bad</span>
 *   </button>
 *   <button class="c-ratingInput_button">
 *     <i class="c-ratingInput_icon is-checked"></i>
 *     <span>Good</span>
 *   </button>
 * </div>
 */
.c-ratingInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $base-margin;

  &_button {
    background: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: $base-margin-2;
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
    padding: 0;
    min-width: 60px;
  }

  &_icon {
    background: url($image-off) no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 40px;
    height: 40px;

    &.is-checked {
      background-image: url($image-on);
    }
  }
}
