@use "../../Foundation/mixin/typography";

.p-cvsPayment {
  margin-top: 16px;
  text-align: left;

  .p-cvsPayment__content {
    border: solid 1px $color-gray5;
    background: $color-gray0;
    max-width: 450px;
    margin: 28px auto 0;
  }

  .p-cvsPayment__title {
    @include typography.subTitle-small;
    background: $color-gray5;
    padding: 6px;
  }

  .p-cvsPayment__detail {
    @include typography.caption;
    padding: 16px;

    dt {
      @include typography.subTitle-small;
    }

    dd {
      @include typography.body-small;
      margin-bottom: 8px;
    }
  }
}
