@use "../../Foundation/mixin/typography";

.product_info {
  display: grid;
  grid-template-columns: 64px 1fr;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .product_thumb {
    display: block;
    width: 64px;
    height: 64px;
    background: {
      image: url(" ../../app/assets/images/product_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .selectFruitsThumb {
    display: block;
    width: 64px;
    height: 64px;
    background: {
      image: url(" ../../app/assets/images/select_fruits_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .selectGochisoThumb {
    display: block;
    width: 64px;
    height: 64px;
    background: {
      image: url(" ../../app/assets/images/select_gochiso_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .selectMealsThumb {
    display: block;
    width: 64px;
    height: 64px;
    background: {
      image: url(" ../../app/assets/images/select_meals_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .giftCardProductThumb {
    display: block;
    width: 64px;
    height: 64px;
    background: {
      image: url(" ../../app/assets/images/gift_card_product_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .detail {
    // pではなくdivで作るべき場所だけど、数が多くて直せないため一旦cssを上書きして対応しています
    p + p {
      margin-top: 0;
    }

    .buy_date {
      @include typography.caption;
      color: $color-gray2;
    }

    .name {
      @include typography.subTitle;
      margin-bottom: 8px;

      .fixed_term_subscription_name {
        @include typography.label;
      }

      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    .quantity {
      @include typography.body-small;
    }

    .price {
      @include typography.body-small;
    }

    .farm_name {
      @include typography.body-small;
    }
  }
}
