.p-activities {
  background-color: $color-gray5;
  padding-top: 0;
  position: relative;
  min-height: 500px;

  @media screen and (min-width: 769px) {
    padding-bottom: 80px;
  }

  .p-activities_container {
    max-width: 700px;
    margin: 0 auto;
  }

  .p-activities_title {
    max-width: 700px;
    margin: 0 auto 1px;
    background-color: #fff;
    padding: 30px 1rem 20px;

    @media screen and (min-width: 769px) {
      padding: 40px 1rem 20px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  .p-activities__header {
    background-color: $color-gray0;
    max-width: 700px;
    margin: 0 auto;
    border-bottom: 1px solid $color-gray4;
    position: sticky;
    top: 62px;
    z-index: 1;

    @include screen("smartphone") {
      top: 98px;
      grid-template-rows: 1fr;
    }
  }

  .p-activities__headerLink {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    padding: $base-padding-2 $base-padding-4;
  }

  .p-activities__headerImage {
    grid-row: 1/3;
    grid-column-start: 1;
    border-radius: 50%;
    overflow: hidden;
    margin-right: $base-margin-3;

    > img {
      width: 68px;
      height: 68px;

      @include screen("smartphone") {
        width: 40px;
        height: 40px;
      }
    }
  }

  .p-activities__headerProducerAddress {
    grid-row-start: 1;
    grid-column-start: 2;
    align-self: end;
    font-size: $font-size5;
    color: $color-gray2;

    @include screen("smartphone") {
      display: none;
    }
  }

  .p-activities__headerProducerName {
    grid-row-start: 2;
    grid-column-start: 2;
    align-self: start;
    font-size: $font-size2;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen("smartphone") {
      grid-row: 1/3;
      align-self: center;
      font-size: $font-size5;
    }
  }

  .p-activities_timeline {
    padding: 20px 0;
    background: $color-gray0;

    @include screen("smartphone") {
      background: $color-gray6;
    }

    > li {
      position: relative;
      padding: 0 16px 34px 16px;
    }

    > li:last-child {
      &::before {
        background: none;
      }
    }
  }
}

.p-activities__dateText {
  font-size: $font-size7;
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: center;
}

.p-activities__time {
  font-size: $font-size7;
  display: flex;
  justify-content: flex-end;
  color: $color-gray2;
  margin-bottom: $base-margin-2;

  .p-activities__timeText {
    margin-top: 0;
  }
}

.p-activities__orderContainer {
  display: flex;
  padding-top: $base-padding-3;
}

.p-activities__orderInformation {
  width: 100%;
  flex: 1;
  min-width: 0;
}

.p-activities__orderUserImageContainer {
  flex-basis: min-content;
  padding-left: $base-padding-2;
}

.p-activities__orderUserImage {
  border-radius: 50%;
  overflow: hidden;

  > img {
    display: block;
    width: 40px;
    height: 40px;
  }
}

.p-activities__orderStatus {
  margin-bottom: $base-margin;
}

.p-activities__acceptStatus,
.p-activities__shipStatus {
  margin-bottom: $base-margin-2;
}

.p-activities__orderContent {
  overflow: hidden;
  margin-bottom: $base-margin;

  @include screen("tablet-and-pc") {
    width: fit-content;
    margin-left: auto;
  }
}

.p-activities__orderProduct {
  border: 1px solid $color-gray4;
  border-radius: 4px;
  background: $color-gray0;
  overflow: hidden;
}

.p-activities__orderProductImage {
  @include screen("smartphone") {
    // iOS Safari 14 では aspect-ratio が使えないため、padding-top で代用
    // 本当は aspect-ratio: 4/3; が使いたい

    padding-top: (calc(3 / 4) * 100%);
    position: relative;
  }

  img {
    object-fit: cover;
    width: 320px;
    height: 240px;

    @include screen("smartphone") {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    }
  }
}

.p-activities__orderProductName {
  font-size: $font-size5;
  font-weight: $font-weight-bold;
  padding: $base-padding-4;
  width: 100%;

  @include screen("tablet-and-pc") {
    max-width: 320px;
  }
}

.p-activities__acceptContainer,
.p-activities__shipContainer {
  display: flex;
  padding-top: $base-padding-3;
}

.p-activities__acceptProducerImageContainer,
.p-activities__shipProducerImageContainer {
  flex-basis: min-content;
  padding-right: $base-padding-2;
}

.p-activities__acceptInformation,
.p-activities__shipInformation {
  flex-basis: calc(100% - 96px); // 生産者・ユーザーのアイコン分の幅を除く;
  min-width: 0;

  @include screen("smartphone") {
    flex-basis: 92%;
  }
}

.p-activities__acceptProducerImage,
.p-activities__shipProducerImage {
  border-radius: 50%;
  overflow: hidden;

  > img {
    display: block;
    width: 40px;
    height: 40px;
  }
}

.p-activities__acceptMessage,
.p-activities__shipMessage {
  font-size: $font-size5;
  padding: $base-padding-3 $base-padding-4 $base-padding-4 $base-padding-4;
  border-radius: 0px 8px 8px 8px;
  background: $color-primary4;
  margin-bottom: $base-margin;
  line-height: 1.6;
}

.p-activities__productContainer {
  background: $color-gray0;
  display: flex;
  position: relative;
  border: 1px solid $color-gray4;
  border-radius: 4px;
  padding: $base-padding-2;
  margin-bottom: $base-margin;

  &::before {
    position: absolute;
    top: calc(50% - 4px);
    right: 12px;
    @include arrow($color-gray1, 1px, $arrowDirectionRight);
  }
}

.p-activities__productImage {
  > img {
    min-width: 48px;
    width: 48px;
    height: 48px;
  }
}

.p-activities__productName {
  font-size: $font-size5;
  font-weight: $font-weight-bold;
  padding: $base-padding $base-padding-3;
  width: 90%;
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include screen("smartphone") {
    width: 80%;
  }
}

.p-activities__postRequest {
  padding: $base-padding-4;
  border-radius: 0px 8px 8px 8px;
  background: $color-gray0;
  margin-bottom: $base-margin;

  @include screen("tablet-and-pc") {
    border: 1px solid $color-gray4;
  }
}

.p-activities__postRequestMessage {
  font-size: $font-size7;
  margin-bottom: $base-margin-2;
}

.p-activities__postRequestButtonContainer {
  width: 100%;

  @include screen("tablet-and-pc") {
    display: flex;
    justify-content: center;
  }

  .p-activities__postRequestButton {
    font-size: $font-size5;
    font-weight: $font-weight-bold;
  }
}

.p-activities__reactionButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.p-activities__reactionButton {
  background: none;
  color: $color-gray2;
  display: inline-flex;
  align-items: center;
  padding: 0;

  svg.is-active {
    fill: $color-order2;
  }
}

.p-activities__reactionButtonText {
  margin-left: $base-margin;
}
