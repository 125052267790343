@use "../../Foundation/variable";
@use "../../Foundation/mixin";

.p-producerPostImages {
  &.producerPostImages--block {
    padding: 48px;
    margin-bottom: 48px;

    @include mixin.screen("smartphone") {
      padding: 24px;
      margin-bottom: 8px;
    }
  }

  .p-producerPostImages__title {
    margin-bottom: 24px;
    text-align: center;
    @include mixin.title-1;
  }

  .p-producerPostImages__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    margin-bottom: 24px;

    @include mixin.screen("smartphone") {
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
      margin-bottom: 16px;
    }
  }

  .p-producerPostImages__card {
    position: relative;
  }

  .p-producerPostImages__image {
    border-radius: 4px;
    width: 100%;
    height: auto;
  }

  .p-producerPostImages__good {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background: variable.$color-gray0;
    padding: 2px 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  .p-producerPostImages__goodCount {
    font-size: variable.$font-size9;
    font-weight: variable.$font-weight-semibold;
    color: variable.$color-primary2;
  }
}
