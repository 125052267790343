@use "sass:math";

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:
    "Hiragino Kaku Gothic Pro",
    "ヒラギノ角ゴ Pro W3",
    Meiryo,
    メイリオ,
    Osaka,
    "MS PGothic",
    arial,
    helvetica,
    Lucida Grande,
    sans-serif;
  color: #212121;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-size: 14px;
}

a,
a:hover,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: initial;
}

// display
.is-flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }
}

/* ========================================================== */
/* columns  Grid system*/
/* ========================================================== */

.columns {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  max-width: 1240px;
  margin: 0 auto;

  .column {
    width: 100%;
    $columns: 12;
    @include screen("pc-only") {
      @for $i from 1 through $columns {
        &.is-#{$i} {
          width: percentage(math.div($i, $columns));
        }
      }
    }
    @include screen("tablet-and-pc") {
      @for $j from 1 through $columns {
        &.is-tablet-#{$j} {
          width: percentage(math.div($j, $columns));
        }
      }
    }
  }
} /* container */

.rank-list {
  overflow: hidden;
  justify-content: center;
  li {
    float: left;
    width: 18px;
    margin-right: 2px;

    img {
      width: 100%;
      height: auto;
    }
  }
} /* rank-list */

.upload-image {
  position: relative;
  width: 70px;
  margin-bottom: 30px;

  input[type="file"] {
    display: none;
  }

  .img-area {
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    background: {
      color: #646464;
      image: url("/app/assets/images/icon-camera.png");
      repeat: no-repeat;
      position: center center;
      size: 90%;
    }

    &:hover {
      opacity: 0.8;
    }
  } /* img-area */
} /* upload-image */

/* ========================================================== */
/* container */
/* ========================================================== */
.container {
  margin: 0 auto;
  @include screen("smartphone") {
    padding: 0 1rem;
  }
} /* container */

/* ========================================================== */
/* layout */
/* ========================================================== */
.main-content {
  clear: both;
  position: relative;
  padding-top: 28px;

  .index & {
    @include screen("smartphone") {
      background: $color-primary4;
      padding-top: 0;
    }
  }

  .product &,
  .producer &,
  .recipe &,
  .feature_article &,
  .showcases & {
    @include screen("smartphone") {
      padding-top: 0;
    }
  }

  .reels & {
    padding-top: 0;
    @include screen("pc-only") {
      padding-top: 28px;
    }
  }
} /* main-content */

.inner-main-content {
  overflow: hidden;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  @include screen("smartphone") {
    padding: 0px;
  }

  .left-content {
    float: left;
    overflow: hidden;
    width: 22.5%;
  }

  .right-content {
    float: right;
    width: 77.5%;
    padding-left: 3%;
    @include screen("smartphone") {
      width: 100%;
      padding: 0;

      > .container {
        padding-top: 16px;
      }
    }
  } /* right-content */
} /* inner-main-content */

/* ========================================================== */
/* search count */
/* ========================================================== */
.search-count {
  text-align: right;
  font-size: 12px;
  margin-bottom: 8px;
} /* search-count */

// ----
// Common Forms
// ----
.notice-message {
  background-color: $color-gray5;
  padding: 20px;
  margin: 10px 0;

  .is-important {
    color: #ea352d;
    font-size: 14px;
    font-weight: bold;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
  }

  .product-description {
    margin-bottom: 0;
  }
}

#snackbar {
  //width: 100%;
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  //margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 20px 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 9999999; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  transform: translate(-50%, -50%);
  white-space: pre;
  .delete {
    width: 20px;
    height: 20px;
    background: inherit;
    position: absolute;
    right: 2px;
    top: 2px;

    &::before {
      background-color: #fff;
      content: "";
      height: 2px;
      width: 50%;
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }

    &::after {
      background-color: #fff;
      content: "";
      height: 50%;
      width: 2px;
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }
  }
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */

  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* shield */
.full-size-field {
  overflow: scroll;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}
