@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.c-titleProgressCircle {
  --_stroke-width: 6px;
  --_circle-width: 54px;
  --_circle-height: 54px;
  --_percent: 75;
  --_diameter: calc(var(--_circle-width) - var(--_stroke-width)); // 直径
  --_circumference: calc(var(--_diameter) * 3.14); // 円周
  --_progress-bar-stroke-color: #{color.$color-gray4};
  --_progress-item-stroke-color: #bd8c5e;
  --_threshold-border-color: #{color.$color-gray4};
  --_color: #{color.$color-gray1};
  --_count-font-size: 14px;

  @for $i from 1 through 100 {
    &:has(.c-titleProgressCircle__circle[aria-valuenow="#{$i}"]) {
      --_percent: #{$i};
    }
  }

  &:has(.c-titleProgressCircle__circle[aria-valuenow="100"]) {
    --_percent: 100;
    --_count-font-size: 12px;
    --_color: #bd8c5e;
    --_threshold-border-color: #bd8c5e;
  }

  &:has(.c-titleProgressCircle__circle[aria-valuenow="0"]) {
    --_percent: 0;
    opacity: 0.2; // MEMO : 0% の時に opacity を下げる方法で良いか？は検討が必要 => disable ではないので、opacity にする
  }

  &.c-titleProgressCircle--silver {
    --_progress-item-stroke-color: #{color.$color-gray3};

    &:has(.c-titleProgressCircle__circle[aria-valuenow="100"]) {
      --_color: #{color.$color-gray3};
      --_threshold-border-color: #{color.$color-gray3};
    }
  }

  &.c-titleProgressCircle--gold {
    --_progress-item-stroke-color: #{color.$color-primary2};

    &:has(.c-titleProgressCircle__circle[aria-valuenow="100"]) {
      --_color: #{color.$color-primary2};
      --_threshold-border-color: #{color.$color-primary2};
    }
  }

  & {
    width: var(--_circle-width);
    height: var(--_circle-height);
    display: grid;
    place-items: center;
  }

  .c-titleProgressCircle__circle {
    grid-area: 1/1;
    transform: rotate(-90deg);

    width: var(--_circle-width);
    height: var(--_circle-height);
  }

  .c-titleProgressCircle__progress {
    fill: none;
    stroke-width: var(--_stroke-width);

    &.c-titleProgressCircle__progress--bar {
      stroke: var(--_progress-bar-stroke-color);
    }

    &.c-titleProgressCircle__progress--item {
      stroke: var(--_progress-item-stroke-color);
      stroke-dasharray: var(--_circumference);
      stroke-dashoffset: calc(var(--_circumference) - (var(--_circumference) * var(--_percent)) / 100);
      stroke-linecap: round;

      animation: draw 0.25s ease 1;
    }
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: var(--_circumference);
    }
    100% {
      stroke-dashoffset: calc(var(--_circumference) - (var(--_circumference) * var(--_percent)) / 100);
    }
  }

  .c-titleProgressCircle__info {
    min-width: 32px;
    grid-area: 1/1;

    color: var(--_color);
    font-family: Arial, sans-serif;
    text-align: center;
  }

  /* stylelint-disable declaration-property-value-allowed-list */
  .c-titleProgressCircle__count {
    font-size: var(--_count-font-size);
    font-weight: font.$font-weight-bold;
    line-height: 1;
  }

  /* stylelint-enable declaration-property-value-allowed-list */

  .c-titleProgressCircle__threshold {
    font-size: font.$font-size5;
    font-weight: font.$font-weight-normal;
    line-height: 1.2;

    border-top: 1px solid var(--_threshold-border-color);
  }
}
