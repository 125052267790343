@use "../Foundation/mixin/markdown";
@use "../Foundation/mixin/typography";

// 過去の特集記事の見た目を保持するため、古いボタンcssを読み込んでいます
@import "../Object/Component/c-btn--old";

.articleMarkdown {
  @include markdown.markdown;
}

.feature-article {
  max-width: 894px;
  margin: auto;

  .article__eyecatch {
    margin-bottom: 32px;

    > img {
      width: 100%;
      height: auto;
    }
  }

  .article__title {
    margin-bottom: 16px;
    @include typography.title-1;
  }

  .article__releaseDate {
    text-align: right;
    color: $color-gray2;
    margin-bottom: 20px;
    @include typography.caption;
  }

  .article__leadContent,
  .article__productList,
  .article__footContent {
    padding-bottom: 48px;
  }

  .p-articleProduct {
    .p-articleProduct__title {
      padding: 20px 0 8px;
      border-bottom: solid 2px $color-gray1;
      margin-bottom: 20px;
      @include typography.subTitle;
    }

    .p-articleProduct__inner {
      margin-bottom: 16px;

      @include screen("tablet-and-pc") {
        display: flex;
      }
    }

    .p-articleProduct__img {
      @include screen("tablet-and-pc") {
        width: 70%;
      }

      > img {
        width: 100%;
      }
    }

    .p-articleProduct__detail {
      background: $color-primary4;
      padding: 14px 12px;
      @include screen("tablet-and-pc") {
        width: 30%;
        padding: 28px 16px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .p-articleProduct__price {
      @include typography.price;
      text-align: right;
      margin-top: 16px;
    }

    .p-articleProduct__producer {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .p-articleProduct__producerImg {
      display: flex;

      > img {
        border-radius: 50%;
        object-fit: cover;
        width: 48px;
        height: 48px;
      }
    }

    .p-articleProduct__btn {
      margin: 32px auto 48px;
    }
  }
}
