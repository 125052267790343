@use "../../Foundation/mixin/typography";

.c-title_wrap {
  margin-bottom: 1rem;
  line-height: 1.2;
  text-align: center;
  @include screen("smartphone") {
    text-align: left;
  }
}

.c-title {
  align-items: center;
  display: flex;
  justify-content: center;

  @include typography.title-1;
  @include screen("smartphone") {
    justify-content: flex-start;
  }

  .c-title_label {
    @include typography.label-bold;
    background: $color-primary2;
    border-radius: 20px;
    color: $color-gray0;
    padding: 8px 12px;
    margin-right: 8px;
  }
}

.c-subTitle {
  @include typography.subTitle-small;
  color: $color-primary1;
}
