@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-averageRatings {
  display: flex;
  gap: 12px;
  background: color.$color-gray0;
  flex-direction: column;

  .p-averageRatings__ratings {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
