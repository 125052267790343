@use "../../Foundation/mixin/typography";

.p-moreMenu {
  &[aria-expanded="true"] {
    --visibility: initial;
  }

  &[aria-expanded="false"] {
    --visibility: hidden;
  }

  .p-moreMenu__btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0;
  }

  .p-moreMenu__balloon {
    position: absolute;
    background: $color-gray0;
    padding: 12px;
    top: 40px;
    right: -5px;
    width: max-content;
    border-radius: 4px;
    display: grid;
    gap: 8px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
    visibility: var(--visibility);

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid $color-gray0;
      border-top: 0;
    }

    .c-btn {
      transition: unset;
    }
  }
}
