* {
  box-sizing: border-box;
}

button,
label,
input[type="submit"] {
  cursor: pointer;
}
input,
button,
select {
  outline: 0;
  border: none;
  appearance: none;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
input:-ms-input-placeholder {
  color: #ccc;
}
input::-moz-placeholder {
  color: #ccc;
}
img {
  vertical-align: top;
}
figure {
  margin: 0;
}
select::-ms-expand {
  display: none;
}
