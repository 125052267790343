@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/line-clamp";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";

.c-producerCard {
  display: block;
  transition: 300ms;
  width: 100%;
  height: 100%;
  letter-spacing: 0;
  background: color.$color-gray0;
  border-radius: 4px;
  overflow: hidden;

  .c-producerCard__photo {
    overflow: hidden;
    position: relative;
  }

  .c-producerCard__image {
    width: 100%;
    height: auto;
  }

  .c-producerCard__infoBlock {
    display: grid;
    gap: 8px;

    padding: 12px;
  }

  .c-producerCard__area {
    @include typography.caption;
    @include line-clamp.line-clamp(1);
  }

  .c-producerCard__name {
    margin-bottom: 0; // MEMO: h4 全てにmargin-bottom: 20px; が適用されているための対策
    @include typography.subTitle-small;
    @include line-clamp.line-clamp(2);
  }

  .c-producerCard__description {
    @include typography.caption;
    @include line-clamp.line-clamp(4);
  }

  .c-producerCard__followIcon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(70, 70, 70, 0.9);
  }
}
