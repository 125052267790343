.l-headerMenu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 100vh;
  z-index: $zIndex-headerMenu;
  background: $color-gray5;

  @include screen("tablet-and-pc") {
    position: absolute;
    border: 1px solid $color-gray4;
    border-radius: 16px;
    top: 56px;
    right: -22px;
    bottom: unset;
    width: max-content;
    height: unset;
    background-color: $color-gray0;
  }

  .l-headerMenu__inner {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 100vh;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "user"
      "product"
      "other"
      "help"
      "producer";

    @include screen("tablet-and-pc") {
      padding: 32px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 50px 1fr;
      row-gap: 16px;
      grid-template-areas:
        "user user user user"
        "product other help producer";
    }
  }

  .l-headerMenu__closeBtn {
    position: absolute;
    top: 11px;
    right: -1px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-weight: $font-weight-light;
    font-size: $font-size9;
    color: $color-gray1;

    @include screen("tablet-and-pc") {
      display: none;
    }
  }

  .l-headerMenu__title {
    padding: 8px 14px;
    font-weight: $font-weight-light;
    font-size: $font-size7;
    color: $color-gray2;
    background-color: $color-gray5;

    @include screen("tablet-and-pc") {
      font-weight: $font-weight-bold;
      background-color: $color-gray6;
    }
  }

  .l-headerMenuUser {
    grid-area: user;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-gray0;

    @include screen("tablet-and-pc") {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .l-headerMenu__title {
        display: none;
      }
    }
  }

  .l-headerMenuUser__container {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    font-weight: $font-weight-light;
    font-size: $font-size7;
  }

  .l-headerMenuUser__thumb {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .l-headerMenuUser__name {
    padding: 0 7px;
    font-weight: $font-weight-semibold;
    font-size: $font-size7;
  }

  .l-headerMenuUser__btnList {
    display: flex;
    flex-direction: row;
    gap: 12px;

    @include screen("tablet-and-pc") {
      .l-headerMenu__title {
        display: none;
      }
    }
  }

  .l-headerMenuUser__btn {
    height: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-weight: $font-weight-light;
    font-size: $font-size9;
    color: $color-gray1;

    img {
      width: 28px;
    }

    @include screen("tablet-and-pc") {
      height: unset;
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      background-color: $color-gray5;
      width: fit-content;
      padding: 12px 12px 8px 12px;
      font-weight: $font-weight-semibold;
      font-size: $font-size7;

      img {
        width: 24px;
      }
    }
  }

  .l-headerMenuUser__guestBtnList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0 12px 12px 12px;
    background-color: $color-gray0;

    @include screen("tablet-and-pc") {
      flex-direction: row;
      padding: 0;

      .c-btn {
        width: 146px;
        height: 42px;
      }
    }
  }

  .l-headerMenuLink {
    @include screen("tablet-and-pc") {
      padding: 0 8px;
      border-right: 1px solid #f2f2f2;

      &:last-child {
        border: 0px;
        padding-right: 0px;
      }
    }

    .l-headerMenu__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: $color-gray0;

      @include screen("tablet-and-pc") {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        align-items: start;
        padding-top: 8px;
      }
    }
  }

  .l-headerMenuLink--product {
    grid-area: product;
  }

  .l-headerMenuLink--other {
    grid-area: other;
  }

  .l-headerMenuLink--help {
    grid-area: help;
  }

  .l-headerMenuLink--producer {
    grid-area: producer;
  }

  .l-headerMenu__link {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px;
    width: 100%;
    background-color: $color-gray0;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: $color-gray5;
    font-weight: $font-weight-semibold;
    font-size: $font-size6;
    color: $color-gray1;

    &::after {
      position: absolute;
      top: calc(50% - 4px);
      right: 15px;
      @include arrow($color-gray3, 1px, $arrowDirectionRight);
    }

    @include screen("tablet-and-pc") {
      border: 0;
      &::after {
        display: none;
      }
    }
  }

  .l-headerMenu__wideItem {
    grid-column: 1 / -1;
  }

  .l-headerMenu__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    @include screen("tablet-and-pc") {
      background: rgba(0, 0, 0, 0);
    }
  }
}
