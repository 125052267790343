@use "../Foundation/mixin/typography";

.slim-wrap {
  min-height: 500px;
  padding-bottom: 60px;
  background: #f4f4f4;
  @include screen("smartphone") {
    min-height: initial;
    padding: 0;
  }

  .container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 3rem 2rem 4rem;
    background: $color-gray0;
    @include screen("smartphone") {
      padding: 2rem 1rem 3rem;
    }

    h2 {
      text-align: center;
      border-bottom: 1px solid $color-gray5;
      padding-bottom: 20px;
      margin-bottom: 30px;
      @include typography.title-1;
    }

    .txt_desc {
      @include typography.subTitle;
    }
  }
}
