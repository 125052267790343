/*
# c-formInputText

## 検討事項
- `.c-formInputText--short` クレジットカード以外で未使用。他でも利用することがあれば調整が必要です。
*/

.c-formInputText {
  width: 100%;

  color: $color-gray1;
  font-size: $font-size-base;

  background: $color-gray0;
  border: 1px solid #e9e9e9;
  border-radius: 4px;

  padding: 10px;

  &:focus {
    border: 1px solid $color-primary2;
  }

  &[aria-invalid="true"] {
    border: 1px solid #ff0000;
    background: rgba(255, 0, 0, 0.05);
  }

  &:disabled {
    color: $color-gray3;
    background: $color-gray5;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  &.c-formInputText--short {
    width: 50px;
  }

  &.c-formInputText--year {
    width: 80px;
  }

  &.c-formInputText--month {
    width: 65px;
  }

  &.c-formInputText--day {
    width: 65px;
  }
}
