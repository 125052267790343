.c-sideSearchCategoryList {
  border: 1px solid $color-gray5;
  border-radius: 4px;

  .c-sideSearchCategoryList__title {
    background: $color-primary4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: $color-gray1;
    font-size: $font-size5;
    font-weight: $font-weight-semibold;
  }

  .c-sideSearchCategoryList__inner {
    background: $color-gray5;
  }

  .c-sideSearchCategoryList__unordered {
    display: grid;
    gap: 1px;
  }

  .c-sideSearchCategoryList__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: $color-gray0;

    &::after {
      content: "";
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
      border-top: 1px solid $color-gray3;
      border-right: 1px solid $color-gray3;
    }
  }
}
