@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;800&display=swap");

/* 画面幅に応じて px サイズを可変させる（SP サイズにのみ使用） */
$vwpx-base: 0.2667vw; // 画面幅 375px における 1px あたりの vw サイズ
$vwpx: 0.0714rem; //  画面幅 375px における 1px あたりの rem（1rem / 14）
$gutter: $vwpx * 15;
$max-width: 980px;
$font-bold: 800;
$font-xlarge: $vwpx * 20;
$font-large: $vwpx * 18;
$font-larger: $vwpx * 16;
$font-normal-size: $vwpx * 14;
$font-smaller: $vwpx * 12;
$font-small: $vwpx * 10;
$font-smallest: $vwpx * 8;
$bgColor: #faf8f0;

@mixin ttlUnderline($color, $width, $height, $margin) {
  content: "";
  display: block;
  width: $width;
  height: $height;
  background-color: $color;
  margin: $margin auto 0;
}
@mixin counter($name, $start: 0, $increment: 1) {
  .u-counter {
    &-#{$name} {
      counter-reset: #{$name} $start;
    }
    &_increment {
      &-#{$name} {
        counter-increment: #{$name} $increment;
      }
    }
    &_content {
      &::before {
        content: counter(#{$name});
      }
      &-withDot {
        &::before {
          content: counter(#{$name}) ". ";
        }
      }
    }
  }
}

html {
  font-size: $vwpx-base * 14;
  @include min-breakpoint($dimen-sp-h) {
    font-size: 14px;
  }
}

.about {
  /* 一般的なクラス名を使っているため、念のためページ名でスコープ */
  @include counter("recommend");
  @import "../../lp/product-card";
  &.l- {
    &main {
      margin: auto;
      padding-top: $vwpx * 50;
    }
  }
  &.p- {
    &main {
      color: #000;
      font-family: serif, "Noto Serif JP";
    }
  }
  .p- {
    &main {
      &_header {
        max-width: $max-width;
        margin: auto;
        text-align: center;
      }
      &_ttl {
        font-size: $vwpx * 18;
        text-align: center;
        letter-spacing: $vwpx;
        line-height: 1.7;
        margin-bottom: $vwpx * 45;
        @include screen("tablet-and-pc") {
          font-size: 28px;
          letter-spacing: 2px;
          margin-bottom: 55px;
        }
      }
      &_container {
        padding: 0 $gutter;
      }
    }
    &section {
      max-width: $max-width;
      margin: auto;
      padding: #{$gutter * 2} 0;
      &_ttl {
        margin-bottom: $gutter * 2;
        font-size: $font-xlarge;
        @include screen("tablet-and-pc") {
          font-size: $vwpx * 26;
        }
      }
      &_kv {
        width: 100%;
        max-width: $max-width;
      }
      &_kvImg {
        margin-bottom: $gutter;
      }
      &_container {
        max-width: $max-width;
        margin: auto;
        padding: 0 $gutter;
        > img {
          width: 100%;
        }
        @include screen("tablet-and-pc") {
          padding: 0;
        }
      }
      &_ref {
        display: inline-block;
        font-size: $font-small;
        line-height: 1.5;
      }
      &-bgColor {
        margin-bottom: $gutter * 2;
        background-color: $bgColor;
        @include screen("tablet-and-pc") {
          padding-left: $gutter;
          padding-right: $gutter;
        }
      }
      &-full {
        max-width: none;
      }
      &-qa {
        background-color: #f1f1f1;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        @include screen("tablet-and-pc") {
          margin-bottom: $gutter * 2;
        }
      }
    }
    &sectionSub {
      max-width: 650px;
      margin: auto;
      text-align: left;
      &:not(:last-of-type) {
        margin-bottom: #{$gutter * 2};
        @include screen("tablet-and-pc") {
          margin-bottom: #{$gutter * 3};
        }
      }
      &_ttl {
        margin-bottom: $gutter * 2;
        font-size: $font-large;
        @include screen("tablet-and-pc") {
          font-size: $vwpx * 22;
        }
      }
      &_container {
        text-align: left;
        > p:not(:last-of-type) {
          margin: 0 0 1em;
        }
        &-award {
          font-size: $vwpx * 16;
        }
      }
      &_lead {
        font-weight: $font-bold;
      }
      &-award {
        text-align: center;
        @include screen("tablet-and-pc") {
          max-width: none;
        }
      }
    }
    &sectionImg {
      padding-top: $gutter * 2;
    }
    &no1 {
      position: relative;
      overflow: hidden;
      max-width: 600px;
      margin: 0 auto $gutter;
      padding-bottom: $gutter * 2;
      &_list {
        > li {
          > img {
            width: 100%;
          }
        }
      }
      @include screen("tablet-and-pc") {
        max-width: none;
        margin: 0;
        z-index: 0;
        &_list {
          z-index: 0;
          display: flex;
          flex-wrap: wrap;
          transition-property: none;
          box-sizing: border-box;
          > li {
            max-width: calc(100% / 3);
            &:not(:nth-last-of-type(-n + 3)) {
              margin-bottom: #{$gutter * 2};
            }
          }
        }
        &_pagination {
          display: none;
        }
      }
    }
    &mediaList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > li {
        flex: 0 0 100%;
        &:not(:last-of-type) {
          margin-bottom: $gutter;
        }
        @include screen("tablet-and-pc") {
          flex: 0 0 calc(50% - #{$gutter});
        }
      }
    }
  }
  .p-section__youtube {
    display: flex;
    justify-content: center;
    margin: 30px 0 0;

    @include screen("smartphone") {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
    }
  }
  .p-section__youtubeIframe {
    @include screen("smartphone") {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .c- {
    &ttl {
      font-weight: $font-bold;
      &-underline {
        text-align: center;
        position: relative;
        &:after {
          @include ttlUnderline($gold300, $vwpx * 22, $vwpx * 2, $vwpx * 8);
        }
      }
      &-underlinePlain {
        text-align: center;
        position: relative;
        &:after {
          @include ttlUnderline($color-gray3, $vwpx * 22, $vwpx * 2, $vwpx * 8);
        }
      }
      &-award {
        display: inline-block;
        text-align: center;
        position: relative;
        margin-top: 0;
        padding: 0 #{$vwpx * 18} #{$vwpx * 25};
        color: $text-gold;
        font-size: $vwpx * 24;
        font-weight: $font-bold;
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: $vwpx * -20;
          bottom: $vwpx * -10;
          margin: auto;
          background: url("/app/assets/images/about/img-award-l.png") left center no-repeat;
          background-size: contain;
          width: $vwpx * 80;
          height: $vwpx * 90;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: $vwpx * -18;
          bottom: $vwpx * -10;
          margin: auto;
          background: url("/app/assets/images/about/img-award-r.png") right center no-repeat;
          background-size: contain;
          width: $vwpx * 80;
          height: $vwpx * 90;
        }
      }
    }
    &sup {
      position: relative;
      font-size: $vwpx * 8;
      @include screen("tablet-and-pc") {
        font-size: $vwpx * 10;
      }
      &-ref {
        position: absolute;
        top: -1em;
        right: 2em;
      }
    }
    &img {
      width: 100%;
      > img {
        width: 100%;
      }
      &-doubleLayer {
        position: relative;
        > img:last-child {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &textLink {
      position: relative;
      color: #c30d23;
      display: inline-block;
      &-large {
        font-size: $font-large;
      }
      &-underline {
        text-decoration: underline;
      }
      &-withRightArrow {
        padding-right: $gutter;
        &:before {
          position: absolute;
          content: "";
          right: 0;
          top: calc(50% - #{$vwpx * 3});
          width: $vwpx * 8;
          height: $vwpx * 8;
          border-top: $vwpx solid #c30d23;
          border-right: $vwpx solid #c30d23;
          transform: rotate(45deg);
        }
      }
      @include screen("smartphone") {
        font-size: $font-larger;
      }
      &:hover,
      &:active {
        text-decoration: none;
        color: #aaa;
      }
    }
    &wrapMovie {
      position: relative;
      width: 100%;
      &::before {
        content: "";
        display: block;
        padding-top: 56.25%; /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
      }

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &figMedia {
      > figcaption {
        padding: $vwpx * 20;
        text-align: right;
      }
      &_capDl {
        text-align: center;
        margin-bottom: $gutter * 2;
        > dt {
          font-size: $font-xlarge;
          margin-bottom: $gutter;
        }
      }
    }
    &img {
      display: block;
      width: 100%;
      &-w475 {
        max-width: $vwpx * 475;
      }
      &-center {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &dlQa {
      margin-bottom: $gutter;
      > dt {
        display: inline-flex;
        margin-bottom: $gutter * 2;
        &::before {
          content: "Q:";
          margin-right: 0.5em;
        }
      }
      &_ansTtl {
        display: inline-flex;
        margin-bottom: $gutter;
        font-size: $font-xlarge;
        font-weight: $font-bold;
        &::before {
          content: "A:";
          margin-right: 0.5em;
        }
      }
    }
  }

  /* 以下、元の CSS から引き継ぎ */
  .about--top--kv {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .about--top--img--wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .about--top--img--wrap--inner {
        position: relative;
        width: 100%;
        height: 100%;
        .about-kv-list {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
