@use "../../Foundation/mixin/screen";
@use "../../Foundation/variables/color";

.l-mypageMain {
  max-width: 700px;
  background-color: color.$color-gray0;
  padding: 32px 16px;
  margin: 0 auto;

  @include screen("tablet-and-pc") {
    padding: 40px 32px;
  }

  .l-mypageMain__tabNav {
    margin: 32px auto 0;

    & + .l-mypageMain__body {
      margin-top: 24px;
    }
  }

  .l-mypageMain__body {
    margin-top: 32px;
  }

  .l-mypageMain__fixedFooterSpOnly {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: color.$color-gray0;
    padding: 16px;

    @include screen("tablet-and-pc") {
      position: relative;
      padding: 0;
    }
  }
}
