.c-modalSmall {
  border-radius: 8px;

  .c-modalSmall__content {
    padding: 0 24px 24px;
    width: 350px;
    max-height: 80vh;
    overflow-x: auto;
  }

  .c-modalSmall__header {
    display: flex;
    padding: 20px 0 12px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray4;
    position: sticky;
    top: 0;
    background: $color-gray0;
    z-index: 1;
  }

  .c-modalSmall__title {
    font-size: $font-size3;
    font-weight: $font-weight-semibold;
    white-space: pre-wrap;
  }

  .c-modalSmall__close {
    background: $color-gray0;
  }
}
