@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/font";
@use "../../Foundation/variables/color";

.c-userProfileTag {
  @include typography.label-bold;
  padding: 8px;
  color: $color-gray0;
  border-radius: 16px;
  width: fit-content;

  &.c-userProfileTag--small {
    padding: 4px 8px;
  }

  &.c-userProfileTag--xsmall {
    font-size: font.$font-size9;
    padding: 2px 4px;
  }

  &.c-userProfileTag--gold {
    background: color.$color-gold;
  }

  &.c-userProfileTag--silver {
    background: color.$color-silver;
  }

  &.c-userProfileTag--bronze {
    background: color.$color-bronze;
  }
}
