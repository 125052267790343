@use "../../../Foundation/variables/color";

.p-privacySettingsEditForm {
  .p-privacySettingsEditForm__item {
    padding: 12px;

    & + .p-privacySettingsEditForm__item {
      border-top: 1px solid color.$color-gray4;
    }
  }
}
