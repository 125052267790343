@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.c-productRating {
  margin: 16px 0;
  padding: 12px 0;
  border-top: 1px solid color.$color-gray5;
  border-bottom: 1px solid color.$color-gray5;
  display: grid;
  row-gap: 12px;

  .c-productRating__list {
    display: grid;
    row-gap: 16px;
    align-items: center;
    grid-template-columns: 10fr 1fr 1fr;

    @include screen.screen("smartphone") {
      grid-template-columns: 3fr 1fr 1fr;
    }

    &:first-child {
      border-bottom: 1px solid color.$color-gray4;
      padding-bottom: 12px;
    }
  }

  .c-productRating__label {
    color: color.$color-gray1;
    font-size: font.$font-size5;
    font-weight: font.$font-weight-semibold;
  }

  .c-productRating__score {
    color: color.$color-primary1;
    font-size: font.$font-size2;
    font-weight: font.$font-weight-bold;
    font-family: arial, robot, sans-serif;
    text-align: center;
  }

  .c-productRating__content {
    padding: 16px;
    background: color.$color-primary4;
    border-radius: 4px;
  }

  .c-productRating__contentTitle {
    @include typography.subTitle-small;
    margin-bottom: 4px;
  }

  .c-productRating__contentBody {
    @include typography.body-small;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  }
}
