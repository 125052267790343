@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/variables/color";

.p-producerPromotion {
  display: flex;
  gap: 8px;
  align-items: center;

  .p-producerPromotion__area {
    @include typography.caption;
  }

  .p-producerPromotion__areaSkeleton {
    width: 8em;
    background-color: color.$color-gray4;
    height: 1.2em;
  }

  .p-producerPromotion__name {
    @include typography.body-small-bold;
  }

  .p-producerPromotion__nameSkeleton {
    width: 14em;
    background-color: color.$color-gray4;
    height: 1.2em;

    margin-top: calc((1em * 1.5) - 1em);
  }
}
