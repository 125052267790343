@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/arrow";

.p-labelFilter__filterContainer {
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid variable.$color-gray5;
  }
}

.p-labelFilter__typeTitle {
  font-weight: variable.$font-weight-bold;
}

.p-labelFilter__checkboxGroup {
  max-height: 0;
  visibility: hidden;
  &.is-open {
    max-height: 100vh;
    visibility: visible;
  }
}

.p-labelFilter__checkboxLabel {
  padding: 6px 0;
}

.p-labelFilter__count {
  color: variable.$color-gray2;
  font-size: variable.$font-size7;
  font-weight: variable.$font-weight-light;
  &::before {
    content: "\FF08"; // "（"
  }
  &::after {
    content: "\FF09"; // "）"
  }
}

.p-labelFilter__collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: variable.$font-weight-bold;
  gap: 4px;
}

.p-labelFilter__collapseArrowUp {
  @include arrow.arrow(variable.$color-primary2, 2px, arrow.$arrowDirectionTop);
  margin-left: variable.$base-margin-2;
  margin-right: variable.$base-margin-2;
  margin-top: -4px;
  display: inline-block;
}

.p-labelFilter__collapseArrowDown {
  @include arrow.arrow(variable.$color-primary2, 2px, arrow.$arrowDirectionBottom);
  margin-left: variable.$base-margin-2;
  margin-right: variable.$base-margin-2;
  margin-top: -4px;
  display: inline-block;
}
