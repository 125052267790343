@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/mixin/screen";
@use "../../../../Foundation/variables/color";

.c-magazinesRepayment {
  display: grid;
  grid-template-columns: 56px auto 12px;
  align-items: center;
  gap: 12px;

  padding: 0;

  @include screen.screen("tablet-and-pc") {
    padding: 0 16px;
  }

  .c-magazinesRepayment__title {
    color: color.$color-primary1;
    @include typography.title-2;
  }
}
