.alert-list {
  width: 90%;
  min-width: 250px;
  margin: 16px auto;
  padding: 10px;
  border: 1px solid #f00;

  li {
    color: #f00;
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
} /* alert-list */

.alert-inquiry-container {
  border: 1px solid #f00;
  font-size: 12px;
  padding: 16px;
  color: #f00;

  .center-text {
    font-size: 11px;
    text-align: center;
    color: black;
  }
}
