.p-postList {
  .p-postList__anotherPostLink {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .p-postList__time {
    font-size: $font-size7;
    color: $color-gray2;
  }

  .p-postList__title {
    font-size: $font-size4;
    font-weight: $font-weight-semibold;
  }

  .p-postList__footer {
    font-size: $font-size7;
    color: $color-gray2;
    display: flex;
    gap: 12px;
    margin-top: 4px;
  }

  .p-postList__item {
    border-bottom: 1px solid $color-gray4;
    padding: 12px 0;
  }
}
