@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.c-pagination {
  .c-pagination__inner {
    display: flex;
    gap: 8px;
  }

  .c-pagination__item {
    display: flex;
    height: 32px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: color.$color-gray1;

    background-color: color.$color-gray0;
  }

  .c-pagination__link {
    display: block;
    min-width: 32px;

    border: 1px solid color.$color-primary2;
    border-radius: 4px;

    color: color.$color-primary2;
    font-family: Arial, sans-serif;
    font-size: font.$font-size7;
    font-weight: font.$font-weight-normal;
    line-height: 1.5;
    text-align: center;

    padding: 8px;

    &[aria-current="true"] {
      color: color.$color-gray0;
      font-weight: font.$font-weight-bold;

      background-color: color.$color-primary2;
    }
  }
}
