@use "../../Foundation/mixin/typography";

.c-searchCategoryPopularCard {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 8px;

  .c-searchCategoryPopularCard__item {
    width: 24%;

    @include screen("smartphone") {
      width: 48%;
    }
  }

  .c-searchCategoryPopularCard__imageSection {
    width: 100%;
    position: relative;
    padding-top: 66.666%;
    margin-bottom: 8px;
  }

  .c-searchCategoryPopularCard__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
  }

  .c-searchCategoryPopularCard__itemTitle {
    color: $color-gray1;
    margin-bottom: 8px;
    @include typography.title-2;
  }

  .c-searchCategoryPopularCard__description {
    color: $color-gray1;
    margin-bottom: 16px;
    @include typography.body;
  }
}
