.sp-menu {
  display: none;
  position: fixed;
  top: 62px;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  height: 100%;
  z-index: 999999;
  padding-bottom: 62px;
  @include screen("smartphone") {
    top: 93px;
    padding-bottom: 93px;
    max-width: 100%;
  }

  &::after {
    content: "";
    width: 2000px;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: -2000px;
    z-index: -1;
  }

  .inner-sp-menu {
    position: relative;
    overflow: scroll;
    width: 100%;
    height: 100%;
    font-size: 11px;
    background: $color-primary4;

    .main-sp-nav {
      .normal-list {
        margin-bottom: 10px;

        li {
          &:not(:last-child) {
            border-bottom: 1px solid $color-primary4;
          }

          a {
            display: block;
            padding: 15px;
            font-weight: bold;
            background: #fff;
          }
        }
      }

      .category-list {
        display: flex;
        flex-wrap: wrap;
        padding: 8px;

        .category-list-item {
          width: calc(33% - 5px);
          margin-bottom: 8px;
          min-height: 44px;

          &:not(:nth-child(3n)) {
            margin-right: 8px;
          }

          .category-list-content {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 4px;
            padding: 10px;
            background-size: cover;
            background-position: center;
            position: relative;

            &::after {
              content: "";
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              display: block;
              position: absolute;
              top: 0;
              left: 0;
            }

            .category-name {
              font-weight: 600;
              color: #fff;
              position: relative;
              z-index: 1;
              filter: drop-shadow(0px 0px 5px #000);
            }

            $category-list-categories: vegetable_set vegetable fruit meat dairy honey fish liquor tea seasoning rice processing flower;
            @each $category in $category-list-categories {
              &.category-#{$category} {
                background-image: url(" ../../app/assets/images/top/category/category_" + $category + ".jpg");
              }
            }
          }
        }
      }

      .sns-list {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        margin-bottom: 10px;
        padding: 10px;

        a {
          img {
            display: block;
            width: 40px;
            height: auto;
            margin: 0 auto 3px;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    } /* main-sp-nav */
  } /* inner-sp-menu */
} /* sp-menu */
