@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";

.p-userProfile {
  background: $color-gray5;
  padding-bottom: 40px;

  .p-userProfile__container {
    @include screen("tablet-and-pc") {
      max-width: 1200px;
      display: flex;
      align-items: flex-start;
      gap: 40px;
      margin: 0 auto;
    }
  }

  .p-userProfile__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    background-color: color.$color-gray0;

    padding: 40px 16px;

    @include screen("tablet-and-pc") {
      position: sticky;
      top: 80px;
      min-width: 480px;

      &:has(.c-halfModal[open]) {
        // MEMO : sticky と modal dialog と イメージギャラリー の組み合わせ対応
        position: static;
      }
    }
  }

  .p-userProfile__body {
    width: 100%;
    background-color: color.$color-gray0;
  }

  .p-userProfile__desc {
    padding: 28px 16px;
    @include typography.body;
  }

  .p-userProfile__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .p-userProfile__name {
    @include typography.title-2;
  }

  .p-userProfile__tagWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    overflow: hidden;
    max-width: 400px;
    max-height: 64px;
  }

  .p-userProfile__seeMore {
    margin-top: 16px;
  }

  .p-userProfile__attributeList {
    display: flex;
    gap: 16px;
    margin-top: 20px;
  }

  .p-userProfile__attributeListItem {
    @include typography.caption;
    display: flex;
    gap: 8px;
    align-items: center;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: $color-primary2;
    }
  }

  .p-userProfile__reviewCount {
    @include typography.caption;
    display: flex;
    gap: 8px;
    align-items: center;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: $color-primary2;
    }
  }

  .p-userProfile__inner {
    padding: 28px 16px;
    font-size: $font-size4;
    font-weight: $font-weight-normal;
  }

  .p-userProfile__noContent {
    color: $color-gray3;
    font-size: $font-size4;
  }
}
