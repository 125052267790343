/*
  Old Color Palette

  ToDo
  ・順次New Paletteに差し替え
  ・使われていないことが確認でき次第、削除
*/

$brown: #9f8745; // $color-primary1
$gold400: #9f8745; // $color-primary1
$gold300: #cab270; // $color-primary2
$gold200: #c9bc9c; // $color-primary2
$gold100: #f5f1eb; // $color-primary4

$red300: #c30d23; // $color-order1
$red200: #db3347; // $color-order2
$red100: #d87182; // 廃止

$neutral500: #1e1b12; // $color-gray1
$neutral400: #504e47; // $color-gray2
$neutral300: #9b9a96; // $color-gray3
$neutral200: #e6e6e5; // $color-gray4
$neutral100: #f2f2f2; //$color-gray5
$neutral000: #ffffff; // $color-gray0

$yellow-rating: #ecdc6b; // $color-rating-on

$pink-heart: #e04954; // $color-favorite

// text
$text-default: $neutral500; // $color-primary1
$text-white: $neutral000; // $color-gray0
$text-gray: $neutral300; // $color-gray2
$text-gold: $gold300; // $color-primary2
$text-brown: $brown; // $color-primary1
$text-red: $red300; // $color-order2

// background
$bg-gray-01: $neutral100; // $color-gray0
$bg-gray-02: #f9f9f9; // $color-gray6
$bg-gold-01: #f0efe9; // $color-primary4
$bg-gold-02: $gold100; // $color-primary4
$bg-footer: #fefefd; // 廃止

// border
$border-color: #f1f1f1; //$color-gray5
