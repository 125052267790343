.c-productFreeShipping {
  &::before {
    content: "\9001\6599\7121\6599"; // 送料無料
    color: $color-gray0;
    padding: 4px 4px;
    margin-right: 4px;
    font-size: $font-size7;
    line-height: 1;
    background: $color-order2;
    border-radius: 2px;
    @include screen("smartphone") {
      padding: 4px 3px;
      margin-right: 4px;
      font-size: $font-size9;
    }
  }
}
