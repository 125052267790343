@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";

.c-formInputSeach {
  width: 100%;
  display: flex;
  gap: 8px;

  --_min-height: 42px;

  .c-formInputSeach__input {
    min-height: var(--_min-height);
    flex: 1;

    @include typography.body-small;

    background-color: color.$color-gray0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.85706%209.0953C3.85706%206.2023%206.2023%203.85706%209.0953%203.85706C11.9883%203.85706%2014.3335%206.2023%2014.3335%209.0953C14.3335%2011.9883%2011.9883%2014.3335%209.0953%2014.3335C6.2023%2014.3335%203.85706%2011.9883%203.85706%209.0953ZM9.0953%202.5C5.45281%202.5%202.5%205.45281%202.5%209.0953C2.5%2012.7378%205.45281%2015.6906%209.0953%2015.6906C10.6607%2015.6906%2012.0988%2015.1452%2013.2298%2014.234L16.4144%2017.4186C16.5139%2017.5271%2016.6858%2017.5271%2016.7943%2017.4186L17.69%2016.5229C17.7986%2016.4144%2017.7986%2016.2425%2017.69%2016.1429L14.4682%2012.9211C15.2378%2011.8422%2015.6906%2010.5216%2015.6906%209.0953C15.6906%205.45281%2012.7378%202.5%209.0953%202.5Z%22%20fill%3D%22%23B8B8B8%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 10px;
    border: 1px solid color.$color-gray4;
    border-radius: 4px;

    padding: 10px 10px 10px calc(10px + 20px + 10px);

    &:focus {
      border: 1px solid color.$color-primary2;
    }
  }

  .c-formInputSeach__button {
    min-height: var(--_min-height);
    display: flex;
    align-items: center;

    @include typography.button;
    color: color.$color-gray1;
    white-space: nowrap;

    background-color: color.$color-gray4;
    border-radius: 4px;
    padding: 10px 20px;
  }
}
