.stepper-nav {
  margin: 20px auto 5px auto;

  .stepper-breadcrumb {
    position: relative;
    display: flex;
    justify-content: center;

    li {
      position: relative;
      margin-right: 4px;

      &.current > * {
        color: white;
        background-color: #cab270;
        border-color: #cab270;
      }
    }

    li > * {
      position: relative;
      display: inline-block;
      padding: 0.5em 1.5em 0.5em 2em;
      color: #2c3f4c;
      background-color: #edeff0;
      border-color: #edeff0;
      @include screen("smartphone") {
        padding: 0.5em 1em 0.5em 1.5em;
      }
    }

    li::after,
    li > *::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      border: 16px solid transparent;
      border-right-width: 0;
      border-left-width: 10px;
    }

    li::after {
      z-index: 1;
      transform: translateX(4px);
      border-left-color: #ffffff;
      margin: 0;
    }

    li > *::after {
      z-index: 2;
      border-left-color: inherit;
    }

    li:last-of-type::after,
    li:last-of-type > *::after {
      display: none;
    }

    a {
      text-decoration: none;
    }
  }
}
