.product-card-wrap {
  display: flex;
  overflow: auto;
  @include screen("tablet-and-pc") {
    flex-wrap: wrap;
  }

  .product-card {
    flex: 0 0 44%;
    background-color: #fff;
    overflow: hidden;
    line-height: 1.5;
    letter-spacing: 0;
    margin-bottom: 20px;
    position: relative;
    border-radius: 8px;
    @include screen("tablet-and-pc") {
      flex: none;
      width: calc(25% - 15px);
      margin-bottom: 30px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not(:nth-child(4n)) {
      @include screen("tablet-and-pc") {
        margin-right: 20px;
      }
    }

    &:nth-child(4n) {
      @include screen("tablet-and-pc") {
        margin-right: 0;
      }
    }

    &:nth-child(n + 9) {
      @include screen("tablet-and-pc") {
        display: none;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .product-card-product-link {
      .product-img {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 70%;
        border-radius: 12px;
        @include screen("smartphone") {
          border-radius: 0;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .product-info {
        padding: 8px;

        h4 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          height: 63px;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 600;
          overflow: hidden;
        }

        .price {
          font-size: 18px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include screen("smartphone") {
            font-size: 16px;
          }
        } /* price */
      } /* info-block-product */

      @mixin flag($color, $background) {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 80px;
        height: 40px;
        transform: rotate(-45deg);
        position: absolute;
        top: -6px;
        left: -26px;
        z-index: 1;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.8;
        color: $color;
        background-color: $background;
      }

      &.flag-few {
        &:before {
          @include flag(#212121, #ffeb3b);
          content: "\6B8B\308A\308F\305A\304B"; // 残りわずか
        }
      }

      &.flag-soldout {
        &:before {
          @include flag($color-gray0, $color-gray3);
          content: "SOLD";
        }
      }

      &.flag-free-shipping {
        .price {
          &::before {
            content: "\9001\6599\7121\6599"; // 送料無料
            color: $color-gray0;
            padding: 4px 4px;
            margin-right: 4px;
            font-size: 12px;
            line-height: 1;
            background: #db3347;
            border-radius: 2px;
            @include max-breakpoint($dimen-sp-mini) {
              padding: 4px 3px;
              margin-right: 4px;
              font-size: 10px;
            }
          }
        }
      } /* flag-free-shipping */

      &.flag-bundle {
        .product-img {
          &::after {
            content: "\3042\308F\305B\8CB7\3044\5BFE\8C61"; // あわせ買い対象
            background: $color-primary2;
            color: $color-gray0;
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            padding: 4px 8px;
            border-radius: 8px 0 0 0;
          }
        }
      } /* flag-bundle */
    }

    .product-card-producer-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      position: relative;
      border-top: 1px solid #f2f2f2;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: -1px;
        border-width: 7px 7px 0;
        border-color: #f2f2f2 transparent transparent;
        left: 12%;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: -1px;
        border-width: 6px 6px 0;
        border-color: #fff transparent transparent;
        left: calc(12% + 1px);
      }

      .producer-img {
        width: 25%;
        max-width: 50px;
        @include screen("smartphone") {
          width: 25%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }

      .info-producer {
        width: calc(100% - 25% - 8px);

        .area {
          font-size: 10px;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #666;
        }

        .name {
          font-size: 11px;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
