@use "../../Foundation/mixin/typography";

.info_card_list {
  .info_card {
    li {
      padding: 20px;
      border: 1px solid $color-gray5;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .notice-label {
        @include typography.subTitle-small;
        text-align: center;
        margin-bottom: 4px;
      }

      .info_area {
        @include typography.body-small;

        &:not(:first-child) {
          margin-top: 16px;
        }

        dt {
          clear: both;
          float: left;
          margin-bottom: 5px;
        }

        dd {
          overflow: hidden;
          white-space: nowrap;
          margin-left: 70px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
