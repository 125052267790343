@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/arrow";

.p-ordersToRegularPurchases {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 16px 0;

  &::after {
    @include arrow.arrow(color.$color-gray2, 2px, $arrowDirectionRight);
  }
}
