.p-orderCard {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 2fr;

  .p-orderCard__productThumbnail {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }

  .p-orderCard__productName {
    font-size: $font-size5;
    font-weight: $font-weight-semibold;
    margin-top: 4px;
  }

  .p-orderCard__producer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    max-width: 190px;
  }

  .p-orderCard__producerThumbnail {
    border-radius: 50%;
    margin-right: 4px;
  }

  .p-orderCard__name {
    max-width: 162px;
    font-size: $font-size7;
    color: $color-gray2;
  }
}
