.p-loaderVegetable {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 80%);
  z-index: 9999999999;

  .p-loaderVegetable__container {
    position: fixed;
    top: calc(50% - 80px);
    width: 100%;
    margin: 0 auto;

    .p-loaderVegetable__image {
      background: url("/app/assets/images/loader/vegetable.png") no-repeat;
      margin: 0 auto 40px;
      width: 155px;
      height: 160px;
      background-size: 155px 160px;
      animation: rotation ease-in-out 3s infinite;
    }

    .p-loaderVegetable__description {
      font-size: $font-size2;
      text-align: center;
      font-weight: $font-weight-bold;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
