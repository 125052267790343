.c-userActivityStatus {
  width: fit-content;
  font-size: $font-size7;
  font-weight: $font-weight-bold;
  line-height: 12px;
  border-radius: 16px;
  padding: $base-padding $base-padding-2;
}

.c-userActivityStatus--progress {
  color: $color-gray1;
  background: $color-progress;
}

.c-userActivityStatus--success {
  color: $color-gray1;
  background: $color-success;
}
