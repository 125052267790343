@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.p-userSubscription {
  & + .p-userSubscription {
    margin-top: 80px;
  }

  .p-userSubscription__productInfo {
    margin-bottom: 8px;
  }

  .p-userSubscription__heading {
    @include typography.subTitle;
    margin-bottom: 4px;
  }

  .p-userSubscription__schedule {
    & + .p-userSubscription__schedule {
      margin-top: 48px;
    }
  }

  .p-userSubscription__calendarHeading {
    @include typography.subTitle;
    border-left: 6px solid variable.$color-primary2;
    padding-left: 12px;
    margin-bottom: 8px;
  }

  .p-userSubscription__description {
    @include typography.body;
    margin-bottom: 16px;
  }

  .p-userSubscription__month {
    margin-bottom: 20px;
  }

  .p-userSubscription__table {
    width: 100%;
    border: 1px solid variable.$color-gray4;
  }

  .p-userSubscription__th {
    @include typography.body-bold;
    background-color: variable.$color-gray5;
    padding: 8px;
    width: 80px;
    border-bottom: 1px solid variable.$color-gray4;
    vertical-align: top;
  }

  .p-userSubscription__td {
    @include typography.body;
    padding: 8px;
    border-bottom: 1px solid variable.$color-gray4;
  }

  .p-userSubscription__selectLineupImageContainer {
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 16px;
  }

  .p-userSubscription__selectLineupUrl {
    position: relative;
    display: inline-block;

    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      color: variable.$color-gray0;
      font-weight: variable.$font-weight-bold;
      background: rgba(0, 0, 0, 0.5);
      content: "";
    }
  }

  .p-userSubscription__selectLineupOverlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: variable.$color-gray0;
    font-weight: variable.$font-weight-bold;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .p-userSubscription__selectLineupImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
