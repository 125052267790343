@use "../../Foundation/variable";

.p-emotionCountList {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;

  .p-emotionCountList__panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    background: variable.$color-primary0;
    border-radius: 4px;
    width: 100%;
    padding: 8px 0;
    line-height: 1;

    .p-emotionCountList__label {
      font-size: variable.$font-size7;
      font-weight: variable.$font-weight-semibold;
      color: variable.$color-primary1;
    }

    .p-emotionCountList__count {
      font-size: variable.$font-size4;
      font-weight: variable.$font-weight-bold;
      color: variable.$color-primary1;
    }
  }
}
