.user-opinion-form {
  padding: 40px 16px;
  font-size: 13px;
  border-top: 1px solid #eee;
  text-align: center;
  background: #ededed;
  @include screen("tablet-and-pc") {
    padding: 20px 15px;
    font-size: 12px;
    border-radius: 8px;
  }

  .goiken-form {
    .goiken-form-btn-radio {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      input {
        display: none;
      }

      input[type="radio"]:checked {
        & + label {
          &::before {
            border-color: $color-primary2;
            background: #efdaa2;
          }
        }
      }

      label {
        text-align: left;
        position: relative;
        display: block;

        span {
          padding-left: 25px;
        }

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 8px);
          left: 5px;
          width: 16px;
          height: 16px;
          border: 2px solid #ccc;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
    }
  }

  .goiken-thanks {
    p {
      max-width: 410px;
      margin: 10px auto;
    }
  }

  .link {
    color: #636363;
    text-decoration: underline;
  }

  .ttl {
    font-weight: 600;
  }

  #user-form-send-goiken-footer {
    max-width: 400px;
    margin: 10px auto;
  }
}
