@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

.user-image-uploader {
  width: 120px;
  margin-bottom: 10px;

  input[type="file"] {
    display: none;
  }
  .image-upload-area {
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    background: {
      image: url(" ../../app/assets/images/icon-camera.png");
      repeat: no-repeat;
      position: center center;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .upload-cursor {
    cursor: pointer !important;
  }
}

.user-image-uploader-attention-text {
  color: color.$color-gray2;
  font-size: font.$font-size7;
  padding: 8px;
  background: color.$color-gray6;
}
