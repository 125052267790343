@use "../../Foundation/mixin/typography";

.tabs {
  @include typography.subTitle;
  background: $color-gray0;
  width: 100%;

  &.search {
    ul {
      li {
        @include screen("tablet-and-pc") {
          flex-grow: initial;
        }

        a {
          padding: 12px 32px;
        }
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    border-bottom: 2px solid $color-gray4;

    li {
      flex-grow: 1;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;
        color: $color-gray2;
        position: relative;
        @include screen("tablet-and-pc") {
          padding: 16px;
        }

        &:hover {
          color: $color-primary2;

          .is-icon {
            svg {
              fill: $color-primary2;
            }
          }
        }

        .icon-wrap {
          display: flex;
          align-items: center;
        }

        .is-icon {
          width: 24px;
          height: auto;
          margin-right: 4px;

          svg {
            width: 100%;
            height: auto;
            fill: #999;
          }
        }
      }

      &.active {
        a {
          color: $color-primary1;

          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            display: block;
            height: 3px;
            background: $color-primary1;
            @include screen("tablet-and-pc") {
              height: 4px;
              border-radius: 4px;
            }
          }
        }

        .is-icon {
          svg {
            fill: $color-primary1;
          }
        }
      }
    }
  }
}
