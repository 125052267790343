.p-titleSection {
  .p-titleSection__inner {
    display: grid;
    gap: 16px;
    padding: 16px 16px 40px;

    &.p-titleSection__inner--noContainer {
      @include screen("smartphone") {
        padding: 16px 0 40px;
      }
    }
  }
}
