.mypage-wrap {
  .c-btn {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    @include screen("tablet-and-pc") {
      font-size: 1.8rem;
    }
  }

  h2,
  .h2 {
    font-size: 1.1rem !important;
    font-weight: 600;
    margin-bottom: 1.5rem;
    @include screen("tablet-and-pc") {
      font-size: 1.5rem !important;
    }
  }

  h3,
  .h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .text-link {
    color: #cbb270 !important;
    border-bottom: 1px solid #cbb270;
  }

  .container {
    padding: 0 17px;
    max-width: 800px;
    margin: 0 auto;
  }

  .bg-img {
    background-image: url("../../app/assets/images/mypage/bg-message.jpg");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section {
    padding: 2.5rem 0;
    @include screen("tablet-and-pc") {
      padding: 4rem 0;
    }
  }

  .d-flex {
    display: flex;

    .icon {
      margin-right: 10px;
    }
  }

  .d-flex-center {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
    }
  }

  .d-sm-flex {
    display: flex;
    flex-direction: column;

    @include screen("tablet-and-pc") {
      flex-direction: row;
    }

    @include screen("tablet-and-pc") {
      div:not(:nth-child(2n)) {
        margin-right: 40px;
      }
    }

    .col-50 {
      @include screen("tablet-and-pc") {
        width: calc(50% - 20px);
      }

      &:not(:nth-child(2n)) {
        @include screen("tablet-and-pc") {
          margin-right: 40px;
        }
      }
    }
  }

  .link-list {
    a {
      display: block;
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 17px 30px 17px 14px;
      position: relative;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 2px solid #ccc;
        border-right: 2px solid #ccc;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        right: 14px;
        top: calc(50% - 5px);
      }
    }

    a + a {
      margin-top: -1px;
    }

    a:first-child {
      border-radius: 5px 5px 0 0;
    }

    a:last-child {
      border-radius: 0 0 5px 5px;
    }

    a:only-of-type {
      border-radius: 5px;
    }
  }

  .table {
    border-radius: 5px;
    border-spacing: 0;
    border-collapse: separate;
    border: 1px solid #aaa;
    width: 100%;

    tr {
      th {
        padding: 12px 17px;
        background: #f1f1f1;
        border-bottom: 1px solid #ccc;
        font-weight: 600;
      }

      td {
        padding: 12px 17px;
        border-bottom: 1px solid #ccc;
      }
    }

    tr:first-child {
      th {
        border-radius: 5px 0 0 0;
      }

      td {
        border-radius: 0 5px 0 0;
      }
    }

    tr:last-child {
      th {
        border-radius: 0 0 0 5px;
        border-bottom: none;
      }

      td {
        border-radius: 0 0 5px 0;
        border-bottom: none;
      }
    }
  }
  .table-col-35 {
    width: 35%;
  }

  .balloon {
    position: relative;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    width: 100%;

    &.balloon-circle-left {
      margin-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -20px;
        width: 13px;
        height: 12px;
        bottom: 0;
        background: #fff;
        border-radius: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        left: -10px;
        width: 25px;
        height: 25px;
        bottom: 5px;
        background: #fff;
        border-radius: 50%;
      }
    }

    &.balloon-arrow-top {
      margin-top: 20px;
      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 20px 15px;
        border-color: transparent transparent #ffffff transparent;
      }
    }
  }

  /*checkbox*/
  .checkbox-input {
    display: none;
  }

  .checkbox-parts {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 50px;
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      width: 18px;
      height: 18px;
      border: 2px solid #cbb270;
      background: #fff;
    }
  }

  .checkbox-input {
    &:checked + .checkbox-parts {
      background-color: #f1f1f1;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 14px;
        left: 17px;
        width: 5px;
        height: 12px;
        transform: rotate(40deg);
        border-bottom: 3px solid #cbb270;
        border-right: 3px solid #cbb270;
        @include screen("tablet-and-pc") {
          top: 16px;
        }
      }
    }
  }

  /*radio-button*/
  .radio-input {
    display: none;
  }

  .radio-parts {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 50px;
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 17px;
      width: 18px;
      height: 18px;
      border: 2px solid #cbb270;
      background: #fff;
      border-radius: 50%;
    }
  }

  .radio-input {
    &:checked + .radio-parts {
      background-color: #f1f1f1;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 17px;
        left: 22px;
        width: 12px;
        height: 12px;
        background: #cbb270;
        border-radius: 50%;
        @include screen("tablet-and-pc") {
          top: 18px;
        }
      }
    }
  }

  /*
1: 0.5rem
2: 1rem
3: 1.5rem
4: 2rem
5: 2.5rem
6: 3rem
7: 3.5rem
8: 4rem
*/

  /*padding*/
  @for $i from 0 through 8 {
    .p-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
      padding-right: #{$i * 0.5}rem !important;
      padding-bottom: #{$i * 0.5}rem !important;
      padding-left: #{$i * 0.5}rem !important;
    }
    .px-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
      padding-left: #{$i * 0.5}rem !important;
    }
    .py-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
      padding-bottom: #{$i * 0.5}rem !important;
    }
    .pt-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i * 0.5}rem !important;
    }
    .pl-#{$i} {
      padding-left: #{$i * 0.5}rem !important;
    }
    .pr-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 8 {
    .p-sm-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
      padding-right: #{$i * 0.5}rem !important;
      padding-bottom: #{$i * 0.5}rem !important;
      padding-left: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-top: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
      }
    }
    .px-sm-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
      padding-left: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
    .py-sm-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
      padding-bottom: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .pt-sm-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-top: 0 !important;
      }
    }
    .pb-sm-#{$i} {
      padding-bottom: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-bottom: 0 !important;
      }
    }
    .pl-sm-#{$i} {
      padding-left: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-left: 0 !important;
      }
    }
    .pr-sm-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        padding-right: 0 !important;
      }
    }
  }

  /*margin*/
  @for $i from 0 through 8 {
    .m-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
      margin-right: #{$i * 0.5}rem !important;
      margin-bottom: #{$i * 0.5}rem !important;
      margin-left: #{$i * 0.5}rem !important;
    }
    .mx-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
      margin-right: #{$i * 0.5}rem !important;
    }
    .my-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
      margin-bottom: #{$i * 0.5}rem !important;
    }
    .mt-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i * 0.5}rem !important;
    }
    .ml-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
    }
    .mr-#{$i} {
      margin-right: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 8 {
    .m-sm-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
      margin-right: #{$i * 0.5}rem !important;
      margin-bottom: #{$i * 0.5}rem !important;
      margin-left: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-top: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 0 !important;
      }
    }
    .mx-sm-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
      margin-right: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    .my-sm-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
      margin-bottom: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .mt-sm-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-top: 0 !important;
      }
    }
    .mb-sm-#{$i} {
      margin-bottom: #{$i * 0.5}rem;
      @include screen("tablet-and-pc") {
        margin-bottom: 0;
      }
    }
    .ml-sm-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-left: 0 !important;
      }
    }
    .mr-sm-#{$i} {
      margin-right: #{$i * 0.5}rem !important;
      @include screen("tablet-and-pc") {
        margin-right: 0 !important;
      }
    }
  }
}
