@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-magazinesSection {
  background-color: color.$color-gray0;

  padding: 24px 16px;

  @include screen.screen("tablet-and-pc") {
    padding: 24px 0;

    & + .p-magazinesSection {
      border-bottom: 1px solid color.$color-gray5;
    }
  }

  &.p-magazinesSection--reviews {
    @include screen.screen("tablet-and-pc") {
      background: color.$color-gray6;
      padding: 24px;
    }
  }

  .p-magazinesSection__title {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    color: color.$color-primary1;
    text-align: center;
    font-family: "Noto Serif JP", serif;
    font-size: font.$font-size-large5;
    font-weight: font.$font-weight-bold;
    line-height: 1.4;

    &.p-magazinesSection__title--textAlignLeft {
      text-align: left;
    }
  }

  .p-magazinesSection__subTitle {
    color: color.$color-primary1;
    text-align: center;

    margin-top: 8px;

    @include typography.subTitle;
  }

  .p-magazinesSection__secondTitle {
    @include typography.title-1;
  }

  .p-magazinesSection__lead {
    text-align: center;
    overflow-wrap: anywhere;
    word-break: keep-all;

    margin-top: 16px;

    @include typography.body-small-bold;
  }

  .p-magazinesSection__body {
    margin-top: 24px;

    &.p-magazinesSection__body--marginTopZero {
      margin-top: 0;
    }
  }
}
