@use "../../../Foundation/variables/color";

.c-titleFavorite {
  --_icon-stroke: url('data:image/svg+xml,<svg viewBox="0 0 20 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.8877 1.08243C6.59683 -0.517051 3.14501 -0.566024 0.810009 2.45629C0.609985 2.72299 0.454411 3.02303 0.354399 3.33417C-1.18878 8.19686 2.44782 11.4956 8.53822 17.0202L8.55538 17.0358C9.3777 17.7803 10.6223 17.7803 11.4446 17.0469L11.481 17.0138C17.5598 11.4887 21.1872 8.19173 19.6456 3.33417C19.5456 3.02303 19.39 2.71188 19.19 2.45629C16.855 -0.566024 13.4032 -0.517051 11.1123 1.08242C10.6994 1.37073 10.3242 1.70942 10 2.08958C9.67585 1.70942 9.30063 1.37074 8.8877 1.08243ZM10.4424 15.9308C13.5405 13.1149 15.8208 11.033 17.1811 9.07924C18.4622 7.23938 18.8147 5.67782 18.2168 3.79092C18.1628 3.62372 18.084 3.4769 18.0087 3.38075L18.003 3.37337L18.003 3.37336C16.912 1.96123 15.5969 1.47069 14.3957 1.50134C13.1461 1.53322 11.9344 2.13278 11.1414 3.06283L10 4.40143L8.8586 3.06283C8.06557 2.13278 6.85387 1.53322 5.60427 1.50134C4.4058 1.47076 3.09391 1.95903 2.00437 3.36386C1.90592 3.49685 1.8305 3.64457 1.78318 3.7909C1.18524 5.67787 1.5379 7.2393 2.81861 9.07747C4.17959 11.0308 6.46197 13.1117 9.56318 15.9248L8.55548 17.0357L9.56215 15.9239C9.81284 16.1509 10.1901 16.1525 10.4424 15.9308Z" /></svg>');
  --_icon-fill: url('data:image/svg+xml,<svg viewBox="0 0 20 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M11.4446 17.0469C10.6223 17.7803 9.3777 17.7803 8.55538 17.0358C2.45465 11.5018 -1.19023 8.20142 0.354399 3.33417C0.454411 3.02303 0.609985 2.72299 0.810009 2.45629C3.5659 -1.1108 7.87752 -0.399607 10 2.08958C12.1225 -0.399607 16.4341 -1.1108 19.19 2.45629C19.39 2.71188 19.5456 3.02303 19.6456 3.33417C21.1902 8.20142 17.5453 11.5018 11.4446 17.0469Z" /></svg>');

  background-color: color.$color-gray0;

  &[aria-pressed="true"] {
    .c-titleFavorite__icon {
      --_background-color: #bd8c5e;
      mask-image: var(--_icon-fill);
    }
  }

  .c-titleFavorite__icon {
    --_background-color: #{color.$color-gray4};

    display: inline-block;
    mask-image: var(--_icon-stroke);
    width: 20px;
    height: 18px;
    background-color: var(--_background-color);
  }
}
