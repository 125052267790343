.concierge-subscriptions__vegetable-category-icon {
  width: 18px;
}

/* mypage */
.mypage-concierge {
  // ...
}

.block {
  &.have-next:not(:last-child) {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-gray5;
  }
}

.mypage-concierge-edit {
  .info_list {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}

.mypage-concierge__attention {
  font-size: 12px;
  margin-bottom: 16px;
}

.mypage-concierge-subscriptions {
  .note {
    background-color: $color-primary4;
    padding: 10px 10px;
  }
}

.concierge-subscriptions {
  .concierge-plan-radio {
    display: table;
    width: 100%;
    @include max-breakpoint($dimen-sp) {
      display: block;
    }

    .concierge-plan-radio-title {
      font-weight: bold;
    }

    .concierge-plan-radio-textarea {
      display: table-cell;
      width: 220px;
      @include max-breakpoint($dimen-sp) {
        display: block;
        padding-left: 10px;
        width: 100%;
      }
    }

    .concierge-plan-radio-image {
      display: table-cell;
      width: 300px;
      padding-left: 100px;
      @include screen("smartphone") {
        width: 300px;
        padding-left: 100px;
      }
      @include max-breakpoint($dimen-sp) {
        width: 100%;
        display: block;
        padding-left: 10px;
        margin-top: 20px;
      }

      img {
        width: 100%;
      }
    }
  }

  .concierge-subscriptions__sub-title {
    background-color: #ffffff;
  }

  .price-off-campaign {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding: 6px;
    color: #ffffff;
    background-color: #cab270;
  }

  .recommend-badge {
    display: inline-block;
    border-radius: 1em;
    background-color: #dc0800;
    color: #fff;
    padding: 3px 8px 2px;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
  }

  .recommend-frequency-badge {
    display: inline-block;
    border-radius: 1em;
    background-color: #dc0800;
    color: #fff;
    padding: 3px 8px 2px;
    margin-left: 16px;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
  }

  .common_forms_attr-value-fields-important {
    margin-bottom: 0;
  }

  .list-annotation {
    margin-bottom: 5px;
  }

  .concierge-subscriptions-warning {
    margin-top: 20px;
  }
}
