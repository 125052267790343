@use "../../../Foundation/variables/color";

.p-regularPurchasesToOrders {
  .p-regularPurchasesToOrders__link {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;

    &::after {
      @include arrow(color.$color-gray2, 2px, $arrowDirectionRight);
    }
  }
}
