// Note: この設定は廃止予定。app/styles/user/Foundation/mixin/_typography.scss を使う。 https://vivid-garden.esa.io/posts/11209
// Font Size
$default-font-size: 14px;
$font-size-base: $default-font-size !default;
$font-size1: 24px;
$font-size2: 20px;
$font-size3: 18px;
$font-size4: 16px;
$font-size5: $font-size-base;
$font-size6: 13px;
$font-size7: 12px;
$font-size8: 11px;
$font-size9: 10px;
$font-size-large1: 36px;
$font-size-large2: 34px;
$font-size-large3: 32px;
$font-size-large4: 30px;
$font-size-large5: 28px;

// Font Weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;
