ul {
  &.list-annotation {
    font-size: $font-size5;

    /* stylelint-disable selector-class-pattern */
    /* FIXME : 従来のクラス名に沿ったため。命名全部しなおせば対応できる */
    li {
      display: list-item;
      list-style-type: "※";
      margin-left: 1em;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    /* stylelint-enable selector-class-pattern */

    a {
      color: #bda35a;
    }

    &.-for-gift {
      margin-left: 2.4rem;
      margin-top: 1rem;
    }

    &.-for-cart-producer-holiday {
      font-size: 10px;
      color: $text-gray;
      text-align: left;
      margin-bottom: 5px;
      @include screen("tablet-and-pc") {
        text-align: right;
      }
    }
  }

  &.list-arrow {
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 8px;
        border-color: transparent transparent transparent $color-primary2;
      }
    }
  }

  &.list-disc {
    padding-left: 20px;

    li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

ol {
  &.list-number {
    padding-left: 20px;

    li {
      list-style: decimal;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &.list_parentheses {
    padding: 0 0 0 2em;

    li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &::before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }
}

.c-listLowerAlpha {
  list-style-type: lower-alpha;

  .c-listLowerAlpha_item {
    list-style: lower-alpha;
  }

  .c-listLowerAlpha_attention {
    font-size: 0.8em;
  }
}
