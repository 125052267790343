@use "../../Foundation/mixin/typography";

.p-producerProducerPickupProduct {
  .p-producerProducerPickupProduct__heading {
    color: $color-primary1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    text-align: center;
    word-break: keep-all;
    margin-bottom: 16px;
    @include typography.title-2;
  }

  .p-producerProducerPickupProduct__headingLabel {
    @include typography.title-2;
  }

  .p-producerProducerPickupProduct__container {
    padding: 32px;
    background: $color-gray6;

    @include screen("smartphone") {
      padding: 20px 16px;
    }
  }

  .p-producerProducerPickupProduct__sellingPoints > li {
    position: relative;
    padding-left: 20px;
    @include typography.subTitle;

    &:before {
      content: "";
      background-color: $color-primary2;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: 0;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
