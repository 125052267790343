@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";

.c-formCheckBox2 {
  --_label-border: 1px solid #{color.$color-gray4};
  --_label-background-color: #{color.$color-gray0};
  --_label-color: #{color.$color-gray1};
  --_checkbox-border: 2px solid #{color.$color-gray4};
  --_checkbox-background-color: #{color.$color-gray0};
  --_checkbox-width: 16px;

  display: block;

  @include typography.body-small;
  color: var(--_label-color);

  background-color: var(--_label-background-color);
  border: var(--_label-border);
  border-radius: 4px;

  padding: 16px;

  // MEMO : iOS 15.2 のサポートを切ると &[type="checkbox"]:disabled + .c-formCheckBox2__label の指定もここに移動できます。
  &:has(.c-formCheckBox2__input[type="checkbox"]:checked) {
    --_label-background-color: #{color.$color-primary3};
  }

  &:has(.c-formCheckBox2__input[type="checkbox"]:disabled) {
    --_label-background-color: #{color.$color-gray5};
    --_label-color: #{color.$color-gray1};
  }

  .c-formCheckBox2__input {
    // MEMO : display: none; にすると input 要素のフォーカスの操作ができなくなるので、見せないようにしています。
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    border: 0;

    &[type="checkbox"]:checked + .c-formCheckBox2__label {
      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 10px;
        grid-area: 1/1;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.7238%201.13554C10.3332%200.74502%209.70006%200.74502%209.30954%201.13554L4.92548%205.51961L2.80416%203.39829C2.41363%203.00776%201.78047%203.00776%201.38995%203.39829L1.1071%203.68113C0.716578%204.07165%200.716578%204.70482%201.1071%205.09534L3.9348%207.92304C3.93504%207.92329%203.93529%207.92353%203.93553%207.92377L4.21837%208.20661C4.26719%208.25543%204.31979%208.29814%204.37524%208.33475C4.7634%208.59104%205.29088%208.54832%205.63258%208.20661L5.91543%207.92377C5.91523%207.92397%205.91563%207.92357%205.91543%207.92377L11.0066%202.8326C11.3971%202.44208%2011.3971%201.80891%2011.0066%201.41839L10.7238%201.13554Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        margin: 0 auto;
      }

      &::before {
        --_checkbox-border: 1px solid #{color.$color-primary2};
        --_checkbox-background-color: #{color.$color-primary2};
      }
    }

    &[type="checkbox"]:disabled + .c-formCheckBox2__label {
      &::before {
        --_checkbox-border: 1px solid #{color.$color-gray4};
        --_checkbox-background-color: #{color.$color-gray4};
      }
    }
  }

  .c-formCheckBox2__label {
    width: 100%;

    display: grid;
    grid-template-columns: var(--_checkbox-width) 1fr;
    gap: 8px;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: var(--_checkbox-width);
      height: var(--_checkbox-width);
      border: var(--_checkbox-border);
      border-radius: 4px;
      background: var(--_checkbox-background-color);
      grid-area: 1/1;
      box-sizing: border-box;
    }
  }
}
