@use "../../Foundation/variables/color";
@use "../../Foundation/mixin/typography";

.c-halfModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 70%);
  z-index: 99999;
  --dialog-width: none;
  --dialog-height: none;

  &.c-halfModal--open {
    animation: fadein 0.25s;
    animation-fill-mode: forwards;

    @keyframes fadein {
      0% {
        opacity: 0;
        background-color: color.$color-gray0;
      }
      100% {
        opacity: 1;
        background-color: rgba(0, 0, 0, 70%);
      }
    }
  }

  &.c-halfModal--close {
    animation: fadeout 0.25s;

    @keyframes fadeout {
      0% {
        opacity: 1;
        background-color: rgba(0, 0, 0, 70%);
      }
      100% {
        opacity: 0;
        background-color: color.$color-gray0;
      }
    }
  }

  .c-halfModal__close {
    position: fixed;
    top: 2%;
    right: 4%;
    background: none;
  }

  .c-halfModal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
  }

  .c-halfModal__title {
    white-space: pre-wrap;
    @include typography.title-2;
  }

  .c-halfModal__body {
    padding: 0 16px 24px 16px;
  }

  .c-halfModal__content {
    background-color: color.$color-gray0;
    border-radius: 12px 12px 0 0;
    position: fixed;
    bottom: 0;
    height: 91%;
    overflow-x: auto;
    width: 100%;
    left: 0;

    &.c-halfModal__content--open {
      animation: fadeup 0.5s;

      @keyframes fadeup {
        0% {
          opacity: 0;
          transform: translateY(80%);
        }
        100% {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }

    &.c-halfModal__content--close {
      animation: fadedown 0.5s;

      @keyframes fadedown {
        0% {
          opacity: 1;
          transform: translateY(0%);
        }
        100% {
          opacity: 0;
          transform: translateY(100%);
        }
      }
    }
  }

  .p-productReviewsModal {
    margin: 0 -16px;
  }
}
