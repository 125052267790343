@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

// stylelint-disable tabechoku/selector-type-allowed-list -- ユーティリティで利用箇所の制限をするため
a.u-textlink {
  color: color.$color-primary1;
  text-decoration: underline;

  &.u-textlink--bold {
    font-weight: font.$font-weight-bold;
  }
}
// stylelint-enable tabechoku/selector-type-allowed-list
