.u-aspectSquare {
  aspect-ratio: $aspect-square;
}

.u-aspectWide {
  aspect-ratio: $aspect-wide;
}

.u-aspectGridOne3rd {
  aspect-ratio: $aspect-grid-one-3rd;
}

.u-aspectPhoto {
  aspect-ratio: $aspect-photo;
}

.u-aspectThumb {
  aspect-ratio: $aspect-thumb;
}

.u-aspectArticleThumb {
  aspect-ratio: $aspect-article-thumb;
}

.u-aspectBanner {
  aspect-ratio: $aspect-banner;
}

.u-aspectSidebarBanner {
  aspect-ratio: $aspect-sidebar-banner;
}

.u-aspectShortBanner {
  aspect-ratio: $aspect-short-banner;
}
