@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";

.p-magazinesSelect {
  display: grid;
  gap: 16px;

  & + .p-magazinesSelect {
    margin-top: 24px;
  }

  .p-magazinesSelect__title {
    display: flex;
    align-items: flex-start;

    border-left: 6px solid color.$color-primary2;

    padding: 4px 8px 4px 16px;

    @include typography.title-2;
  }

  .p-magazinesSelect__areaList {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
  }

  .p-magazinesSelect__areaListItemLink {
    display: flex;
    align-items: center;
    gap: 4px;

    color: color.$color-gray1;

    border-radius: 16px;
    border: 1px solid color.$color-gray4;
    background-color: color.$color-gray6;

    padding: 8px 12px;

    @include typography.button-small;
  }

  .p-magazinesSelect__priceList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    border-radius: 4px;
    border: 1px solid color.$color-gray5;

    @include screen.screen("tablet-and-pc") {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .p-magazinesSelect__priceItem {
    background: color.$color-gray0;

    & + .p-magazinesSelect__priceItem {
      border-left: 1px solid color.$color-gray5;
    }
  }

  .p-magazinesSelect__priceItemLink {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 16px;

    padding: 12px;

    @include typography.subTitle;
  }
}
