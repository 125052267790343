@use "../../Foundation/mixin/typography";

.c-faqProducer {
  padding: $base-padding-5 $base-padding-5 $base-padding-6;
  border: 1px solid $color-gray5;
  border-radius: 8px;
  background-color: $color-gray6;
}

.c-faqProducer__header {
  padding-bottom: $base-padding-2;
  border-bottom: 1px solid $color-gray4;
  margin-bottom: $base-padding-4;
}

.c-faqProducer__subTitle {
  @include typography.subTitle;
}

.c-faqProducer__main {
  display: flex;
  flex-direction: column;
  gap: $base-padding-4;
}

.c-faqProducer__summary {
  display: flex;
  align-items: center;
  gap: $base-padding-4;

  &::after {
    @include arrow($color-gray1, 2px, $arrowDirectionRight);
  }
}

.c-faqProducer__thumbnail {
  border-radius: 50%;
}

.c-faqProducer__textSummary {
  display: flex;
  flex-direction: column;
  gap: $base-padding;
  flex-grow: 1;
}

.c-faqProducer__area {
  @include typography.caption;
  color: $color-gray1;
}

.c-faqProducer__name {
  @include typography.subTitle;
  margin-bottom: 0; // h4のマージンを打ち消す
  color: $color-gray1;
}

.c-faqProducer__founderOn {
  @include typography.caption;
  color: $color-gray2;
}

.c-faqProducer__description {
  @include typography.caption;
}
