.p-areasShowSection {
  & + .p-areasShowSection {
    margin-top: 42px;
    @include screen("tablet-and-pc") {
      margin-top: 48px;
    }
  }

  & + .p-productList {
    margin-top: 42px;
    @include screen("tablet-and-pc") {
      margin-top: 48px;
    }
  }

  .p-areasShowSection__title {
    margin-top: 20px;
    text-align: center;
  }

  .p-areasShowSection__description {
    margin-top: $font-size4;
    @include screen("tablet-and-pc") {
      margin-top: 32px;
    }
  }

  .p-areasShowSection__majorSeachCategories {
    background: #f9f9f9;
    border-radius: 8px;

    padding: 20px 16px 24px;
    margin-top: 42px;
    @include screen("tablet-and-pc") {
      margin-top: 48px;
    }
  }

  .p-areasShowSection__majorSeachCategoriesTitle {
    font-size: $font-size4;
    line-height: 1.4;
    font-weight: $font-weight-bold;
    text-align: center;
    letter-spacing: 0.15px;
  }

  .p-areasShowSection__majorSeachCategoryItems {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    margin-top: 12px;
    @include screen("tablet-and-pc") {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .p-areasShowSection__seemore {
    padding: 1rem;
  }
}
