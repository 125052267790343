.p-preferenceForm {
  .p-preferenceForm__cardContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    @include screen("smartphone") {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .p-preferenceForm__card {
    padding: 12px 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &.is-selected {
      background: $color-primary4;
    }
  }

  .p-preferenceForm__cardImgContainer {
    &.is-selected {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 25%;
        left: 25%;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: white;
      }

      &::after {
        content: "";
        position: absolute;
        top: 47%;
        left: 50%;
        width: 8px;
        height: 16px;
        border-bottom: 2px solid $color-primary1;
        border-right: 2px solid $color-primary1;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  .p-preferenceForm__cardImg {
    border-radius: 50%;
    width: 64px;
  }

  .p-preferenceForm__cardTitle {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size5;

    &.is-selected {
      color: $color-primary1;
    }
  }
}
