@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";

.c-mypageHeadTitle {
  color: color.$color-gray1;
  text-align: center;

  border-bottom: 1px solid color.$color-gray5;

  padding-bottom: 16px;
  margin: 0;

  @include typography.title-1;
}
