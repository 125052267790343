@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/variables/font";

.c-shippingDetailButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-size: font.$font-size9;
  font-weight: font.$font-weight-bold;

  width: 60px;
  height: 60px;
  border-radius: 4px;

  background-color: color.$color-gray4;

  img {
    width: 20px;
    height: 20px;
  }
}
