.c-searchKeywordranking {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;

  font-weight: $font-weight-semibold;
  font-size: $font-size4;
  line-height: 1.4;
  letter-spacing: 0.15px;

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(3, 1fr);
  }

  .c-searchKeywordranking__item {
    background: #ffffff;
    border: 1px solid $color-gray5;
    border-radius: 4px;
  }

  .c-searchKeywordranking__link {
    display: flex;
  }

  .c-searchKeywordranking__icon {
    width: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $color-gray6;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 8px;
    background-size: 12px 8px;
    padding-top: 8px;

    &.c-searchKeywordranking__icon--first {
      background-image: url("../../app/assets/images/products/categories/icon_crown_1.png");
    }

    &.c-searchKeywordranking__icon--second {
      background-image: url("../../app/assets/images/products/categories/icon_crown_2.png");
    }

    &.c-searchKeywordranking__icon--third {
      background-image: url("../../app/assets/images/products/categories/icon_crown_3.png");
    }
  }

  .c-searchKeywordranking__label {
    padding: 12px 8px;
  }
}
