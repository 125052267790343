@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/mixin/screen";

/*
  app/styles/user/Object/Component/txt.scss の .h2-title をベースに調整
 */

.c-productsPostsIndexTitle {
  display: grid;
  grid-template-columns: var(1fr);
  align-items: end;
  gap: 4px;
  background: $color-primary4;

  @include typography.title-2;

  .c-productsPostsIndexTitle__page {
    @include typography.caption-bold;
  }
}
