@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";

.c-titleItem {
  --_badge-color: #bd8c5e;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  padding: 12px 16px;

  & + .c-titleItem {
    border-top: 1px solid color.$color-gray5;
  }

  .c-titleItem__bookmark {
    flex-basis: 22px;
  }

  .c-titleItem__body {
    flex: 1;
  }

  .c-titleItem__titleName {
    position: relative;
    display: inline-block;
    @include typography.subTitle-small;

    padding-right: calc(8px + 4px);

    &[data-with-badge="silver"] {
      --_badge-color: #{color.$color-gray3};
    }

    &[data-with-badge="gold"] {
      --_badge-color: #{color.$color-primary2};
    }

    &::after {
      position: absolute;
      top: 2px;
      right: 0;
      content: "";
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      background-color: var(--_badge-color);
    }
  }

  .c-titleItem__count {
    @include typography.body-small;

    margin-top: 4px;
  }
}
