@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/line-clamp";

.c-recipeCardSlide {
  background-color: color.$color-gray0;
  border: 1px solid color.$color-gray5;
  border-radius: 8px;
  overflow: hidden;

  .c-recipeCardSlide__recipeImage {
    height: auto;
    width: 100%;
  }

  .c-recipeCardSlide__producer {
    display: flex;
    background-color: color.$color-primary4;
    padding: 12px 16px;

    .c-recipeCardSlide__producerImage {
      border: 3px solid color.$color-gray0;
      border-radius: 50%;
      height: 48px;
      width: 48px;
      margin-top: calc((48px / 2) * -1);
      margin-right: 8px;
    }

    .c-recipeCardSlide__producerArea {
      @include typography.caption;
      @include line-clamp.line-clamp(1);
    }

    .c-recipeCardSlide__producerName {
      @include typography.subTitle-small;
      @include line-clamp.line-clamp(1);
    }
  }

  .c-recipeCardSlide__foot {
    height: 120px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px 20px;
  }

  .c-recipeCardSlide__name {
    @include typography.subTitle;
    @include line-clamp.line-clamp(2);
  }

  .c-recipeCardSlide__info {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 16px;

    @include typography.caption;
  }

  .c-recipeCardSlide__infoLabel {
    display: grid;
    gap: 4px;
    align-items: center;
    grid-template-columns: 16px 1fr;
  }

  .recipeCardSlide__infoCategoryLabel {
    display: grid;
    gap: 4px;
    align-items: center;
    grid-template-columns: 16px 1fr;
  }

  .c-recipeCardSlide__icon {
    height: 16px;
    width: 16px;
  }
}
