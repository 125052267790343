.p-recipeSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include screen("smartphone") {
    padding: 14px 0;
  }

  @at-root {
    .p-recipeSummary_pickup {
      color: $color-primary1;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .p-recipeSummary_pickupIcon {
      height: auto;
      width: 22px;
    }

    .p-recipeSummary_pickupText {
      margin-left: 4px;
    }

    .p-recipeSummary_title {
      font-size: 20px;
      font-weight: bold;
      word-break: break-all;
      margin-bottom: 4px;

      &.p-recipeSummary_title-normal {
        font-size: 16px;
      }
    }

    .p-recipeSummary_lead {
      align-items: center;
      display: flex;
      gap: 16px;
    }

    .p-recipeSummary_cookingMinutes {
      flex-shrink: 0;
      font-size: 12px;
    }

    .p-recipeSummary_category {
      font-size: 12px;
    }

    .p-recipeSummary_cookingMinutesIcon,
    .p-recipeSummary_categoryIcon {
      height: 16px;
      width: 16px;
    }

    .p-recipeSummary_description {
      font-size: 14px;
      margin: 16px 0 24px;
    }
  }
}
