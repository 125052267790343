@use "../../Foundation/variable" as variable;

.p-privacyPolicyForTools {
  .p-privacyPolicyForTools__table {
    font-size: variable.$font-size7;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th,
    td {
      padding: 8px;
      border: 1px solid variable.$color-gray5;
      text-align: left;
    }
  }

  .p-privacyPolicyForTools__column--narrow {
    min-width: 60px;
  }

  .p-privacyPolicyForTools__column--wide {
    min-width: 90px;
  }
}
