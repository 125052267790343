@for $i from 0 through 8 {
  .u-mt#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .u-mb#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .u-ml#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .u-mr#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .u-pt#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .u-pb#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .u-pl#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .u-pr#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}

.u-mxAuto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.u-mlAuto {
  margin-left: auto !important;
}

.u-mrAuto {
  margin-right: auto !important;
}

.u-mxAmAuto {
  margin-right: auto !important;
  margin-left: auto !important;
  @include screen("tablet-and-pc") {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.u-mlSmAuto {
  margin-left: auto !important;
  @include screen("tablet-and-pc") {
    margin-left: 0 !important;
  }
}

.u-mrSmAuto {
  margin-right: auto;
  @include screen("tablet-and-pc") {
    margin-right: 0 !important;
  }
}
