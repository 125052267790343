.p-conciergeSubscription {
  .p-conciergeSubscription__cancel {
    position: relative;
    padding-right: 24px;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 5.655px);
      width: 6px;
      height: 6px;
      right: 4px;
      transform: rotate(45deg);
      border-top: 2px solid $color-gray1;
      border-right: 2px solid $color-gray1;
    }
  }
}
