@use "../../Foundation/mixin/screen";
@use "../../Foundation/variables/color";

.l-mypageContainer {
  min-height: 500px;
  background-color: color.$color-gray5;

  padding-bottom: 0;

  @include screen("tablet-and-pc") {
    padding-bottom: 60px;
  }
}
