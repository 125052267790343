/**
 * @see https://css-tricks.com/almanac/properties/l/line-clamp/
 */
@mixin line-clamp($n) {
  display: -webkit-box;
  -webkit-line-clamp: #{$n};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
