@use "../../Foundation/variables/color";
@use "../../Foundation/mixin/typography";

.p-reviews {
  margin-top: 1px;
  margin-bottom: 1px;
  background: color.$color-gray6;

  @include screen("tablet-and-pc") {
    margin: 20px 0 40px 0;
  }

  .p-reviews__header {
    display: flex;
    background: color.$color-primary4;
    gap: 8px;
    padding: 12px 16px;
  }

  .p-reviews__ratingBlock {
    @include screen("smartphone") {
      padding: 24px 0 32px 0;
      gap: 32px;
      border-bottom: 1px solid color.$color-gray4;
    }
  }

  .p-reviews__rating {
    padding: 24px;
  }

  .p-reviews__footer {
    @include screen("smartphone") {
      padding-top: 24px;
      background: color.$color-gray0;
    }
  }

  .p-reviews__paginate {
    margin: -22px 0 -4px 0;
    @include screen("smartphone") {
      margin: -22px 0 -16px 0;
    }
  }

  .p-reviews__link {
    @include screen("tablet-and-pc") {
      width: min(100%, 600px);
      margin: 0 auto;
      padding-bottom: 32px;
    }

    @include screen("smartphone") {
      margin: 0 16px;
      padding-bottom: 24px;
      background: color.$color-gray0;
    }
  }
}
