@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/typography";

.p-topicSeasonalItems {
  --columns: 2;
  --gap: 20px 12px;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--gap);

  @include screen("tablet-and-pc") {
    --columns: 4;
    --gap: 32px 16px;
  }

  .p-topicSeasonalItems__itemThumbnail {
    overflow: hidden;
    border-radius: 12px;
  }

  .p-topicSeasonalItems__itemImage {
    width: 100%;
  }

  .p-topicSeasonalItems__itemTitle {
    @include typography.subTitle;

    margin-top: 16px;
  }

  .p-topicSeasonalItems__itemComment {
    @include typography.body-small;

    margin-top: 8px;
  }
}
