.p-productReviewPosts {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: $base-margin-4;

  > * {
    // NOTE: "Flexbox Holy Albatross" というテクニックです。 See https://heydonworks.com/article/the-flexbox-holy-albatross-reincarnated/
    flex-grow: 1;
    flex-basis: calc((800px - 100%) * 999);
  }
}
