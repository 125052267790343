.is-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
  }
}
