@use "../../Foundation/mixin/typography";

@mixin checkBox {
  input[type="checkbox"] {
    display: none;

    &:checked {
      + .checkBox__label {
        &::before {
          background: $color-primary2;
          border: none;
        }

        &::after {
          display: block;
        }
      }
    }

    &:indeterminate {
      + .checkBox__label {
        &::before {
          background: $color-primary2;
          border: none;
        }

        &::after {
          display: block;
          border-left: 0;
          border-bottom: 2px solid $color-gray0;
          transform: rotate(0);
          width: 10px;
          height: 0;
          margin-top: 0;
          margin-left: 1px;
        }
      }
    }
  }

  .checkBox__label {
    display: flex;
    align-items: center;

    &::before {
      position: absolute;
      content: "";
      left: 14px;
      width: 16px;
      height: 16px;
      border: 1px solid #ccc;
      background: $color-gray0;
      border-radius: 4px;
      box-sizing: border-box;
    }

    &::after {
      display: none;
      position: absolute;
      content: "";
      left: 16px;
      width: 9px;
      height: 4px;
      margin-top: -3px; // safariでデザインが崩れるため -4pxではなく-3pxとした
      border-left: 2px solid $color-gray0;
      border-bottom: 2px solid $color-gray0;
      transform: rotate(-45deg);
    }
  }
}

.p-searchFormNarrow {
  position: relative;
  z-index: unset;
  user-select: none;
  width: 100%;
  min-height: 74px;

  @include screen("tablet-and-pc") {
    padding-bottom: 28px;
    min-height: 99px;
  }

  // 絞り込み操作パネル

  .searchTools__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
  }

  .searchTools__filterButtonContainer {
    display: flex;
    padding-top: 8px; // バッチ表示スペース
  }

  .searchTools__filterButtonContainer--scrollable {
    overflow-x: auto;
  }

  .searchTools__sortButtonContainer {
    display: flex;
  }

  .searchTools__button {
    position: relative;
    padding: 10px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background-color: $color-gray0;
    border: 1px solid $color-gray4;
    border-radius: 4px;
    user-select: none;
    margin-right: 8px;
    cursor: pointer;

    .searchTools__icon {
      margin-right: 5px;
      margin-bottom: 2px;
      width: 16px;
      height: 16px;

      svg {
        fill: $color-primary2;
      }
    }

    .searchTools__label {
      font-size: $font-size5;
      font-weight: $font-weight-normal;
      color: $color-gray1;
    }

    .badge__container {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $color-primary1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .badge__text {
      color: $color-gray0;
      font-size: $font-size7;
      font-weight: $font-weight-bold;
      padding-left: 1px;
    }
  }

  .searchTools__buttonDivider {
    border-left: 1px $color-gray4 solid;
    margin-right: 8px;
  }

  // 並び順

  .selecter__container {
    position: absolute;
    display: none;
    top: 43px;
    left: calc(50% - 279px / 2);
    width: 279px;
    z-index: auto;

    &.selecter__container--offset {
      left: 0px;

      &::before {
        left: 50px;
      }
    }
  }

  .selecter__container::before {
    content: "";
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
    position: absolute;
    top: -4px;
    left: calc(50% - 6px);
    background-color: $color-gray0;
  }

  .selecter__inner {
    background-color: $color-gray0;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }

  .selecter__container.is-open {
    display: block;
    z-index: 1;
  }

  .selecter__item {
    width: 100%;
    padding: 8px auto;
  }

  .selecter__label {
    font-weight: $font-weight-light;
    font-size: $font-size5;
  }

  .searchTools__button.is-open {
    background-color: $color-primary2;

    .searchTools__icon svg {
      fill: $color-gray0;
    }

    .searchTools__label {
      color: $color-gray0;
    }
  }

  // 絞り込みフォーム

  .searchForm__container {
    display: none;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0;
    padding: 0;
    @include screen("smartphone") {
      min-height: 44px;
      padding: 8px 10px;
      border-bottom: 1px solid $color-gray5;
    }
  }

  .searchForm__container.is-open {
    display: unset;

    // TODO: 検索UI改善後再調整する
    z-index: 999;

    @include screen("smartphone") {
      position: fixed;
      top: 0;
      padding: 8px 10px 8px;
    }

    .searchMenu__wrap {
      display: block;
    }

    .floating__container {
      display: none;
    }
  }

  .searchMenu__wrap {
    display: none;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $color-primary4;

    .searchMenu {
      max-width: 640px;
      margin: 0 auto;
      padding: 0 0 150px;
      min-height: 100%;
    }

    .searchMenu__head {
      font-size: $font-size4;
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      position: relative;
      @include screen("tablet-and-pc") {
        font-size: $font-size3;
      }
    }

    .searchMenu__backBtn {
      position: absolute;
      left: 1rem;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      color: $text-brown;
      font-size: $font-size5;
      cursor: pointer;

      &::before {
        @include arrow($text-brown, 2px, $arrowDirectionLeft);
        position: absolute;
        left: 0;
      }
    }

    .searchMenu__cancelBtn {
      position: absolute;
      right: 1rem;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      color: $text-brown;
      font-size: $font-size5;
      cursor: pointer;
    }

    .searchMenu__inner {
      background: $color-gray0;
      @include screen("tablet-and-pc") {
        border-radius: 12px;
        overflow: hidden;
        padding: 1.5rem;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    @mixin searchMenuList {
      display: flex;
      justify-content: space-between;
      padding: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid $color-primary4;
      }
    }

    .searchMenuList {
      @include searchMenuList;
    }

    .searchMenuList__slider {
      position: relative;
      cursor: pointer;
      @include searchMenuList;

      &::before {
        @include arrow($color-gray3, 1px, $arrowDirectionRight);
        position: absolute;
        top: calc(50% - 6px);
        right: 1rem;
        @include screen("tablet-and-pc") {
          right: 1.5rem;
        }
      }
    }

    .searchMenuPrice__list {
      align-items: center;
      @include searchMenuList;
    }

    .searchMenuPrice {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 70%;
      @include screen("smartphone") {
        width: 80%;
      }
    }

    .searchMenuPrice__dashText {
      color: #212121;
      margin-left: 8px;
      margin-right: 8px;
    }

    .searchMenuPrice__priceUnitText {
      color: #212121;
      margin-left: 8px;
    }

    .searchMenuPrice__select {
      background-color: $color-gray5;
      width: 30%;
      text-align: center;

      @include screen("smartphone") {
        width: 40%;
      }
    }

    .searchMenuGoodsQuantity__list {
      align-items: center;
      @include searchMenuList;
    }

    .searchMenuGoodsQuantity {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 70%;

      @include screen("smartphone") {
        width: 80%;
      }
    }

    .searchMenuGoodsQuantity__dashText {
      color: $color-gray1;
      margin-left: 8px;
      margin-right: 8px;
    }

    .searchMenuGoodsQuantity__goodsQuantityUnitText {
      color: $color-gray1;
      margin-left: 8px;
    }

    .searchMenuGoodsQuantity__select {
      background-color: $color-gray5;
      width: 30%;
      text-align: center;
      line-height: 1.5;

      @include screen("smartphone") {
        width: 40%;
      }
    }

    .searchMenu__selectItemText {
      color: $text-gray;
      margin-right: 1.5rem;
      max-width: 70%;
    }
  }

  .searchDialog {
    max-width: 640px;
    margin: 0 auto;
    // NOTE: 画面下部にあるボタンと重ならないようにするため、padding-bottomを設定
    padding-bottom: 80px;
  }

  .searchMenuCheckbox {
    margin-bottom: 8px;
  }

  .searchMenuCheckbox__list {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    @include screen("tablet-and-pc") {
      border-radius: 12px;
      padding: 1.5rem;
    }
  }

  .searchCheckBox {
    width: 50%;
    @include checkBox;

    .checkBox__label {
      padding: 1rem 1rem 1rem 3rem;
      background: $color-gray0;
      position: relative;
      cursor: pointer;
      margin: 1px;
    }

    input[type="checkbox"] {
      &:checked {
        + .checkBox__label {
          background: #f7f7f7;
        }
      }
    }
  }

  .searchCheckboxGroup__head {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    background: $color-gray0;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-primary4;
    cursor: pointer;

    &::after {
      @include arrow($color-gray3, 1px, $arrowDirectionBottom);
      position: absolute;
      right: 1rem;
      transition: 0.1s;
    }

    &.is-open {
      background: rgba($color-primary4, 0.5);

      &::after {
        @include arrow($color-gray3, 1px, $arrowDirectionTop);
      }

      + .searchCheckboxGroup__list {
        display: flex;
      }
    }
  }

  .searchCheckboxGroup__checkBox {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include checkBox;

    .checkBox__label {
      width: 100px;
      height: 30px;
    }
  }

  .searchCheckboxGroup__list {
    display: none;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 20px;
  }

  .searchBtn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background: #fff;
    border-top: 1px solid $color-gray5;

    .c-btn--basic {
      flex-shrink: 0;
      width: unset;
    }

    .c-btn--primary {
      flex: 1 0 0;
      max-width: unset;
    }

    // NOTE: SPでボタンクリック後にopacityが残ることがあるため、クラスを付与することで対応
    .is-sp-opacity-normal {
      @include screen("smartphone") {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .searchBtn__inner {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    position: relative;
  }

  .searchBtn__attention {
    @include typography.caption-bold;
    display: inline-flex;
    background: $color-progress;
    border-radius: 16px;
    padding: 4px 8px;
    position: absolute;
    top: -23px;
    left: 100px;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      bottom: -11px;
      left: 22px;
      border: 7px solid transparent;
      border-left: 17px solid $color-progress;
      transform: rotate(51deg);
    }
  }

  .searchBtn__totalCount {
    @include typography.subTitle-small;
    padding-left: 2px;
    padding-right: 5px;
  }

  .searchCategoryCheckboxGroup__head {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    background: $color-gray0;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-primary4;
    cursor: pointer;
  }

  .searchCategoryCheckboxGroup__collapse {
    &::after {
      @include arrow($color-gray3, 1px, $arrowDirectionBottom);
      position: absolute;
      right: 1rem;
      transition: 0.1s;
    }

    &.is-open {
      background: rgba($color-primary4, 0.5);

      &::after {
        @include arrow($color-gray3, 1px, $arrowDirectionTop);
      }

      + .searchCheckboxGroup__list {
        display: flex;
      }
    }
  }

  .searchCategoryCheckboxGroup__checkBox {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include checkBox;

    .checkBox__label {
      width: 100px;
      height: 30px;
    }
  }
}

.filterMenu {
  $item-height: 36px;

  .filterMenu__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;

    // 最大２行表示
    min-height: $item-height;
    max-height: $item-height * 2 + 8px;

    @include scrollbar-hide;
    @include screen("tablet-and-pc") {
      gap: 9px;
      height: $item-height;
    }
  }

  .filterMenu__item {
    height: $item-height;
    padding: 10px 14px;
    color: $color-gray1;
    display: flex;
    align-items: center;
    gap: 4px;
    @include typography.button-small;
    background: $color-gray6;
    border: 1px solid $color-gray4;
    border-radius: 28px;
    user-select: none;
    cursor: pointer;

    &.filterMenu__checked {
      background: $color-primary3;
      border: 1px solid $color-primary2;
    }
  }

  .filterMenu__icon {
    height: 16px;
    width: 16px;
  }
}

.p-searchFormNarrow__labelPanel {
  background: $color-gray0;
  margin-bottom: 8px;
}

.p-searchFormNarrow__labelPanelTitle {
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;
  padding: 12px 16px;

  &::after {
    @include arrow($color-gray3, 1px, $arrowDirectionBottom);
    position: absolute;
    right: 1rem;
    transition: 0.1s;
  }
}

.p-searchFormNarrow__labelPanelItems {
  display: none;
  flex-wrap: wrap;
}

.p-searchFormNarrow__labelPanel.is-open {
  .p-searchFormNarrow__labelPanelTitle::after {
    @include arrow($color-gray3, 1px, $arrowDirectionTop);
  }

  .p-searchFormNarrow__labelPanelItems {
    display: flex;
  }
}
