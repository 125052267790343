.p-searchCategoryIndexSection {
  & + .p-searchCategoryIndexSection {
    margin-top: 16px;
  }

  // MEMO h2 は .h2-title を利用しているためありません
  .p-searchCategoryIndexSection__h3 {
    color: $color-gray1;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;

    background-color: $color-gray6;
    padding: 4px 8px;

    & + .c-searchCategorySubList {
      margin-top: 12px;
    }

    & + .c-searchCategoryWithIconList {
      margin-top: 12px;
    }
  }

  .p-searchCategoryIndexSection__body {
    padding: 16px 16px 20px;
  }

  .p-searchCategoryIndexSection__second {
    & + .p-searchCategoryIndexSection__second {
      margin-top: 16px;
    }
  }
}
