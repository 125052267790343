@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/variables/color";

.c-toastMessage {
  --_border: 1px solid #{color.$color-success};
  --_background-color: #{color.$color-success-background};

  display: flex;
  min-width: 300px;
  align-items: center;

  color: color.$color-gray1;

  border-radius: 4px;
  border: var(--_border);
  background-color: var(--_background-color);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);

  padding: 16px;

  &.c-toastMessage--success {
    --_border: 1px solid #{color.$color-success};
    --_background-color: #{color.$color-success-background};
  }

  &.c-toastMessage--preogress {
    --_border: 1px solid #{color.$color-progress};
    --_background-color: #{color.$color-progress-background};
  }

  &.c-toastMessage--warning {
    --_border: 1px solid #{color.$color-warning};
    --_background-color: #{color.$color-warning-background};
  }

  &.c-toastMessage--annotation {
    --_border: 1px solid #{color.$color-annotation};
    --_background-color: #{color.$color-annotation-background};
  }

  .c-toastMessage__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .c-toastMessage__title {
    @include typography.button;
  }

  // stylelint-disable tabechoku/selector-type-allowed-list
  .c-toastMessage__message {
    & > a {
      text-decoration: underline; // MEMO: 背景色の関係で色の指定をしません。
    }
    @include typography.body-small;
  }
  // stylelint-enable tabechoku/selector-type-allowed-list

  .c-toastMessage__link {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid color.$color-gray3;
    background: color.$color-gray6;

    padding: 12px 16px;

    @include typography.button-small;
  }
}
