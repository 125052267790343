@use "../variables/font";
@use "screen";

/*

# タイポグラフィーを指定する mixin

## Figma での確認
- https://www.figma.com/file/MinQ1wwvMMzoEBDp6H6kmv/

## SCSS での使い方

- 引数に「list」
  - 商品カードが並ぶときに使います。デフォルトではこちらを指定しています。
- 引数に「detail」
  - 商品詳細ページでの価格表示に使います。

```scss
.vivid-garden-typography {
  @include typography.price("detail")
}
```
### ⚠️ キーワードがない場合

- キーワードがないのを設定した場合は、コンパイル時にエラーがでます

*/

@mixin price($font-size: "list") {
  @if ($font-size == "list") {
    --font-size: #{font.$font-size3};
  } @else if ($font-size == "detail") {
    --font-size: #{font.$font-size-large5};
  } @else {
    @error "Unknown screen: '#{$font-size}'";
  }

  font-family: Arial, sans-serif;
  font-style: normal;
  font-size: var(--font-size);
  font-weight: font.$font-weight-bold;
  line-height: 1.5;
}

@mixin title-1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0;

  @include screen.screen("smartphone") {
    font-size: 20px;
  }
}

@mixin title-2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.15px;

  @include screen.screen("smartphone") {
    font-size: 18px;
  }
}

@mixin subTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.15px;
}

@mixin subTitle-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.1px;
}

@mixin body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.5px;
}

@mixin body-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.5px;
}

@mixin body-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.25px;
}

@mixin body-small-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.25px;
}

@mixin button {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.5px;
}

@mixin button-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.5px;
}

@mixin label {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.4px;
}

@mixin label-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.4px;
}

@mixin caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

@mixin caption-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.4px;
}
