@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.deliveryDateCalendar {
  margin-bottom: 28px;

  .deliveryDateCalendar__title {
    display: flex;
    justify-content: space-between;
    border-left: 4px solid variable.$color-primary2;
    padding-left: 12px;
    margin-bottom: 16px;
    @include typography.title-2;
  }

  .deliveryDateCalendar__calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-items: center;
    text-align: center;
    font-weight: variable.$font-weight-semibold;

    @include screen.screen("smartphone") {
      grid-gap: 8px;
    }

    @include screen.screen("tablet-and-pc") {
      grid-gap: 12px;
    }
  }

  .deliveryDateCalendar__head {
    width: 42px;
    @include typography.caption-bold;
  }

  .deliveryDateCalendar__day {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    @include typography.subTitle;

    &.deliveryDateCalendar__day--delivery {
      background: variable.$color-primary3;
      border: 4px solid variable.$color-primary3;
    }

    &.deliveryDateCalendar__day--current {
      border: 4px solid variable.$color-primary2;
    }

    &.deliveryDateCalendar__day--past {
      opacity: 0.3;
    }
  }
}

.orderModal {
  display: grid;
  gap: 16px;
  margin-top: 20px;
}

.orderEmptyModal {
  margin-top: 1rem;
}
