@use "../Foundation/mixin/typography";
@use "../Foundation/mixin/arrow";
@use "./reorders/buyAgain";
@use "./orders/postItem";

@use "../Object/Component/mypage/orders/c-shippingProducerInfo";
@use "../Object/Component/mypage/orders/c-shippingDetailButton";

@use "../Object/Project/mypage/orders/p-shipmentOrderArticle";
@use "../Object/Project/mypage/orders/p-ordersListing";
@use "../Object/Project/mypage/orders/p-orderItemList";

/* 注文履歴 and 再び購入*/

.order-slim-wrap {
  background-color: $color-gray5;
  padding-bottom: 80px;

  .container {
    max-width: 700px;
    background-color: $color-gray0;
    padding: 0;
  }

  .no-orders {
    padding: 80px 0 100px;

    > p {
      @include typography.body-bold;
      text-align: center;
    }
  }
}

.mypage-order-posts-listing {
  margin-bottom: 20px;

  .post-item {
    background-color: $color-primary4;
    border-radius: 4px;
    margin-top: 8px;
    padding: 16px;
    position: relative;

    &::after {
      @include arrow.arrow($color-primary2, 1px, arrow.$arrowDirectionRight);
      position: absolute;
      right: 16px;
      top: calc(50% - 4px);
    }

    .post-item__title {
      @include typography.subTitle-small;
      margin-bottom: 4px;
    }

    .post-item__body {
      @include typography.body-small;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
    }
  }
}

.each-shipping-header {
  font-size: 14px;
  font-weight: bold;
  margin: 35px 0 10px;
}

section.history {
  dl.list-table {
    margin-bottom: 30px;

    dd.shipping-detail {
      .order-status {
        margin-bottom: 0.5em;
      }

      p {
        margin-top: 0.25em;
      }
    }

    div.set-products-detail {
      padding-bottom: 3px;

      ul.set-products-detail-list {
        padding-left: 3px;
      }
    }
  }

  ul.product-list {
    li:not(:last-child) {
      border-bottom: 1px solid #f4f4f4;
      margin-bottom: 16px;
    }
  }

  table.total-price-detail {
    width: 100%;

    tr {
      th,
      td {
        padding: 0.25em 0;
      }

      td {
        text-align: right;

        &.coupon-total {
          color: #dc0800;
        }
      }

      &:nth-last-child(2) {
        th,
        td {
          padding-bottom: 0.5em;
        }
      }

      &:last-child:not(:first-child) {
        border-top: 1px solid $color-gray5;

        th,
        td {
          padding-top: 12px;
        }
      }
    }
  }
}

// 生産者確認中の場合に補足情報を表示する
details.order-status-unaccepted {
  cursor: pointer;
  position: relative;

  summary {
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }

    .summaryTitle__wrap {
      display: flex;
      align-items: center;
    }
  }

  .icon-addition {
    margin-left: $base-margin-2;
  }

  .order-status-addition {
    background-color: $color-primary4;
    padding: 12px;
    margin: 4px 0 8px;
    border-radius: 4px;
    @include typography.body-small;
  }

  .title {
    @include typography.subTitle-small;
  }
}

.orderInfomation {
  color: $color-gray1;
  font-size: $font-size5;

  padding: 8px 0;

  .orderInfomation__reason {
    font-weight: $font-weight-semibold;

    &.orderInfomation__reason--gray {
      color: $color-gray2;
    }

    &.orderInfomation__reason--red {
      color: $color-order1;
    }
  }

  & + .orderInfomation {
    border-top: 1px solid $color-gray4;
  }
}

.gift {
  text-align: center;
  margin-top: 12px;
  color: $color-gray2;
  font-size: $font-size7;
}

.orders__postForProducers {
  max-width: 700px;
  margin: 0 auto 20px;
  @include screen("smartphone") {
    padding: 0 15px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.orderDetail__postForProducers {
  max-width: 700px;
  margin: 0 auto;
  padding: 15px 0;
  @include screen("smartphone") {
    padding: 15px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.orderSearch {
  padding: 24px;
  border-bottom: 1px solid $color-gray5;
  display: flex;
  gap: 16px;

  @include screen("smartphone") {
    gap: 8px;
  }

  .orderSearch__form {
    border: 1px solid $color-gray4;
    border-radius: 4px;
    padding: 8px 12px 8px 32px;
    width: calc(100% - 65px - 16px);
    background: {
      image: url("/app/assets/images/icon-search-gray.png");
      repeat: no-repeat;
      position: 10px center;
      size: 12px auto;
    }

    @include screen("smartphone") {
      width: calc(100% - 65px - 8px);
    }
  }

  .orderSearch__button {
    @include typography.button;
    border-radius: 4px;
    padding: 12px 16px;
    width: 65px;
  }
}
