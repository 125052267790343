.l-header {
  border-bottom: 1px solid $color-gray5;
  width: 100%;
  background-color: $color-gray0;
  z-index: $zIndex-header;
  position: sticky;
  top: 0;

  .l-header__inner {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include screen("smartphone") {
      display: block;
      padding: 0;
    }
  }

  .l-headerTopNav {
    height: 62px;
    width: 100%;
    display: grid;
    grid-template-areas: "logo search navi";
    grid-template-columns: 210px 1fr auto;
    align-items: center;
    gap: 28px;

    @include screen("smartphone") {
      padding: 0 4px 0 16px;
      height: auto;
      width: 100%;
      grid-template-areas:
        "logo navi"
        "search search";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 51px 47px;
      gap: 0px;
    }
  }

  .l-headerTopNav__logo {
    grid-area: logo;

    --_logo-width: 104px;
    --_gap: 4px;

    display: grid;
    grid-template-columns: var(--_logo-width) 35px;
    align-items: center;
    gap: var(--_gap);

    height: 50px;

    @include screen("tablet-and-pc") {
      --_logo-width: 188px;
      --_gap: 8px;
    }
  }

  .l-headerTopNav__nav {
    grid-area: navi;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .l-headerTopNav__navBtn {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: $font-size9;

    @include screen("tablet-and-pc") {
      width: 62px;
      height: 62px;
    }

    // stylelint-disable selector-class-pattern -- communication.scssと同時に改善を行う
    .dropdown {
      &.is-right {
        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }

    .dropdown-menu {
      min-width: 18rem;
    }

    @include screen("smartphone") {
      $leftAdjust: 140px;
      .dropdown.is-right .dropdown-menu {
        right: -$leftAdjust;
      }
      .dropdown .dropdown-content.dropdown-content-notification:before {
        right: 14px + $leftAdjust;
      }
      .dropdown .dropdown-content.dropdown-content-notification:after {
        right: 12px + $leftAdjust;
      }
    }
    // stylelint-enable selector-class-pattern
  }

  .iconHamburger {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    position: relative;

    // stylelint-disable-next-line tabechoku/selector-type-allowed-list
    span {
      position: absolute;
      display: block;
      width: 18px;
      height: 2px;
      left: calc(50% - 9px);
      background: #403a39;
      border-radius: 4px;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: calc(50% - 1px);
      }

      &:nth-child(3) {
        bottom: 0px;
      }
    }
  }

  .is-open {
    .iconHamburger {
      // stylelint-disable-next-line tabechoku/selector-type-allowed-list
      span {
        &:nth-child(1) {
          transform: rotate(-45deg);
          top: calc(50% - 1px);
          transition-duration: 0.1s;
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          transform: rotate(45deg);
          bottom: calc(50% - 1px);
          transition-duration: 0.1s;
        }
      }
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .dropdown-trigger,
  .btnCart,
  .btnInbox,
  .btnHamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size9;
    height: 36px;
    width: 42px;
    line-height: 1;
    letter-spacing: 0;
    padding: 0;
    background-color: transparent;
    color: $color-gray1;

    @include screen("tablet-and-pc") {
      height: 40px;
    }

    .is-icon {
      height: 24px;
      width: auto;

      svg {
        height: 24px;
        width: auto;
      }
    }
  }

  .btnInbox .is-icon {
    margin-left: 3px;
  }
  .btnCart .is-icon {
    margin-left: -7px;
  }

  .l-headerTopNav__search {
    grid-area: search;

    @include screen("smartphone") {
      align-self: start;
      padding: 0 12px 0 0;
    }
  }
}
