.c-searchCategoryList2More {
  text-align: center;

  .c-searchCategoryList2More__button {
    display: flex;
    align-items: center;
    gap: 8px;

    color: #a18845;
    font-size: $font-size4;
    font-weight: bold;
    background-color: #ffffff;
    margin: 0 auto;

    &[area-hidden="false"] {
      display: inline-block;
    }

    &[area-hidden="true"] {
      display: none;
    }

    &::before {
      @include arrow(#a18845, 2px, $arrowDirectionBottom);
    }
  }
}
