/*
# p-formGroup

## 概要

入力項目、項目説明文、エラーメッセージ、入力欄、項目説明補助文のレイアウトをします。

## 使用例

- ラベル（p-formGroup__label）
  - 入力項目、必須・任意
- 項目説明文（p-formGroup__describedby）
  - 例）8文字以上16文字以下
  - aria-describedby 属性と合わせています
- エラーメッセージ
- 入力欄
- 項目説明補助文
  - 例）食べチョクサイト上で表示されます。
  - 例）大切な注文の連絡や、当社または提携企業のサービスのご案内をお届けします。

## 検討事項

- `.p-formGroup__helptext` の a要素は下線をつけます。

*/

@use "../../../Foundation/mixin/typography";

.p-formGroup {
  display: block;

  & + .p-formGroup {
    margin-top: 16px;
  }

  .p-formGroup__label {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .p-formGroup__statusLabel {
    min-width: 3em;
  }

  .p-formGroup__labelTitle {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    line-height: 1.4;
    letter-spacing: 0.1px;

    text-align: left;
  }

  .p-formGroup__describedby {
    font-size: $font-size5;
    line-height: 1.5;
    letter-spacing: 0.4px;
    text-align: left;

    margin: 4px 0 0;
  }

  .p-formGroup__errors {
    color: #ff0000;
    font-size: $font-size5;
    font-weight: $font-weight-semibold;
    line-height: 1.5;

    margin: 4px 0 0;
  }

  .p-formGroup__errorsMessages {
    margin: 0;
    padding: 0;
  }

  .p-formGroup__errorMessage {
    text-align: left;
  }

  .p-formGroup__inputs {
    margin: 4px 0 0;
  }

  .p-formGroup__helptext {
    font-size: $font-size7;
    line-height: 1.5;
    letter-spacing: 0.4px;

    margin: 4px 0 0;
  }

  .p-formGroup__helptextLink {
    text-decoration: underline;
  }

  .p-formGroup__strongLabel {
    color: $color-primary1;
    background: $color-gray6;
    padding: 8px;
    border-radius: 4px;
    margin-top: 4px;
    @include typography.body-small-bold;
  }

  .p-formGroup__strong {
    font-weight: $font-weight-semibold;
  }

  .p-formGroup__warning {
    color: $color-warning;
  }

  .p-formGroup__counter {
    @include typography.subTitle-small;
    text-align: right;
  }
}
