.p-searchKeywordrankingTitleLine {
  background: $color-primary4;
  padding: 12px 20px;

  .p-searchKeywordrankingTitleLine__title {
    display: inline;
    font-size: $font-size4;
    font-weight: $font-weight-bold;
  }

  .p-searchKeywordrankingTitleLine__updatedDate {
    display: inline;
    font-size: $font-size-base;
  }
}
