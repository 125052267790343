// Aspect Ratio
$aspect-square: 1 #{"/"} 1; // NOTE: 直接 `/` 演算子を使うと計算結果が代入されてしまうので避ける。
$aspect-wide: 16 #{"/"} 9;
$aspect-grid-one-3rd: 33 #{"/"} 37;
$aspect-photo: 4 #{"/"} 3;
$aspect-thumb: 3 #{"/"} 2;
$aspect-article-thumb: 143 #{"/"} 75;
$aspect-banner: 296 #{"/"} 115;
$aspect-sidebar-banner: 343 #{"/"} 104;
$aspect-short-banner: 8 #{"/"} 5;
$aspect-og-image: 1200 #{"/"} 630;
$aspect-logo: 339 #{"/"} 89;
