@use "../../Foundation/mixin/typography";

.p-producerInfo {
  .p-producerInfo__inner {
    @include screen("pc-only") {
      gap: 24px;
      display: grid;
      grid-template-columns: 144px 1fr auto;
    }

    @include screen("tablet-and-smartphone") {
      display: grid;
      gap: 16px;
      grid-template-columns: 100px 1fr;
    }

    &.p-producerInfo__inner--tandem {
      @include screen("tablet-and-smartphone") {
        grid-template-columns: none;
        text-align: center;
      }
    }
  }

  .p-producerInfo__imageWrap {
    @include screen("tablet-and-smartphone") {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
  }

  .p-producerInfo__image {
    overflow: hidden;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    @include screen("tablet-and-smartphone") {
      width: 100px;
      height: 100px;
    }
  }

  .p-producerInfo__imageItem {
    width: 100%;
    height: auto;
  }

  .p-producerInfo__text {
    display: grid;
    gap: 4px;
  }

  .p-producerInfo__area {
    @include typography.body;
  }

  .p-producerInfo__nameArea {
    margin-bottom: 12px;

    > .p-producerInfo__name {
      display: inline;
    }
  }

  .p-producerInfo__name {
    word-break: break-all;
    @include typography.title-2;
  }

  .p-producerInfo__repName {
    word-break: break-all;
    display: inline;
    @include typography.title-2;

    &::before {
      content: "";
      border-left: 1px solid $color-gray1;
      margin-right: 8px;
    }
  }

  .p-producerInfo__founderOn {
    color: $color-gray2;
    @include typography.body-small;
  }

  .p-producerInfo__registeredAt {
    color: $color-gray2;
    @include typography.body-small;
  }

  .p-producerInfo__followWrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include screen("tablet-and-smartphone") {
      display: flex;
      flex-direction: unset;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .p-producerInfo__description {
    padding-top: 16px;
    border-top: 1px solid $color-gray5;
    overflow: hidden;
    @include typography.body;
  }
}
