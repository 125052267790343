// TODO: ここから期間限定の朝市/夜市キャンペーンで使用します
//        キャンペーンが終了したら削除してください
.market-collection-title {
  margin-bottom: 1rem;
  line-height: 1.2;
  text-align: center;
  position: relative;
  @include screen("smartphone") {
    display: flex;
    text-align: left;
  }

  .img-wrap {
    margin-bottom: 12px;
  }

  .title-wrap {
    @include screen("smartphone") {
      margin-top: 4px;
      margin-left: 16px;
    }
    .main {
      font-size: 24px;
      font-weight: bold;
      @include screen("smartphone") {
        font-size: 18px;
      }
    }

    .sub {
      font-size: 12px;
      font-weight: bold;
      color: $color-order1;
      margin-top: 10px;
    }
  }
  img {
    height: auto;
    width: 67px;
  }

  span {
    display: inline-block;
  }

  &.icon {
    @include screen("smartphone") {
      padding-top: 0;
    }

    .main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include screen("smartphone") {
        display: block;
      }

      &::before {
        content: "";
        width: 100px;
        height: 1px;
        background-color: #000;
        @include screen("smartphone") {
          content: none;
        }
      }

      &::after {
        content: "";
        width: 100px;
        height: 1px;
        background-color: #000;
        @include screen("smartphone") {
          content: none;
        }
      }
    }
  }
}
