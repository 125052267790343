// note: https://projects.lukehaas.me/css-loaders/
.c-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-loader--positionAbsolute {
  position: absolute;
}

.c-loader > .c-loader__content {
  margin: 10px auto;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: c-loader-animation 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes c-loader-animation {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #3e3d3e,
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.2),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.2),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.2),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.5),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.7),
      1.8em -1.8em 0 0em #3e3d3e,
      2.5em 0em 0 0em rgba(62, 61, 62, 0.2),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.2),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.2),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.5),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.7),
      2.5em 0em 0 0em #3e3d3e,
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.2),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.2),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.2),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.5),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.7),
      1.75em 1.75em 0 0em #3e3d3e,
      0em 2.5em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.2),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.2),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.5),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.7),
      0em 2.5em 0 0em #3e3d3e,
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.2),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.2),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.2),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.5),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.7),
      -1.8em 1.8em 0 0em #3e3d3e,
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.2),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.2),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.2),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.5),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.7),
      -2.6em 0em 0 0em #3e3d3e,
      -1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(62, 61, 62, 0.2),
      1.8em -1.8em 0 0em rgba(62, 61, 62, 0.2),
      2.5em 0em 0 0em rgba(62, 61, 62, 0.2),
      1.75em 1.75em 0 0em rgba(62, 61, 62, 0.2),
      0em 2.5em 0 0em rgba(62, 61, 62, 0.2),
      -1.8em 1.8em 0 0em rgba(62, 61, 62, 0.5),
      -2.6em 0em 0 0em rgba(62, 61, 62, 0.7),
      -1.8em -1.8em 0 0em #3e3d3e;
  }
}
