@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/typography";

.c-shippingStepper {
  --icon-on: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2211.9893%22%20cy%3D%2212%22%20r%3D%2210%22%20fill%3D%22%239F8745%22%20stroke%3D%22%239F8745%22%20stroke-width%3D%223%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M17.7136%208.29289C18.1041%208.68342%2018.1041%209.31658%2017.7136%209.70711L11.0239%2016.3968C10.6333%2016.7873%2010.0002%2016.7873%209.60966%2016.3968L6.28215%2013.0693C5.89163%2012.6788%205.89163%2012.0456%206.28215%2011.6551C6.67268%2011.2645%207.30584%2011.2645%207.69637%2011.6551L9.60966%2013.5684C10.0002%2013.9589%2010.6333%2013.9589%2011.0239%2013.5684L16.2993%208.29289C16.6899%207.90237%2017.323%207.90237%2017.7136%208.29289Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E");
  --icon-off: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2212.0117%22%20cy%3D%2212%22%20r%3D%2210%22%20stroke%3D%22%23E9E9E9%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E");
  --border-on: #{color.$color-primary1};
  --border-off: #{color.$color-gray6};
  --icon: var(--icon-on);
  --border-color: var(--border-on);

  &[data-nocurrent="false"] {
    --icon: var(--icon-off);
    --border-color: var(--border-off);
  }

  & + .c-shippingStepper {
    margin-top: 2rem;
  }

  .c-shippingStepper__list {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    &:has(> :nth-child(2):last-child) {
      width: 75%;
      margin: 0 auto;
    }
  }

  .c-shippingStepper__item {
    display: flex;

    & + .c-shippingStepper__item {
      flex-grow: 1;

      &::before {
        flex: 1;
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 6px;
        background-color: var(--border-color);

        margin: 12px -1em 0;
      }
    }

    &[aria-current="true"] ~ .c-shippingStepper__item {
      // memo: 現在時点以降
      font-weight: bold;
      --icon: var(--icon-off);
      --border-color: var(--border-off);
    }
  }

  .c-shippingStepper__label {
    @include typography.body;

    min-width: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      background-image: var(--icon);
    }
  }
}
