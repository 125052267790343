@use "../../Foundation/mixin/typography";

$switch_width: 34px;
$switch_height: 18px;
$slider_width: 14px;
$slider_height: 14px;
$slider_translate: 16px;

.switch {
  position: relative;
  display: inline-block;
  width: $switch_width;
  height: $switch_height;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-gray3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100px;

    &::before {
      position: absolute;
      content: "";
      height: $slider_height;
      width: $slider_width;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $color-primary2;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $color-primary2;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX($slider_translate);
      -ms-transform: translateX($slider_translate);
      transform: translateX($slider_translate);
    }
  }
}

.form-switch-checkbox {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  @include typography.body;
}

.switch-checkbox {
  display: flex;
  align-items: center;

  .text {
    margin-left: 8px;
    text-align: left;
    min-width: 66px;
  }
}
