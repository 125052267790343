@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/typography";

.c-ordersMessageAccordion {
  --background-icon: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2215%22%20cy%3D%2215%22%20r%3D%2215%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M8.35742%2016.5L14.3678%2010.4896C14.5631%2010.2943%2014.8797%2010.2943%2015.0749%2010.4896L21.0853%2016.5%22%20stroke%3D%22%23F45800%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  --background-icon-rotate-top: rotate(0deg);
  --background-icon-rotate-under: rotate(180deg);
  --background-color: #{color.$color-annotation-background};
  --header--color: #{color.$color-annotation};
  --rows-true: 1fr;
  --rows-false: 0fr;

  @include typography.body;

  border-radius: 4px;
  background-color: var(--background-color);

  padding: 28px;

  &[data-active="true"] {
    --rows: var(--rows-true);
    --background-icon-rotate: var(--background-icon-rotate-top);
  }

  &[data-active="false"] {
    --rows: var(--rows-false);
    --background-icon-rotate: var(--background-icon-rotate-under);
  }

  &.c-ordersMessageAccordion--annotation {
    --background-color: #{color.$color-annotation-background};
    --header--color: #{color.$color-annotation};
    --background-icon: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2215%22%20cy%3D%2215%22%20r%3D%2215%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M8.35742%2016.5L14.3678%2010.4896C14.5631%2010.2943%2014.8797%2010.2943%2015.0749%2010.4896L21.0853%2016.5%22%20stroke%3D%22%23F45800%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }

  &.c-ordersMessageAccordion--primary {
    --background-color: #{color.$color-primary4};
    --header--color: #{color.$color-gray1};
    --background-icon: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2215%22%20cy%3D%2215%22%20r%3D%2215%22%20fill%3D%22%23F0E8D5%22%2F%3E%3Cpath%20d%3D%22M8.35693%2016.5L14.3673%2010.4896C14.5626%2010.2943%2014.8792%2010.2943%2015.0744%2010.4896L21.0849%2016.5%22%20stroke%3D%22%239F8745%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }

  .c-ordersMessageAccordion__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .c-ordersMessageAccordion__title {
    color: var(--header--color);
  }

  .c-ordersMessageAccordion__label {
    @include typography.body-bold;
  }

  .c-ordersMessageAccordion__toggleButton {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: var(--background-icon);
    transform: var(--background-icon-rotate);
  }

  .c-ordersMessageAccordion__body {
    display: grid;
    transition: grid-template-rows 0.5s;
    grid-template-rows: var(--rows);
  }

  .c-ordersMessageAccordion__bodyInner {
    overflow: hidden;
  }

  .c-ordersMessageAccordion__actions {
    margin-top: 10px;
  }
}
