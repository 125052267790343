@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/variables/font";

.c-magazinesTitleConditions {
  display: grid;
  grid-template-columns: 80px auto;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: color.$color-primary4;

  padding: 12px;

  .c-magazinesTitleConditions__image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .c-magazinesTitleConditions__body {
    display: grid;
    gap: 4px;
  }

  .c-magazinesTitleConditions__titleName {
    color: color.$color-primary1;

    @include typography.subTitle;
  }

  .c-magazinesTitleConditions__condition {
    margin: 0;

    @include typography.body-small;
  }

  .c-magazinesTitleConditions__holderCount {
    font-size: font.$font-size9;
    line-height: 1.5;
    letter-spacing: 0.217px;

    margin: 0;
  }
}
