.p-recipeUseProductCard {
  @at-root {
    .p-recipeUseProductCard_title {
      color: $color-primary1;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }
  }
}
