.c-searchCategoryWithIconList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }

  .c-searchCategoryWithIconList__item {
    min-width: 0; // Memo : ellipsis 対策
  }

  .c-searchCategoryWithIconList__itemLink {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .c-searchCategoryWithIconList__itemIcon {
    border-radius: 50%;
    height: 64px;
    width: 64px;

    @include screen("tablet-and-pc") {
      height: 80px;
      width: 80px;
    }
  }

  .c-searchCategoryWithIconList__itemLabel {
    max-width: 100%;

    font-size: $font-size5;
    font-weight: $font-weight-bold;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.25px;

    margin-top: 4px;
  }
}
