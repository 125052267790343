// メモ： ttl-recommendと統一したい

.p-recipeCategoryHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  @include screen("tablet-and-pc") {
    align-items: center;
  }

  .p-recipeCategoryHeader__title {
    display: flex;
    gap: 8px;

    @include screen("tablet-and-pc") {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .p-recipeCategoryHeader__titleItem {
      font-size: 18px;
      font-weight: bold;

      @include screen("tablet-and-pc") {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &::before,
        &::after {
          content: "";
          flex-grow: 1;
          max-width: 200px;
          height: 1px;
          background-color: #000000;
        }
      }
    }
  }

  .p-recipeCategoryHeader__description {
    font-size: 12px;
    font-weight: bold;
    color: $text-brown;
    width: fit-content;
  }
}
