@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/line-clamp";

.c-reelCard {
  position: relative;

  .c-reelCard__title {
    @include line-clamp.line-clamp(2);
    @include typography.caption-bold;
    padding: 0 4px;
    margin-bottom: 4px;
  }

  .c-reelCard__producerName {
    @include line-clamp.line-clamp(1);
    @include typography.caption;
    padding: 0 4px;
  }

  .c-reelCard__producerImg {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid variable.$color-gray0;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .c-reelCard__thumbnail {
    overflow: hidden;
    position: relative;
    aspect-ratio: 9 / 16;
    border-radius: 4px;
    margin-bottom: 8px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 48px / 2);
      left: calc(50% - 48px / 2);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(variable.$color-gray1, 0.5);
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 20px / 2);
      left: calc(50% - 7px);
      width: 20px;
      height: 20px;
      background-image: url("/app/assets/images/icon-play.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
