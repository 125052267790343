@use "../../Foundation/mixin/typography";

.p-searchForm {
  position: relative;
  width: 100%;

  .searchForm__container {
    position: relative;
    width: 100%;
    max-width: 650px;
    background: #fff;
    z-index: $zIndex-searchForm;
    @include screen("smartphone") {
      min-height: 35px;
      max-width: unset;
    }
  }

  .searchKeyword__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    position: relative;

    .searchKeyword__closeBtn {
      font-size: $font-size9;
      font-weight: $font-weight-bold;
      letter-spacing: 0;
      color: $color-primary2;
      padding: 8px 0 8px 8px;
      position: absolute;
      right: 0;
      background: none;
    }

    .searchKeyword__deleteBtn {
      display: none;
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      right: 62px;
      background: none;
      -webkit-tap-highlight-color: transparent;
    }

    .searchKeyword__deleteBtnContainer {
      width: 22px;
      height: 22px;
      top: 7px;
      left: 7px;
      background: #ccc;
      border-radius: 50%;
      position: absolute;
      right: 70px;

      // prettier-ignore
      > span { // stylelint-disable-line tabechoku/selector-type-allowed-list
        position: absolute;
        display: block;
        width: 14px;
        height: 1px;
        left: calc(50% - 7px);
        background: $color-gray5;
        border-radius: 4px;

        &:nth-child(1) {
          transform: rotate(-45deg);
          top: calc(50% - 1px);
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          top: calc(50% - 1px);
        }
      }
    }

    .searchKeyword__field {
      width: 100%;
      height: 100%;
      border-radius: 50px;
      padding: 4px 0 4px 30px;
      background-color: $color-gray5;
      background: {
        color: #f2f2f2;
        image: url("/app/assets/images/icon-search-gray.png");
        repeat: no-repeat;
        position: 10px center;
        size: 12px auto;
      }
      -webkit-tap-highlight-color: transparent;
    }
  }

  .searchMenu__wrap {
    display: none;
    overflow-y: scroll;
    position: absolute;
    top: calc(100% / 2 + 32px);
    width: 100%;
    max-height: calc(100vh - 63px);

    @include screen("smartphone") {
      left: 0px;
      top: 51px;
      min-height: calc(100vh - 51px);
    }
  }

  .searchMenu__overlay {
    position: fixed;
    top: 63px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 63px);
    background: rgba(0, 0, 0, 0.2);

    @include screen("smartphone") {
      top: 51px;
      min-height: calc(100vh - 51px);
    }
  }

  .searchMenu {
    position: relative;
    padding: 0;
    min-height: 0;
    background: $color-gray5;
    @include screen("smartphone") {
      min-height: 110vh;
      padding: 0 0 150px;
    }
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
    min-height: 56px;
    border-bottom: 1px solid $color-gray5;
    @include typography.body-small;
    background: $color-gray0;
    color: $color-gray1;
  }

  .menuItem:last-child {
    border-bottom: none;
  }

  .menuItem__empty {
    padding: 32px 16px;
    color: $color-gray2;
  }

  .menuItem__link {
    position: relative;
    &::after {
      position: absolute;
      top: calc(50% - 4px);
      right: 15px;
      @include arrow($color-gray3, 1px, $arrowDirectionRight);
    }
  }

  .menuItem__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  .menuItem__labelText {
    padding: 8px 12px;
    border-radius: 20px;
    background: $color-primary2;
    color: $color-gray0;
    @include typography.label-bold;
  }

  .menuItem__breadcrumb {
    color: $color-gray2;
    font-size: $font-size6;

    .menuItem__breadcrumbItem:not(:last-child) {
      position: relative;
      margin-right: 5px;
      padding-right: 9px;

      &:before {
        position: absolute;
        content: "";
        top: 6px;
        right: 0;
        width: 5px;
        height: 1px;
        background: $color-gray2;
        transform: rotate(35deg);
      }

      &:after {
        position: absolute;
        content: "";
        top: 9px;
        right: 0;
        width: 5px;
        height: 1px;
        background: $color-gray2;
        transform: rotate(-35deg);
      }
    }
  }

  .menuHistory__wrap {
    display: grid;
    gap: 4px;
    @include screen("smartphone") {
      gap: 12px;
    }
  }

  .menuHistory__deleteButton {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .menuHistoryDeleteButton {
    align-items: self-end;
    @include typography.button-small;
    color: $color-primary1;
    background: transparent;
  }

  .searchForm__container.is-open {
    @include screen("smartphone") {
      position: fixed;
      top: 0;
      left: 0px;
      padding: 8px 10px 7px;
      border-bottom: 1px solid $color-gray5;
    }

    .searchKeyword__field {
      margin-right: 60px;
      border: 2px solid $color-primary2;
      padding: 4px 0 4px 8px;
      transition: 0.1s;
      background: {
        color: #f2f2f2;
        image: none;
      }

      + .searchKeyword__deleteBtn {
        display: block;
      }
    }

    .searchMenu__wrap {
      display: block;
    }
  }

  .p-searchForm__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }
}
