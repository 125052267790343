.p-product-recipes {
  display: grid;
  gap: 8px;
  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}

.p-product-recipe-card {
  @include screen("smartphone") {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 12px 14px;
    width: 100%;
  }

  .p-product-recipe-card__link {
    display: flex;
    gap: 16px;
    @include screen("tablet-and-pc") {
      flex-direction: column;
    }
  }

  .p-product-recipe-card__img {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    @include screen("smartphone") {
      border-radius: 0;
      max-width: 50px;
    }

    > img {
      aspect-ratio: $aspect-photo;
      width: 100%;
      height: auto;
      object-fit: cover;
      @include screen("smartphone") {
        aspect-ratio: $aspect-square;
      }
    }
  }

  .p-product-recipe__name {
    font-weight: $font-weight-semibold;
    font-size: $font-size4;
    word-break: break-all;
    @include screen("smartphone") {
      $font-size-base: 14px;
      width: 100%;
    }
  }
}
