// DEPRECATED

.btn-radio {
  position: relative;

  input {
    display: none;
  }

  input[type="radio"]:checked {
    & + label {
      background: #f7f7f7;

      &::before {
        border-color: $color-primary2;
        background: #efdaa2;
      }
    }
  }

  input[type="radio"]:disabled + label {
    color: #999;
    background: #efefef;
    border-color: #ccc;
    cursor: not-allowed;
  }

  input[type="radio"]:checked:disabled + label {
    color: #999;
    background: #efefef;
    border-color: #ccc;
    cursor: not-allowed;
  }

  .radio-checked-label {
    background: #f7f7f7;

    .label-add::before {
      border-color: $color-primary2;
      background: #efdaa2;
    }
  }

  label {
    position: relative;
    display: block;
    padding: 15px 20px 15px 60px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 20px;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      border: 2px solid #ccc;
      border-radius: 50%;
    }
  }

  .label-disabled {
    color: #999;
    background: #efefef;
    border-color: #ccc;
    cursor: not-allowed;
  }

  .label-alert {
    margin-right: 60px;
    .text {
      font-size: 11px;
      color: red;
    }
  }

  .label-attention {
    margin-right: 60px;
    font-size: 11px;
  }

  .label-order-title {
    font-size: 14px;
    font-weight: bold;
  }
} /* btn-radio */

// btn-radio のCSSは React + ブラウザキャッシュの組み合わせで意図しないデザインとなることがある
// ただし、利用箇所も多いため簡単に取り除けない
// .btn-radioは deprecated とし、今後は btn-form-radio を使う

.person {
  dt {
    clear: both;
    float: left;
  }

  dd {
    margin-bottom: 3px;
    padding: 0 55px 0 40px;
  }

  .mb0 {
    margin-bottom: 0;
  }
} /* person */

.btn-form-radio {
  position: relative;

  input {
    display: none;
  }

  label {
    position: relative;
    display: block;
    padding: 15px 20px 15px 60px;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 9px);
      left: 20px;
      width: 18px;
      height: 18px;
      border: 2px solid #ccc;
      background: #fff;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  .label-selected {
    background: #f7f7f7;
    &::before {
      border-color: $color-primary2;
      background: #efdaa2;
    }
  }

  .label-disabled {
    color: #999;
    background: #efefef;
    border-color: #ccc;
    cursor: not-allowed;
  }

  .label-order-title {
    font-size: 14px;
    font-weight: bold;
  }
}
