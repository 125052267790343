.l-footer {
  background: $color-gray0;

  .l-footer_containerApp {
    display: none;
    @media screen and (max-width: $dimen-tablet+ "px") {
      display: block;
      background-color: #fefefd;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding: 25px;
    }
  }

  &_containerTop {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    @media screen and (max-width: $dimen-tablet+ "px") {
      border-top: none;
    }
  }

  &_containerBtm {
    padding: 25px 0;
  }

  &_copy {
    display: block;
    text-align: center;
  }

  .l-footer_prefectureSitemap {
    width: 82%; // .p-footerSitemap_container の flex-basis が、スマートフォンで 41% で設定されているため x 2 で 82%
    max-width: calc(700px * 0.92); // .p-footerSitemap_container の flex-basis が、PC で、23% で x 4 で 0.92
    margin: 25px auto;

    font-size: 12px;
    @include screen("tablet-and-pc") {
      font-size: 14px;
    }
  }

  .l-footer_prefectureSitemapTitle {
    & {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 20px;
    }

    @include screen("tablet-and-pc") {
      font-size: 14px;
    }
  }

  .l-footer_prefectureSitemapLinks {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    @include screen("tablet-and-pc") {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .l-footer_prefectureSitemapItemLink {
    color: #1b1b1b;
    font-size: 12px;
  }
}
