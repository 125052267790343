.c-imageCarouselModal {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: $base-margin;
  overflow: visible;
  top: 0;
  .c-imageCarouselModal_content {
    @include screen("tablet-and-pc") {
      padding: $base-padding-8;
    }

    // NOTE: app/styles/user/Object/Component/c-modal.scss の設定を上書きする
    // https://github.com/vividgarden/tabechoku_app/blob/a47428ed7a290404928bbc9d0b68312597c95b38/app/styles/user/Object/Component/c-modal.scss#L52-L53
    @include screen("smartphone-horizontal") {
      height: 100%;
      max-height: calc(100% - var(--_modal-form-min-height));
    }
  }

  .c-imageCarouselModal_swiper {
    height: 100%;

    &:not(.c-imageCarouselModal_swiper-one) .swiper-wrapper {
      max-height: calc(100% - 60px); // nav分だけ減らす
    }

    .swiper-slide {
      overflow: hidden;
    }

    .swiper-zoom-container {
      img {
        object-fit: contain;
      }
    }
  }

  .c-imageCarouselModal_nav {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: min(90vw, 400px);
    position: absolute;
    bottom: 0;
    left: 50%; // centering
    transform: translateX(-50%);
    z-index: 999; // .swiper-wrapper より大きい値にする必要がある

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      position: static;
    }

    .swiper-button-prev,
    .swiper-button-next {
      --swiper-navigation-color: #{$color-gray3};
      background: transparent;
      transform: translateY(-4px);
    }
  }
}
