@use "../Foundation/variables/color";

.thread-link {
  position: relative;
  display: flex;
  text-align: left;
  padding: 16px 0;

  + .thread-link {
    border-top: 1px solid #dbdbdb80;
  }

  .thread-thumbnail {
    display: block;
    height: 50px;
    width: 50px;
    margin-right: 16px;
    border-radius: 50%;
    background: {
      image: url(" ../../app/assets/images/product_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  .thread-text {
    width: calc(100% - 66px);

    .thread-top {
      display: flex;
      align-items: center;

      .thread-status {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        padding: 3px 10px;
        border-radius: 4px;
        display: inline-block;
        background-color: color.$color-annotation;
        color: #fff;
      }

      .thread-time {
        font-size: 12px;
        margin-left: auto;
        color: $text-gray;
      }
    }

    .thread-message {
      font-size: 14px;
      color: $color-gray2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .thread-product-name {
      font-size: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}

.p-confirmList {
  font-size: $font-size6;

  dt {
    float: left;
    font-weight: $font-weight-bold;
    padding: 16px 8px;
  }

  dd {
    border-bottom: 1px solid $color-gray5;
    padding: 16px 8px 16px 150px;
  }
}
