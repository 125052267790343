.p-areasRegions {
  & + .p-areasRegions {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 16px;
  }

  .p-areasRegions__prefectures {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 16px;

    @include screen("tablet-and-pc") {
      margin: 0;
    }
  }

  /* stylelint-disable selector-class-pattern */
  /* .h2-title が従来からのクラス名のため */
  .h2-title + .p-areasRegions__prefectures {
    margin-top: 20px;
    margin-top: 16px;

    .c-linkAreaPrefecture {
      color: #9f8745;
      @include screen("tablet-and-pc") {
        color: #212121;
      }
    }
  }
  /* stylelint-enable selector-class-pattern */
}
