.c-privacyInnerSection {
  padding-left: 15px;
  @include screen("smartphone") {
    padding-left: 0;
  }

  .c-privacyInnerSection__title {
    font-weight: $font-weight-semibold;
    padding-bottom: 10px;
  }

  .c-privacyInnerSection__textIndent {
    padding-left: 1em;
    text-indent: -1em;
  }
}

.c-privacyListAlpha {
  list-style-type: lower-alpha;

  li {
    list-style: lower-alpha;
  }
}

.c-privacyListParentheses {
  .c-privacyListParentheses__item {
    list-style-type: none;
    list-style-position: inside;
    counter-increment: cnt;
    padding-left: 1em;
    text-indent: -2em;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &::before {
      display: marker;
      content: "(" counter(cnt) ") ";
    }
  }
}
