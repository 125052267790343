@use "../../Foundation/mixin/typography";

.c-producerSupervisedDescription {
  padding: $base-padding-4;
  border-radius: 20px;
  background-color: $color-gray6;
}

.c-producerSupervisedDescription__description {
  padding-bottom: $base-padding-3;
}

.c-producerSupervisedDescription__descriptionText {
  @include typography.body;
}

.c-producerSupervisedDescription__producerFooter {
  padding-top: $base-padding-3;
  border-top: 1px solid $color-gray4;
  display: flex;
  gap: $base-padding-3;
}

.c-producerSupervisedDescription__thumbnail {
  border-radius: 50%;
}

.c-producerSupervisedDescription__producerTextSummary {
  display: grid;
  grid-template-columns: 1fr 75px;
  align-items: center;
}

.c-producerSupervisedDescription__producerName {
  @include typography.subTitle-small;
  color: $color-gray2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-producerSupervisedDescription__producerNameLink:link,
.c-producerSupervisedDescription__producerNameLink:visited {
  color: $color-gray2;
}

.c-producerSupervisedDescription__producerArea {
  @include typography.caption;
  color: $color-gray2;
}
