@use "../variable";

/*

# ブレイクポイントをキーワードで指定する mixin

- 必ず引数を指定してください
- 必ず引数を必要とするのは、何を指定しているのか伝えるためです

## SCSS での使い方

- 引数に「tablet-and-pc」
  - タブレットとPCの時に適用するスタイルを指定できます
- 引数に「pc-only」
  - タブレットとPCの時に適用するスタイルを指定できます
- 引数に「tablet-only」
  - タブレットの時に適用するスタイルを指定できます
- 引数に「smartphone」
  - スマートフォンの時に適用するスタイルを指定できます
- 引数に該当しないキーワードを入れた場合
  - エラーになります

```scss
.vivid-garden-screen {
  @include screen("smartphone") {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
```

### ⚠️ キーワードがない場合

- キーワードがないのを設定した場合は、コンパイル時にエラーがでます

```scss
.vivid-garden-screen {
  @include screen("smartphone123") {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
```

エラー例

```
SassError: "Unknown screen: smartphone123"
   ╷
17 │   @include screen("smartphone123") {
   │   ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   ╵
```


## 端末幅について

- $dimen-* 変数を参考にしてください
- 注意点： $dimen-tablet 幅の端末は、スマートフォンとなります（例：iPad mini）

## 基準

- スマートフォンの利用が多いので、スマートフォンを基準として考えます

*/

@mixin screen($screen) {
  @if ($screen == "tablet-and-pc") {
    @media screen and (min-width: (variable.$dimen-tablet + 1) + "px") {
      @content;
    }
  } @else if ($screen == "pc-only") {
    @media screen and (min-width: (variable.$dimen-pc + 1) + "px") {
      @content;
    }
  } @else if ($screen == "pc-large-only") {
    @media screen and (min-width: (variable.$dimen-pc-large - 1) + "px") {
      @content;
    }
  } @else if ($screen == "tablet-only") {
    @media screen and (min-width: (variable.$dimen-tablet + 1) + "px") and (max-width: (variable.$dimen-pc - 1) + "px") {
      @content;
    }
  } @else if ($screen == "tablet-and-smartphone") {
    @media screen and (max-width: (variable.$dimen-pc) + "px") {
      @content;
    }
  } @else if ($screen == "smartphone") {
    @media screen and (max-width: variable.$dimen-tablet + "px") {
      @content;
    }
  } @else if ($screen == "smartphone-horizontal") {
    @media screen and (max-height: variable.$dimen-sp-h + "px") {
      @content;
    }
  } @else {
    @error "Unknown screen: '#{$screen}'";
  }
}
