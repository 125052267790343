$arrowDirectionTop: -45deg;
$arrowDirectionRight: 45deg;
$arrowDirectionBottom: 135deg;
$arrowDirectionLeft: -135deg;

@mixin arrow($borderColor, $borderWeight, $direction) {
  content: "";
  height: 8px;
  width: 8px;
  transform: rotate($direction);
  border-top: $borderWeight solid $borderColor;
  border-right: $borderWeight solid $borderColor;
}
