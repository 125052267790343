@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/arrow";

.c-searchTabs__wrap {
  min-height: 68px;
}

.c-searchTabs {
  font-weight: variable.$font-weight-semibold;
  background: variable.$color-gray0;
  width: 100%;
  border-bottom: 4px solid variable.$color-primary2;

  @include screen.screen("smartphone") {
    margin-bottom: 0;
  }

  .c-searchTabs__items {
    display: grid;
    grid-template-columns: 180px 180px;
    gap: 4px;

    @include screen.screen("smartphone") {
      grid-template-columns: 1fr 1fr;
      margin: 0 8px;
    }
  }

  .c-searchTabs__item {
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 52px 10px;
    background: variable.$color-gray6;
    color: variable.$color-gray2;

    svg {
      fill: variable.$color-gray3;
    }

    &.c-searchTabs__item--active {
      background: variable.$color-primary2;
      color: variable.$color-gray0;

      svg {
        fill: variable.$color-gray0;
      }
    }
  }

  .c-searchTabs__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: variable.$color-gray2;
  }

  .c-searchTabs__itemWrap {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: $font-size5;

    svg {
      fill: #999;
    }
  }

  .c-searchTabs__count {
    font-size: variable.$font-size7;
    font-weight: variable.$font-weight-light;
  }
}
