@use "../../Foundation/mixin/typography";

.p-producerFullInfo {
  .p-producerFullInfo__inner {
    @include screen("tablet-and-pc") {
      display: grid;
      grid-template-columns: 360px 1fr;
      justify-content: space-evenly;
      gap: 40px;
    }

    @include screen("smartphone") {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .p-producerFullInfo__block {
    display: flex;
    gap: 16px;
  }

  .p-producerFullInfo__image {
    width: 120px;
    @include screen("smartphone") {
      width: 88px;
    }
  }

  .p-producerFullInfo__imageItem {
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;
    @include screen("smartphone") {
      height: 88px;
      width: 88px;
    }
  }

  .p-producerFullInfo__area {
    @include typography.body-small;
  }

  .p-producerFullInfo__name {
    word-break: break-all;
    margin-bottom: 16px;
    @include typography.title-2;
  }

  .p-producerFullInfo__founderOn {
    margin-bottom: 8px;
    color: $color-gray2;
  }

  .p-producerFullInfo__descriptionWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include screen("smartphone") {
      padding: 16px 0;
    }
  }

  .p-producerFullInfo__description {
    @include typography.body;
  }

  .p-producerFullInfo__btns {
    margin-top: 20px;
  }

  .p-producerFullInfo__btn {
    margin-top: 8px;
    @include screen("smartphone") {
      margin-top: 0;
      padding-top: 16px;
      border-top: 1px solid $color-gray4;
    }
  }

  .p-producerFullInfo__nextBtn {
    margin-top: auto;
  }
}
