@use "../../../Foundation/mixin/screen";

.p-magazinesReviews {
  .p-magazinesReviews__list {
    overflow-x: scroll;
    display: flex;
    gap: 16px;

    @include screen.screen("tablet-and-pc") {
      gap: 16px;
    }
  }

  .p-magazinesReviews__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 300px;
  }
}
