@use "../Foundation/variables/color";

.l-oneColumn {
  min-height: 500px;
  background: color.$color-gray5;

  @include screen("tablet-and-pc") {
    padding-bottom: 60px;
  }

  .l-oneColumn__inner {
    max-width: 700px;
    background-color: color.$color-gray0;
    padding: 24px 16px 40px;
    margin: 0 auto;

    @include screen("tablet-and-pc") {
      padding: 32px 16px 40px;
    }
  }
  .l-oneColumn__header {
    & + .l-oneColumn__body {
      margin-top: 32px;
    }
  }
}
