@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/typography";

.c-goodButton {
  min-width: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: color.$color-gray1;

  border-radius: 4px;
  border: 2px solid color.$color-gray4;
  background: color.$color-gray0;

  padding: 16px;

  @include typography.button;

  &:hover {
    opacity: 0.7;
  }

  &[aria-pressed="true"] {
    color: color.$color-primary1;
    border: 2px solid color.$color-primary3;
    background: color.$color-primary4;
  }

  &.c-goodButton--small {
    height: 48px;
    padding: 12px 16px;
  }

  &.c-goodButton--width100 {
    width: 100%;
  }
}
