@use "../../Foundation/mixin/screen" as mixin;

.p-relatedFeaturedTerm__title {
  font-size: $font-size4;
  font-weight: $font-weight-bold;
  padding-left: 12px;
  border-left: 6px solid $color-primary2;
  margin-bottom: 16px;
}

.p-relatedFeaturedTerm__contents {
  margin-bottom: 30px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include mixin.screen("smartphone") {
    gap: 4px;
    grid-template-columns: none;
  }
}

.p-relatedFeaturedTerm__term {
  background: {
    image: url("/app/assets/images/icon-search-gray.png");
    repeat: no-repeat;
    position: 16px center;
    size: 12px auto;
  }
  border-radius: 50px;
  padding: 10px 0px 10px 40px;
  border: 1px solid $color-gray5;
}
