@use "../../Foundation/variable";
@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/screen";

.c-promotionCode {
  background-color: variable.$color-gray6;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .c-promotionCode__label {
    @include typography.caption;
    color: variable.$color-gray2;
  }

  .c-promotionCode__codeWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .c-promotionCode__code {
    color: variable.$color-gray1;
    font-size: 20px;
    font-weight: 700;

    &.c-promotionCode__code--used {
      color: variable.$color-gray3;
      text-decoration: line-through;
    }
  }

  .c-promotionCode__disableLabel {
    width: fit-content;
    @include typography.label-bold;
    border-radius: 16px;
    padding: 4px 8px;
    color: variable.$color-gray2;
    background: variable.$color-gray4;
  }

  .c-promotionCode__name {
    background: variable.$color-gray5;
    padding: 4px 8px;
    border-radius: 4px;
    @include typography.body-small-bold;
  }
}
