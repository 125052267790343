/* Note: このcssはjquery版モーダルで利用されています */
/* jquery版は徐々に廃止したいがスクリプトが以下構造に依存しているため変更できません */
/* そのため、React版モーダルではjquery版とは別のcssを利用します */
/* stylelint-disable selector-class-pattern -- 既存コンポーネントから流用しているため */
.modal,
.no-scroll-modal {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999999999999;
  @include screen("smartphone") {
    padding-top: 0;
  }

  .inner-modal {
    display: inline-block;
    max-width: 500px;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include screen("smartphone") {
      top: 0;
      left: 0;
      transform: translate(0, 0);
    }

    .h2_ttl {
      position: relative;
      margin-bottom: 20px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      background: #f4f4f4;

      .btn-close {
        position: absolute;
        top: 50%;
        right: 20px;
        padding: 5px 0;
        cursor: pointer;
        z-index: 99999999999999999;
        transform: translate(0, -50%);
        img {
          width: 22px;
          height: auto;
        }
      } /* btn-close */
    } /* h2_ttl */

    .form_account {
      height: 90%;
      padding: 0 20px 20px;
    } /* form_account */
  } /* inner-modal */
} /* modal */

.modal-wrap {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999999999999;

  .inner-modal {
    max-width: 500px;
    width: 90%;
    background: #fff;
    border-bottom: 5px solid $color-primary2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-head {
      padding: 20px 30px;
      background: $color-primary2;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 600;
    }

    .modal-content {
      padding: 30px;
    }

    .modal-close {
      background-color: #fff;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -10px;
      right: -10px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 22px;
        height: 3px;
        background: $color-primary2;
        border-radius: 4px;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  } /* inner-modal */
} /* modal */

/* React版モーダル */
.tabechoku-modal {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 999999999; // FIXME
  @include screen("smartphone") {
    padding-top: 0;
  }

  .tabechoku-inner-modal {
    display: inline-block;
    max-width: 500px;
    width: 100%;
    position: relative;
    background: #fff;
    .h2_ttl {
      position: relative;
      margin-bottom: 20px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      background: #f4f4f4;

      .btn-close {
        position: absolute;
        top: 50%;
        right: 20px;
        padding: 5px 0;
        cursor: pointer;
        z-index: 9999;
        transform: translate(0, -50%);

        img {
          width: 22px;
          height: auto;
        }
      }
    }

    .modal-form {
      height: 90%;
      padding: 0 20px 20px;
    }
  }
} /* tabechoku-modal */

.tabechoku-modal-visible {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* cart-modal */
.cart-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  text-align: center;
  z-index: 99999999;

  .cart-modal-window {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 90%;
    max-width: 600px;
    padding: 30px 20px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    vertical-align: middle;
    z-index: 20;

    &.full-content {
      padding: 0;
    }
  }

  .cart-modal-content {
    max-height: 90vh;
    overflow-y: auto;
    position: relative;

    .content-header {
      margin: 20px 0;
    }

    .head-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      position: relative;

      .btn-close {
        position: absolute;
        top: 50%;
        right: 20px;
        padding: 5px 0;
        cursor: pointer;
        z-index: 9999;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);

        img {
          width: 22px;
          height: auto;
        }
      }
    }

    .cart-alert {
      margin-top: 20px;
      font-size: 13px;
      color: red;
    }

    .content-main {
      margin: 0 30px;
      padding-top: 16px;
      border-top: solid 1px #f2f2f2;
    }

    .content-recommend {
      .product-detail-slider {
        padding: 16px;

        .product-list-wrap {
          display: flex;
          overflow-x: scroll;
          flex-wrap: initial;
          text-align: left;

          .item-wrap {
            flex: 0 0 44%;
            min-width: 130px;
            margin-right: 2%;
            margin-bottom: 0px;
            @include screen("tablet-and-pc") {
              flex: 0 0 calc(25% - 8px);
              margin-right: 8px;
            }
          }
        }
        .product-list-wrap::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .content-footer {
      margin: 20px auto;
      padding: 0 20px;
    }

    .attention-description {
      text-align: left;
      padding: 8px;
    }

    .cart-campaign-attention-description {
      h4 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      ul {
        margin-bottom: 20px;
      }

      li {
        list-style: disc;
        margin-left: 20px;
        font-size: 13px;
      }
    }
  }
}

.cart-modal-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.cart-modal-loading-overlay {
  z-index: 21;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

// vertical center
.cart-modal-wrapper::after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  vertical-align: middle;
  content: "";
}

.cart-modal-invisible {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s,
    visibility 0.2s;
}

.cart-modal-visible {
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.2s,
    visibility 0.2s;
}

.cart-modal-fast-invisible {
  opacity: 0;
}

.cart-modal-delay-visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 1s;
}

.campaign-attention {
  .attention-message {
    font-size: 13px;
  }
  .attention-icon {
    width: 10px;
    height: 10px;
    font-weight: bold;
    color: #fff;
    background: #cab270;
    border-radius: 100%;
    padding: 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .attention-note-button {
    position: absolute;
    cursor: pointer;
    padding: 0;
    background: none;
  }
}
