.p-recipeCategories {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  @include screen("smartphone") {
    grid-template-columns: repeat(2, 1fr);
  }

  @at-root {
    .p-recipeCategories_list {
      background-color: #ffffff;
    }

    .p-recipeCategories_link {
      align-items: center;
      border: 1px solid $color-primary2;
      border-radius: 4px;
      display: flex;
      gap: 8px;
      padding: 12px;
    }

    .p-recipeCategories_icon {
      width: 20px;
    }

    .p-recipeCategories_label {
      font-weight: bold;
    }
  }
}
