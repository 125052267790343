@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";

.c-tabNav {
  --_badge-color: #{color.$color-annotation};
  --_tab-padding: 12px;

  width: 100%;
  display: flex;
  list-style: none;

  font-size: font.$font-size4;
  font-weight: font.$font-weight-bold;
  line-height: 1.4;
  letter-spacing: 0.15px;

  border-bottom: 2px solid color.$color-gray4;
  background: color.$color-gray0;

  &.c-tabNav--small {
    --_tab-padding: 12px 8px;
    @include typography.subTitle-small;
  }

  .c-tabNav__tab {
    flex: 1;
  }

  .c-tabNav__tabLink {
    position: relative;

    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: color.$color-gray2;

    padding: var(--_tab-padding);

    &[aria-selected="true"] {
      color: color.$color-primary1;

      &::after {
        content: "";
        position: absolute;
        display: block;

        bottom: -2px;
        left: 0;
        right: 0;

        height: 4px;
        border-radius: 4px;
        background: color.$color-primary1;
      }
    }
  }

  .c-tabNav__tabLabel {
    position: relative;
    display: inline-block;

    &[data-with-badge="true"] {
      &:hover {
        color: color.$color-primary1;
      }

      &::before {
        position: absolute;
        top: 0;
        right: -10px;
        content: "";
        min-width: 8px;
        min-height: 8px;
        border-radius: 50%;
        background-color: var(--_badge-color);
      }
    }
  }
}
