@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-magazinePush {
  background-image: url("/app/assets/images/products/magazine/background_sp.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 48px 32px;
  margin: 0 12px;

  @include screen.screen("tablet-and-pc") {
    background-image: url("/app/assets/images/products/magazine/background_pc.png");
    background-position: center;
    background-size: 714px 211px;
  }

  .p-magazinePush__inner {
    max-width: 798px;
    display: grid;
    gap: 8px;

    border-radius: 16px;
    border: 3px solid color.$color-primary4;
    background: color.$color-primary0;

    padding: 24px 20px;
    margin: 0 auto;
  }

  .p-magazinePush__subTitle {
    text-align: center;

    @include typography.subTitle;
  }

  .p-magazinePush__title {
    font-size: font.$font-size1;
    font-weight: font.$font-weight-bold;
    line-height: 1.4;
    text-align: center;
  }
}
