.c-liquorAttention {
  color: #4a4a4a;
  display: block;
  padding: 1.25rem 0; // MEMO 元々、.box というクラスがあったので、そこでの指定を継承

  .c-liquorAttention__title {
    font-size: $font-size5;
    font-weight: $font-weight-bold;
  }

  .c-liquorAttention__body {
    font-size: $font-size1; // MEMO : 酒類の通信販売における表示。法律上、価格表示と同じフォントサイズが必要なため。価格表示の共通処理ができたらそちらに合わせる
  }
}
