@use "../../Foundation/mixin/typography";

.c-card01 {
  width: 100%;
  padding: 1.5rem 1.5rem 1.75rem;
  background-color: $color-gray0;
  border-radius: 8px;
  @include screen("tablet-and-pc") {
    border: 1px solid $color-gray5;
    padding: 2rem 2rem 2.5rem;
  }

  .c-card01 + & {
    margin-top: 1.5rem;
  }

  .c-card02 + & {
    margin-top: 1.5rem;
  }

  /* stylelint-disable selector-class-pattern */
  /* FIXME : 従来のクラス名に沿ったため。命名全部しなおせば対応できる */
  .c-inHouseAdvertising + & {
    margin-top: 1.5rem;
  }

  /* stylelint-enable selector-class-pattern */

  &_ribbon-image {
    margin-bottom: 8px;
  }

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &_title-text {
    border-left: 4px solid $color-primary2;
    padding-left: 12px;
    @include typography.title-2;
  }

  &_title-label {
    width: 4rem;
    display: inline-block;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: #ff3860;
    border-radius: 4px;
    padding: 4px 7px;
  }

  &_body {
    padding: 0 10px;
  }

  &_thumbnail-image {
    width: 100%;
    height: auto;
  }

  &_lead {
    font-size: 13px;
    margin: 10px 0 15px;
    @include screen("tablet-and-pc") {
      font-size: 14px;
      margin: 1rem 0 2rem;
    }
  }

  &_lead-strong {
    font-weight: bold;
  }

  &_action-button-caption {
    display: block;
    max-width: 300px;
    text-align: center;
    margin: 0 auto;

    &.-margin-bottom {
      margin-bottom: 0.5rem;
    }
  }
}
