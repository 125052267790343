@use "../../Foundation/mixin/typography";

.p-productPostImages {
  background: $color-gray0;

  @include screen("tablet-and-pc") {
    padding: 16px;
  }

  .p-productPostImages__heading {
    @include typography.subTitle-small;
  }

  .p-productPostImages__grid {
    --_base-image-size: 90px;
    --_gap: #{$base-margin-2};
    display: grid;
    grid-template-rows: repeat(2, var(--_base-image-size));
    gap: var(--_gap);
    overflow: scroll hidden;
    justify-content: flex-start;
    margin-top: $base-margin-3;

    .p-productPostImages__gridItem {
      background: none;
      padding: 0;

      &:nth-child(3n + 1) {
        grid-row: 1 / 3;
        width: calc(2 * var(--_base-image-size) + var(--_gap));
      }

      &:nth-child(3n + 2) {
        grid-row: 1 / 2;
        width: var(--_base-image-size);
      }

      &:nth-child(3n + 3) {
        grid-row: 2 / 3;
        width: var(--_base-image-size);
      }

      img {
        object-fit: cover;
        width: 100%;
        border-radius: 4px;
      }
    }
  }
}
