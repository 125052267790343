@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/line-clamp";

.c-recommendSettingToggleSwitch {
  --_track-size: 1em;
  --_knob-postion: 0;
  --_knob-color: #{color.$color-gray0};
  --_default-color: #{color.$color-gray3};
  --_checked-color: #{color.$color-primary2};
  --_desabled-color: #{color.$color-gray5};
  --_knob-background-color: #{color.$color-primary0};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  cursor: pointer;

  @include typography.body-small-bold;

  background-color: var(--_knob-background-color);
  border-radius: 4px;

  .c-recommendSettingToggleSwitch__product {
    display: flex;
    align-items: center;
  }

  .c-recommendSettingToggleSwitch__productImage {
    min-width: 100px;
    max-width: 100px;
  }

  .c-recommendSettingToggleSwitch__productPhoto {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100px;
    height: 100%;
    min-height: 100px;
    object-fit: cover;
  }

  .c-recommendSettingToggleSwitch__productInfo {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .c-recommendSettingToggleSwitch__productName {
    @include line-clamp.line-clamp(2);
    @include typography.subTitle-small;
  }

  .c-recommendSettingToggleSwitch__productPrice {
    font-family: Arial, sans-serif;
    font-size: font.$font-size3;
    font-weight: font.$font-weight-bold;
    line-height: 1;
    letter-spacing: 0.5px;
  }

  .c-recommendSettingToggleSwitch__productDetail {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .c-recommendSettingToggleSwitch__productWeightLabel {
    // TODO : .c-cardlabel などを作れば置き換えられる
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 3px;

    color: color.$color-primary1;
    font-size: font.$font-size9;
    font-weight: font.$font-weight-bold;
    line-height: 1.5;

    border-radius: 16px;
    background: color.$color-primary3;
  }

  .c-recommendSettingToggleSwitch__producer {
    display: flex;
    align-items: center;
  }

  .c-recommendSettingToggleSwitch__producerImage {
    min-width: 100px;
    max-width: 100px;
  }

  .c-recommendSettingToggleSwitch__producerPhoto {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100px;
    height: 100%;
    min-height: 100px;
    object-fit: cover;
  }

  .c-recommendSettingToggleSwitch__producerInfo {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .c-recommendSettingToggleSwitch__producerArea {
    color: color.$color-gray1;
    font-size: font.$font-size9;
    font-weight: font.$font-weight-normal;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .c-recommendSettingToggleSwitch__producerName {
    @include line-clamp.line-clamp(2);
    @include typography.subTitle-small;
  }

  .c-recommendSettingToggleSwitch__input {
    inline-size: calc(var(--_track-size) * 2);
    block-size: var(--_track-size);

    flex-shrink: 0;

    display: grid;
    align-items: center;
    grid: [track] 1fr / [track] 1fr;
    box-sizing: content-box;
    border-radius: calc(var(--_track-size) * 2);
    background-color: var(--_default-color);
    padding: 2px;
    margin: 0 16px;

    transition: background-color 0.25s ease;

    &:checked {
      --_knob-postion: calc(calc(var(--_track-size) * 2) - 100% * 1);
      background-color: var(--_checked-color);
    }

    &:disabled {
      background-color: var(--_desabled-color);
    }

    &::after {
      grid-area: track;

      inline-size: var(--_track-size);
      block-size: var(--_track-size);

      content: "";
      cursor: pointer;
      pointer-events: auto;

      background-color: var(--_knob-color);
      border-radius: 50%;
      transform: translateX(var(--_knob-postion));
      transition: transform 0.25s ease;
    }
  }
}
