@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";

.p-mypageProfie {
  display: flex;
  justify-content: center;

  border-bottom: 1px solid color.$color-gray5;
  padding-bottom: 30px;

  .p-mypageProfie__link {
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    gap: 16px;
  }

  .p-mypageProfie__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .p-mypageProfie__name {
    word-break: break-all;
    @include typography.subTitle;
  }

  .p-mypageProfie__mail {
    word-break: break-all;
    @include typography.caption;
  }
}
