.wrap {
  &.wrap--withPaddingBottom {
    padding-bottom: 80px;
  }

  &.wrap--withPaddingBottomOnlySP {
    @include screen("smartphone") {
      padding-bottom: 80px;
    }
  }
}
