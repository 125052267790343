/* ========================================================== */
/* TOP */
/* ========================================================== */
.product-list-wrap {
  display: flex;
  @include screen("tablet-and-pc") {
    flex-wrap: wrap;
  }

  @include screen("smartphone") {
    overflow-x: scroll;
  }

  .item-wrap {
    margin-bottom: 15px;
    flex: 0 0 44%;
    min-width: 100px;
    margin-right: 3%;

    @include screen("tablet-and-pc") {
      flex: 0 0 calc(25% - 12px);
      margin-right: 16px;
      margin-bottom: 32px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(n + 9) {
        display: none;
      }
    }

    .list-tag {
      height: 25px;
    }
  }
}
