/* .u-spacing.scss を使用してください。.u-spacing.scss への移行が完了したら削除します。 */

@for $i from 0 through 8 {
  .mt-#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .mb-#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .ml-#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .mr-#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .pt-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .pb-#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .pl-#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .pr-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-sm-auto {
  margin-right: auto !important;
  margin-left: auto !important;
  @include screen("tablet-and-pc") {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.ml-sm-auto {
  margin-left: auto !important;
  @include screen("tablet-and-pc") {
    margin-left: 0 !important;
  }
}

.mr-sm-auto {
  margin-right: auto;
  @include screen("tablet-and-pc") {
    margin-right: 0 !important;
  }
}
