@use "../../Foundation/mixin/typography";

.p-contentHeader {
  margin: auto;

  .p-contentHeader__title {
    margin-bottom: 20px;
  }

  .p-contentHeader__searchCount {
    @include typography.caption;
    text-align: right;
    margin-bottom: 16px;
  }

  .p-contentHeader__description {
    margin-bottom: 16px;
  }

  .p-contentHeader__descriptionParagraph {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
