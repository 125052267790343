.order-product {
  display: flex;

  .thumb {
    display: block;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    background: {
      image: url(" ../../app/assets/images/product_sample.jpg");
      repeat: no-repeat;
      position: center center;
      size: cover;
    }

    &.giftcardThumb {
      background: {
        image: url(" ../../app/assets/images/gift_card_product_sample.jpg");
      }
    }
  }

  .text {
    width: calc(100% - 66px);

    .farm {
      font-size: 12px;
    }

    .name {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
