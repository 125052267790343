.c-favoriteEmpty {
  background: #fff;
  padding: 32px 20px;
  width: 100%;

  &_icon {
    display: block;
    margin: 0 auto 30px;
    @include screen("tablet-and-pc") {
      display: none;
    }
  }

  &_ttl {
    text-align: center;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
