.p-producerPanel {
  padding: 16px 12px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 4px;

  .p-producerPanel__image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .p-producerPanel__area {
    font-size: 12px;
    color: $color-gray3;
  }

  .p-producerPanel__name {
    font-size: 14px;
    font-weight: bold;
  }
}
