.c-modal {
  overflow-y: auto;
  // size
  --c-modal-max-width: 800px;
  --c-modal-max-height: 800px;
  --dialog-width: min(100vw, var(--c-modal-max-width));
  --dialog-height: min(90vh, var(--c-modal-max-height));

  @include screen("smartphone") {
    // HACK: `dvh` 未対応ブラウザのためにJSでも対応する。
    //       See: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    --dialog-height: calc(var(--vh, 1vh) * 100);

    // HACK: 100vh 問題への根本対応。上記のJSが不要になったら有効にする。
    //       See:
    //       * https://caniuse.com/viewport-unit-variants
    //       * https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports/
    //       * https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    // --dialog-height: 100vh; // fallback
    // @supports (height: 100dvh) {
    //   --dialog-height: 100dvh;
    // }
  }

  .c-modal__content {
    width: 100%;
    height: 100%;
  }

  .c-modal__form {
    // noop
  }

  @include screen("smartphone") {
    --_modal-form-min-height: 70px;

    .c-modal__content {
      max-height: calc(100% - var(--_modal-form-min-height));
    }

    .c-modal__form--sm {
      min-height: var(--_modal-form-min-height);
    }

    .c-modal__form--lg {
      display: none;
    }
  }

  @include screen("smartphone-horizontal") {
    .c-modal__content {
      height: auto;
      max-height: unset;
    }
  }

  @include screen("tablet-and-pc") {
    .c-modal__form--sm {
      display: none;
    }
  }

  .c-modal__form--sm {
    .c-modal__closeButton--sm {
      font-size: $font-size5;
      margin: $base-margin-2 auto $base-margin-4;
      max-width: 95%;
      position: relative;

      svg {
        position: absolute;
        left: 0;
        margin: 0 1em;
        width: 1em;
        height: 1em;
        fill: $color-gray1;
      }
    }
  }

  .c-modal__form--lg {
    .c-modal__closeButton--lg {
      background: none;
      position: fixed;
      top: $base-margin-4;
      right: $base-margin-4;
      width: 20px;
      height: 20px;

      svg {
        fill: $color-gray0;
      }
    }
  }
}
