@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/arrow";

.p-neighborhood {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  background-color: $color-primary4;
  padding: 16px;

  .p-neighborhood__desc {
    @include typography.body-small;
    text-align: center;
  }

  .p-neighborhood__showbtn {
    @include typography.button;
    color: $color-primary1;
    background: none;
    display: flex;
    align-items: center;
    gap: 8px;

    &::after {
      display: block;
      transition: 0.3s ease-in-out;
      transform: rotate(135deg);
      @include arrow($color-primary1, 2px, $arrowDirectionBottom);
    }

    &.is-active {
      &::after {
        top: 40%;
        transform: rotate(-45deg);
      }

      + .p-neighborhood__items {
        display: flex;
      }
    }
  }

  .p-neighborhood__items {
    display: none;
    flex-wrap: wrap;
    gap: 4px;
  }

  .p-neighborhood__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 18px 4px 4px;
    border: 1px solid $color-primary3;
    background-color: $color-gray0;
    color: $color-primary1;
    border-radius: 50px;
  }
}
