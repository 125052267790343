@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/arrow";

.p-titleShowWithSearchCategories {
  --_list-columns: 2;

  margin-top: 24px;

  @include screen.screen("tablet-and-pc") {
    --_list-columns: 4;
  }

  .p-titleShowWithSearchCategories__title {
    background: color.$color-primary4;
    padding: 12px 16px;

    @include typography.title-2;

    @include screen.screen("smartphone") {
      margin: 0 calc(50% - 50vw);
      width: 100vw;
    }
  }

  .p-titleShowWithSearchCategories__list {
    display: grid;
    grid-template-columns: repeat(var(--_list-columns), 1fr);

    border-top: 1px solid color.$color-gray5;
    border-left: 1px solid color.$color-gray5;

    margin-top: 24px;
  }

  .p-titleShowWithSearchCategories__item {
    display: grid;
    align-items: center;

    border-right: 1px solid color.$color-gray5;
    border-bottom: 1px solid color.$color-gray5;
  }

  .p-titleShowWithSearchCategories__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 12px;

    @include typography.body-small-bold;

    &::after {
      content: "";
      display: block;
      @include arrow.arrow(color.$color-primary1, 2px, arrow.$arrowDirectionRight);
    }
  }

  .p-titleShowWithSearchCategories__nextAction {
    margin-top: 16px;
  }
}
