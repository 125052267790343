@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/screen";

.p-footerSitemap {
  max-width: 648px; // FIXME: Figma から取得。他と合わせるところが決まると変更できる
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px 16px;

  margin: 16px;

  @include screen("tablet-and-pc") {
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    margin: 32px auto;
  }

  .p-footerSitemap__container {
    display: grid;
    align-items: start;
    grid-template-rows: auto 1fr;
    gap: 24px;
  }

  .p-footerSitemap__subContainer {
    display: grid;
    align-items: start;
    grid-template-rows: auto 1fr;
    gap: 24px;

    & + .p-footerSitemap__subContainer {
      margin-top: 24px;
    }
  }

  .p-footerSitemap__ttl {
    @include typography.subTitle-small;
  }

  .p-footerSitemap__list {
    display: grid;
    gap: 16px;
    @include typography.caption;
  }
}

.p-footerApp {
  display: none;
  @media screen and (max-width: $dimen-tablet+ "px") {
    display: flex;
    justify-content: center;

    .p-footerApp_container {
      margin-bottom: 5px;
    }

    .p-footerApp_headline2 {
      display: block;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: left;
    }

    .p-footerApp_buttons {
      display: flex;
    }

    .p-footerApp_button {
      flex: 1;
    }

    .p-footerApp_button + .p-footerApp_button {
      padding-left: 10px;
    }

    .p-footerApp_bunner {
      width: 100%;
      max-width: 212px;
      height: auto;

      &-ios {
        aspect-ratio: 111/32;
      }

      &-android {
        aspect-ratio: 53/16;
      }
    }
  }
}

.p-footerSns {
  margin-bottom: 30px;

  &_list {
    display: flex;
    align-items: center;
    justify-content: center;

    > li {
      width: 30px;

      &:not(:first-child) {
        margin-left: 25px;
      }

      > a {
        display: block;

        > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.p-footerCopy {
  display: block;
  text-align: center;
  font-size: 11px;
}

.p-footerGoiken {
  padding-top: 85px;
  margin-top: -85px;
}
