@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";

.c-titleBookmarkButton {
  --_icon-stroke: url('data:image/svg+xml,<svg viewBox="0 0 22 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M20.5505 0H0.625977V21.7763C0.625977 22.5714 1.05048 23.306 1.73935 23.7028C2.08252 23.9006 2.46652 24 2.84954 24C3.2342 24 3.61951 23.9 3.96395 23.7006L10.009 20.1992C10.3155 20.0216 10.657 19.9332 10.9999 19.9332C11.3427 19.9332 11.6843 20.0216 11.9907 20.1992L18.0357 23.7006L18.0364 23.7009C18.3802 23.8996 18.7651 24 19.1501 24C19.5328 24 19.9172 23.901 20.261 23.7025C20.9492 23.306 21.3738 22.5714 21.3738 21.7764V0H20.5505ZM19.4383 22.2761C19.3489 22.3276 19.2502 22.353 19.1501 22.3533C19.0504 22.3533 18.9507 22.3276 18.8604 22.2752L18.8092 22.2459L12.816 18.7745C12.2544 18.449 11.6266 18.2863 10.9998 18.2863C10.373 18.2863 9.7449 18.4493 9.18366 18.7745L3.13866 22.2758C3.04927 22.3276 2.95023 22.3534 2.84954 22.3534C2.75016 22.3534 2.65079 22.3277 2.56135 22.2762C2.38252 22.1732 2.2726 21.9829 2.2726 21.7764V1.64667H19.7272V21.7764C19.7272 21.9829 19.6171 22.1732 19.4383 22.2761Z" /><path d="M15.3712 9.42671L12.5409 9.25627C12.4323 9.24952 12.337 9.18038 12.2972 9.07909L11.2603 6.44026C11.2185 6.33348 11.115 6.2627 10.9998 6.2627C10.8847 6.2627 10.7811 6.33315 10.7393 6.44026L9.70243 9.07909C9.66254 9.18038 9.56733 9.24952 9.45868 9.25627L6.62846 9.42671C6.51333 9.43379 6.41424 9.51034 6.37889 9.62002C6.3435 9.72938 6.37824 9.84934 6.46735 9.92265L8.65655 11.7247C8.74083 11.7938 8.77688 11.9057 8.74983 12.0112L8.03714 14.7555C8.0085 14.8671 8.05064 14.9848 8.14393 15.0524C8.23721 15.1202 8.36199 15.1238 8.45911 15.062L10.8493 13.5366C10.941 13.4781 11.0587 13.4781 11.1503 13.5366L13.5402 15.062C13.6373 15.1238 13.7625 15.1202 13.8557 15.0524C13.9487 14.9848 13.9908 14.8671 13.9619 14.7555L13.2498 12.0112C13.2228 11.9057 13.2588 11.7938 13.3431 11.7247L15.5323 9.92265C15.6214 9.84934 15.6564 9.72938 15.6207 9.62002C15.5851 9.51034 15.4863 9.43384 15.3712 9.42671Z" /></svg>');
  --_color: #{color.$color-gray1};
  --_border-color: #{color.$color-gray4};
  --_background-color: #{color.$color-gray0};
  --_icon-color: #{color.$color-gray4};
  --_max-width: auto;

  width: 100%;
  max-width: var(--_max-width);
  min-height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @include typography.button;
  color: var(--_color);

  border-radius: 4px;
  border: 1px solid var(--_border-color);
  background-color: var(--_background-color);

  padding: 16px;

  &::before {
    content: "";
    mask-image: var(--_icon-stroke);
    width: 22px;
    height: 24px;
    background-color: var(--_icon-color);
  }

  &[aria-pressed="true"] {
    --_color: #{color.$color-primary2};
    --_border-color: #{color.$color-primary3};
    --_background-color: #{color.$color-gray0};
    --_icon-color: #{color.$color-primary2};
  }

  @include screen.screen("tablet-and-pc") {
    --_max-width: 343px;
  }
}
