@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";

.p-myTakedTitleFavoriteStatus {
  @include typography.caption;

  background-color: color.$color-primary4;
  border-radius: 4px;

  padding: 8px;
  margin-top: 16px;

  & + .p-myTakedTitleItems {
    margin-top: 16px;
  }
}
