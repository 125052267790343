@use "../../Foundation/mixin/typography";

.c-txt {
  &-copy {
    font-size: 11px;
  }
}

.h2-title {
  display: flex;
  align-items: center;
  gap: 8px;
  background: $color-primary4;
  padding: 14px 20px;
  @include typography.title-2;

  span {
    @include typography.caption-bold;
  }
}

.h3-title {
  @include typography.subTitle;
  padding-left: 12px;
  border-left: 6px solid $color-primary2;
}
