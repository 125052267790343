@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/arrow";

.p-prefectureFilter {
  padding: 14px 12px;
}

.p-prefectureFilterModal {
  --dialog-width: min(90vw, 1200px);
  --dialog-height: min(70vh, 800px);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .p-prefectureFilterModal__header {
    display: flex;
    justify-content: center;
    padding: 20px;
    color: variable.$color-gray1;
    background: variable.$color-primary4;
    font-weight: variable.$font-weight-semibold;
  }

  .p-prefectureFilterModal__back {
    position: absolute;
    left: 20px;
  }

  .prefectureFilterModal__checkbox {
    padding: 12px;
  }

  .prefectureFilterModal__checkboxContainer {
    &:not(:last-child) {
      border-bottom: 1px solid variable.$color-primary4;
    }
  }

  .prefectureFilterModal__checkboxHeader {
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      @include arrow.arrow(variable.$color-primary1, 1px, arrow.$arrowDirectionBottom);
      position: absolute;
      right: 1rem;
      transition: 0.1s;
    }

    &.is-open {
      &::after {
        @include arrow.arrow(variable.$color-primary1, 1px, arrow.$arrowDirectionTop);
      }
    }
  }

  .prefectureFilterModal__checkboxList {
    display: none;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 20px;

    &.is-open {
      display: grid;
    }
  }

  .prefectureFilterModal__body {
    overflow-y: scroll;
    flex: 1;
    padding-bottom: 80px;
  }

  .p-prefectureFilterModal__footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 34px;
    border-top: 1px solid variable.$color-gray5;

    .c-btn--basic {
      margin-right: 20px;
      width: 40%;
    }

    .c-btn--primary {
      max-width: 100%;
    }
  }
}
