@use "../../../Foundation/mixin/screen";

.c-producerAvatar {
  --avatar-size: 24px;

  display: inline-block;
  overflow: hidden;
  line-height: 1;
  vertical-align: middle;
  border-radius: 50%;

  height: var(--avatar-size);
  width: var(--avatar-size);

  &.c-producerAvatar--s {
    --avatar-size: 24px;
  }

  &.c-producerAvatar--m {
    --avatar-size: 32px;

    @include screen.screen("tablet-and-pc") {
      --avatar-size: 40px;
    }
  }

  &.c-producerAvatar--l {
    --avatar-size: 48px;

    @include screen.screen("tablet-and-pc") {
      --avatar-size: 72px;
    }
  }

  &.c-producerAvatar--ll {
    --avatar-size: 80px;

    @include screen.screen("tablet-and-pc") {
      --avatar-size: 96px;
    }
  }
}
