// Spacing
$base-padding: 4px;
$base-padding-2: $base-padding * 2;
$base-padding-3: $base-padding * 3;
$base-padding-4: $base-padding * 4;
$base-padding-5: $base-padding * 5;
$base-padding-6: $base-padding * 6;
$base-padding-8: $base-padding * 8;
$base-padding-10: $base-padding * 10;
$base-padding-12: $base-padding * 12;
$base-padding-16: $base-padding * 16;
$base-margin: 4px;
$base-margin-2: $base-margin * 2;
$base-margin-3: $base-margin * 3;
$base-margin-4: $base-margin * 4;
$base-margin-5: $base-margin * 5;
$base-margin-6: $base-margin * 6;
$base-margin-8: $base-margin * 8;
$base-margin-10: $base-margin * 10;
$base-margin-12: $base-margin * 12;
$base-margin-16: $base-margin * 16;
