@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/variables/aspect-ratio";

// .p-post__imageGallery を移植

.c-postImageGallery {
  --_grid-rows: 1;
  --_grid-cols: 1;
  --_aspect-ratio: 16/9;

  border-radius: 8px;
  cursor: pointer;
  display: grid;
  gap: $base-margin;
  grid-template-rows: repeat(var(--_grid-rows), 1fr);
  grid-template-columns: repeat(var(--_grid-cols), 1fr);
  overflow: hidden;

  &.c-postImageGallery--two {
    --_grid-cols: 2;
    --_aspect-ratio: 1/1;
  }

  &.c-postImageGallery--three,
  &.c-postImageGallery--four {
    --_grid-rows: 2;
    --_grid-cols: 2;
  }

  &.c-postImageGallery--three {
    > :first-child {
      grid-row: 1 / 3;
    }
  }

  .c-postImageGallery__image {
    aspect-ratio: aspect-ratio.$aspect-wide;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .c-postImageGallery__more {
    position: relative;

    > i {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: color.$color-gray0;
      background: rgba(0, 0, 0, 0.6);
      font-size: font.$font-size2;
      font-style: normal;
      font-weight: font.$font-weight-bold;
    }
  }
}
