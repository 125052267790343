.payment-content {
  .couponBlock {
    margin-bottom: $base-margin-4;
  }

  .couponBlock__text {
    font-weight: $font-weight-normal;
    text-align: left;
    margin-bottom: $base-margin-2;
  }

  h2 {
    padding: 7px 16px;
    font-size: 14px;
    font-weight: bold;
    background: $color-gray5;
    border-top: 1px solid $color-gray5;
    border-bottom: 1px solid $color-gray5;
  }

  .common-forms__element-block {
    padding: 16px 1rem 32px;

    ul.about_order_cancel,
    ul.about_subscription_cancel {
      li {
        list-style-type: disc;
        margin-left: 16px;
        text-align: left;

        a {
          color: $color-primary2;
          font-weight: 600;
        }
      }
    }

    ul.about_subscription_cancel:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .payment-block {
    .btn_area {
      a,
      button {
        width: auto;
        min-width: 240px;
        margin: 0;
        padding: 10px;
      }
    }

    .price {
      .detail {
        overflow: hidden;
        padding: 20px 0 10px;
        font-size: 14px;
        border-bottom: 1px solid $color-gray5;

        dt {
          float: left;
          clear: both;
        }

        dd {
          float: right;
          text-align: right;
          margin-bottom: 10px;

          .small-attention {
            font-size: 12px;
          }
        }
      } /* detail */

      .total {
        overflow: hidden;
        padding: 20px 0 10px;

        dt {
          padding-top: 5px;
          float: left;
          clear: both;
        }

        dd {
          font-size: 18px;
          float: right;
          text-align: right;
          margin-bottom: 10px;
        }
      } /* total */
      /* 割引表示 */
      .discount-original-price {
        text-align: right;
        text-decoration: line-through;
      }

      .discount-price {
        text-align: right;
        color: #dc0800;

        .discount-label {
          margin: 3px;
          display: inline-block;
          border-radius: 1em;
          background-color: #dc0800;
          color: #fff;
          padding: 3px 8px 2px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .discount-note {
        font-size: 11px;
        text-align: right;
      }
    } /* price */
  } /* payment-block */

  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0 40px;
    font-size: 13px;
  }

  .center-tiny-attention {
    font-size: 10px;
    text-align: center;
  }

  .error-message {
    color: #dc0800;
    padding: 0 10px;
  }
} /* payment-content */
