@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-myTakedTitle {
  --_border: 1px solid #{color.$color-gray4};
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  border-right: var(--_border);
  border-left: var(--_border);

  .p-myTakedTitle__item {
    display: grid;
    justify-items: center;
    gap: 4px;

    & + .p-myTakedTitle__item {
      border-left: var(--_border);
    }
  }

  .p-myTakedTitle__name {
    @include typography.caption-bold;
  }

  .p-myTakedTitle__count {
    font-family: Arial, sans-serif;
    font-size: font.$font-size2;
    font-weight: font.$font-weight-bold;
    line-height: 1.4;
    letter-spacing: 0.1px;
    text-align: center;
  }
}
