.about-purchase {
  margin-bottom: 48px;
  padding: 24px;

  .column {
    padding: 8px;

    .contents {
      .title {
        background-color: #f7f7f7;
        font-size: 13px;
        font-weight: 700;
        padding: 4px 14px;
        text-align: center;
      }

      .description {
        margin-top: 16px;

        .link {
          color: #bda35a;
        }

        .sub-contents {
          margin-top: 24px;

          .sub-title {
            border-bottom: 1px solid #f2f2f2;

            span {
              background-color: #f7f7f7;
              border-radius: 12px 12px 0 0;
              font-size: 12px;
              font-weight: 700;
              padding: 6px 10px;
            }
          }

          .sub-description {
            align-items: center;
            display: flex;
            margin-top: 16px;

            .payment-image-area {
              margin-right: 8px;
              width: 72px;

              .payment-image-wrap {
                align-items: center;
                border: 1px solid #e5e5e5;
                display: flex;
                height: 68px;
                padding: 2px;

                .payment-image {
                  height: auto;
                  width: 100%;
                }
              }

              .label {
                background-color: #504e47;
                color: #ffffff;
                font-size: 10px;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
              }
            }
          }

          .supplement {
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }

      &.repayment {
        margin-top: 40px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    border: solid 1px #e5e5e5;
    border-radius: 12px;
    display: flex;
    .column {
      width: 50%;
    }
  }

  @media screen and (max-width: 1023px) {
    .shipping {
      margin-top: 24px;
    }
  }
}
