@use "../../Foundation/variable";

.p-cardSecurityBtn {
  margin-top: 16px;
  font-size: variable.$font-size4;
  font-weight: variable.$font-weight-bold;
  color: variable.$color-primary1;
  background: variable.$color-gray0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &::after {
    content: "";
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
    border-top: 2px solid variable.$color-primary2;
    border-right: 2px solid variable.$color-primary2;
  }
}
