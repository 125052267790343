.p-recipeSlider {
  display: flex;
  gap: 16px;
  overflow-x: scroll;

  @include screen("tablet-and-pc") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .c-recipeCard {
    @include screen("smartphone") {
      min-width: 85%;
      max-width: 85%;
    }

    &:nth-child(n + 7) {
      @include screen("tablet-and-pc") {
        display: none;
      }
    }
  }
}
