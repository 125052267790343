@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";

.c-selectShippingPrefecture {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 16px;
  background: color.$color-gray6;
  margin-bottom: 16px;

  .c-selectShippingPrefecture__label {
    @include typography.body-small;
    width: 76px;
  }
}
