@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";

.p-magazinesMainSection {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 8px;

  background-color: color.$color-gray5;

  @include screen.screen("tablet-and-pc") {
    display: block;
    gap: 0;
    background-color: color.$color-gray0;
    margin-top: 0;
  }
}
