@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/arrow";

.p-titleTypesList {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;

  @include screen.screen("tablet-and-pc") {
    padding: 32px 32px 40px;
  }

  .p-titleTypesList__item {
    width: 100%;
    border-top: 1px solid color.$color-gray5;

    &:last-of-type {
      border-bottom: 1px solid color.$color-gray5;
    }
  }

  .p-titleTypesList__link {
    display: grid;
    gap: 0px 16px;
    grid-template:
      "name arrow" auto
      "description arrow" auto
      / 1fr auto;
    align-items: center;
    padding: 24px 16px;

    &::after {
      content: "";
      display: block;
      grid-area: arrow;
      @include arrow.arrow(color.$color-primary1, 2px, arrow.$arrowDirectionRight);
    }

    @include screen.screen("tablet-and-pc") {
      gap: 16px;
      grid-template:
        "name description arrow" auto
        / 120px 1fr auto;
    }
  }

  .p-titleTypesList__name {
    @include typography.subTitle-small;
    grid-area: name;
  }

  .p-titleTypesList__description {
    @include typography.body-small;
    grid-area: description;
  }
}
