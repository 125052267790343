@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/screen";

.p-goodsQuantityFilter {
  padding: 12px;

  @include screen.screen("pc-large-only") {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-goodsQuantityFilter__select {
    background: variable.$color-gray5;
    line-height: 1.5;
  }
}
