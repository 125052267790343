@use "../Foundation/mixin/typography";

.order-shipping-options {
  background-color: $color-gray6;

  &-undrinkableage {
    padding: 16px;
    margin-bottom: 30px;
  }

  .order-reciept-input {
    margin-top: 10px;
  }

  .payment-liquor-attentions {
    font-size: $font-size3; // MEMO : 酒類の通信販売における表示。18pxに変更。法律上、価格表示と同じフォントサイズが必要なため。価格表示の共通処理ができたらそちらに合わせる
    border-bottom: solid 1px $color-gray4;
    margin-bottom: 12px;
    padding-bottom: 12px;

    .attention-title {
      @include typography.subTitle;
    }
  } /* payment-liquor-attentions */

  &-area {
    padding: 16px;
    margin-top: 16px;
  }

  .payment-attentions {
    @include typography.body-small;
    border-bottom: solid 1px $color-gray4;
    margin-bottom: 12px;
    padding-bottom: 12px;

    .attention-title {
      @include typography.subTitle;
    }
  }

  .payment-attention-link {
    color: $color-primary1;
    font-weight: bold;
  }

  .label-form-checkbox {
    margin-bottom: 0;
  }
}
