@use "../Foundation/mixin/typography";

.subscription-box {
  background: $color-gray0;
  border: 4px solid $color-primary3;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;

  .subscription-box-title {
    @include typography.subTitle;
    margin-bottom: 8px;
    position: relative;
    padding-left: 8px;
    border-left: 4px solid $color-primary2;
  }

  .c-btn {
    display: inline-block;
    width: auto;
    margin: 8px 8px 0 0;
  }
}
