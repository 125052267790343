@use "../variable";
@use "arrow";
@use "screen";
@use "typography";

@mixin markdown {
  // stylelint-disable tabechoku/selector-type-allowed-list
  em {
    @include typography.body-bold;
    font-style: italic;
  }

  strong {
    @include typography.body-bold;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    margin: 8px 0;
  }

  p {
    @include typography.body;
    margin: 12px 0;
  }

  ul,
  ol {
    display: grid;
    gap: 4px;
    padding-left: 20px;
    margin: 16px 0;
    @include typography.body;
  }

  ul {
    > li {
      list-style-type: disc;
    }
  }

  ol {
    > li {
      list-style-type: decimal;
    }
  }

  hr {
    margin: 48px 0;
    border: none;
    border-top: 1px solid variable.$color-gray4;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 24px;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid variable.$color-gray4;
  }

  th {
    font-weight: variable.$font-weight-bold;
    background-color: variable.$color-gray5;
  }
  // stylelint-enable tabechoku/selector-type-allowed-list

  // memo: instagramの埋め込み対応
  iframe:not(.instagram-media) {
    @include screen.screen("smartphone") {
      width: 100%;
      height: auto;
      min-height: 200px;
    }
  }

  .markdownTextLink {
    color: variable.$color-primary1;
    text-decoration: underline;
  }

  h2 {
    background: variable.$color-primary4;
    padding: 12px 16px;
    margin-bottom: 28px;

    @include typography.title-2;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  h3 {
    padding-left: 12px;
    border-left: 6px solid variable.$color-primary2;
    margin-bottom: 20px;

    @include typography.title-2;

    &:not(:first-child) {
      margin-top: 42px;
    }
  }

  h4 {
    margin-bottom: 12px;

    @include typography.subTitle;

    &::before {
      content: "\25A0"; // "■"
      margin-right: 8px;
    }

    &:not(:first-child) {
      margin-top: 28px;
    }
  }

  .c-markdownBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    transition: 300ms;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    color: variable.$color-gray0;
    background: variable.$color-primary2;
    margin: 48px auto 60px;
    @include typography.button;
  }

  .c-markdownBox {
    background-color: variable.$color-gray6;
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0;

    &.c-markdownBox--default {
      background-color: variable.$color-gray6;
    }

    &.c-markdownBox--primary {
      background-color: variable.$color-primary0;
    }
  }

  .c-markdownBlockRight {
    display: flex;
    justify-content: flex-end;

    @include typography.body;
  }

  .p-markdownAnchorLinkBtnList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin-bottom: 16px;

    @include screen.screen("smartphone") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .p-markdownAnchorLinkBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 20px 16px 12px;
    transition: 300ms;
    border-radius: 4px;
    border: 1px solid variable.$color-gray1;
    background: variable.$color-gray0;
    @include typography.button;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 4px);
      right: 12px;
      @include arrow.arrow(variable.$color-gray1, 1px, arrow.$arrowDirectionBottom);
    }

    &:hover {
      background: rgba(variable.$color-gray1, 0.1);
    }
  }

  .p-markdownCardList {
    display: grid;
    gap: 12px;
    margin: 20px 0;

    &.p-markdownCardList--vertical {
      grid-template-columns: repeat(4, 1fr);
      @include screen.screen("smartphone") {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.p-markdownCardList--horizontal {
      grid-template-columns: repeat(2, 1fr);
      @include screen.screen("smartphone") {
        grid-template-columns: 1fr;
      }
    }
  }

  .p-markdownListCard {
    border: solid 1px variable.$color-gray4;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    transition: 300ms;

    &:hover {
      opacity: 0.8;
    }

    .p-markdownListCard__img {
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .p-markdownListCard__text {
      padding: 12px 12px 16px;
    }

    .p-markdownListCard__title {
      margin-bottom: 4px;
      @include typography.subTitle-small;
    }

    &.p-markdownListCard--horizontal {
      display: grid;
      grid-template-columns: 40% 60%;
    }

    &.p-markdownListCard--vertical {
      .p-markdownListCard__img {
        aspect-ratio: variable.$aspect-thumb;
      }
    }
  }
}
