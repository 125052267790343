.c-snsList {
  display: flex;
  justify-content: center;
  gap: 8px;

  > li {
    width: 40px;

    img {
      width: 100%;
    }
  }
}
