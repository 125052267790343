@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/font";

.p-titleExpertList {
  --_columns: 1;
  --_gap: 0px;

  display: grid;
  grid-template-columns: repeat(var(--_columns), 1fr);
  gap: var(--_gap);

  @include screen.screen("tablet-and-pc") {
    --_columns: 2;
    --_gap: 8px;
  }

  .p-titleExpertList__item {
    border-top: 1px solid color.$color-gray5;

    &:last-of-type {
      border-bottom: 1px solid color.$color-gray5;
    }
  }

  .p-titleExpertList__itemLink {
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 12px 16px 12px 0;
  }

  .p-titleExpertList__itemInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  .p-titleExpertList__itemTitleName {
    @include typography.subTitle-small;
  }

  .p-titleExpertList__itemDescription {
    @include typography.body-small;
  }

  .p-titleExpertList__itemHolder {
    font-size: font.$font-size9;
    line-height: 1.5;

    margin: 0; // MEMO: app/styles/user/components/molecules/_text.scss で p + p のマージンをリセットしているため、ここでリセットする必要がある
  }
}
