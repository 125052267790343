/*
  New Color Palette

  - Figma上のColorStyles名とcss上の変数名を一致させています
  - Statusカラー追加予定
  - stylelint を用意しているので、変更・追加時は stylelint も調整してください
 */

$color-order1: #c30d23; // CVボタンにのみ用いる
$color-order2: #db3347; // 価格に関する部分（値段、送料、割引等）にのみ用いる

$color-primary1: #9f8745;
$color-primary2: #cab270;
$color-primary3: #f0e8d5;
$color-primary4: #f5f1eb;
$color-primary0: #fcfaf8;

// grayscale
$color-gray1: #212121;
$color-gray2: #858585;
$color-gray3: #b8b8b8;
$color-gray4: #e9e9e9;
$color-gray5: #f2f2f2;
$color-gray6: #f9f9f9;
$color-gray0: #ffffff;

// status
$color-warning: #ff0000; // エラー時に使用する。これ以上の利用者が進むことのできない状態を示す
$color-annotation: #f45800; // 注釈、注意事項、注意喚起
$color-waiting: #f53290; // 未完了、未契約のときに使う
$color-progress: #ffdd57; // 残りわずか / 進行中 / 確認中
$color-success: #23d160; // 完了
$color-canceled: #858585; // 売り切れ

// status background-color
$color-warning-background: #fff2f2;
$color-annotation-background: #fff5f0; // 注釈、注意事項、注意喚起
$color-waiting-background: #fef5f9;
$color-progress-background: #fff8dd;
$color-success-background: #d3f6df;
$color-canceled-background: #ededed;

// favorite
$color-favorite: #f3919d; // お気に入りにのみ用いる
$color-favorite-off: $color-gray5;

// Rating
$color-rating: #ffdd57; // レビューの星にのみ用いる
$color-rating-off: $color-gray5;

// others
$color-morning: #dd8432; // 朝市にのみ用いる
$color-night: #295b8b; // 夜市にのみ用いる

// 3rd パーティー
$color-twitter: #1d9bf0;
$color-facebook: #1877f2;
$color-line: #06c755;
$color-instagram: radial-gradient(139.66% 139.66% at 0% 97.5%, #ff8100 0%, #ff7209 19%, #f55e16 32%, #d92938 48%, #9100ff 100%);
$color-x: #000000;

// Title
$color-gold: $color-primary2;
$color-silver: $color-gray3;
$color-bronze: #bd8c5e;
