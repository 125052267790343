.l-formFooter {
  color: $color-gray1;
  font-size: $font-size7;
  text-align: center;

  min-height: 100px;
  border-top: 1px solid $color-gray5;
  padding: 25px 0 80px;

  .l-formFooter__linkList {
    & + .l-formFooter__copyright {
      margin-top: 15px;
    }
  }

  .l-formFooter__link {
    & + .l-formFooter__link {
      margin-top: 15px;
    }
  }

  .l-formFooter__copyright {
    display: block;
    letter-spacing: 0.6px;
  }
}
