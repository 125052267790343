@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/font";

.c-contentTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @include screen("pc-only") {
    align-items: center;
    &::before {
      display: block;
      content: "";
      background-image: url("/app/assets/images/icon-title.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 70px;
      height: 70px;
    }
  }
}

.c-contentTitle__main {
  display: block;
  width: 100%;
  @include typography.title-1;
  @include screen("pc-only") {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  &::before,
  &::after {
    content: none;
    @include screen("pc-only") {
      content: "";
      flex-grow: 1;
      max-width: 200px;
      height: 1px;
      background-color: $color-gray1;
    }
  }
}

.c-contentTitle__sub {
  @include typography.subTitle-small;
  color: $color-primary1;
  width: fit-content;
}
