@use "../Foundation/mixin/h2-title";

.concierge-footer {
  .inner-help {
    padding: 10px 20px;
    max-width: 960px;
    margin: auto;

    @include screen("smartphone") {
      padding: 0;
    }

    .help-detail {
      margin: 20px 20px;
      font-size: 12px;

      h2 {
        @include h2-title.h2-title;
        font-size: 14px;
      }

      .help-sentence-head {
        font-size: 13px;
        font-weight: bold;
        padding: 12px 20px;
      }

      ul {
        margin: 5px 40px;

        li {
          margin-left: 10px;
          list-style-type: disc;
        }
      }

      p {
        margin: 20px 20px;
      }

      .company-detail {
        margin: 20px 20px;

        h3 {
          font-weight: bold;
        }

        p {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .outer-sitemap {
    margin: auto;

    .sitemap {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 12px;
      padding: 25px 0;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      @include screen("tablet-and-pc") {
        width: 700px;
      }

      .footer-cell {
        flex-basis: 23%;
        text-align: center;
        padding-right: 5px;
        @include screen("smartphone") {
          flex-basis: 41%;
        }

        .footer-title {
          font-size: 14px;
          text-align: center;
          margin-bottom: 20px;
          @include screen("smartphone") {
            font-size: 12px;
          }
        }
      }
    }

    .copy {
      padding: 20px;
      font-size: 11px;
      text-align: center;
    }
  } /* outer-sitemap */
} /* inner-footer */
