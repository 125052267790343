@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/arrow";

.p-seachCategoriesWithTitle {
  --_columns: 1;

  @include screen.screen("tablet-and-pc") {
    --_columns: 2;
  }

  .p-seachCategoriesWithTitle__list {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(var(--_columns), 1fr);
  }

  .p-seachCategoriesWithTitle__item {
    @include typography.subTitle-small;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    border-bottom: 1px solid color.$color-gray5;

    padding: 16px;

    &::after {
      content: "";
      display: block;
      @include arrow.arrow(color.$color-primary1, 2px, arrow.$arrowDirectionRight);
    }
  }

  .p-seachCategoriesWithTitle__back {
    margin-top: 24px;
  }
}
