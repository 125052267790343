.c-searchCategoryList2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  padding: 1px;
  border-radius: 4px;
  background-color: $color-gray5;

  .c-searchCategoryList2__item {
    background-color: #ffffff;

    &[area-hidden="false"] {
      display: block;
    }

    &[area-hidden="true"] {
      display: none;
    }

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:nth-child(2) {
      border-top-right-radius: 4px;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  .c-searchCategoryList2__itemLink {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    justify-content: space-between;

    &::after {
      @include arrow($color-gray3, 1px, $arrowDirectionRight);
    }
  }
}
