@use "../../Foundation/variables/color";

.p-activity {
  padding: $base-padding-4;
  background-color: #fff;
  border-bottom: 1px solid $color-gray4;
  display: block;

  .p-activity__producer {
    display: grid;
    grid-template-columns: auto 1fr auto 12px;
    grid-gap: 4px 8px;
  }

  .p-activity__producerImage {
    width: 48px;
    height: 48px;
    grid-row: 1/4;

    > img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .p-activity__producerName {
    grid-column: 2/4;
    grid-row: 1;
    font-size: $font-size7;
    font-weight: $font-weight-bold;
  }

  .p-activity__message {
    grid-column: 2/4;
    grid-row: 2;
    font-size: $font-size5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .p-activity__message--unread {
    color: $color-gray1;
  }

  .p-activity__message--read {
    color: $color-gray2;
  }

  .p-activity__statusContainer {
    grid-column: 2;
    grid-row: 3;
  }

  .p-activity__datetime {
    grid-column: 3;
    grid-row: 3;
    font-size: $font-size7;
    color: $color-gray2;
  }

  .p-activity__badgeContainer {
    grid-column: 4;
    grid-row: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-activity_badge {
    width: 12px;
    height: 12px;
    background-color: color.$color-annotation;
    border-radius: 50%;
    position: relative;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.p-activityNone {
  height: 424px;
  padding: 40px 0;
  > p {
    font-size: $font-size4;
    font-weight: $font-weight-semibold;
    text-align: center;
    margin-bottom: 20px;
    color: $text-gray;
  }
}

.p-activity__noData {
  padding: $base-padding-8 $base-padding-4 $base-padding-16 $base-padding-4;
}

.p-activity__noDataMessage {
  font-size: $font-size4;
  font-weight: $font-weight-semibold;
  text-align: center;
  margin-bottom: $base-margin-3;
}

.p-activity__noDataDescription {
  font-size: $font-size5;
  margin-bottom: $base-margin-6;
  display: flex;
  justify-content: center;

  @include screen("smartphone") {
    justify-content: flex-start;
  }
}

.p-activity__noDataImage {
  margin-bottom: $base-margin-8;
  display: flex;
  justify-content: center;

  img {
    width: 343px;
    height: 221px;
  }
}

.p-activity__noDataPopularProductsBtn {
  font-size: $font-size4;
}
