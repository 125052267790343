@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";

input.form_inputText {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid $color-gray5;
  border-radius: 4px;
  + .form_inputText {
    margin-top: 1rem;
  }
}

.form_inputBirth {
  display: flex;
  justify-content: flex-start;
  input {
    height: 35px;
  }
  span {
    margin-top: 8px;
  }
  input.form_inputYear {
    width: 90px;
    margin-right: 5px;
  }
  input.form_inputMonth {
    width: 70px;
    margin: 0 5px;
  }
  input.form_inputDate {
    width: 70px;
    margin: 0 5px;
  }
}

.form_selectHouseHold {
  display: flex;
  align-items: center;

  .form_selectHouseHold__label {
    flex-shrink: 0;
    min-width: 40px;
  }
}

select,
.form_select {
  position: relative;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid $color-gray5;
  border-radius: 4px;
  background: {
    image: url(" ../../app/assets/images/icon-arrow-down.png");
    repeat: no-repeat;
    position: 95% center;
    size: 8px auto;
    color: #fff;
  }
}

textarea.form_textarea {
  width: 100%;
  height: 100px;
  padding: 6px;
  border: 1px solid $color-gray5;
  border-radius: 4px;
}

.form_label {
  display: block;
  margin: 0 auto 5px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .txt_must {
    display: inline-block;
    margin-left: 5px;
    padding: 0 4px;
    color: #fff;
    font-size: 11px;
    font-weight: normal;
    background: #ea352d;
    border-radius: 2px;
  }

  .sub_attention {
    font-size: 10px;
    font-weight: normal;

    a {
      color: $text-brown;
    }
  }
}

.order-top-area {
  padding: 20px 16px;

  .strong {
    font-size: $font-size4;
    font-weight: $font-weight-semibold;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

.common-forms {
  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  h2 {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background: #f1f1f1;
  }
}

.common-forms__container {
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 40px;
  font-size: 13px;
}

.common-forms__element-block {
  padding-bottom: 20px;

  &.pad0 {
    padding: 0 0 20px;
  }

  .btn_area {
    a,
    button {
      width: auto;
      min-width: 240px;
      margin: 0;
      padding: 10px;
    }
  }
  .tiny-block-attention {
    font-size: 10px;
    font-weight: normal;
    text-align: right;
    margin: 10px auto 0 auto;
  }

  .small-textarea {
    height: 50px;
    padding: 5px;
  }

  .expiration_date {
    width: 250px;
    margin-bottom: 25px;
    text-align: left;

    input {
      display: inline-block;
      margin-right: 3px;
      margin-bottom: 0;
      padding: 10px;
      border: 1px solid $color-gray5;
      border-radius: 4px;
    }

    .month {
      width: 40px;
    }

    .year {
      width: 70px;
    }
  }

  /* stylelint-disable selector-class-pattern */
  /* stylelint-disable declaration-property-value-allowed-list */
  .small-attention-message {
    font-size: 12px;
  }
  /* stylelint-enable declaration-property-value-allowed-list */
  /* stylelint-enable selector-class-pattern */
}
/* stylelint-disable selector-class-pattern */
/* stylelint-disable declaration-property-value-allowed-list */
// FIXME : .common-forms__element-block なくても単独で使えるようにするが、適切なデザインが決まれば置き換えができる
.input-with-button-area {
  overflow: hidden;
  display: flex;
  width: 100%;
  border-radius: 4px;

  input {
    width: 75%;
    border-radius: 4px 0 0 4px;
  }

  button {
    width: 25%;

    // MEMO : .c-searchByCodeButton と同じスタイルです
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 4px 4px 0px;
    background-color: color.$color-gray2;

    padding: 10px 12px;

    white-space: nowrap;
    color: color.$color-gray0;
    @include typography.button-small;
  }
}
/* stylelint-enable declaration-property-value-allowed-list */
/* stylelint-enable selector-class-pattern */

.common-forms__clickable-link {
  color: $color-primary1 !important;
  text-decoration: underline !important;
  font-weight: bold;
}

.common-forms__attr-value-fields {
  dt {
    float: left;
    clear: both;
  }

  dd {
    float: right;
    margin-bottom: 10px;
  }
}

.common_forms_attr-value-fields-important {
  font-size: 130%;
}

.common-forms__alert-container {
  padding: 20px;
  border-radius: 4px;
  background: $color-warning-background;
  border: 1px solid $color-warning;
  margin: 20px 0;
  @include screen("smartphone") {
    padding: 20px 16px;
  }

  h3 {
    @include typography.subTitle;
    text-align: center;
    color: $color-warning;
    margin-bottom: 16px;
  }

  p {
    @include typography.body-small;
    margin-bottom: 20px;
  }
}
