@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/arrow";

.p-searchCategoryFilter__groupedSearchCategoryContainer {
  padding: 12px;
  border-bottom: 1px solid variable.$color-gray5;
}

.p-searchCategoryFilter__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  font-weight: variable.$font-weight-bold;
}

.p-searchCategoryFilter__itemCollapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: variable.$font-weight-bold;
  gap: 4px;
}

.p-searchCategoryFilter__collapse {
  flex-grow: 1;
  text-align: right;
}

.p-searchCategoryFilter__collapseArrowUp {
  @include arrow.arrow(variable.$color-primary2, 2px, arrow.$arrowDirectionTop);
  margin-left: variable.$base-margin-2;
  margin-right: variable.$base-margin-2;
  margin-top: -4px;
  display: inline-block;
}

.p-searchCategoryFilter__collapseArrowDown {
  @include arrow.arrow(variable.$color-primary2, 2px, arrow.$arrowDirectionBottom);
  margin-left: variable.$base-margin-2;
  margin-right: variable.$base-margin-2;
  margin-top: -4px;
  display: inline-block;
}

.p-searchCategoryFilter__children {
  max-height: 0;
  visibility: hidden;

  &.is-open {
    max-height: 100vh;
    visibility: visible;
  }
}

.p-searchCategoryFilter__count {
  color: variable.$color-gray2;
  font-size: variable.$font-size7;
  font-weight: variable.$font-weight-light;
  &::before {
    content: "\FF08"; // "（"
  }
  &::after {
    content: "\FF09"; // "）"
  }
}

.p-searchCategoryFilter__itemName {
  color: variable.$color-gray1;
  font-size: variable.$font-size5;
}

.p-searchCategoryFilter__itemCount {
  color: variable.$color-gray2;
  font-size: variable.$font-size7;
  font-weight: variable.$font-weight-light;

  &::before {
    content: "\FF08"; // "（"
  }
  &::after {
    content: "\FF09"; // "）"
  }
}

.p-searchCategoryFilter__itemChild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 0;
}
