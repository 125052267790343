.left-nav {
  @include screen("smartphone") {
    display: none;
  }

  .block-left-nav {
    border-radius: 8px;
    padding-top: 25px;
    background: #f5f3e9;
    overflow: hidden;

    h3 {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding: 0 16px;

      .sub {
        font-size: 12px;
      }

      .subImage {
        margin-top: 4px;
      }

      &::after {
        content: "";
        background-image: url("../assets/images/top/line-leftNav.png");
        width: 90%;
        margin: 0 auto;
        padding: 30px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
      }
    }

    .list-left-nav {
      li {
        border-bottom: 1px solid #fff;

        a {
          position: relative;
          display: block;
          min-width: 100px;
          padding: 13px 16px 13px 9%;
          font-size: 14px;
          font-weight: bold;
          transition: 300ms;
          background: #f5f3e9;

          &:hover {
            opacity: 0.5;
            background: #fff;
          }

          &::before {
            content: "";
            position: absolute;
            top: calc(50% - 3.5px);
            right: 12px;
            height: 7px;
            width: 7px;
            transform: rotate(45deg);
            border-top: 2px solid #ddd;
            border-right: 2px solid #ddd;
          }

          &.open::before {
            transform: rotate(135deg);
          }

          &.child {
            filter: brightness(95%);
          }
        }

        .is-current {
          background-color: #f0e8d5;
          &::before {
            display: none;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .list-bnr {
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .sidebar-common-button {
        border: 1px solid #ddd;
        background: #fff;
        color: #000;
        padding: 0 20px;
        height: 60px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        @include max-breakpoint($dimen-pc) {
          font-size: 14px;
        }

        &.bigenner {
          span {
            margin-left: 4.5%;
          }
          img {
            width: 17%;
            max-width: 25px;
          }
        }
      }
    }
  }

  .image-bnr {
    display: block;

    img {
      width: 100%;
    }
  }

  .sns-btns {
    display: block;
    padding: 20px 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #000;
    border: 5px solid #f5f3e9;
    letter-spacing: 0;
    border-radius: 8px;
    @include max-breakpoint($dimen-pc) {
      font-size: 14px;
    }

    .sns-btn {
      display: flex;
      align-items: center;
      max-width: 132px;
      margin: 0 auto 10px;
      padding: 6px;
      padding-left: 18px;
      background-color: #fff;
      color: #222;
      font-size: 13px;
      transition: 300ms;
      border-radius: 30px;
      @include max-breakpoint($dimen-pc-large) {
        font-size: 13px;
      }

      @include max-breakpoint($dimen-pc) {
        font-size: 12px;
      }

      img {
        display: block;
        width: 20px;
        height: auto;
        margin-right: 5px;
      }

      &.facebook {
        border: 1px solid $color-facebook;
        &:hover {
          color: #fff;
          background: $color-facebook;
        }
      }

      &.instagram {
        border: 1px solid #d92938;
        &:hover {
          color: #fff;
          background: $color-instagram;
        }
      }

      &.twitter {
        border: 1px solid $color-twitter;
        &:hover {
          color: #fff;
          background: $color-twitter;
        }
      }

      &.line {
        border: 1px solid $color-line;
        &:hover {
          color: #fff;
          background: $color-line;
        }
      }
      /* stylelint-disable selector-class-pattern */
      &.x {
        border: 1px solid $color-x;
        &:hover {
          color: $color-gray0;
          background: $color-x;
        }
      }
      /* stylelint-enable selector-class-pattern */
    }
  }

  .ttl {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 14px;

    @include max-breakpoint($dimen-pc-large) {
      font-size: 13px;
    }

    @include max-breakpoint($dimen-pc) {
      font-size: 12px;
    }
  }

  .hidden {
    display: none;
  }

  .children {
    margin-top: 0;
  }
}

.l-leftNavGoiken {
  padding-top: 62px;
  margin-top: -62px;
}
