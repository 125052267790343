.cart-order-content {
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 40px;
    font-size: 13px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;
    @include screen("smartphone") {
      margin: 20px;
    }
  }

  h2 {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background: #f1f1f1;
  }

  .center-tiny-attention {
    font-size: 10px;
    text-align: center;
  }
}
