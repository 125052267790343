@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";
@use "../../Foundation/mixin";

.p-produerCommitmentLikeButton {
  display: grid;
  gap: 8px;
  padding-bottom: 24px; // FIXME： p-producerCommitmentModal__detail を変更するとこは不要になる

  .p-produerCommitmentLikeButton__like {
    display: flex;
    align-items: center;
    gap: 8px;

    color: color.$color-gray2;
    font-size: font.$font-size7;
  }

  .p-produerCommitmentLikeButton__count {
    font-size: 120%;
  }
}
