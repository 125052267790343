.c-buttonWithSearchIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f2f2f2;
  border-radius: 4px;

  padding: 4px 8px;

  .c-buttonWithSearchIcon__icon {
    width: 16px;
    height: 16px;
  }

  .c-buttonWithSearchIcon__label {
    font-size: $font-size4;
    font-weight: $font-weight-bold;
    line-height: 1.8;
  }
}
