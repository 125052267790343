.p-bannerOverlay {
  visibility: hidden;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  width: 25vw;
  @include screen("smartphone") {
    width: auto;
    right: auto;
  }

  .p-bannerOverlay__closeBtn {
    text-align: right;
    position: relative;
    top: 10px;
    left: 1rem;
    width: initial;
    @include screen("smartphone") {
      width: 60%;
      margin: auto;
      float: left;
    }

    img {
      cursor: pointer;
      width: 25px;
      padding: 5px;
      background: #ccc;
      border-radius: 30px;
    }
  }

  // stylelint-disable selector-class-pattern
  img {
    &.show-only-pc {
      float: left;
      width: 100%;
      display: block;
    }

    &.show-only-sp {
      float: left;
      width: 60%;
      display: block;
    }
  }
  // stylelint-enable selector-class-pattern
}
