@use "../../../../Foundation/mixin/typography";

.c-notificationsTitle {
  // MEMO : おなじスタイルになっているが、役割は異なっていたので別クラスになっている
  // FIXME : .c-leadTItle と同じになる。c-leadTitle の実装を用意したら消すことができる
  &.c-notificationsTitle--h2 {
    @include typography.title-1;
  }

  &.c-notificationsTitle--h3 {
    @include typography.title-1;
  }
}
