@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/typography";

.c-formLabel {
  color: variable.$color-gray1;
  @include typography.subTitle-small;

  .c-formLabel__more {
    margin-right: 8px;
    padding: 4px 8px;
    background: variable.$color-gray4;
    color: variable.$color-gray1;
    border-radius: 16px;
    @include typography.label-bold;
  }
}
