@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.p-distributionScheduleChangeProductForm {
  .p-distributionScheduleChangeProductForm__noSelect {
    background-color: white;
    text-align: center;
    padding: 32px;
    border: variable.$color-gray3 1px dotted;
  }

  .p-distributionScheduleChangeProductForm__selectLineupImageContainer {
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 40px;

    .p-distributionScheduleChangeProductForm__selectLineupUrl {
      position: relative;
      display: inline-block;

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        color: variable.$color-gray0;
        font-weight: variable.$font-weight-bold;
        background: rgba(0, 0, 0, 0.5);
        content: "";
      }
    }

    .p-distributionScheduleChangeProductForm__selectLineupOverlayText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: variable.$color-gray0;
      font-weight: variable.$font-weight-bold;
      z-index: 1;
      width: 100%;
      text-align: center;
    }
  }

  .p-distributionScheduleChangeProductForm__selectLineupImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .p-distributionScheduleChangeProductForm__card {
    border: 1px solid variable.$color-gray3;
    border-radius: 4px;
    position: relative;

    &:not(.is-selectedproduct) {
      @include screen.screen("tablet-and-pc") {
        display: flex;
        justify-content: space-between;
      }
    }

    &.is-savedproduct {
      border: 1px solid variable.$color-primary2;
      background-color: variable.$color-primary3;
    }

    &.is-soldout::after {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px);
      content: "\58F2\308A\5207\308C"; // 売り切れ
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: variable.$color-gray0;
      font-weight: variable.$font-weight-bold;
      letter-spacing: 0;
      font-size: variable.$font-size3;
    }

    .p-distributionScheduleChangeProductForm__cardProduct {
      padding: 16px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .p-distributionScheduleChangeProductForm__cardProductTitle {
      font-size: variable.$font-size5;
      font-weight: variable.$font-weight-bold;
      line-height: 1.6;
      letter-spacing: 0.25px;
    }

    .p-distributionScheduleChangeProductForm__cardProductInfo {
      color: variable.$color-gray2;
      font-size: variable.$font-size5;
      line-height: 1.4;
      letter-spacing: 0.25px;
      margin-top: 4px;
    }

    .p-distributionScheduleChangeProductForm__cardGoods {
      flex: 0 0 50px;
    }

    .p-distributionScheduleChangeProductForm__cardProducer {
      display: flex;
      align-items: center;
      padding: 6px 16px 16px 16px;

      .p-distributionScheduleChangeProductForm__cardProducerImg {
        margin-right: 8px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        flex: 0 0 auto;
      }

      .p-distributionScheduleChangeProductForm__cardProducerInfo {
        display: flex;
        flex-direction: column;
      }

      .p-distributionScheduleChangeProductForm__cardProducerName {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .p-distributionScheduleChangeProductForm__cardFooter {
      flex: 1 1 auto;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .p-distributionScheduleChangeProductForm__specialLabel {
      background: variable.$color-primary1;
      border-radius: 16px;
      padding: 4px 16px;
      text-align: center;
      font-weight: variable.$font-weight-semibold;
      font-size: variable.$font-size5;
      color: variable.$color-gray0;
      width: 154px;
      height: 30px;
    }

    .p-distributionScheduleChangeProductForm__cardProducerButton {
      margin-bottom: 16px;
      width: 154px;
      height: 48px;
      flex: 0 0 auto;
      background: variable.$color-gray0;
      border: 1px solid variable.$color-primary1;
      border-radius: 4px;
      color: variable.$color-primary1;
      font-weight: variable.$font-weight-semibold;
      font-size: variable.$font-size4;
      &.is-selected {
        background: variable.$color-primary3;
        border: 2px solid variable.$color-primary1;
      }
      @include screen.screen("smartphone") {
        width: 120px;
        margin-bottom: 0px;
      }
    }
  }

  .p-distributionScheduleChangeProductForm__cardAction {
    display: flex;
    align-items: center;
    padding: 16px;

    @include screen.screen("tablet-and-pc") {
      flex-direction: column-reverse;
    }
  }

  .p-distributionScheduleChangeProductForm__producer {
    display: flex;
    align-items: center;
    padding: 8px 4px;
  }

  .p-distributionScheduleChangeProductForm__producerImg {
    margin-right: 8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    flex: 0 0 auto;
  }

  .p-distributionScheduleChangeProductForm__producerContainer {
    display: flex;
    flex-direction: column;
  }

  .p-distributionScheduleChangeProductForm__heading {
    margin-bottom: 16px;
  }

  .p-distributionScheduleChangeProductForm__btnFloat {
    z-index: 1;
  }

  .p-distributionScheduleChangeProductForm__attention {
    text-align: center;
    margin-top: 16px;
    @include typography.caption;
    font-size: variable.$font-size7;
  }

  .p-distributionScheduleChangeProductForm__deliveryRangeAttention {
    font-size: variable.$font-size6;
    margin-bottom: 8px;
  }

  .p-distributionScheduleChangeProductForm__modalLink {
    text-decoration-line: underline;
    font-weight: variable.$font-weight-light;
    font-size: variable.$font-size5;
    letter-spacing: 0.25px;
  }

  .p-distributionScheduleChangeProductForm__linkArea {
    text-align: right;
    padding: 4px;
  }
}

.p-distributionScheduleChangeProductForm__specialAttention {
  margin-bottom: 16px;
  font-size: variable.$font-size4;
}

.p-distributionSchedulePassModal {
  padding: 20px 20px 40px;
  border-radius: 4px;

  @include screen.screen("smartphone") {
    width: 90%;
    height: 90%;
  }

  .p-distributionSchedulePassModal__title {
    font-weight: variable.$font-weight-semibold;
    font-size: variable.$font-size3;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px variable.$color-gray4;
  }

  .p-distributionSchedulePassModal__selectPassReasonContainer {
    padding: 0 0 16px 0;

    .p-distributionSchedulePassModal__attention {
      font-size: variable.$font-size4;
    }

    .p-distributionSchedulePassModal__passReason {
      .p-distributionSchedulePassModal__passReasonItem {
        margin: 8px auto;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
      }
    }
  }

  .p-distributionSchedulePassModal__buttonArea {
    .p-distributionSchedulePassModal__button {
      margin: 8px auto;
    }

    .p-distributionSchedulePassModal__attention {
      text-align: right;
      font-size: variable.$font-size7;
      font-weight: variable.$font-weight-light;
      text-decoration-line: underline;
    }
  }

  .p-rdistributionSchedulePassModal__hiddenButton {
    display: none;
  }
}

.p-distributionScheduleSpecialProductDescriptionModal {
  .p-distributionScheduleSpecialProductDescriptionModal__container {
    margin: variable.$base-margin-4 0;
    font-size: variable.$font-size4;
  }
}
