@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-magazinesHeader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .p-magazinesHeader__image {
    position: relative;
  }

  .p-magazinesHeader__info {
    position: relative;

    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: color.$color-primary4;

    padding: 16px;
    margin: -24px 16px 0;

    @include screen.screen("tablet-and-pc") {
      width: 600px;
      flex-direction: row;

      margin: -24px auto 0;
    }
  }

  .p-magazinesHeader__title {
    flex: 1;

    font-family: "Noto Serif JP", serif;
    line-height: 1;
    text-align: center;
  }

  .p-magazinesHeader__titleCatch {
    font-size: font.$font-size4;
    font-weight: font.$font-weight-bold;
    line-height: 1.4;
  }

  .p-magazinesHeader__titleMain {
    font-size: font.$font-size-large5;
    font-weight: font.$font-weight-bold;
    line-height: 1.4;
    word-break: keep-all;
  }

  .p-magazinesHeader__border {
    display: flex;

    padding: 16px;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      border-bottom: 1px solid color.$color-primary2;

      @include screen.screen("tablet-and-pc") {
        width: 1px;
        height: 50px;
        border-bottom: none;
        border-right: 1px solid color.$color-primary2;
      }
    }
  }

  .p-magazinesHeader__lead {
    flex: 1;
    @include typography.body-small;
  }
}
