@use "../../Foundation/mixin/typography";

.p-productList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 12px;

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 16px;
  }

  // FIXME : 過去のCSS の影響なのか、これを書いておくと 320px 幅でも表示崩れがない。tag / レビュー数の当たりが怪しいがまずはこれで直す
  // FIXME : 各所で .item-wrap と HTMLに記載されているので、変更が大きくなるので、一括で変更できる時に辞められる
  /* stylelint-disable selector-class-pattern */
  .item-wrap {
    overflow: hidden;
  }

  /* stylelint-enable selector-class-pattern */
}

// FIXME: コンポーネント外向けmargin設定は避けるべき
.p-productList--withMarginBottom {
  margin-bottom: 30px;
  @include screen("tablet-and-pc") {
    margin-bottom: 32px;
  }
}

.p-productListZeroMatch {
  display: block;
  justify-content: center;
}

// 検索結果の間に列表示する

.p-productListRowDisplay {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid $color-gray5;
  background-color: $color-gray0;

  @include screen("tablet-and-pc") {
    padding: 32px;
    border-radius: 16px;
  }
}

@for $i from 1 through 25 {
  .p-productListRowDisplay__row#{$i} {
    grid-column: 1 / -1;
    grid-row: #{$i};
  }
}

.p-productListRowDisplay__title {
  display: flex;
  align-items: center;
  gap: 8px;
  border-left: 6px solid $color-primary2;
  padding-left: 12px;
  @include typography.title-2;
  @include screen("tablet-and-pc") {
    padding-left: 16px;
  }

  img {
    width: 24px;
    max-height: 28px;
  }
}

.p-productListRowDisplay.p-productListRowDisplay__beginnerProducer {
  margin: 0 -1rem;
  padding: 0;
  border-radius: 0;
  border: 1px solid $color-gray5;
  border-width: 8px 0;

  @include screen("tablet-and-pc") {
    margin: 0;
    padding: inherit;
    border: inherit;
    border-width: inherit;
  }

  .p-productSlider {
    padding: 1rem 0;

    @include screen("tablet-and-pc") {
      border: 1px solid $color-gray5;
      border-radius: 16px;
      padding: 32px 1rem;
    }

    .p-productSlider__title {
      padding: 0 1rem;
      font-size: $font-size2;
      font-weight: $font-weight-semibold;
      color: $color-gray1;
    }

    .p-productSlider__wrap {
      display: flex;
      gap: 20px 12px;
      overflow-x: scroll;
      padding: 16px 1rem;

      @include screen("tablet-and-pc") {
        overflow-x: hidden;
      }

      .p-productTilingCard {
        flex: 0 0 44%;
        @include screen("tablet-and-pc") {
          flex: 0 0 calc(25% - 8px);
        }
      }
    }
  }
}

// スポットライト施策用の設定
// https://github.com/vividgarden/tabechoku_pbi/issues/3538
.p-productListRowDisplay.p-productListRowDisplay__boostProducts {
  margin: 0;
  padding: inherit;
  border: inherit;
  border-width: inherit;

  .p-boostProductList {
    padding: 1rem 0;
    border-bottom: 1px solid $color-gray4;
    margin-bottom: $base-padding-4;

    @include screen("tablet-and-pc") {
      padding: $base-padding-4 0;
    }

    .p-boostProductList__title {
      padding: $base-padding $base-padding-2 $base-padding $base-padding-3;
      font-size: $font-size4;
      font-weight: $font-weight-bold;
      color: $color-gray1;
      border-left: 6px solid $color-primary2;
      line-height: 22.4px;
    }

    .p-boostProductList__wrap {
      padding: $base-padding-4 0 0 0;
    }
  }
}

.p-productListRowDisplay__preference {
  border: 1px solid $color-primary4;
  background-color: $color-primary0;
  min-height: 119px;
  padding: 24px;
  @include screen("tablet-and-pc") {
    min-height: 146px;
  }

  .filterMenu__item {
    background: $color-gray0;
  }
}
