@use "../../Foundation/mixin/typography";

.c-favoriteBtn {
  position: relative;

  .c-favoriteBtn__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $color-gray0;
    border: 1px solid $color-gray4;
    border-radius: 4px;
    padding: 16px;
    @include typography.button-small;
    transition: background 200ms;
  }

  .icon {
    margin-right: 8px;

    > svg {
      transition: fill 400ms;
    }
  }

  &.is-liked {
    .c-favoriteBtn__item {
      background: $color-gray4;
    }

    .icon {
      > svg {
        fill: $color-favorite;
      }
    }
  }

  .c-favoriteBtn__attention {
    display: inline-flex;
    font-size: $font-size7;
    font-weight: $font-weight-bold;
    letter-spacing: 0;
    background: #ffdd57;
    border-radius: 30px;
    padding: 4px 8px;
    position: absolute;
    left: -8px;
    top: -13px;

    &::before {
      content: "";
      position: absolute;
      bottom: -11px;
      left: 22px;
      border: 7px solid transparent;
      border-left: 17px solid #ffdd57;
      transform: rotate(51deg);
    }
  }
}
