.react-toast {
  display: none;
  position: fixed;
  top: 90px;
  right: 20px;
  padding-left: 15px;
  color: #fff;
  text-align: left;
  font-size: 13px;
  background: rgba(89, 181, 141, 0.95);
  z-index: 9999999999999999;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  pointer-events: none;
  @include screen("smartphone") {
    width: 90%;
    max-width: 500px;
    top: initial;
    right: initial;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .inner-toast {
    .text {
      span {
        display: inline-block;
        font-weight: bold;
        padding: 20px;
        padding-left: 37px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        background: {
          image: url(" ../../app/assets/images/icon-check.png");
          repeat: no-repeat;
          position: left center;
          size: 26px auto;
        }
      }
    }

    .alert-text {
      span {
        display: inline-block;
        font-weight: bold;
        padding: 20px;
        padding-left: 37px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        background: {
          image: url(" ../../app/assets/images/icon-alert.png");
          repeat: no-repeat;
          position: left center;
          size: 26px auto;
        }
      }
    }
  }
}

.react-bottom-toast {
  display: none;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 300px / 2);
  width: 300px;

  padding-left: 15px;
  color: #fff;
  text-align: left;
  font-size: 13px;
  background: rgba(89, 181, 141, 0.95);
  z-index: 9999;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  @include screen("smartphone") {
    width: 90%;
    max-width: 500px;
    top: initial;
    right: initial;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .inner-toast {
    .text {
      span {
        display: inline-block;
        font-weight: bold;
        padding: 20px;
        padding-left: 37px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        background: {
          image: url(" ../../app/assets/images/icon-check.png");
          repeat: no-repeat;
          position: left center;
          size: 26px auto;
        }
      }
    }

    .alert-text {
      span {
        display: inline-block;
        font-weight: bold;
        padding: 20px;
        padding-left: 37px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        background: {
          image: url(" ../../app/assets/images/icon-alert.png");
          repeat: no-repeat;
          position: left center;
          size: 26px auto;
        }
      }
    }
  }
}

.react-toast-visible {
  display: block;
  animation: fadeInOutToast 4s linear forwards;
  -webkit-animation: fadeInOutToast 4s linear forwards;
}

.react-toast-link {
  display: block;
  pointer-events: initial;
  animation: fadeInOutToast 10s linear forwards;
  -webkit-animation: fadeInOutToast 10s linear forwards;
}

.react-toast-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}

.raact-toast-link-icon {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 9px;
    right: -18px;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid $color-gray0;
    border-right: 1px solid $color-gray0;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes fadeInOutToast {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInOutToast {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
