.article-card {
  padding: 16px 0px;
  display: flex;
  border-top: 1px solid #f3f3f3;

  color: $color-gray1;

  &:last-child {
    border-bottom: 1px solid #f3f3f3;
  }

  &:hover {
    opacity: 0.8;
  }

  .article-card-img-wrap {
    width: 50%;
    margin-right: 16px;
    @include screen("tablet-and-pc") {
      width: 30%;
    }

    .article-card-img {
      position: relative;
      width: 100%;

      &::before {
        content: "";
        display: block;
        padding-top: 52.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .article-card-body {
    width: 50%;
    @include screen("tablet-and-pc") {
      width: 70%;
    }

    .article-card-content {
      display: none;
      @include screen("tablet-and-pc") {
        margin-top: 8px;
        max-height: 58px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    .article-card-title {
      font-size: 13px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      @include screen("tablet-and-pc") {
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .article-card-content {
      @extend .h5;
    }

    .article-card-date {
      text-align: right;
      margin-top: 8px;
      color: $color-gray2;
      @extend .h6;
    }
  }
}
