@use "../../Foundation/variable";
@use "../../Foundation/mixin";

.p-userPreference {
  .p-userPreference__userImage {
    border-radius: 50%;
    width: 45px;
    @include screen("smartphone") {
      width: 24px;
      height: 24px;
    }
  }

  .p-userPreference__title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: variable.$color-primary1;
    margin-bottom: 8px;
  }

  .p-userPreference__collection {
    border: 1px solid variable.$color-primary3;
    background-color: variable.$color-primary4;
    padding: 16px;
    margin-bottom: 16px;
  }

  .p-userPreference__titleText {
    font-size: variable.$font-size2;
    font-weight: variable.$font-weight-bold;
    margin-left: 8px;
  }

  .p-userPreference__action {
    margin-top: 8px;

    @include screen("tablet-and-pc") {
      margin-top: 32px;
    }
  }
}
