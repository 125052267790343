@use "../../Foundation/mixin/typography";

.p-productTilingCardWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px 16px;
  margin-bottom: 15px;

  @include screen("smartphone") {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

.p-productTilingCard {
  transition: 300ms;
  background: $color-gray0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  @include screen("smartphone") {
    border-radius: 4px;
    background: $color-primary0;
  }

  .p-productTilingCard__productLink {
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }

  .p-productTilingCard__productImg {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    @include screen("smartphone") {
      border-radius: 0;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .p-productTilingCard__productInfo {
    padding: 8px 4px;
    word-break: break-word;
  }

  .p-productTilingCard__shippingDate {
    color: $color-gray2;
    font-size: $font-size7;
    height: 36px;

    @include screen("smartphone") {
      font-size: $font-size9;
    }
  }

  .p-productTilingCard__suppls {
    display: flex;
    align-items: center;
    height: 18px;
    font-weight: $font-weight-semibold;
  }

  .p-productTilingCard__quantity {
    background: $color-gray4;
    border: 1px solid $color-gray4;
    border-radius: 16px;
    font-size: $font-size9;
    max-height: 100%;
    padding: 0 8px;
  }

  .p-productTilingCard__other {
    margin-left: 4px;
    background: $color-gray0;
    border: 1px solid $color-gray4;
    border-radius: 16px;
    font-size: $font-size9;
    max-height: 100%;
    padding: 1px 8px;
  }

  .p-productTilingCard__review {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    font-size: $font-size7;
    white-space: nowrap;
    height: 18px;

    i {
      --rating-size: #{$font-size4};
    }
  }

  .p-productTilingCard__reviewNum {
    color: $color-primary2;
  }

  .p-productTilingCard__productName {
    margin-bottom: 12px;
    font-weight: $font-weight-semibold;
    font-size: $font-size4;
    height: 72px;

    @include screen("smartphone") {
      height: 42px;
      font-size: $font-size5;
      -webkit-line-clamp: 2;
    }
  }

  .p-productTilingCard__donationLabel {
    font-size: $font-size5;
    font-weight: $font-weight-normal;
  }

  .p-productTilingCard__limitedLabel {
    padding: 8px;
    @include typography.label-bold;
    border-radius: 16px;
    background-color: $color-primary2;
    color: $color-gray0;
    margin-right: 8px;
  }

  .p-productTilingCard__price {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include typography.price;
  }

  .p-productTilingCard__campaignDiscountLabel {
    border-radius: 16px;
    padding: 4px 8px;
    margin-right: 4px;
    background: $color-order2;
    color: $color-primary0;
    @include typography.label-bold;
    @include screen("smartphone") {
      font-size: $font-size9;
    }

    &::before {
      content: "\00A5"; // ¥
    }

    &::after {
      content: "\5F15\304D"; // 引き
    }
  }

  .p-productTilingCard__municipalityCampaignLabel {
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    background: $color-primary4;
    padding: 4px 6px;
    border-radius: 20px;
    width: fit-content;
    margin: 0 4px 4px 0;
    position: relative;

    &.is-hidden {
      visibility: hidden;
    }
  }

  .p-productTilingCard__shippingPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 21px;
  }

  .p-productTilingCard__producerLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid $color-gray5;
    position: relative;

    @include screen("smartphone") {
      background-color: $color-primary4;
    }
  }

  .p-productTilingCard__producerImg {
    width: 25%;
    max-width: 50px;
    @include screen("smartphone") {
      width: 25%;
    }

    > img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .p-productTilingCard__producerInfo {
    width: 75%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .p-productTilingCard__producerArea {
    overflow: hidden;
    width: 100%;
    font-size: $font-size8;

    @include screen("smartphone") {
      font-size: $font-size9;
    }
  }

  .p-productTilingCard__producerName {
    overflow: hidden;
    width: 100%;
    font-size: $font-size6;
    font-weight: $font-weight-semibold;
    margin-top: 0;

    @include screen("smartphone") {
      font-size: $font-size7;
    }
  }

  .btn-product-fav-wrap {
    width: 22%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    .btn-product-fav {
      position: relative;
      margin: 0;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 14%;
      padding-top: 22%;

      .icon {
        margin: 0;
        width: 80%;
      }
    }
  }

  .p-productTilingCard__cartBtn {
    padding: 0 4px 8px;
  }
}

.p-productCardSkeleton {
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba(#fff, 0.5), transparent);
    position: absolute;
    top: 0;
    left: 0;
    animation: skeleton-animation 1.2s linear infinite;
  }

  @keyframes skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.p-productCardSkeleton__box {
  background: $color-gray4;
  width: 100%;
  height: 100%;
  box-shadow: none;
}

.p-productCardSkeleton__circle {
  background: $color-gray4;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 50%;
}

.p-productCardSkeleton__bar {
  background: $color-gray4;
  max-width: 500px;
  width: 100%;
  height: 1em;
  margin-bottom: 0.4em;
}

.p-productCardSkeleton__w50 {
  width: 50%;
}
