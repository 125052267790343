@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/typography";

.c-shippingProducerInfo {
  display: flex;
  gap: 16px;

  color: color.$color-gray1;

  .c-shippingProducerInfo__detail {
    @include typography.caption;
    flex: 1;
  }

  .c-shippingProducerInfo__name {
    @include typography.subTitle;
  }

  .c-shippingProducerInfo__date {
    margin-top: 4px;
  }
}
