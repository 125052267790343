.c-card02-wrapper {
  width: 100%;
  padding: 2rem 1rem 3rem;
  background-color: $color-primary4;
  @include screen("tablet-and-pc") {
    width: calc(100% + 4rem);
    padding: 2rem;
    margin: 0 -2rem;
  }

  &.-wide {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }

  &.-margin-bottom {
    margin-bottom: 1rem;
    @include screen("tablet-and-pc") {
      margin-bottom: 2rem;
    }
  }
}
