@use "../../../Foundation/mixin/screen";

.p-overrideCHalfModalInMyTakedTitle {
  @include screen.screen("tablet-and-pc") {
    & > .c-halfModal {
      .c-halfModal__content {
        height: 100%;
        width: 560px;
        right: 0;
        left: calc(100% - 560px);
        border-radius: 0;

        &.c-halfModal__content--open {
          animation: fadeup 0.25s;

          @keyframes fadeup {
            0% {
              opacity: 0;
              transform: translateX(80%);
            }
            100% {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        }

        &.c-halfModal__content--close {
          animation: fadedown 0.25s;

          @keyframes fadedown {
            0% {
              opacity: 1;
              transform: translateX(0%);
            }
            100% {
              opacity: 0;
              transform: translateX(100%);
            }
          }
        }
      }

      .c-halfModal__header {
        padding: 48px 48px 16px;
      }

      .c-halfModal__body {
        padding: 0 48px 48px;
      }
    }
  }
}
