@use "../../../Foundation/variable";

.p-searchFiltersCard {
  border: 1px solid variable.$color-gray5;
  border-radius: 4px;

  .p-searchFiltersCard__title {
    background: variable.$color-primary4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: variable.$color-gray1;
    font-size: variable.$font-size5;
    font-weight: variable.$font-weight-semibold;
  }

  .p-searchFiltersCard__search {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px 12px 0;
    gap: 8px;
  }
}
