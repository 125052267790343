@use "../../Foundation/mixin/typography";

.c-orderStatus {
  @include typography.caption;

  &:before {
    width: 8px;
    height: 8px;
    content: "";
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin-right: 4px;
    background-color: $color-canceled;
  }

  /* stylelint-disable selector-class-pattern */
  /* shipment_orders.statusのtypeをClass名に入れたいためstylelintを無視する */
  &.c-orderStatus--unaccepted {
    &:before {
      background-color: $color-waiting;
    }
  }

  &.c-orderStatus--accepted,
  &.c-orderStatus--waiting_for_shipment,
  &.c-orderStatus--waiting_for_shipment_request {
    &:before {
      background-color: $color-progress;
    }
  }

  &.c-orderStatus--shipped {
    &:before {
      background-color: $color-success;
    }

    /* stylelint-enable selector-class-pattern */
  }
}
