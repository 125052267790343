.c-reviewImageCarousel {
  --_item-height: 100px;
  --_item-width: 100px;

  overflow: hidden;
  height: var(--_item-height);
  width: 100%;

  .c-reviewImageCarousel__mask {
    width: 100%;
    height: var(--_item-height);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .c-reviewImageCarousel__list {
    max-width: 100%;
    display: flex;
    gap: 8px;
  }

  .c-reviewImageCarousel__listItem {
    width: var(--_item-width);
  }

  .c-reviewImageCarousel__image {
    width: var(--_item-width);
    height: var(--_item-height);
    object-fit: cover;
  }
}
