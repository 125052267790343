@mixin ratingIcon($size) {
  content: "";
  background-size: contain;
  width: $size;
  height: $size;
}

.c-starRating {
  display: flex;
  gap: 2px;
  .c-starRating__star {
    display: flex;

    &::before {
      @include ratingIcon(24px);
    }

    &.c-starRating__star--small {
      &::before {
        @include ratingIcon(16px);
      }
    }

    &.c-starRating__star--medium {
      &::before {
        @include ratingIcon(20px);
      }
    }

    &.c-starRating__star--large {
      &::before {
        @include ratingIcon(24px);
      }
    }

    &.c-starRating__star--on {
      &::before {
        background-image: url("/app/assets/images/icon-star-on.svg");
      }
    }

    &.c-starRating__star--off {
      &::before {
        background-image: url("/app/assets/images/icon-star-off.svg");
      }
    }

    &.c-starRating__star--half {
      &::before {
        background-image: url("/app/assets/images/icon-star-half.svg");
      }
    }
  }
}
