.btn_layout {
  margin: 40px auto 0px;
  /* stylelint-disable selector-class-pattern -- 過去の仕様のため */
  .btn_layout__attention {
    margin-bottom: 16px;
  }

  .btn_layout__attentionLink {
    color: $color-primary1;
    text-decoration: underline;
  }
  /* stylelint-enable selector-class-pattern */
}

.btn_area {
  text-align: center;
  margin-top: 20px;

  button,
  a {
    display: inline-block;
    width: 80px;
    padding: 6px;
    color: $color-primary2;
    font-size: 12px;
    border: 1px solid $color-primary2;
    background: $color-gray0;
    border-radius: 4px;

    &:first-child {
      margin-right: 15px;
    }
  }
}

.btn-float {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  background-color: $color-gray0;
  border-top: 1px solid #eee;
}
