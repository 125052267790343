.u-list {
  margin-left: 1em; // FIXEME: リセットCSS で設定されているのを回避します。リセットCSSが変わると直せます。

  > li {
    list-style-type: disc;
  }

  &.u-list--decimal {
    > li {
      list-style-type: decimal;
    }
  }
}
