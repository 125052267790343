@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";

.c-headerNav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: 1200px;
  height: 52px;
  margin: 0 auto;

  .c-headerNav__link {
    @include typography.subTitle-small;
    color: color.$color-gray2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid color.$color-gray5;

    &.c-headerNav__link--active {
      color: color.$color-primary2;
      border-bottom: 4px solid color.$color-primary2;
    }
  }
}
