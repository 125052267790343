@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/screen";

.c-productCategories {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
  }

  .c-productCategories__link {
    min-height: 68px;
    display: grid;

    background-image: var(--_background-image, url("https://image-cdn.tabechoku.com/compress/images/category_vegetable_set.jpg"));
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: center;
    background-size: cover;

    border-radius: 4px;
  }

  .c-productCategories__label {
    display: grid;
    align-items: center;
    justify-content: center;

    grid-area: 1/1;

    background: rgba(0, 0, 0, 0.15);

    color: color.$color-gray0;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    @include typography.subTitle;
  }
}
