@use "../../Foundation/mixin/screen";

.p-reelListWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 16px;

  @include screen.screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
  }
}
