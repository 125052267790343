@use "../../../Foundation/variables/font";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";

.p-myTakedTitleItems {
  border-bottom: 1px solid color.$color-gray5;

  &.p-myTakedTitleItems--noBorder {
    border: none;
  }

  .p-myTakedTitleItems__item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px 12px 0;

    & + .p-myTakedTitleItems__item {
      border-top: 1px solid color.$color-gray5;
    }
  }

  .p-myTakedTitleItems__itemLink {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .p-myTakedTitleItems__itemImage {
    display: grid;
  }

  .p-myTakedTitleItems__itemIcon {
    grid-area: 1 / 2;
    align-self: end;
    justify-self: end;
    width: 18px;
    height: 18px;
  }

  .p-myTakedTitleItems__itemLevelSymbol {
    --_icon-gold: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2222%22%20viewBox%3D%220%200%2018%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9%2019L2.0718%2015L2.0718%207L9%203L15.9282%207L15.9282%2015L9%2019Z%22%20fill%3D%22%23CAB270%22%2F%3E%3Cpath%20d%3D%22M8.5%2019.866L9%2020.1547L9.5%2019.866L16.4282%2015.866L16.9282%2015.5774V15V7V6.42265L16.4282%206.13398L9.5%202.13397L9%201.8453L8.5%202.13397L1.5718%206.13398L1.0718%206.42265V7L1.0718%2015V15.5774L1.5718%2015.866L8.5%2019.866Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
    --_icon-silver: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2222%22%20viewBox%3D%220%200%2018%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9%2019L2.0718%2015L2.0718%207L9%203L15.9282%207L15.9282%2015L9%2019Z%22%20fill%3D%22%23858585%22%2F%3E%3Cpath%20d%3D%22M8.5%2019.866L9%2020.1547L9.5%2019.866L16.4282%2015.866L16.9282%2015.5774V15V7V6.42265L16.4282%206.13398L9.5%202.13397L9%201.8453L8.5%202.13397L1.5718%206.13398L1.0718%206.42265V7L1.0718%2015V15.5774L1.5718%2015.866L8.5%2019.866Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
    --_icon-bronze: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2222%22%20viewBox%3D%220%200%2018%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9%2019L2.0718%2015L2.0718%207L9%203L15.9282%207L15.9282%2015L9%2019Z%22%20fill%3D%22%23bd8c5e%22%2F%3E%3Cpath%20d%3D%22M8.5%2019.866L9%2020.1547L9.5%2019.866L16.4282%2015.866L16.9282%2015.5774V15V7V6.42265L16.4282%206.13398L9.5%202.13397L9%201.8453L8.5%202.13397L1.5718%206.13398L1.0718%206.42265V7L1.0718%2015V15.5774L1.5718%2015.866L8.5%2019.866Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");

    display: inline-block;
    width: 18px;
    height: 22px;
    background-image: var(--_icon-bronze);

    &.p-myTakedTitleItems__itemLevelSymbol--gold {
      background-image: var(--_icon-gold);
    }

    &.p-myTakedTitleItems__itemLevelSymbol--silver {
      background-image: var(--_icon-silver);
    }

    &.p-myTakedTitleItems__itemLevelSymbol--bronze {
      background-image: var(--_icon-bronze);
    }
  }

  .p-myTakedTitleItems__itemThumbnail {
    --_bg-image: url("/app/assets/images/missing.png");
    grid-area: 1 / 2;

    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-image: var(--_bg-image);
    background-size: cover;
  }

  .p-myTakedTitleItems__itemInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  .p-myTakedTitleItems__itemName {
    @include typography.subTitle-small;
  }

  .p-myTakedTitleItems__itemDescription {
    @include typography.body-small;
  }

  .p-myTakedTitleItems__itemCountAndLevel {
    font-size: font.$font-size9;
    line-height: 1.5;
  }
}
