.p-recipeWithProducer {
  @at-root {
    .p-recipeWithProducer_recipeImage {
      height: auto;
      width: 100%;
    }

    .p-recipeWithProducer_foot {
      display: flex;
      background-color: $color-primary4;
      padding: 16px;
    }

    .p-recipeWithProducer_footProfile {
      padding-right: 8px;
    }

    .p-recipeWithProducer_footProfileImage {
      border: 3px solid #ffffff;
      border-radius: 50%;
      height: 84px;
      width: 84px;
      margin-top: calc((84px / 3) * -1);

      @include screen("tablet-and-pc") {
        height: 112px;
        width: 112px;
        margin-top: calc((112px / 3) * -1);
      }
    }

    .p-recipeWithProducer_footInfoTitle {
      color: #aa7f4d;
      font-size: 12px;
      font-weight: bold;

      margin-bottom: 4px;
    }

    .p-recipeWithProducer_footInfoArea {
      font-size: 12px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

      @include screen("tablet-and-pc") {
        font-size: 14px;
      }
    }

    .p-recipeWithProducer_footInfoName {
      font-size: 14px;
      font-weight: bold;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

      @include screen("tablet-and-pc") {
        font-size: 16px;
      }
    }
  }

  @at-root {
    .p-recipeWithProducer_swiperFoot {
      display: flex;
      background-color: $color-primary4;
      padding: 12px 14px;
    }

    .p-recipeWithProducer_swiperFootProfileImage {
      border: 3px solid #ffffff;
      border-radius: 50%;
      height: 75px;
      width: 75px;

      margin-top: calc((75px / 2) * -1);
    }

    .p-recipeWithProducer_swiperFootProfile {
      padding-right: 12px;
    }

    .p-recipeWithProducer_swiperFootArea {
      font-size: 11px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .p-recipeWithProducer_swiperFootName {
      font-size: 13px;
      font-weight: bold;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
