@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";

.p-regularPurchase {
  max-width: 412px;
  margin: 24px auto 0;

  & + .p-regularPurchase {
    margin-top: 32px;

    @include screen.screen("tablet-and-pc") {
      margin-top: 40px;
    }
  }

  .p-regularPurchase__body {
    display: flex;

    align-items: stretch;
    gap: 12px;
  }

  .p-regularPurchase__thumbnail {
    min-width: 70px;
    max-width: 70px;

    @include screen.screen("tablet-and-pc") {
      min-width: 100px;
      max-width: 100px;
    }
  }

  .p-regularPurchase__image {
    width: 100%;
    height: auto;
  }

  .p-regularPurchase__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .p-regularPurchase__title {
    @include typography.subTitle;

    &.p-regularPurchase__title--forPc {
      display: none;
      @include screen.screen("tablet-and-pc") {
        display: block;
      }
    }

    &.p-regularPurchase__title--forSp {
      margin-bottom: 8px;
      @include screen.screen("tablet-and-pc") {
        display: none;
      }
    }
  }

  .p-regularPurchase__state {
    @include typography.label;
  }

  .p-regularPurchase__summary {
    @include typography.body;

    margin-top: 8px;
    @include screen.screen("tablet-and-pc") {
      margin-top: 12px;
    }
  }
}
