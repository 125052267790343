@use "../../Foundation/variables/color";
@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/line-clamp";
@use "../../Foundation/mixin/arrow";

.p-productLink {
  + .p-productLink {
    margin-top: 8px;
  }

  .p-productLink__inner {
    display: grid;
    gap: 8px;
    background: $color-primary4;
    border-radius: 4px;
    padding: 12px 20px 12px 12px;
    position: relative;

    &::before {
      @include arrow.arrow($color-primary2, 1px, $arrowDirectionRight);
      position: absolute;
      top: calc(50% - 4px);
      right: 12px;
    }
  }

  .p-productLink__product {
    display: flex;
    gap: 12px;

    > img {
      width: 48px;
      height: 48px;
      aspect-ratio: $aspect-square;
      object-fit: cover;
    }
  }

  .p-productLink__detail {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
  }

  .p-productLink__name {
    @include typography.subTitle-small;
    @include line-clamp.line-clamp(3);
  }

  .p-productLink__price {
    @include typography.price;
    // price 用の typography を使用したいけれど適切なフォントサイズのものがないため、font-size を上書きしています
    // ToDo： price 用の typography の見直し
    font-size: 16px;
  }

  .p-productLink__goodsQuantity {
    @include typography.caption;
    color: $color-gray2;
  }

  .p-productLink__producer {
    display: flex;
    align-items: center;
    gap: 8px;
    @include typography.subTitle-small;

    > img {
      width: 32px;
      height: 32px;
      aspect-ratio: $aspect-square;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &.p-productLink--white {
    .p-productLink__inner {
      background: $color-gray0;
      border: 1px solid $color-gray4;
    }
  }
}
