@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-ratingBlock {
  align-items: center;
  display: flex;
  gap: 8px;

  + .p-ratingBlock {
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid color.$color-gray4;
  }

  &.p-ratingBlock--center {
    justify-content: center;
  }

  &.p-ratingBlock--twoColumns {
    @include screen("tablet-and-pc") {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }

    @include screen("smartphone") {
      &:nth-child(1) {
        padding-right: 16px;
      }

      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }

  .p-ratingBlock__label {
    @include typography.caption;
  }

  .p-ratingBlock__label--twoColumns {
    @include screen("tablet-and-pc") {
      width: 62px;
    }
  }

  .p-ratingBlock__rating {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .p-ratingBlock__ratings {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .p-ratingBlock__score {
    color: color.$color-primary1;
    font-size: font.$font-size-large4;
    font-weight: font.$font-weight-semibold;
    line-height: 1;
    font-family: Arial, sans-serif;

    &.p-ratingBlock__score--small {
      @include typography.title-2;
    }
  }
}
