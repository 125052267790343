@use "../../Foundation/mixin/typography";

.p-snsShare {
  width: 100%;
  background-color: $color-gray5;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;

  .p-snsShare__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .p-snsShare__description {
    font-size: 14px;
    font-weight: normal;
  }

  .p-snsShare__snsList {
    margin: 16px auto 0px;
  }
}

//ToDo: ファイル分ける
/* stylelint-disable */
.social-button-area {
  border-top: 1px solid #e8e8f3;
  padding: 16px 0 20px;
  @include typography.body-small;

  .fb_iframe_widget > span {
    vertical-align: baseline !important;
  }

  .list-share {
    display: flex;
    gap: 8px;
    padding-top: 4px;
  }
}
/* stylelint-enable */
