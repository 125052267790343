.p-staticContent {
  padding-bottom: 30px;
  background: $color-gray5;

  //stylelint-disable tabechoku/selector-type-allowed-list -- とりあえずファイルを entries に移動
  //stylelint-disable declaration-property-value-allowed-list

  .container {
    max-width: 960px;
    background: $color-gray0;
    padding: 30px 16px;
    font-size: 13px;
    @include screen("tablet-and-pc") {
      padding: 40px 30px;
    }

    h1 {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h2 {
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin-bottom: 20px;
      a {
        text-decoration: underline;
      }
    }

    section {
      padding-bottom: 20px;

      ul,
      ol {
        padding-left: 20px;
        @include screen("tablet-and-pc") {
          padding-left: 30px;
        }
      }

      li {
        ul,
        ol {
          padding-left: 10px;
        }

        table {
          ul,
          ol {
            padding-left: 20px;
          }
        }
      }
    }
  }
  // stylelint-enable declaration-property-value-allowed-list
  // stylelint-enable tabechoku/selector-type-allowed-list
}
