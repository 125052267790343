@use "../../Foundation/mixin/typography";

.c-producerList {
  display: grid;
  gap: 16px;

  .c-producerList__itemLink {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .c-producerLink__itemProducerArea {
    @include typography.caption;
  }

  .c-producerLink__itemProducerName {
    @include typography.subTitle;
  }

  .c-producerLink__itemIcon {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 50%;

    @include screen("tablet-and-pc") {
      height: 80px;
      width: 80px;
    }
  }
}
