@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

.p-producerDetailCommitment {
  border-top: 1px solid color.$color-gray5;
  padding-top: 24px;
  margin-top: 24px;

  .p-producerDetailCommitment__headline {
    @include typography.subTitle;
    margin-bottom: 24px;
  }

  .p-producerDetailCommitment__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .p-producerDetailCommitment__content {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  .p-producerDetailCommitment__detail {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .p-producerDetailCommitment__title {
    color: color.$color-primary1;
    @include typography.subTitle;
  }

  .p-producerDetailCommitment__text {
    line-height: 1.8;
    font-size: font.$font-size4;
    word-break: break-all;
  }
}
