.p-productRecommendSlider {
  .p-productRecommendSlider__inner {
    display: grid;
    gap: 16px;
    padding: 16px 16px 40px;
  }

  .p-productRecommendSlider__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 16px;
    @include screen("smartphone") {
      display: flex;
      overflow-x: scroll;
      flex-wrap: nowrap;
      gap: 8px;
    }

    .p-productTilingCard,
    .item-wrap {
      @include screen("smartphone") {
        flex: 0 0 44%;
      }

      &:nth-child(n + 9) {
        @include screen("tablet-and-pc") {
          display: none;
        }
      }
    }
  }
}
