@use "../../Foundation/variable";
@use "../../Foundation/mixin/typography";

.checkbox {
  margin: 0 auto 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .description {
    letter-spacing: 0.6px;
  }

  input {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    color: $color-gray0;
    background: $color-primary2;
  }

  input[type="checkbox"]:disabled + label {
    color: $color-gray2;
    background: $color-gray5;
    border-color: $color-gray4;
  }

  input[type="checkbox"]:checked:disabled + label {
    color: $color-gray2;
    background: $color-gray5;
    border-color: $color-gray4;
  }

  label {
    @include typography.button-small;
    padding: 8px;
    color: $color-primary2;
    border: 1px solid $color-primary2;
    background: $color-gray0;
    border-radius: 4px;
  }
}

.form-checkbox {
  input[type="checkbox"] {
    display: none;

    &:checked + .label-form-checkbox {
      &::after {
        display: block;
      }
    }
  }

  .label-form-checkbox {
    display: block;
    position: relative;
    padding: 0 0 0 28px;
    margin-bottom: 12px;
    cursor: pointer;
    @include typography.body-small; // FIXME : 周囲のフォントサイズに合わせて body-small に変更。周囲のフォントサイズを変更したらこのクラスも直せる

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $color-gray4;
      background: $color-gray0;
      transform: translateY(-50%);
    }

    &::after {
      display: none;
      position: absolute;
      content: "";
      top: 50%;
      left: 3px;
      width: 16px;
      height: 8px;
      margin-top: -11px;
      border-left: 3px solid $color-primary2;
      border-bottom: 3px solid $color-primary2;
      transform: rotate(-45deg);
    }
  }

  .label-form-disable-checkbox {
    &::before {
      background: $color-gray6;
    }

    &::after {
      border-left: 3px solid $color-gray4;
      border-bottom: 3px solid $color-gray4;
    }
  }
}

.c-checkbox {
  input[type="checkbox"] {
    display: none;

    &:checked {
      + .c-checkbox__label {
        &::before {
          background: variable.$color-primary2;
          border: none;
        }

        &::after {
          display: block;
        }
      }
    }

    &:disabled,
    &:disabled:checked {
      + .c-checkbox__label {
        color: variable.$color-gray2;

        &::before {
          background-color: variable.$color-gray4;
        }
      }
    }

    &:indeterminate {
      + .c-checkbox__label {
        &::before {
          background: variable.$color-primary2;
          border: none;
        }

        &::after {
          display: block;
          border-left: 0;
          border-bottom: 2px solid variable.$color-gray0;
          transform: rotate(0);
          width: 10px;
          height: 0;
          margin-top: 0;
          margin-left: 1px;
        }
      }
    }
  }

  .c-checkbox__label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      position: relative;
      content: "";
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border: 1px solid variable.$color-gray5;
      background: variable.$color-gray0;
      border-radius: 4px;
      box-sizing: border-box;
    }

    &::after {
      display: none;
      position: absolute;
      content: "";
      left: 3px;
      width: 9px;
      height: 4px;
      margin-top: -3px; // safariでデザインが崩れるため -4pxではなく-3pxとした
      border-left: 2px solid variable.$color-gray0;
      border-bottom: 2px solid variable.$color-gray0;
      transform: rotate(-45deg);
    }
  }
}
