@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

@mixin flag($color, $background) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  font-size: font.$font-size7;
  font-weight: font.$font-weight-bold;
  text-align: center;
  letter-spacing: 0;
  color: $color;
  background-color: $background;
}

.c-productStock {
  width: 100%;
  @for $i from 1 through 5 {
    &.c-productStock--#{$i} {
      &:after {
        @include flag(color.$color-gray1, color.$color-progress);
        content: "\6B8B\308A#{$i}\70B9"; // 残り#{$i}点
        opacity: 0.9;
      }
    }
  }

  &.c-productStock--soldout {
    &:after {
      @include flag(color.$color-gray0, #000000);
      content: "\58F2\308A\5207\308C"; // 売り切れ
      opacity: 0.5;
    }

    > img {
      opacity: 0.5;
    }
  }

  &.c-productStock--reservation {
    &:after {
      @include flag(color.$color-gray1, color.$color-gray4);
      content: "\4E88\7D04\53D7\4ED8\4E2D"; // 予約受付中
      opacity: 0.9;
    }
  }
}
