@use "../../user/Foundation/mixin/typography";
@use "../../user/Foundation/mixin/arrow";

@use "../Object/Project/products/p-productProducerPanel";

/* 商品一覧ページ */
.products {
  .main-content {
    @include screen("smartphone") {
      .inner-main-content {
        padding: 0;
      }
    }
  }

  .productsTitle {
    font-size: $font-size1;
    font-weight: $font-weight-bold;
    text-align: left;
    padding: 28px 0;

    @include screen("smartphone") {
      font-size: $font-size3;
      padding: 20px 16px;
    }
  }

  .productsSearchPrefectureDescription {
    margin: 16px 0 32px;
  }

  .p-searchNote {
    padding: 8px 4px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 16px;
    background: $color-gray6;
    border: 1px solid $color-gray5;
  }
}

.product-content {
  .products-order {
    display: grid;
    grid-template-columns: 100%;
    gap: 16px;
  }

  .recent-order-items {
    border: 1px solid #d0555a;

    .wrap-content {
      margin: 20px;
    }

    h4 {
      @include typography.subTitle;
      color: $color-primary1;
      margin-bottom: 4px;
    }

    .item-list {
      @include typography.body;
    }
  }

  .recent-order-items-attention {
    margin: 10px 0 20px 0;
    color: $color-gray2;
    @include typography.caption;
  }

  .old-order-content {
    @include typography.caption;

    .is-wrap {
      background-color: $color-primary4;
      padding: 20px 10px 10px;
    }

    h4 {
      @include typography.subTitle;
      color: $color-primary1;
      margin: 0;
      text-align: center;
    }

    .tag-list {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      border-radius: 4px;
      padding: 16px 5px;

      li {
        padding: 3px 5px;
      }
    }

    .note {
      margin-top: 8px;
      padding: 3px;
      color: $color-gray2;
      @include typography.caption;
    }
  }

  .image-area {
    float: left;
    width: 40%;
    margin-right: 40px;
    margin-bottom: 2rem;

    @include screen("smartphone") {
      float: none;
      width: 100%;
      margin-right: 0;
    }

    .image-area-inner {
      padding-bottom: $base-padding-2;

      @include screen("smartphone") {
        background: $color-gray5;
      }

      .carousel-image-badge {
        padding: 4px 8px;
        border-radius: 16px;
        background: $color-gray5;
        font-size: $font-size7;
        color: $color-gray1;
        @include typography.subTitle-small;
      }

      .carousel-image-note {
        display: flex;
        align-items: baseline;
        gap: 8px;
      }

      .carousel-image-time {
        color: $color-gray1;
        @include typography.caption;
        font-size: $font-size9;
      }

      .main-image {
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: $base-margin-2;

        img {
          width: 100%;
          height: auto;
        }

        // NOTE: 無効ボタンをクリックされてもズームしないようにする。
        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          pointer-events: auto;
        }

        .carousel-image-badge {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }

      /* main-image */

      .thumbs-list {
        padding: 0 $base-padding-2;

        @include screen("tablet-and-pc") {
          padding: 0;
        }

        .swiper-slide {
          width: fit-content;
        }

        // NOTE: 表示のブレ抑えるためにCSS Gridを使う。Swiper Gridモジュールだと期待通りに表示されない。
        //       See https://swiperjs.com/swiper-api#grid
        @include screen("tablet-and-pc") {
          .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: $base-margin;
          }

          .swiper-slide {
            width: auto;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .carousel-overlay {
        display: none;
        background: #fff;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999999999;

        &.carousel-overlay-shown {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }
      }

      .carousel-overlay-header {
        display: flex;
        align-items: center;
        gap: $base-margin-8;
        padding: $base-padding-2 $base-padding-4 $base-padding-4;

        > p {
          color: $color-gray2;
          @include typography.caption;
        }

        .carousel-image-caption {
          flex: 1;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          gap: 24px;
          padding-right: 24px;
          @include typography.caption-bold;
        }
      }

      .carousel-overlay-button {
        margin: 0;
        width: fit-content;
      }

      .carousel-overlay-images {
        flex: auto;
        overflow: hidden;

        > :first-child {
          height: 100%;
        }

        .carousel-image-badge {
          position: absolute;
          right: 8px;
          top: 8px;
        }

        .carousel-image-caption {
          width: 100%;
          position: absolute;
          bottom: 0px;
          padding: 16px 24px;
          background: rgba($color-gray4, 0.8);
          text-align: left;
          display: flex;
          align-items: center;
          gap: 16px;
          @include typography.caption-bold;
        }
      }

      .carousel-overlay-thumbs {
        padding: $base-padding-4 $base-margin-2;

        @include screen("smartphone") {
          .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, 48px);
            gap: $base-margin;
          }
        }
      }

      .carousel-overlay-thumbs-item {
        width: fit-content;
      }
    }

    /* image-area-inner */
  }

  /* image-area */

  .info-area {
    float: left;
    width: calc(100% - (40% + 40px));
    @include screen("smartphone") {
      width: 100%;
    }

    .p-productLabelWrapper {
      margin-bottom: $base-margin-4;
      display: flex;
      align-items: center;
    }

    .p-donationProductLabel__icon {
      width: 24px;
      height: 24px;
    }

    .p-donationProductLabel__text {
      @include typography.subTitle-small;
      margin-right: 8px;
      margin-left: 4px;
    }

    .p-productCampaignLabel {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 30px;
      border: 1px $color-primary1 solid;
    }

    .p-productCampaignLabel__text {
      @include typography.label-bold;
      color: $color-primary1;
    }

    .info-block {
      margin-bottom: 24px;

      .item-name {
        margin-bottom: $base-margin;
        @include typography.title-1;
      }

      .price-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          // 酒類の通信販売における表示 に関わるので注意
          @include typography.price("detail");

          .tax {
            @include typography.caption;
            color: $color-gray2;
          }
        }

        .few-tag {
          background: $color-progress;
          padding: 5px 10px;
          border-radius: 4px;
          display: inline-flex;
          font-weight: 600;
        }
      }
    }

    /* info-block */

    .btn-buy {
      display: flex;

      .c-btn {
        max-width: none;

        &:nth-child(2n) {
          margin-left: 10px;
        }
      }

      &.with-text-block {
        display: block;

        .btn-buy-group {
          display: flex;
        }
      }
    }

    .free-shipping-block {
      display: flex;
      align-items: center;

      .remaining-time {
        text-align: center;
        @include typography.body-small-bold;
      }

      .free-shipping-text {
        margin: 10px 0 10px 16px;
      }

      h3 {
        @include typography.body-bold;
      }

      p {
        @include typography.caption;
      }

      .track {
        width: 80px;
      }
    }

    /* free-shipping-block */

    .discount-seafood {
      .discount-seafood-block {
        display: flex;
        align-items: center;

        .discount-seafood-text {
          margin: 10px 0 10px 16px;
        }

        .discount-seafood-description {
          @include typography.caption;
        }

        .discount-seafood-price {
          @include typography.subTitle;
        }
      }
    }

    .p-productCampaign {
      .p-productCampaign__campaignBlock {
        background: $color-primary4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        font-weight: $font-weight-semibold;
        font-size: $font-size4;
        border-radius: 4px 4px 0 0;
      }

      .p-productCampaign__campaignName {
        display: flex;
        align-items: center;
      }

      .p-productCampaign__icon {
        margin-right: 8px;
        background: $color-gray0;
        border-radius: 18px;
        padding: 4px;
        height: 36px;
        width: 36px;
      }

      .p-productCampaign__link {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: $font-weight-semibold;
        font-size: $font-size4;
        color: $color-primary1;
      }

      .p-productCampaign__textBlock {
        @include typography.body-small;
        padding: 12px 16px;
        background: $color-gray6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0 0 4px 4px;
      }

      .p-productMunicipalityDiscountCampaign__textBlock {
        padding: 12px 16px;
        background: $color-primary0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0 0 4px 4px;
        @include typography.body-small;
        @include screen("smartphone") {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .p-productCampaign__text {
        @include screen("pc-only") {
          display: flex;
          align-items: baseline;
        }
      }

      .p-productMunicipalityDiscountCampaign__text {
        display: flex;
        align-items: baseline;
        @include screen("smartphone") {
          margin-bottom: 8px;
        }
      }

      .p-productCampaign__text--new,
      .p-productCampaign__text--totalPrice {
        @include typography.body-small-bold;
      }

      .p-productCampaign__text--coupon,
      .p-productCampaign__text--discount {
        @include typography.body-small-bold;
        color: $color-order1;
        margin: 0 4px;
      }

      .p-productCampaign__text--price {
        font-size: $font-size2;
        margin-right: 2px;
      }

      .p-productMunicipalityDiscountCampaign__deadline {
        background: $color-primary4;
        padding: 4px 8px;

        @include screen("smartphone") {
          padding: 4px 0;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .p-productMunicipalityDiscountCampaign__deadlineText {
        color: $color-gray1;
        @include typography.body-small-bold;
      }
    }

    .tag-block {
      padding: 20px 0;
      border-top: 1px solid $color-gray5;
      // border-bottom: 1px solid $color-gray5; TODO: 商品詳細のAB終了後に有効に戻す

      .tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        li {
          a {
            display: block;
            padding: 4px 8px;
            color: $color-gray1;
            @include typography.body-small;
            background: $color-gray6;
            border-radius: 4px;
            transition: 300ms;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        /* li */
      }

      /* tag-list */
    }

    /* tag-block */

    .description {
      padding: 0 0 20px;
      @include typography.body;
      @include screen("smartphone") {
        padding: 0 0 20px 0;
      }

      .notice-message {
        background-color: $color-gray5;
        padding: 20px;
        margin-bottom: 10px;
      }

      a {
        color: $text-brown;
      }

      h3 {
        @include typography.subTitle;
        color: $color-primary1;
      }

      h4 {
        @include typography.body-bold;
      }
    }

    .description-grad-wrap-close {
      .description-item {
        max-height: 300px;
        position: relative;
        overflow: hidden;
      }

      .description-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px; /*グラデーションで隠す高さ*/
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
        content: "";
      }

      .description-andmore {
        display: block;
        color: $text-brown;
        position: absolute;
        bottom: 0;
        font-weight: bold;
        width: 100%;
        text-align: center;

        &::before {
          @include arrow($text-brown, 2px, $arrowDirectionBottom);
          content: "";
          position: absolute;
          top: calc(50% - 8px);
          margin-left: -20px;
        }
      }
    }

    .description-grad-wrap-open {
      .description-bottom {
        display: none;
      }

      .description-andmore {
        display: none;
      }
    }

    // 酒類の通信販売における表示
    // 10ポイントの活字（ インターネット等による場合には酒類の価格表示に使用している文字）以上の大きさの統一のとれた日本文字で明瞭に表示しなければなりません
    .liquor-attention {
      @include typography.body-small;
    }

    .p-productLocalProductReason__text {
      padding: 0 0 20px;
      @include typography.body;
      @include screen("smartphone") {
        padding: 0 0 20px 0;
      }
    }
  }

  /* info-area */

  .social-button-area {
    padding: 16px 10px 20px;
    @include screen("smartphone") {
      padding: 16px 0 20px;
    }
  }
}

/* product-content */

.product-post-link-wrapper {
  margin-bottom: $base-margin-3;

  &.is-hidden {
    margin-bottom: -1 * $base-margin-3;
    visibility: hidden;
  }
}

// HACK: <a>要素のデフォルトCSSの詳細度をオーバーライドするために、クラスセレクタだけでなく要素セレクタが必要…。
a.product-post-link {
  @include typography.caption-bold;
  display: inline-flex;
  gap: 4px;

  > :first-child {
    --rating-size: #{$font-size4};
  }

  > :last-child {
    color: $color-primary2;
    text-decoration: underline;
  }
}

.products-order {
  &.is-placeholder {
    min-height: 650px; /* CLS 対応 ファーストルックでのレイアウト崩れを防ぐため */
  }

  &.is-placeholderSp {
    min-height: 306px;
  }

  .order-discount-percentage {
    border-radius: 16px;
    background: $color-order2;
    color: $color-gray0;
    padding: 6px 8px;
    @include typography.label-bold;
  }

  .about-subscription {
    a {
      display: flex;
      align-items: center;
      color: $color-primary2;
      @include typography.caption-bold;

      &:before {
        content: "?";
        color: $color-gray0;
        background: $color-primary2;
        border-radius: 100%;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        padding: 4px;
        margin-right: 5px;
      }
    }
  }

  .onlySubscription {
    .onlySubscription__title {
      @include typography.subTitle;
    }

    .onlySubscription__desc {
      display: grid;
      gap: 8px;
      @include typography.caption;
      padding: 12px 16px;
      background: $color-gray6;
    }
  }

  .subscription-free-shipping-attention {
    @include typography.caption-bold;
    margin-top: 4px;
    color: $color-order2;
  }

  .product-categories {
    .product-categories_link {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;

      &:nth-child(n + 2) {
        margin-top: 8px;
      }

      .product-categories_link-wrap {
        position: relative;

        > a {
          color: $color-primary1;
        }

        &:nth-child(n + 2) {
          margin-left: 8px;

          &::before {
            content: ">";
            @include typography.caption;
            position: absolute;
            top: 4px;
          }

          > a {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .p-productPreference__wrap {
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    @include screen("smartphone") {
      grid-template-columns: 1fr;
    }
  }

  .p-productPreference {
    padding: 12px 16px 16px 16px;
    display: grid;
    grid-template-areas: "itemName name" "bar bar" "tag tag";
    gap: 12px;
    border-radius: 4px;
    background: $color-primary4;
  }

  .p-productPreference__nameWrap {
    grid-area: name;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
  }

  .p-productPreference__name {
    padding: 4px 8px;
    background-color: $color-gray0;
    border-radius: 24px;
    @include typography.caption-bold;
  }

  .p-productPreference__itemName {
    grid-area: itemName;
    @include typography.body-bold;
  }

  .p-productPreference__barWrap {
    grid-area: bar;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .p-productPreference__barContainer {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 4px;
  }

  .p-productPreference__labels {
    @include typography.caption-bold;
    display: flex;
    justify-content: space-between;
  }

  .p-productPreference__bar {
    position: relative;
    display: flex;
    height: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .p-productPreference__barBar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .p-productPreference__barDot {
    width: 28px;
    height: 28px;
    z-index: 1;
  }

  .p-productPreference__tagWrap {
    grid-area: tag;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .p-productPreference__tag {
    padding: 4px 8px;
    background-color: $color-gray0;
    border-radius: 4px;
    @include typography.body-small;
  }

  .subscriptionUserHelp__link {
    color: $color-primary1;
  }

  .productShippingWarning__link {
    color: $color-primary1;
  }

  .product-prefecture_link-wrap {
    > a {
      color: $color-primary1;
    }
  }

  .product-sell-wrap {
    @include typography.subTitle;
    border: 2px solid $color-gray5;
    padding: 20px;
    white-space: pre-wrap;

    .product-sell-pr {
      color: $color-primary1;
      text-align: center;
      margin-bottom: 10px;
    }

    .product-sell-list {
      li {
        padding-left: 20px;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &::before {
          content: "";
          background-color: $color-primary1;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          top: 7px;
          left: 0;
        }
      }
    }
  }
}

.product-subscription-form {
  .price {
    .subscription-before-price {
      text-decoration: line-through;
      text-decoration-color: line-through;
      text-align: right;
    }

    .subscription-after-price {
      color: #dc0800;
      text-align: right;
    }

    .subscription-price {
      text-align: right;
    }
  }

  /* price */
}

.product__footer-content {
  // ...
}

.product__footer-content-banner {
  max-width: 800px;
  position: relative;
  margin: 0 auto;
}

.product__footer-content {
  .bnr-long-wrap {
    transition: 300ms;
    margin-bottom: 0px;

    > li {
      > a {
        > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.bundle-product {
  background: $color-primary4;
  padding: 12px 16px;

  .bundle-product-label {
    @include typography.subTitle-small;
    color: $color-gray0;
    background: $color-primary2;
    border-radius: 16px;
    padding: 4px 12px;
    width: fit-content;
  }

  .bundle-product-about {
    @include typography.caption-bold;
    color: $color-gray2;
    margin-top: 8px;
  }
}

.productMoreLink {
  display: flex;

  &.productMoreLink--post {
    flex-direction: column;
    padding-bottom: 24px;
  }

  .productMoreLink__button {
    @include typography.button;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid $color-gray5;
    padding: 28px 16px;
    color: $color-gray1;
  }

  .productMoreLink__label {
    display: flex;
    align-items: center;
  }

  .productMoreLink__count {
    @include screen("smartphone") {
      @include typography.caption-bold;
    }
  }
}

.salesAttentionPanel {
  padding: 12px 16px;
  background: $color-gray0;
  border: 1px solid $color-gray5;

  &.salesAttentionPanel--top {
    margin: 1rem;
  }

  .salesAttentionPanel__block {
    @include screen("tablet-and-pc") {
      display: flex;
      align-items: center;
    }
  }

  .salesAttentionPanel__label {
    background: $color-gray5;
    border-radius: 16px;
    padding: 4px 12px;
    @include typography.subTitle-small;
    display: inline-block;

    &.salesAttentionPanel__label--soldOut {
      color: $color-gray0;
      background: $color-gray2;
    }
  }

  .salesAttentionPanel__term {
    @include typography.body-small;
    @include screen("tablet-and-pc") {
      margin-left: 8px;
    }
    @include screen("smartphone") {
      margin-top: 8px;
    }
  }
}

.product-detail-list {
  display: grid;
  grid-template-columns: 82px 1fr;

  dt {
    border-top: 1px solid $color-gray5;
    padding: 16px 8px;
    @include typography.body-bold;
  }

  dd {
    border-top: 1px solid $color-gray5;
    padding: 16px 8px;
    @include typography.body;
    display: grid;
    align-items: center;
    gap: 8px;
  }

  .product-detail-sales-period {
    position: relative;
  }

  .product-detail-sales-period-status {
    font-weight: bold;
    padding: 4px 10px;
    background: #ffdd57;
    border-radius: 16px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: -0.3em;
  }

  .donationProduct__localProductAttention {
    @include typography.body-small;
    color: $color-gray2;
  }
}

.imperfectReasonCategories {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  margin-top: 12px;

  .imperfectReasonCategories__title {
    background: $color-primary2;
    border-radius: 16px;
    color: $color-gray0;
    @include typography.subTitle;
    padding: 4px 8px;
  }

  .imperfectReasonCategories__tag {
    @include typography.body;
  }
}

.imperfectReasonNote {
  padding: 20px;
  background: $color-primary4;

  .imperfectReasonNote__title {
    color: $color-primary1;
    @include typography.subTitle;
  }

  .imperfectReasonNoteList__btn {
    margin-top: 4px;
  }
}

.imperfectReasonNoteList {
  display: none;
  margin-top: 8px;
  @include typography.body;

  &.imperfectReasonNoteList--show {
    display: block;
  }
}

.preservation {
  padding: 20px 0;
  border-bottom: 1px solid $color-gray5;
  margin-bottom: 20px;

  .preservation__title {
    @include typography.subTitle;
  }

  .preservation__listWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @include typography.body;
  }

  .preservation__note {
    @include typography.body;
    margin-top: 8px;
  }
}

.couponDiscountPrice {
  border: 1px solid $color-order1;
  color: $color-order1;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  @include typography.caption;

  .couponDiscountPrice--strong {
    @include typography.body-small-bold;
  }

  .couponDiscountPrice__text {
    display: flex;
    flex-direction: column-reverse;

    @include screen("pc-large-only") {
      flex-direction: unset;
      gap: 12px;
      align-items: center;
    }
  }
}

.shippingDetails {
  .shippingDetails__label {
    display: flex;
    align-items: center;
    gap: 12px;
    @include screen("smartphone") {
      justify-content: space-between;
      width: 100%;
    }
  }

  .shippingDetails__carrierName {
    display: flex;
    align-items: baseline;
    column-gap: 8px;
    @include screen("smartphone") {
      flex-direction: column;
    }
  }

  .shippingDetails__carrierSubName {
    @include typography.caption;
  }

  .shippingDetails__form {
    display: flex;
    align-items: center;
    gap: 20px;

    @include screen("smartphone") {
      justify-content: space-between;
      width: 100%;
    }
  }
  .shippingDetails__deliveryDateForm {
    display: block;
    align-items: center;
    gap: 20px;

    @include screen("smartphone") {
      justify-content: space-between;
      width: 100%;
    }
  }

  .shippingDetails__select {
    width: 160px;
  }

  .shippingDetails__cost {
    @include typography.price("list");

    &.shippingDetails__cost--through {
      text-decoration-line: line-through;
      color: $color-gray3;
    }
  }

  .shippingDetails__free {
    @include typography.label-bold;
    border-radius: 16px;
    background: $color-order2;
    color: $color-gray0;
    padding: 6px 8px;

    @include screen("tablet-and-pc") {
      margin-left: 8px;
    }
  }

  .shippingDetails__detail {
    @include typography.body-small;
    color: $color-gray2;
  }

  .shippingDetails__date {
    @include typography.body;
    border-top: 1px solid $color-gray4;
    padding-top: 12px;
  }

  .shippingDetails__desiredDate {
    @include typography.body-bold;
    margin-right: 4px;
  }

  .shippingDetails__noDesiredDate {
    @include typography.body-bold;
  }

  .shippingDetails__shippingPeriod {
    @include typography.body-small;
  }

  .shippingDetails__averageShippingDays {
    color: $color-primary1;
    background: $color-gray6;
    padding: 8px;
    border-radius: 4px;
    @include typography.body-small-bold;
  }

  .notice-message {
    margin: 0;
    padding: 12px;
    .shippingDetails__notice-message-label {
      color: $color-primary1;
      font-weight: $font-weight-semibold;
    }
    .ellipsis {
      @include typography.body-small;
    }
  }
}

.supportLabel {
  color: $color-primary2;
  margin-bottom: 12px;
  font-weight: $font-weight-semibold;
  font-size: $font-size5;
}

/* stylelint-disable */
.productYoutubeContainer {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.productYoutubeContainer iframe {
  width: 100%;
  height: 100%;
}

/* stylelint-enable */

.p-supportDetails {
  display: flex;
  justify-content: space-around;

  .p-supportDetails__block {
    text-align: center;
    cursor: pointer;
    flex-grow: 1;

    & + .p-supportDetails__block {
      border-left: 1px solid $color-gray4;
    }
  }

  .p-supportDetails__image {
    margin-bottom: 4px;
    vertical-align: bottom;
  }

  .p-supportDetails__text {
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
    color: $color-primary1;
  }
}

.p-alpsHotateFreeShipping {
  display: flex;
  align-items: center;
}

.p-alpsHotateFreeShippingTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.p-alpsHotateFreeShippingTextContainer__subText {
  font-size: $font-size7;
  font-weight: $font-weight-bold;
}

.p-alpsHotateFreeShippingTextContainer__text {
  font-size: $font-size2;
  font-weight: $font-weight-bold;
}
