.c-adInquiryContact__list {
  list-style: none;
  text-indent: -1em;
  padding-left: 1em;
}

.c-adInquiryContact__listItem {
  &::before {
    content: "\203B"; // "※"
  }

  .c-adInquiryContact__link {
    text-decoration: underline;
  }
}
