@use "../../Foundation/variable";
@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/screen";

.buyAgain {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 8px 20px;
  align-items: center;
  margin-top: 16px;

  @include screen.screen("tablet-and-pc") {
    grid-template-columns: 1fr 1fr;
  }

  .buyAgain__price {
    @include typography.price;

    @include screen.screen("tablet-and-pc") {
      text-align: right;
    }
  }
}
