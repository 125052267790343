/*
# c-formStatusLabel

## 用途
- フォームの入力項目について、必須項目（required）・任意項目（optional）のどちからの状態を示します
- 任意が初期状態です
- 任意と必須以外の状態はありません

## 禁止事項
- 入力項目名（label）と必ず一緒に使われます。 単独で使われることはありません。

## 検討事項
- 入力項目名と一緒に使う前提なので、コンポーネントなのか？という疑問がある
*/

.c-formStatusLabel {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 16px;

  font-size: $font-size7;
  font-weight: $font-weight-semibold;
  letter-spacing: 0.5px;
  line-height: 1;
  white-space: nowrap;

  color: $color-gray1;
  background-color: $color-gray4;

  &.c-formStatusLabel--optional {
    color: $color-gray1;
    background-color: $color-gray4;
  }

  &.c-formStatusLabel--required {
    color: $color-gray0;
    background-color: $color-primary2;
  }
}
