@use "../../Foundation/variable";

.c-btnSearch {
  border-radius: 24px;
  font-size: variable.$font-size7;
  font-weight: variable.$font-weight-semibold;
  background: variable.$color-primary2;
  color: variable.$color-gray0;
  width: 66px;
  height: 26px;

  &.c-btnSearch--reset {
    background: variable.$color-gray0;
    color: variable.$color-primary2;
    border: 1px solid variable.$color-primary2;
  }
}
