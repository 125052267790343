.p-productProducerPickupProduct {
  border-top: 1px solid $color-gray4;
  padding: 20px 0;

  .p-productProducerPickupProduct__container {
    max-width: 500px;
  }

  .p-productProducerPickupProduct__heading {
    font-weight: $font-weight-bold;
    font-size: $font-size4;
    margin-bottom: 8px;
  }
}
