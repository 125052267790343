/*
.c-formDivRadioButtonWithEdit

## 注意事項
`Deprecated`
- この Class は `非推奨` です。.c-formInputRadioButtonWithEdit を使ってください
- コンシェルジュのフォームで使われている input 要素のようで違う UI があり、改めて実装し直しています。input要素は input要素を使うようにしましょう

## アクセシビリティ
- role で radio の場合、一緒に radiogroup の使用も検討してください
*/
.c-formDivRadioButtonWithEdit {
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: 18px 1fr;
  gap: 8px;
  align-items: center;

  color: $color-gray1;

  background-color: $color-gray0;
  border: 1px solid $color-gray4;
  border-radius: 4px;

  padding: 16px;

  &::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid #bda35a;
    background-color: $color-gray0;
    border-radius: 50%;
    grid-area: 1/1;
    box-sizing: border-box;
  }

  &[aria-disabled="true"] {
    color: $color-gray3;
    background-color: $color-gray5;
    border: 1px solid $color-gray4;

    &::before {
      border: 2px solid $color-gray5;
    }

    &::after {
      background: $color-gray5;
    }
  }

  &[aria-checked="true"] {
    color: $color-gray1;

    background-color: $color-primary3;
    border: 1px solid $color-primary2;
    border-radius: 4px;

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background: #bda35a;
      border-radius: 50%;
      grid-area: 1/1;
      margin: 0 auto;
    }
  }

  & + .c-formDivRadioButtonWithEdit {
    margin-top: 8px;
  }

  .c-formDivRadioButtonWithEdit__labelText {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    margin: 0;
    padding: 0;
  }

  .c-formDivRadioButtonWithEdit__labelTextBody {
    flex: 1;
  }

  .c-formDivRadioButtonWithEdit__labelTextInvalid {
    color: #ff0000;
    line-height: 1.5;
  }

  .c-formDivRadioButtonWithEdit__labelTextWithButton {
    min-width: calc(2em + (14px * 2));
  }

  .c-formDivRadioButtonWithEdit__nameAndAddress {
    display: grid;
    gap: 8px 4px;
    width: 100%;
    grid-template-columns: 2.1em 1fr;
  }

  .c-formDivRadioButtonWithEdit__button {
    color: $color-primary2;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.5px;

    border: 1px solid $color-primary2;
    border-radius: 4px;
    padding: 14px;
    background-color: $color-gray0;
  }
}
