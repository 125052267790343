/*
# .c-formInputNameAndAddress
## 用途
- フォームの c-formInputRadioButtonWithEdit でのみ使える
## 禁止事項
- .c-formInputRadioButtonWithEdit 以外と一緒に使う
*/

.c-formInputNameAndAddress {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .c-formInputNameAndAddress__term {
    width: 2em;
  }

  .c-formInputNameAndAddress__description {
    width: calc(100% - (2em + 8px));
  }
}
