@use "../../Foundation/mixin/line-clamp" as mixin;

.p-productSliderRelated {
  .p-productSliderRelated__title {
    padding: 4px 8px;
    background-color: $color-gray6;
    font-size: $font-size4;
    font-weight: $font-weight-semibold;
    color: $color-gray1;
  }

  .p-productSliderRelated__wrap {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 8px 0;
  }

  .p-productRelatedCard {
    background: $color-gray0;
    position: relative;
    flex: 0 0 18%;
    @include screen("smartphone") {
      flex: 0 0 29%;
    }
  }

  .p-productRelatedCard__productLink:hover {
    opacity: 0.8;
  }

  .p-productRelatedCard__productImg {
    position: relative;
    width: 100%;
    padding-top: 70%;
    border-radius: 4px;
    overflow: hidden;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .p-productRelatedCard__productName {
    margin-bottom: 0;
    padding: 3px 0;

    @include mixin.line-clamp(1);
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
  }

  .p-productRelatedCard__price {
    text-align: start;
    font-size: $font-size3;
    font-weight: $font-weight-bold;
  }
}
