.p-orderFormCompletesSectionThankyou {
  background-color: $color-primary4;
  padding-top: 16px;

  @at-root {
    @mixin p-CompletesSectionThankyou {
      content: "";
      height: 60px;
      width: 100%;
      background: {
        image: url(" ../../app/assets/images/decoration-vegetable.svg");
        repeat: no-repeat;
        position: top center;
      }
    }

    .p-orderFormCompletesSectionThankyou__header {
      @include p-CompletesSectionThankyou();
    }

    .p-orderFormCompletesSectionThankyou__footer {
      @include p-CompletesSectionThankyou();
      transform: rotateZ(180deg);
    }

    .p-orderFormCompletesSectionThankyou__body {
      padding: 20px 16px;
      max-width: 90%;
      margin: 0 auto;
      background: #fff;
      border-radius: 16px;
    }

    .p-orderFormCompletesSectionThankyou__title {
      color: $text-gold;
      font-family: Futura, sans-serif;
      font-size: 38px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.5px;
      text-align: center;
    }

    .p-orderFormCompletesSectionThankyou__subTitle {
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;
    }

    .p-orderFormCompletesSectionThankyou__text {
      text-align: center;
    }
  }
}
