@use "../../Foundation/mixin/typography";

.c-inquiryForm {
  .c-inquiryForm__link {
    color: $color-primary1;
    text-decoration: underline;
  }

  // user/Object/Component/form.scss の form_textarea の拡張
  .c-inquiryForm__textarea {
    width: 100%;
    min-height: 100px;
    padding: 6px;
    border: 1px solid $color-gray5;
    border-radius: 4px;
    color: $color-gray1;
  }

  .c-inquiryForm__alertMessage {
    color: $color-warning;
    margin-top: $base-margin-2;
    font-size: $font-size7;
  }
}

.c-inquiryFaq {
  .c-inquiryFaq__title {
    display: block;
    margin: 0 auto 5px;
    font-size: $font-size7;
    font-weight: $font-weight-bold;
    text-align: left;
  }

  .c-inquiryFaq__message {
    padding-left: 12px;
    font-size: $font-size7;
  }

  .c-inquiryFaq__list {
    padding-left: 12px;
  }

  .c-inquiryFaq__listItem {
    margin-top: 8px;
    font-size: $font-size7;
  }

  .c-inquiryFaq__link {
    font-size: $font-size7;
    color: $color-primary1;
    text-decoration: underline;
  }
}
