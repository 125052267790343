@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";

.c-formToggleSwitch {
  --_track-size: 1em;
  --_knob-postion: 0;
  --_knob-color: #{color.$color-gray0};
  --_default-color: #{color.$color-gray3};
  --_checked-color: #{color.$color-primary2};
  --_desabled-color: #{color.$color-gray5};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  cursor: pointer;

  @include typography.body-small-bold;

  .c-formToggleSwitch__input {
    inline-size: calc(var(--_track-size) * 2);
    block-size: var(--_track-size);

    flex-shrink: 0;

    display: grid;
    align-items: center;
    grid: [track] 1fr / [track] 1fr;
    box-sizing: content-box;
    border-radius: calc(var(--_track-size) * 2);
    background-color: var(--_default-color);
    padding: 2px;

    transition: background-color 0.25s ease;

    &:checked {
      --_knob-postion: calc(calc(var(--_track-size) * 2) - 100% * 1);
      background-color: var(--_checked-color);
    }

    &:disabled {
      background-color: var(--_desabled-color);
    }

    &::after {
      grid-area: track;

      inline-size: var(--_track-size);
      block-size: var(--_track-size);

      content: "";
      cursor: pointer;
      pointer-events: auto;

      background-color: var(--_knob-color);
      border-radius: 50%;
      transform: translateX(var(--_knob-postion));
      transition: transform 0.25s ease;
    }
  }
}
