@use "../../../Foundation/variables/color";

.c-badge {
  --badge-color: #{color.$color-annotation};

  position: absolute;
  content: "";
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  background-color: var(--badge-color);

  // FIXME : コンポーネントなのにレイアウト情報をもっているので、レイアウト用のスタイルを別途作る or バッジの実装を mixin にすると削除できる
  top: 0;
  left: calc(50% + 5px);
}
