.c-linkAreaPrefecture {
  display: block;
  color: #9f8745;

  @include screen("tablet-and-pc") {
    color: #212121;
    font-size: $font-size5;
    font-weight: $font-weight-bold;
    text-align: center;

    padding: 16px 0;

    border: 1px solid #cab270;
    border-radius: 4px;
  }
}
