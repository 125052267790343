@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";
@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/arrow";

.p-productAward {
  gap: 2px;
  display: grid;

  @include screen("pc-only") {
    grid-template-columns: repeat(2, 1fr);
  }

  .p-productAward__link {
    background: color.$color-gray6;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: relative;
    border-radius: 4px;

    &::before {
      @include arrow($color-primary1, 2px, $arrowDirectionRight);
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .p-productAward__nonlink {
    background: color.$color-gray6;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: relative;
    border-radius: 4px;
  }

  .p-productAward__name {
    font-size: font.$font-size9;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }

  .p-productAward__rank {
    @include typography.caption;
  }
}
