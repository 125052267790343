@use "../../../Foundation/variable";

.p-farmingFilter {
  padding: 16px;

  .p-farmingFilter__label {
    padding: 6px 0;
  }
  .p-farmingFilter__count {
    color: variable.$color-gray2;
    font-size: variable.$font-size7;
    font-weight: variable.$font-weight-light;
    &::before {
      content: "\FF08"; // "（"
    }
    &::after {
      content: "\FF09"; // "）"
    }
  }
}
