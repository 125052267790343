@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/mixin/screen";

.p-notificationsTitle {
  // MEMO : おなじスタイルになっているが、役割は異なっていたので別クラスになっている
  position: relative;

  &.p-notificationsTitle--h2 {
    text-align: left;
    margin-top: 32px;

    @include screen.screen("tablet-and-pc") {
      text-align: center;
    }
  }

  &.p-notificationsTitle--h3 {
    text-align: left;
    margin: 32px 0;

    @include screen.screen("tablet-and-pc") {
      text-align: center;
    }
  }

  .p-notificationsTitle__moreBtn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
