@use "../../user/Foundation/mixin/typography";

.sub_txt {
  font-size: 11px;
  color: #999;
}

.form_account {
  .sub_attention {
    font-size: 10px;
    font-weight: normal;
  } /* sub_attention */

  .alert {
    width: 250px;
    margin: 0 auto 3px;
    color: #f00;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
  } /* alert */

  .postal_code {
    display: flex;

    input {
      width: 75%;
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    .search_by_code {
      display: block;
      width: 25%;
      color: #fff;
      border: none;
      border-radius: 0 4px 4px 0;
      background: #aaa;
      appearance: none;
    }

    #address_prefecture_code {
      position: relative;
      display: block;
      width: 250px;
      margin: 0 auto 25px;
      padding: 8px 30px 8px 20px;
      font-size: 14px;
      text-align: center;
      border: 1px solid #f1f1f1;
      background-image: url(" ../../app/assets/images//icon-arrow-down.png");
      background-repeat: no-repeat;
      background-position: 95% center;
      background-size: 11px auto;
    }
  }

  .expiration_date {
    input {
      text-align: center;
    }

    .month {
      width: 40px;
    }

    .year {
      width: 70px;
    }
  }

  .image-area {
    overflow: hidden;
    width: 270px;
    margin: 0 auto 30px;
    padding: 15px;
    border: 1px solid $color-gray5;
    background: $color-gray6;

    .ttl {
      @include typography.subTitle;
      margin-bottom: 8px;
    }

    .upload-image {
      margin: 0 auto 10px;
      width: 120px;
    } /* upload-image */

    .text-list {
      @include typography.caption;
    } /* text-list */
  } /* image-area */

  #recaptcha-check {
    width: 250px;
    margin: 0 auto 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attention {
    margin-bottom: 5px;
    font-size: 10px;
    color: #1b1b1b;

    a {
      color: #1b1b1b;
      text-decoration: underline;
    }

    &.tar {
      margin: 0 auto 30px;
      text-align: right;
    }
  } /* attention */
} /* form_account */
