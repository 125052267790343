@use "../../Foundation/mixin/typography";

.c-invitation {
  .c-invitation__section {
    display: grid;
    align-items: flex-end;
    gap: 16px;
    margin: 14px 0 18px;
    @include screen("tablet-and-pc") {
      grid-template-columns: 320px 1fr;
      gap: 20px;
    }
  }

  .c-invitationCodeArea {
    .c-invitationCodeArea__codeSection {
      background-color: $color-gray6;
      padding: 8px 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .c-invitationCodeArea__codeLabel {
      @include typography.caption;
      color: $color-gray2;
    }

    .c-invitationCodeArea__code {
      color: $color-gray1;
      font-size: 28px;
      font-weight: 700;
    }

    .c-invitationCodeArea__shareSection {
      margin-top: 12px;
      display: block;
      width: 100%;
    }

    .c-invitationCodeArea__shareLink {
      max-width: none;
    }
  }

  .c-invitationInfoArea {
    .c-invitationInfoArea__achievementSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 16px;
    }

    .c-invitationInfoArea__achievement {
      display: inline-flex;
      color: $color-gray1;
      align-items: baseline;
    }

    .c-invitationInfoArea__countLabel {
      @include typography.subTitle;
    }

    .c-invitationInfoArea__count {
      @include typography.title-1;
    }

    .c-invitationInfoArea__count--nonzero {
      color: $color-primary1;
      @include typography.title-2;
    }

    .c-invitationInfoArea__countUnit {
      @include typography.subTitle-small;
    }

    .c-invitationInfoArea__message {
      display: flex;
      justify-content: center;
      @include typography.body-small;
      background-color: $color-primary4;
      padding: 12px 16px;
      border-radius: 4px;

      > p {
        width: fit-content;
      }
    }
  }
}
