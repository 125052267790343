.c-inHouseAdvertising {
  background-color: $color-gray0;
  padding: 28px 20px;
  display: grid;
  gap: 16px 28px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid $color-gray5;

  @include screen("tablet-and-pc") {
    padding: 32px;
    grid-template-columns: 50% 1fr;
  }

  @include screen("smartphone") {
    width: calc(100vw - 16px - 16px);
    margin: 0 calc(50% - 50vw + 16px);
  }

  .c-inHouseAdvertising + & {
    margin-top: 1.5rem;
  }

  .c-inHouseAdvertising__ribbonImage {
    text-align: center;
    margin-bottom: 8px;
  }

  .c-inHouseAdvertising__couponCampaign {
    color: #e50b18;
    text-align: center;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    line-height: 1.5;

    & + .c-inHouseAdvertising__subTitle {
      margin-top: 10px;
    }
  }

  .c-inHouseAdvertising__subTitle {
    display: block;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    @include screen("tablet-and-pc") {
      font-size: 14px;
    }

    &.c-inHouseAdvertising__subTitle--small {
      color: $color-primary1;
      font-size: $font-size5;
    }
  }

  .c-inHouseAdvertising__title {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    @include screen("tablet-and-pc") {
      font-size: 20px;
      line-height: 1.4;
      grid-column: 2;
    }

    &.c-inHouseAdvertising__title--big {
      color: $color-primary1;
      font-size: $font-size2;
    }
  }

  .c-inHouseAdvertising__thumbnail {
    display: block;
    @include screen("tablet-and-pc") {
      grid-column: 1;
      grid-row: 1 / 4;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  .c-inHouseAdvertising__text {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;

    @include screen("tablet-and-pc") {
      font-size: 16px;
      grid-column: 2;
    }

    &.c-inHouseAdvertising__text--small {
      font-size: $font-size7;
      text-align: left;
      letter-spacing: 0.8px;
      margin: auto;
      max-width: 300px;
    }
  }

  .c-inHouseAdvertising__action {
    margin-top: 16px;
    @include screen("tablet-and-pc") {
      grid-column: 2;
    }
  }

  .c-inHouseAdvertising__buttonLabel {
    font-size: $font-size7;
    text-align: center;
    margin-bottom: 8px;
  }

  .c-inHouseAdvertising__price {
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: 8px;
  }
}
