@import "../../extension/spacing";

$color-white: hsl(0, 0%, 100%);
$color-black: hsl(0, 0%, 4%);
$color-red: hsl(352, 87%, 40%);
$color-grey: #999999;
$color-grey-light: hsl(0, 4%, 85%);
$color-secondary: #cab270;
$color-speciallink: #aa7f4d;
$color-feed-link: hsl(0, 0%, 4%);

$colors: (
  "primary": $color-white,
  "secondary": $color-secondary,
  "danger": $color-red,
  "link": $color-grey,
  "default": $color-black,
  "gray-light": $color-grey-light,
  "speciallink": $color-speciallink,
);

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;
$sizes: $size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7;

a {
  cursor: pointer;
}

/* Helper ------------------------------ */
.flexbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.marginless {
  margin: 0 !important;
}

.paddingless {
  padding: 0 !important;
}

.borderless {
  border: none !important;
}

.is-flex {
  @extend .flexbox;
  align-items: center;

  &.is-center {
    justify-content: center;
  }
}

.is-flex-column {
  @extend .is-flex;
  flex-direction: column;
}

.no-scroll {
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.no-scroll-x {
  overflow-x: hidden !important;
}

/* ------------------------------ Space helper */

/* Text helper ------------------------------ */
// color
@each $key, $color in $colors {
  .has-text-#{$key} {
    color: $color !important;
  }
}

// size
@each $size in $sizes {
  $i: index($sizes, $size);
  .is-size-#{$i} {
    font-size: $size;
  }
}

// weight
.has-text-weight-bold {
  font-weight: 600;
}

.has-text-weight-light {
  font-weight: 500;
}

// position
$positions: (
  "left": left,
  "center": center,
  "right": right,
);
@each $key, $position in $positions {
  .has-text-#{$key} {
    text-align: $position !important;
  }
}

.text-pre-line {
  white-space: pre-line;
}

.text-no-user-select {
  user-select: none;
}

/* ------------------------------ Text helper */

/* Button ------------------------------ */
button:disabled {
  border-color: #dbdbdb;
  box-shadow: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.is-button {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  color: $color-white;
  font-size: $size-6;
  font-weight: bold;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #464646;
}

.is-button-default {
  font-size: $size-6;
  color: $color-white;
  background: $color-grey-light;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.is-button-danger {
  font-size: $size-6;
  color: $color-white;
  background: $color-red;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

#floating-container {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;

  #floating-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    transition: 0.25s;
    filter: drop-shadow(0px 0px 4px rgba(#000, 0.2));

    .is-icon {
      width: 20px !important;
      height: auto;
      margin-bottom: 1px;

      svg {
        width: 100% !important;
        height: auto;
        vertical-align: bottom;

        path {
          fill: $color-primary2;
        }
      }
    }

    .text {
      color: $color-primary2;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.delete {
  width: 25px;
  height: 25px;
  background-color: grey;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 2px;

  &::before {
    background-color: $color-white;
    content: "";
    height: 2px;
    width: 50%;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  &::after {
    background-color: $color-white;
    content: "";
    height: 50%;
    width: 2px;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

/* ------------------------------ Button */

/* Search Textbox ------------------------------ */
.is-search-input {
  display: block;
  width: 92%;
  margin: 15px auto;
  padding: 5px 5px 5px 30px;
  border: 1px solid $color-gray5;
  border-radius: 18px;
  background: {
    color: rgba(255, 255, 255, 0.4);
    image: url(" ../../app/assets/images/icon-search-gray.png");
    repeat: no-repeat;
    position: 12px center;
    size: 12px auto;
  }
}

/* ------------------------------ Search Textbox */

a .title {
  font-weight: 600;
  line-height: 1.125;
  color: $color-feed-link;
}

a .title:hover {
  text-decoration-line: underline;
}

figure.circle {
  img {
    border-radius: 50%;
    width: auto;
    height: 36px;

    &.mini {
      height: 32px;
      max-width: initial;
    }
  }
}

.dropdown {
  position: relative;

  &.is-right {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  &.is-active {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-trigger {
    > a {
      display: block;

      .is-icon {
        display: block;
        height: auto;
        line-height: 0;

        svg {
          width: 100% !important;
          height: auto;
        }
      }
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 64px;
    padding-top: 8px;
    z-index: 9999999999;

    //@include min-breakpoint($dimen-pc) {
    &:hover {
      display: block;
    }

    //}

    .dropdown-content {
      overflow: visible;
    }
  }

  &.is-hoverable {
    .dropdown-trigger:hover ~ .dropdown-menu {
      display: block;
    }
  }

  .dropdown-content {
    position: relative;
    overflow: scroll;
    max-height: 500px;
    padding: 4px 0;
    background-color: $color-white;
    box-shadow:
      0 2px 3px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.1);

    &:before {
      position: absolute;
      content: "";
      bottom: 100%;
      right: 8px;
      width: 0;
      height: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid #fff;
      z-index: 2;
    }

    &:after {
      position: absolute;
      content: "";
      bottom: 100%;
      right: 6px;
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 7px solid #dadada;
      z-index: 1;
    }

    &.dropdown-content-notification {
      &:before {
        right: 12px;
      }

      &:after {
        right: 10px;
      }

      .no-scroll-x {
        max-height: 495px;
        background-color: white;
      }
    }

    .dropdown-item {
      @extend .flexbox;
      padding: 6px 10px 6px 12px;
      font-size: 12px;
      align-items: center;
      text-align: center;

      &:hover {
        text-decoration: none;
        background-color: $color-primary4;
      }

      .text {
        // ...
      }

      .is-icon {
        display: inline-block;
        float: none;
        width: 10px !important;
        height: auto;
        margin-right: 5px;

        svg {
          width: 100% !important;
          height: auto;
        }
      }
    }
  }
}

.communication-feed {
  background-color: $color-gray5;
  padding: 0px 0 20px 0;
  font-size: 14px;
}

.communication-feed-farmer {
  padding-top: 40px;

  @include screen("smartphone") {
    padding-top: 0;
  }
}

.communication-feed__container {
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  gap: 16px;

  .communication-feed__article {
    @include screen("tablet-and-pc") {
      padding-top: 30px;
    }
  }

  .p-communication-feed__pagination:not(:empty) {
    padding: 10px 0;
    background: $color-white;
  }
}

.communication-feed__post {
  margin-bottom: 1rem;
  padding: 15px 10px;
  background-color: $color-white;
  letter-spacing: 0 !important;
  box-shadow: none !important;

  &.has-shadow {
    border-radius: 2px;
    box-shadow:
      0 2px 3px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  a {
    color: $color-grey;

    &:hover {
      text-decoration: underline;
    }
  }

  .feed-content {
    @extend .flexbox;
    align-items: flex-start;

    .content-left,
    .content-right {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .content-left {
      margin-right: 8px;
    }

    .content-center {
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      text-align: left;

      > p {
        line-height: 1.4;
        font-size: 14px;
        letter-spacing: 0px;
      }

      > .is-size-7 {
        padding-top: 2px;

        a {
          span {
            display: inline-block;
            padding-top: 1px;
            vertical-align: top;
            letter-spacing: 0;
          }
        }
      }

      .post-type {
        color: #aaa;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .post-header {
      .title {
        color: $color-feed-link;
        font-weight: bold;
        text-decoration: underline;
      }

      .title:hover {
        text-decoration: underline;
      }
    }

    .content-right {
      margin-left: 1rem;

      .c-followBtn {
        float: right;
        @include screen("smartphone") {
          float: left;
        }
      }
    }
  }

  .post-main {
    margin: 20px 0 20px 0;
  }

  .feed-content-product-name {
    h4 {
      font-weight: 600;
      line-height: 1.125;
      color: $color-feed-link;
    }

    h4:hover {
      text-decoration: underline;
    }
  }

  .feed-content-title {
    font-weight: 600;
    line-height: 1.125;
    color: $color-black;
  }

  .post-replies {
    .feed-content {
      .content-left {
        .circle {
          img {
            // height: 32px;
          }
        }
      }
    }
  }

  .content {
    white-space: pre-wrap;
    word-break: break-all;
  }

  .grad-item {
    position: relative;
    overflow: hidden;
    letter-spacing: 0;
  }

  .grad-bottom {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px; /*グラデーションで隠す高さ*/
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
    content: "";
  }

  .content-footer {
    ul {
      @extend .flexbox;
      margin: 0.5rem 0 0.25rem 0;

      > li {
        margin-right: 0.7rem;
        @extend .flexbox;
        align-items: center;
        font-size: 14px;

        > span {
          font-size: 12px;
          color: #aaa;

          &.is-size-7 {
            font-size: 12px;

            .is-icon {
              width: 16px;

              svg {
                vertical-align: middle;
              }
            }
          }
        }

        &.is-size-7 {
          > span {
            font-size: 11px;
          }

          > a {
            font-size: 12px;
          }

          .is-flex {
            .is-icon {
              width: 13px;

              .icon-heart-mini {
                width: 100%;
                vertical-align: middle;
              }
            }

            span {
              margin-left: 2px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .action-menu {
      justify-content: space-around;
      border: {
        width: 1px 0;
        style: solid;
        color: #ddd;
      }

      > a {
        flex-grow: 1;
        color: $color-grey;
        padding: 0.4rem;

        &:hover {
          text-decoration: none;
        }

        > div {
          justify-content: center;

          > span {
            margin-left: 4px;
            font-size: 12px;
          }

          .is-icon {
            width: 1.1rem;

            > svg {
              width: 100%;
              height: auto;

              &.icon-comment {
                position: relative;
                // top: -2px;
              }
            }
          }
        }
      }
    }
  }

  .comment {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    padding: 0.2rem 0.8rem;
    background: #f3f3f3;
    border-radius: 18px;

    &:before {
      position: absolute;
      content: "";
      top: 1rem;
      left: -12px;
      width: 0;
      height: 0;
      border-width: 7px 20px 7px 0;
      border-style: solid;
      border-color: transparent #f3f3f3 transparent;
      transform: rotate(30deg);
    }

    > span {
      font-size: 12px;
    }
  }

  .comment-forms {
    @extend .flexbox;

    textarea {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 18px;
      flex-grow: 2;
    }

    .comment-submit-button {
      align-self: flex-end;

      .is-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      a {
        .is-icon {
          .icon-send {
            width: 100%;
            height: auto;

            path {
              stroke: #999;
            }
          }
        }
      }
    }
  }
}

.communication-feed-btn-area {
  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;
}

// 新規登録

.communication-post {
  background-color: $color-gray5;
  padding: 0px 0 20px 0;
}

.communicationPost__warning {
  margin-bottom: 24px;
  padding: 16px;
  background: $color-primary4;
  font-size: $font-size7;
  display: grid;
  gap: 8px;
}

.communicationPost__warningText {
  display: flex;

  .communicationPost__warningText--asterisk {
    margin-right: 4px;
  }
}

.communicationPost__warningLink {
  color: $color-primary1 !important;
}

.communicationPost__tips {
  padding: 12px;
  font-size: $font-size7;
  text-align: left;
  background: $color-primary4;
  margin-bottom: 8px;
}

.communicationPost__tipsTitle {
  font-weight: $font-weight-semibold;
  margin-bottom: 4px;
}

.p-emotionReview {
  background: $color-gray6;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 32px;

  @include screen("smartphone") {
    justify-content: space-around;
    padding: 0;
  }

  li {
    padding: 16px 0 12px;
    width: 100%;
    text-align: center;

    &.p-emotionReview__icon--active {
      background: $color-primary3;
    }
  }

  .p-emotionReview__text {
    font-size: $font-size9;
    font-weight: $font-weight-semibold;
    margin-top: 8px;
  }
}

.communication-post__container {
  .header {
    text-align: center;
    padding: 0 20px 10px 20px;
    border-bottom: 1px solid #f4f4f4;
  }

  .dropdown-content {
    h2 {
      background: #f1f1f1;
    }
  }

  .form-block {
    font-size: 14px;
    margin-bottom: 1rem;

    label {
      display: inline-block;
      margin-bottom: 3px;
      color: #555;
      font-size: 13px;
    }

    .field {
      width: 100%;
      padding: 8px 5px 8px 28px;
      border: 1px solid $color-gray5;
      background: {
        color: rgba(255, 255, 255, 0.4);
        image: url(" ../../app/assets/images/icon-search-gray.png");
        repeat: no-repeat;
        position: 9px 12px;
        size: 12px auto;
      }
      border-radius: 4px;
    }

    .attention {
      font-size: 12px;
      text-align: center;

      a {
        color: $text-brown;
      }
    }

    .submit {
      margin-top: 10px !important;
      padding-top: 10px;
      padding-bottom: 30px;
      text-align: center;
    }
  }

  .form-value,
  .form_select {
    margin-top: 4px;
  }

  .txtMust {
    display: inline-block;
    margin-right: 8px;
    padding: 2px 8px;
    background: $color-primary2;
    color: $color-gray0;
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
    border-radius: 16px;
  }

  .txtMore {
    display: inline-block;
    margin-right: 8px;
    padding: 2px 8px;
    background: $color-gray4;
    color: $color-gray1;
    font-size: $font-size7;
    font-weight: $font-weight-semibold;
    border-radius: 16px;
  }

  .error-box {
    width: 250px;
    margin: 30px auto;
    padding: 10px;
    border: 1px solid red;
    color: red;
    font-size: 13px;
  }
}

/* Image Gallery Component ---------------------- */
.image-gallery {
  width: 100%;
  font-size: 0;
  @extend .flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;

  .image {
    -webkit-box-flex: auto;
    -ms-flex: auto;
    flex: auto;
    width: 200px;
    padding: 0 !important;
    border: 1px solid #fff;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &:last-child {
      position: relative;
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;

      .view-all {
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

        &::before {
          display: inline-block;
          content: "";
          vertical-align: middle;
          height: 100%;
        }

        .view-all-cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.4;
        }

        .view-all-text {
          position: relative;
          font-size: 16px;
          font-family: sans-serif;
          color: white;
        }
      }
    }
  }
}

/* ---------------------- Image Gallery Component */

/* Modal Component ---------------------- */
.modal,
.no-scroll-modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: fixed;
  z-index: 9999999999;

  &.is-active {
    @extend .flexbox;
  }

  .modal-background {
    background-color: rgba(10, 10, 10, 0.86);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.1;
  }

  .modal-content {
    position: relative;
    width: 92%;
    max-height: calc(100vh - 160px);
    margin: 0 20px;

    .photo {
      background-color: #fff;

      img {
        width: 100%;
      }
    }

    .box {
      .card {
        .card-content {
          h2 {
            font-size: 16px;
            color: #555;
            background: #f1f1f1;
          }

          article {
            padding: 0;

            a {
              padding: 0.5rem;

              span {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    h2 {
      // ...
    }

    @media screen and (min-width: 769px) {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 640px;
    }

    img {
      max-width: 100%;
      position: relative;

      &:hover ~ .navigation {
        @extend .flexbox;
      }
    }

    .navigation {
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none;
      justify-content: space-between;
      align-items: center;

      &:hover {
        @extend .flexbox;
      }
    }
  }

  .modal-close {
    background: 0 0;
    position: fixed;
    right: 10px;
    top: 10px;
    -webkit-appearance: none;
    background-color: rgba(10, 10, 10, 0.2);
    border: none;
    border-radius: 290486px;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    max-height: 15px;
    max-width: 15px;
    min-height: 20px;
    min-width: 20px;
    outline: 0;
    vertical-align: top;

    &::before {
      background-color: #fff;
      content: "";
      height: 2px;
      width: 100%;
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }

    &::after {
      background-color: #fff;
      content: "";
      height: 100%;
      width: 2px;
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }
  }
}

/* ---------------------- Modal Component */

/* Full Screen Page */
.fullscreen {
  display: none;
  background: white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
  overflow-y: auto;

  &.is-active {
    display: block;
  }

  .fullscreen_header {
    position: relative;

    .main-title {
      width: 100%;
      font-weight: bold;
      text-align: center;
    }

    .button-close {
      position: absolute;
      width: 47px;
      height: 47px;

      a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        &:before {
          position: absolute;
          content: "";
          top: 17px;
          left: 14px;
          width: 15px;
          height: 2px;
          background: #ccc;
          transform: rotate(-45deg);
          border-radius: 10px;
        }

        &:after {
          position: absolute;
          content: "";
          top: 27px;
          left: 14px;
          width: 15px;
          height: 2px;
          background: #ccc;
          transform: rotate(45deg);
          border-radius: 10px;
        }
      }
    }
  }

  .fullscreen_content,
  .fullscreen_footer {
    position: absolute;
    width: 100%;
  }

  .fullscreen_content {
    bottom: 100px;
    top: 48px;
    overflow-y: auto;

    h2 {
      font-size: 13px;
      color: #555;
      background: #f1f1f1;
    }

    .farmer-list {
      padding: 0;
      border-bottom: 1px dotted #eee;

      a {
        padding: 0.5rem;

        span {
          font-size: 13px;
        }
      }
    }

    textarea {
      left: 0;
      top: 0;
      right: 0;
      bottom: 100px;
      width: 100%;
      border: none !important;
    }

    .textarea:active,
    .textarea.is-active {
      border-color: none;
      box-shadow: none;
    }
  }

  .fullscreen_footer {
    bottom: 0;
  }
}

/* Box Compoent */
.box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow:
    0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}

.review-list-wrap {
  background: #f4f1ec;
  padding: 20px 16px;
  border-radius: 6px;
  margin-bottom: 20px;

  h4 {
    font-weight: 600;
    margin-bottom: 10px;

    span {
      font-size: 12px;
    }
  }
}

.alert-wrap {
  text-align: center;
  font-weight: 600;
  color: #bf1632;
  border: 2px solid #bf1632;
  padding: 8px 16px;
}

.review-list {
  display: flex;
  flex-wrap: wrap;

  label {
    border-radius: 20px;
    border: 1px solid #aa7f4d;
    font-size: 12px;
    color: #aa7f4d;
    background-color: #ffffff;
    padding: 8px 16px;
    min-width: 80px;
    text-align: center;
    font-weight: 600;
    margin: 0 8px 8px 0;
  }

  input[type="checkbox"]:checked + label {
    color: #fff;
    background-color: #cdac4f;
  }
}

.feeling-list {
  display: flex;
  justify-content: center;

  label {
    width: calc(100% / 6);
    max-width: 70px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    font-size: 10px !important;
    letter-spacing: 0;
    font-weight: 600;
    @media screen and (min-width: 321px) {
      font-size: 12px !important;
    }
    @media screen and (min-width: 769px) {
      padding: 5px;
    }

    &::before {
      content: "";
      width: 40px;
      height: 40px;
      display: block;
    }

    &.feeling-1 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_1_off.svg");
      }
    }

    &.feeling-2 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_2_off.svg");
      }
    }

    &.feeling-3 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_3_off.svg");
      }
    }

    &.feeling-4 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_4_off.svg");
      }
    }

    &.feeling-5 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_5_off.svg");
      }
    }

    &.feeling-6 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_6_off.svg");
      }
    }
  }

  input[type="radio"]:checked + label {
    background: #f1f1f1;
    border-radius: 4px;

    &.feeling-1 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_1.svg");
      }
    }

    &.feeling-2 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_2.svg");
      }
    }

    &.feeling-3 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_3.svg");
      }
    }

    &.feeling-4 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_4.svg");
      }
    }

    &.feeling-5 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_5.svg");
      }
    }

    &.feeling-6 {
      &::before {
        background-image: url(" ../../app/assets/images/communication/feeling_6.svg");
      }
    }
  }
}

.post-feeling {
  padding-left: 25px;
  display: flex;
  position: relative;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 0;
    background-size: 100%;
  }

  &.post-feeling-1 {
    &::before {
      background-image: url(" ../../app/assets/images/communication/feeling_1.svg");
    }
  }

  &.post-feeling-2 {
    &::before {
      background-image: url(" ../../app/assets/images/communication/feeling_2.svg");
    }
  }

  &.post-feeling-3 {
    &::before {
      background-image: url(" ../../app/assets/images/communication/feeling_3.svg");
    }
  }

  &.post-feeling-4 {
    &::before {
      background-image: url(" ../../app/assets/images/communication/feeling_4.svg");
    }
  }

  &.post-feeling-5 {
    &::before {
      background-image: url(" ../../app/assets/images/communication/feeling_5.svg");
    }
  }
}

/* Review */
.rank-list {
  display: flex;
  align-items: center;
  margin: 0;

  input {
    display: none;
  }

  .score {
    width: calc(100% / 5);
    max-width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 600;

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      background: {
        image: url("../../app/assets/images/icon-star-off.svg");
        repeat: no-repeat;
        position: center center;
        size: cover;
      }
    }

    &.on {
      &::before {
        background-image: url(" ../../app/assets/images/icon-star-on.svg");
      }
    }
  }
}

/* 規約同意モーダル */
.modal-terms {
  font-size: 14px;
}

.btn-modal-terms-close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 50%;
  position: relative;
  margin-top: -10px;
  margin-right: -5px;

  span {
    position: absolute;
    display: block;
    width: 14px;
    height: 2px;
    left: calc(50% - 7px);
    background: #403a39;
    border-radius: 4px;
  }

  span:nth-child(1) {
    transform: rotate(-45deg);
    top: calc(50% - 1px);
  }

  span:nth-child(2) {
    transform: rotate(45deg);
    top: calc(50% - 1px);
  }
}

a.link-modal-terms {
  color: #336699;
}

.btn-modal-terms-accept {
  width: 250px;
  margin: 20px auto 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #336699;
  color: #fff;
}

.btn-modal-terms-close-wrap {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.label-modal-terms-accept {
  margin-top: 10px;
  display: inline-block;
  position: relative;
  padding-left: 24px;
  font-size: 14px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    background: #fff;
    transform: translateY(-50%);
  }

  &:after {
    display: none;
    position: absolute;
    content: "";
    top: 50%;
    left: 3px;
    width: 16px;
    height: 8px;
    margin-top: -11px;
    border-left: 3px solid #cab270;
    border-bottom: 3px solid #cab270;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .txt {
    display: inline-block;
  }
}

.checkbox-modal-terms:checked + .label-modal-terms-accept:after {
  display: block;
}

.review-modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999999;

  &.is-active {
    display: block;
  }
}

.review-modal-wrap {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 100%;
  }
}

.review-modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.review-modal-box {
  width: 100%;
  max-width: 500px;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  .inner {
    border-radius: 10px;
    background: $color-primary4;
    white-space: normal;

    .modal-content {
      padding: 30px 16px 20px;
    }

    h2 {
      font-size: 18px;
    }

    .btn-wrap {
      background-color: #fff;
      padding: 20px 16px;
      border-radius: 0 0 10px 10px;
    }

    .side-border-red {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-weight: 600;
      color: $color-primary1;
      text-align: center;

      &::before {
        content: "\FF3C"; // "＼"
        margin-right: 5px;
      }

      &::after {
        content: "\FF0F"; // "／"
        margin-left: 5px;
      }
    }

    .border-red {
      display: inline-block;
      padding: 12px 24px;
      border-top: 1px solid #dc2929;
      border-bottom: 1px solid #dc2929;
      font-weight: 600;
      color: #dc2929;
      text-align: center;
    }

    .mock {
      position: relative;
      height: 245px;
      width: 180px;
      margin: 0 auto;
      background: {
        repeat: no-repeat;
        position: top center;
        size: 100% auto;
      }

      &.mock-top {
        background-image: url(" ../../app/assets/images/mock_top.png");
      }

      &.mock-review {
        background-image: url(" ../../app/assets/images/mock_review.png");
      }

      &.mock-communication {
        background-image: url(" ../../app/assets/images/mock_communication.png");
      }

      &.mock-rating {
        background-image: url(" ../../app/assets/images/mock_rating.png");
      }

      img {
        width: 175%;
        position: absolute;
        bottom: 0;
        left: -40%;
      }
    }
  }
}

@import "./communication/communication-feed-new";
