@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-reviewHelpful {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 12px 16px;
  color: color.$color-gray2;
  background: color.$color-gray6;
  @include typography.caption;

  .p-reviewHelpful__button {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    color: color.$color-gray2;
  }
}
