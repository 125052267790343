.p-producerRecipes {
  .p-producerRecipes__spTitle {
    @include screen("smartphone") {
      margin-top: 12px;
    }
  }

  .p-producerRecipes__count {
    font-size: 12px;
    text-align: right;
    margin: 10px;
  }

  .p-producerRecipes__none {
    margin: 10px;
    font-weight: bold;
    text-align: center;
  }
}
