.c-searchCategoryList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1px;
  padding: 1px;
  border-radius: 4px;
  background-color: $color-gray5;

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(2, 1fr);
  }

  .c-searchCategoryList__item {
    background-color: #ffffff;
    &:first-child {
      border-top-left-radius: 4px;
    }

    &:nth-child(2) {
      border-top-right-radius: 4px;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  .c-searchCategoryList__itemLink {
    display: flex;
    align-items: center;
    padding: 12px 16px;

    &::after {
      @include arrow($color-gray3, 1px, $arrowDirectionRight);
    }
  }

  .c-searchCategoryList__itemLinkInner {
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;

    @include screen("tablet-and-pc") {
      gap: 16px;
    }
  }

  .c-searchCategoryList__itemIcon {
    border-radius: 50%;
    height: 48px;
    width: 48px;

    @include screen("tablet-and-pc") {
      height: 60px;
      width: 60px;
    }
  }

  .c-searchCategoryList__itemLabel {
    font-size: $font-size4;
    font-weight: $font-weight-bold;
    line-height: 1.4;
  }
}
