@use "../../../Foundation/variable";
@use "../../../Foundation/mixin/screen";

.p-priceFilter {
  padding: 12px;

  @include screen.screen("pc-large-only") {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-priceFilter__select {
    background: variable.$color-gray5;
  }
}
