@use "sass:color";

.c-restockRequestBtn {
  display: grid;
  gap: $base-margin-2;
  padding: $base-padding-4;
  background-color: $color-gray6;
}

.c-restockRequestBtn__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 13px;
  font-size: $font-size6;
  font-weight: $font-weight-bold;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color: $color-order1;
  border: 1px solid $color-order1;
  background: $color-gray0;

  &:hover {
    background: #fcf3f4; //rgba($color-order1, 0.1);
    border: 1px solid color.adjust($color-order1, $lightness: -4%);
    color: color.adjust($color-order1, $lightness: -4%);
  }
}

.c-restockRequestBtn__button--disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 13px;
  font-size: $font-size6;
  font-weight: $font-weight-bold;
  text-decoration: none;
  cursor: not-allowed;
  text-align: center;
  border-radius: 4px;
  color: $color-order1;
  border: 1px solid $color-order1;
  background: $color-gray0;
  opacity: 0.38;
}

.c-restockRequestBtn__text--bold {
  font-weight: $font-weight-bold;
}
