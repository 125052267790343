@use "../Foundation/mixin/h2-title";

.cart-content {
  background-color: $color-gray0;
  padding-bottom: 80px;

  .container {
    background-color: #fff;
    max-width: 700px;
    margin: 0 auto 1px;
    padding: 30px 16px 20px;
    @include screen("tablet-and-pc") {
      padding: 40px 20px 20px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .bold-txt {
    font-weight: bold;
  }

  .couponBlock {
    margin-bottom: $base-margin-4;
  }

  .couponBlock__text {
    margin-bottom: $base-margin-2;
  }
}

.shopping-cart {
  background-color: #fff;
  padding: 20px 16px 24px;
  max-width: 700px;
  margin: 0 auto 20px;
  @include screen("tablet-and-pc") {
    padding: 20px 20px 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .cart-producer {
    padding: 12px;
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    border-radius: 4px;

    .cart-producer-name {
      font-size: 14px;
      font-weight: 600;
      width: calc(100% - 90px);
      @include screen("tablet-and-pc") {
        font-size: 16px;
      }
    }
  }

  .subtotal {
    margin-bottom: 20px;

    .subtotal-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .subtotal-line-key {
        font-weight: bold;
        font-size: 14px;
      }

      .subtotal-line-value {
        .price {
          font-size: 20px;
        }
      }

      .attention {
        font-size: 11px;
      }
    }
  }

  .no-cart-items {
    text-align: center;
    margin: 10px auto 20px;
  }
}

.cart-item-content {
  display: flex;
  padding-bottom: 16px;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 16px;
    border-bottom: 1px solid $color-gray5;
  }

  .cart-item-thumbnail {
    flex-basis: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;

    @include screen("smartphone") {
      flex-basis: 50px;
      height: 50px;
    }

    a {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cart-item-body {
    flex-basis: calc(100% - 100px);
    margin-left: 12px;

    @include screen("smartphone") {
      flex-basis: calc(100% - 50px);
    }

    .product-name {
      font-size: 14px;
      font-weight: bold;

      @include screen("smartphone") {
        font-size: 12px;
      }
    }

    .cart-item-body-producer {
      font-size: 12px;
    }

    /* stylelint-disable */
    .cart-item-body-order {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cart-item-body-order-price {
        align-items: flex-end;
        font-size: 16px;

        .cart-item-body-order-price-wrap {
          align-items: center;
          display: flex;
          margin-top: 4px;
        }

        .tax-unit {
          margin-left: 3px;
          font-size: 12px;
          color: $text-gray;
        }

        @for $i from 1 through 5 {
          .flag-few-#{$i} {
            &:after {
              background: $color-progress;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: $font-size7;
              font-weight: $font-weight-semibold;
              margin-left: 10px;
              content: "\6B8B\308A#{$i}\70B9"; // 残り#{i}点
            }
          }
        }
      }
      /* stylelint-enable */

      .cart-item-body-order-quantity {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .quantity-button {
          position: relative;
          cursor: pointer;
          padding: 0;
          background: none;
        }

        .quantity {
          font-size: 14px;
          font-weight: bold;
          margin: 0 12px;
        }

        button:disabled {
          cursor: not-allowed;

          span {
            opacity: 0.5;
          }
        }

        button:focus {
          outline: 0;
        }
      }
    }

    .remove-button {
      background: none;
      font-size: 12px;
      color: $color-gray1;
      text-decoration: underline;
    }
  }
}

.cart-item-content.soldout {
  position: relative;

  .cart-item-thumbnail {
    opacity: 0.2;
  }

  .cart-item-body {
    pointer-events: none;
    opacity: 0.2;
  }

  .c-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(-8px);
  }
}
