@use "../../Foundation/variable" as var;

.p-regularSubscriptionOrderProduct {
  .p-regularSubscriptionOrderProduct__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid var.$color-gray5;

    th {
      padding: 8px;
      border: 1px solid var.$color-gray5;
      text-align: left;
    }

    td {
      padding: 8px;
      border: 1px solid var.$color-gray5;
      text-align: left;
    }
  }

  .p-regularSubscriptionOrderProduct__description {
    font-size: var.$font-size7;
  }

  .p-regularSubscriptionOrderProduct__link {
    color: var.$color-primary2;
    font-weight: var.$font-weight-bold;
  }
}
