@use "../../../../Foundation/mixin/typography";
@use "../../../../Foundation/mixin/line-clamp";
@use "../../../../Foundation/mixin/arrow";
@use "../../../../Foundation/mixin/screen";
@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/variables/font";

.c-reviewDetail {
  display: flex;
  flex-direction: column;
  gap: 20px 16px;
  background: color.$color-gray0;

  @include screen.screen("tablet-and-pc") {
    padding: 16px;
  }

  // MEMO: 商品詳細でのみ使用している。他のページで使用する場合は、別途調整が必要です。
  &.c-reviewDetail--modal {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-gray4;
    }

    &:first-of-type {
      border-top: 1px solid $color-gray4;
      margin-top: 20px;
    }
  }

  .c-reviewDetail__product {
    border-radius: 4px;
    background-color: color.$color-primary4;
  }

  .c-reviewDetail__productLink {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 12px;

    padding: 12px;

    &::after {
      content: "";
      @include arrow.arrow(color.$color-primary2, 2px, 45deg);
    }
  }

  .c-reviewDetail__productInner {
    display: grid;
    gap: 8px;
  }

  .c-reviewDetail__productBody {
    display: grid;
    grid-template-columns: 48px auto;
    gap: 12px;
  }

  .c-reviewDetail__productDetail {
    display: grid;
    gap: 4px;
  }

  .c-reviewDetail__productPhoto {
    border: 1px solid color.$color-gray0;
  }

  .c-reviewDetail__productName {
    @include line-clamp.line-clamp(2);
    @include typography.subTitle-small;
  }

  .c-reviewDetail__productInfo {
    display: grid;
    gap: 8px;
  }

  .c-reviewDetail__productPrice {
    @include typography.subTitle-small;
  }

  .c-reviewDetail__producer {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;
  }

  .c-reviewDetail__producerName {
    @include typography.subTitle-small;
  }

  .c-reviewDetail__title {
    @include typography.subTitle;
  }

  .c-reviewDetail__content {
    display: grid;
    gap: 4px;
  }

  .c-reviewDetail__contentBody {
    @include typography.body;
    word-break: break-all;
    position: relative;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  }

  .c-reviewDetail__contentButton {
    background: linear-gradient(90deg, rgba($color-gray0, 0) 0%, $color-gray0 20%, $color-gray0 100%);
    color: color.$color-primary1;
    @include typography.button-small;
    padding: 8px 0 8px 40px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .c-reviewDetail__meta {
    display: flex;
    align-items: center;
    gap: 8px;
    color: color.$color-gray2;
    @include typography.caption;
  }

  .c-reviewDetail__metaQuantity {
    padding-left: 8px;
    border-left: 1px solid color.$color-gray4;
  }

  .c-reviewDetail__reviewrAndProducer {
    display: grid;
    gap: 8px;
  }

  .c-reviewDetail__user {
    display: flex;
    align-items: center;
    gap: 8px;

    > img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .c-reviewDetail__userInfo {
    display: grid;
    gap: 4px;
  }

  .c-reviewDetail__userName {
    @include typography.subTitle-small;
  }

  .c-reviewDetail__count {
    @include typography.caption;
  }

  .c-reviewDetail__produer {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: start;
    align-items: center;
    gap: 8px;

    &::before {
      content: "";
      @include arrow.arrow(color.$color-primary2, 2px, 45deg);
    }
  }

  .c-reviewDetail__produerName {
    @include typography.subTitle-small;
  }
}
