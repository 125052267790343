@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";

.c-breadCrumb {
  background-color: color.$color-primary4;
  font-size: font.$font-size9;
  line-height: 1;

  .c-breadCrumb__inner {
    max-width: 1240px; // FIXME: 定数がわかれば置き換える
    display: flex;
    gap: 8px;

    scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;

    padding: 16px;
    margin: 0 auto;
  }

  .c-breadCrumb__link {
    display: flex;
    gap: 8px;
    color: color.$color-primary1;

    &::after {
      content: ">";
      color: color.$color-gray2;
      font-weight: font.$font-weight-bold;
    }

    &[aria-current="page"] {
      color: color.$color-gray1;
      pointer-events: none;

      &::after {
        content: "";
      }
    }
  }
}
