.c-keywordList {
  max-width: 1240px;
  margin: 0 auto;
  padding: 40px 20px 60px;

  .c-keywordList__title {
    font-size: $font-size1;
    font-weight: $font-weight-bold;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .c-keywordList__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .c-keywordList__itemBlock {
    min-width: 40%;
    width: 40%;
    margin-bottom: 40px;

    @include screen("smartphone") {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .c-keywordList__item {
    @include screen("smartphone") {
      border-bottom: 1px solid $color-gray4;
    }
  }

  .c-keywordList__itemLink {
    color: $color-primary1;
    font-weight: $font-weight-bold;
    font-size: $font-size5;
    padding: 0;

    @include screen("smartphone") {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      font-size: $font-size4;

      &::after {
        content: "";
        height: 8px;
        width: 8px;
        transform: rotate(45deg);
        border-top: 2px solid $color-primary1;
        border-right: 2px solid $color-primary1;
      }
    }
  }

  .c-keywordList__itemText {
    @include screen("smartphone") {
      max-width: 95%;
    }
  }
}
