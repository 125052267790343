@use "../../Foundation/mixin/typography";

.p-mypageLoyalUser {
  .p-mypageLoyalUser__title {
    border-left: 4px solid $color-primary2;
    padding-left: 12px;
    margin-bottom: 16px;
    @include typography.title-2;
  }

  .p-mypageLoyalUser__infoText {
    margin-bottom: 16px;
    @include typography.body;
  }

  .p-mypageLoyalUser__block {
    display: grid;
    gap: 16px;
    border-radius: 8px;
    background-color: $color-primary4;
    padding: 20px 16px 28px;
    @include screen("tablet-and-pc") {
      padding: 28px 28px 32px;
    }
  }

  .p-mypageLoyalUserLine {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p-mypageLoyalUserLine__title {
      @include typography.subTitle;
    }

    .p-mypageLoyalUserList__counter {
      color: $color-primary1;

      .p-mypageLoyalUserList__counterCount {
        font-size: 28px;
        font-weight: 700;
        line-height: 100%;
      }

      .p-mypageLoyalUserList__counterText {
        @include typography.subTitle-small;
      }
    }
  }

  .p-mypageLoyalUserLink {
    margin: 20px 0 28px;
  }

  .p-mypageLoyalUser__producer {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .p-mypageLoyalUser__producerImg {
    width: 48px;
    height: 48px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .p-mypageLoyalUser__producerName {
    @include typography.subTitle;
  }

  .p-mypageLoyalUser__achievementBlock {
    display: flex;
    justify-content: center;
    gap: 32px;
  }

  .p-mypageLoyalUser__achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .p-mypageLoyalUser__achievementText {
    @include typography.subTitle-small;
    font-weight: $font-weight-semibold;
  }
}
