p + p {
  margin-top: 0.5rem;
}

.font-mincho {
  font-family:
    "ヒラギノ明朝 ProN W3",
    "HiraMinProN-W3",
    "HG明朝E",
    "ＭＳ Ｐ明朝",
    "MS PMincho",
    "MS 明朝",
    Lucida Grande,
    serif;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-through {
  text-decoration: line-through;
}

.text-small {
  font-size: 13px;
  letter-spacing: 0;
}

.text-smallest {
  font-size: 10px;
}

.text-red {
  color: #dc2929 !important;
}

.text-gold {
  color: $text-gold !important;
}

.text-brown {
  color: $text-brown !important;
}

.text-white {
  color: $color-gray0 !important;
}

.text-gray {
  color: $color-gray2;
}

.text-link {
  color: $text-brown !important;
  padding: 0 3px;
}

h2,
.h2 {
  font-size: 30px;
  @include screen("smartphone") {
    font-size: 22px;
  }
}

h4,
.h4 {
  font-size: 14px;
  margin-bottom: 20px;
  @include screen("smartphone") {
    margin-bottom: 10px;
  }
}

.h1 {
  font-size: $font-size3;
  line-height: 28px;
}

.h2 {
  font-size: $font-size4;
}

.h3 {
  font-size: $font-size5;
}

.h4 {
  font-size: $font-size6;
}

.h5 {
  font-size: $font-size7;
}

.h6 {
  font-size: $font-size8;
  line-height: 12px;
}

/* typography */
.note {
  font-size: $font-size8;
  color: $text-gray;
  font-weight: normal;
}

.sm {
  font-size: $font-size8;
  line-height: 17px;
}

.label {
  color: $font-size7;
  font-weight: bold;
  line-height: 17px;
}

.u-one-text-indent {
  padding-left: 1em;
  text-indent: -1em;
}

.u-textUnderline {
  text-decoration: underline;
}
