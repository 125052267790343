// TODO: 不要になったら削除する。See https://caniuse.com/mdn-html_elements_dialog
@import "dialog-polyfill/dialog-polyfill.css";

// TODO: FarmerやAdmin、WebViewなどで使うときに、スタイルの調整が必要になるかもしれません。そのときはフロントチームに相談してください。

// NOTE: `:has()` セレクタを使ってダイアログの裏側のコンテンツがスクロールするのを防いでいる。古いiOSではサポート外。
// See https://web.dev/building-a-dialog-component/#scroll-containment
// See https://caniuse.com/css-has
// stylelint-disable-next-line tabechoku/selector-type-allowed-list -- 無視してOK。
html:has(dialog[open]) {
  overflow: hidden;
}

// stylelint-disable-next-line tabechoku/selector-type-allowed-list -- 無視してOK。
dialog {
  --dialog-bg-color: #fff;
  --dialog-border: 0;
  --dialog-width: fit-content;
  --dialog-height: fit-content;

  background: var(--dialog-bg-color);
  border: var(--dialog-border);
  color: inherit;

  // positioning
  position: fixed;
  inset: 0;
  z-index: 99999999999999;

  // spacing
  margin: auto;
  padding: 0;

  // size
  width: var(--dialog-width);
  height: var(--dialog-height);
  max-width: var(--dialog-width);
  max-height: var(--dialog-height);

  &::backdrop {
    background-color: rgba(0, 0, 0, 70%);
  }

  // See https://github.com/GoogleChrome/dialog-polyfill#backdrop
  & + .backdrop {
    background-color: rgba(0, 0, 0, 70%);
  }
}
