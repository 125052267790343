@use "../../Foundation/mixin/typography";

@mixin p-OrderCompletesSectionThankyou {
  content: "";
  height: 50px;
  width: 100%;

  background: {
    image: url(" ../../app/assets/images/decoration-vegetable.svg");
    repeat: no-repeat;
    position: top center;
  }
}

.p-orderComplete {
  max-width: 500px;
  margin: 12px auto 0;

  .p-orderComplete__header {
    @include p-OrderCompletesSectionThankyou();
  }

  .p-orderComplete__footer {
    @include p-OrderCompletesSectionThankyou();
    transform: rotateZ(180deg);
  }

  .p-orderComplete__body {
    padding: 32px;
    margin: 0 auto;
    border: 2px solid $color-primary4;
    background: $color-primary0;
    border-radius: 16px;
    text-align: center;
    @include typography.body;

    @include screen("smartphone") {
      padding: 20px;
    }
  }
}

.p-orderComplete__thankyou {
  font-family: Futura, sans-serif;
  font-size: 38px;
  color: $color-primary1;
  text-align: center;
}

.p-orderComplete__title {
  @include typography.subTitle;
  text-align: center;
}

.p-orderComplete__couponTitle {
  @include typography.subTitle;
  text-align: center;
  position: relative;
  width: fit-content;
  max-width: 400px;
  padding: 0 40px;
  margin: 32px auto 16px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 24px;
    height: 2px;
    background-color: $color-primary2;
  }

  &:before {
    left: 0;
    bottom: 0;
    transform: rotate(45deg);
  }

  &:after {
    right: 0;
    bottom: 0;
    transform: rotate(-45deg);
  }
}

.p-rewardMessage {
  .p-rewardMessage__producer {
    width: 100%;
    background-image: url(" ../../app/assets/images/reward.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    height: 180px;
    border-radius: 0 10px 10px;
    overflow: hidden;
  }

  .p-rewardMessage__imageBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .p-rewardMessage__image {
    border-radius: 50%;
  }

  .p-rewardMessage__message {
    font-weight: $font-weight-semibold;
    color: $color-primary1;
  }
}

// アンケート
.questionnaire {
  background-color: $color-gray0;
  max-width: 700px;
  width: 100%;
  margin-top: 16px;
}
.questionnaireCard {
  background-color: $color-primary4;
  padding: 24px 16px;
}
.questionnaireCard__header {
  color: $color-gray0;
  background-color: $color-primary2;
  border-radius: 8px 8px 0 0;
  font-size: $font-size2;
  padding: 8px 16px; // iPhone SE で改行されてしまったので 20 -> 16 に変更
  line-height: 28px;
  font-weight: $font-weight-semibold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.questionnaireCard__content {
  background-color: $color-gray0;
  padding: 24px;
  border-radius: 0 0 8px 8px;
  font-size: $font-size4;
}
.questionnaireCard__summary {
  margin: 16px 0;
  padding: 8px;
  border: 1px solid $color-gray4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 24px;
}
.questionnaireCard__number {
  font-size: $font-size2;
  font-weight: $font-weight-semibold;
  margin: 0 4px;
}
.questionnaireCard__pipeline {
  width: 1px;
  height: 20px;
  background: #d9d9d9;
  margin: 0 8px;
}
.questionnaireCard__button {
  font-size: $font-size4;
  font-weight: $font-weight-semibold;
  background-color: $color-gray4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
}
