@use "../../Foundation/mixin/typography";

.p-subscriptionRadioButtonWrap {
  display: grid;
  gap: 8px;
}

.p-subscriptionRadioButton {
  display: block;

  .p-subscriptionRadioButton__input {
    // MEMO : display: none; にすると input 要素のフォーカスの操作ができなくなるので、見せないようにしています。
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    border: 0;

    &[type="radio"]:checked + .p-subscriptionRadioButton__label {
      border-color: $color-primary2;
      background: $color-primary3;

      &::before {
        border: 2px solid $color-primary2;
        background-color: $color-gray0;
      }

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: $color-primary2;
        border-radius: 50%;
        grid-area: 1/1;
        margin: 0 auto;
      }
    }
  }

  .p-subscriptionRadioButton__label {
    padding: 16px;
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    gap: 8px;
    background-color: $color-gray0;
    border-radius: 4px;
    border: 2px solid $color-gray4;
    @include typography.subTitle;

    &::before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border: 2px solid $color-gray4;
      background-color: $color-gray0;
      border-radius: 50%;
      box-sizing: border-box;
      grid-area: 1/1;
      margin: 0 auto;
    }
  }

  .p-subscriptionRadioButton__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .p-subscriptionRadioButton__normalLabel {
    display: flex;
    gap: 4px;
  }

  .p-subscriptionRadioButton__fewlLabel {
    @include typography.body-small;
    color: $color-gray1;
  }

  .p-subscriptionRadioButton__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .p-subscriptionRadioButton__price {
    @include typography.price("list");
    text-align: right;

    &.p-subscriptionRadioButton__price--discount {
      color: #dc2929;
    }
  }

  .p-subscriptionRadioButton__priceArea {
    display: flex;
    align-items: center;
  }

  .p-subscriptionRadioButton__tax {
    @include typography.caption;
    color: $color-gray2;
    letter-spacing: 0;
    text-align: right;

    margin-top: 0;
  }

  .p-subscriptionRadioButton__desc {
    @include typography.caption;
    width: 100%;
  }
}
