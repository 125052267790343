// TODO: 期間限定の朝市/夜市キャンペーンで使用します
//       キャンペーンが終了したら削除してください
.market-contents {
  margin-bottom: 48px;
  .open,
  .close {
    border-bottom: 1px solid $color-gray5;
    text-align: center;

    .title {
      .icon {
        height: auto;
        width: 100px;
      }
    }

    .description {
      margin-top: 16px;
      font-size: 24px;
      font-weight: bold;
      @include screen("smartphone") {
        font-size: 18px;
      }
    }

    .sub-description {
      color: #c51a25;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5;
      margin-top: 8px;
    }
  }

  .close {
    padding: 12px 0 36px 0;
  }

  .open {
    border-radius: 12px;
    margin-top: 36px;
    padding: 20px 24px 30px;

    &.morning {
      background-color: #fdefe3;
    }

    &.night {
      background-color: #e3e9ef;
    }
  }

  .link {
    margin-top: 32px;
  }
}
