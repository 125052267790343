.c-formHeaderLogo {
  text-align: center;
  border-bottom: 1px solid $color-gray5;
  padding: 10px;

  .c-formHeaderLogo__image {
    width: auto;
    height: 27px;

    @include screen("tablet-and-pc") {
      height: 50px;
    }
  }
}
