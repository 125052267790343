@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";

.c-recipeCard {
  min-width: 100%;
  background: color.$color-gray0;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 8px 16px;

  border: 1px solid color.$color-gray5;
  border-radius: 8px;
  padding: 16px 20px 24px;

  .c-recipeCard__title {
    grid-column: 1 / 3;
    grid-row: 1;

    > h2 {
      @include typography.subTitle; // FIXME: reset css で、h2 のフォントサイズを指定しているのをやめたら消せる
    }
  }

  .c-recipeCard__image {
    grid-column: 1;
    grid-row: 2;

    > img {
      border-radius: 4px;
      height: auto;
      width: 100%;
    }
  }

  .c-recipeCard__item {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .c-recipeCard__info {
    display: grid;
    gap: 4px;

    > li {
      display: flex;
      align-items: center;
      gap: 4px;
      @include typography.caption;
    }
  }

  .c-recipeCard__icon {
    width: 16px;

    & path {
      stroke: color.$color-gray2;
    }
  }

  .c-recipeCard__producer {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 0 8px;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }

  .c-recipeCard__producerImage {
    border-radius: 50%;
    height: auto;
    width: 40px;
    grid-row: 1 / 3;

    @include screen("smartphone") {
      width: 34px;
    }
  }

  .c-recipeCard__producerAddress {
    font-size: font.$font-size9;
  }

  .c-recipeCard__producerName {
    font-size: font.$font-size7;
    font-weight: font.$font-weight-bold;
  }
}
