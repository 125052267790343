@use "../../../Foundation/variables/color";

.c-formTextarea {
  color: color.$color-gray1;

  width: 100%;
  min-height: 6em;

  padding: 10px;

  border-radius: 4px;
  border: 1px solid color.$color-gray4;
  background-color: color.$color-gray0;

  &:focus {
    outline: 1px solid color.$color-primary2;
  }

  &[aria-invalid="false"] {
    border: 1px solid color.$color-gray4;
    background-color: color.$color-gray0;
  }

  &[aria-invalid="true"] {
    border: 1px solid color.$color-warning;
    background: color.$color-warning-background;

    &:focus {
      outline: none;
    }
  }

  &[aria-disabled="true"],
  &:disabled {
    border: 1px solid color.$color-gray4;
    background-color: color.$color-gray5;
  }
}
