.bg-white {
  background: #fff;
}

.bg-lightbrown {
  background: #f0efe9;
}

.bg-lightgray {
  background-color: #f2f2f2;
}

.border-top {
  border-top: 1px solid #f4f4f4;
}

.border-bottom {
  border-bottom: 1px solid #f4f4f4;
}
