@use "../../../../Foundation/mixin/screen";

.p-toastMessage {
  z-index: 2; // FIXME: 他の要素で使っているのがあり、かぶる時があるための対策です。z-index が整理されたら消せます
  position: fixed;
  top: 90px;
  right: 20px;

  animation: fadeInOutToast 4s linear forwards;
  -webkit-animation: fadeInOutToast 4s linear forwards;

  @include screen.screen("smartphone") {
    width: 90%;
    top: initial;
    right: initial;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

// stylelint-disable tabechoku/selector-type-allowed-list
@keyframes fadeInOutToast {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInOutToast {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// stylelint-enable tabechoku/selector-type-allowed-list
