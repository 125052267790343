@use "../../Foundation/variables/color";
@use "../../Foundation/mixin/typography";

.p-produerCommitmentLikeCounter {
  @include typography.caption;
  display: flex;
  align-items: center;
  gap: 8px;
  color: color.$color-gray2;
  background: color.$color-gray0;
  cursor: pointer;

  .p-produerCommitmentLikeCounter__count {
    font-size: 120%;
  }
}
