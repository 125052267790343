.p-recipeCategoryAnchorLinks {
  padding: 32px 0 20px;

  @include screen("smartphone") {
    padding: 32px 16px 20px;
  }

  @at-root {
    .p-recipeCategoryAnchorLinks_title {
      font-size: 16px;
      font-weight: bold;
    }

    .p-recipeCategoryAnchorLinks_list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 8px;

      @include screen("smartphone") {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .p-recipeCategoryAnchorLinks_item {
      width: 100%;
    }

    .p-recipeCategoryAnchorLinks_link {
      background-color: $color-gray0;
      border: 1px solid $color-primary2;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .p-recipeCategoryAnchorLinks_label {
      font-size: 14px;
      font-weight: bold;

      @include max-breakpoint($dimen-sp) {
        font-size: 12px;
      }
    }

    .p-recipeCategoryAnchorLinks_icon {
      width: 16px;
      stroke: $color-primary2;
    }
  }
}
