@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/arrow";

/* stylelint-disable selector-class-pattern -- プロジェクトファイルへの移行のみ行いました。 */
/* stylelint-disable declaration-property-value-allowed-list -- プロジェクトファイルへの移行のみ行いました。 */
.p-categorySearch {
  .p-categorySearch__list {
    @include screen.screen("tablet-and-pc") {
      background-color: variable.$color-gray0;
      border-radius: 20px;
    }
  }

  .p-categorySearch__list-item {
    &.-no-children-list {
      .p-categorySearch__list-link {
        &::after {
          @include arrow.arrow(variable.$gold200, 1px, arrow.$arrowDirectionRight);
        }
      }
    }

    & + .p-categorySearch__list-item {
      border-top: 1px solid #f1f1f1;
    }

    &[aria-expanded="false"] {
      .p-categorySearch__list-link {
        &::after {
          @include arrow.arrow(variable.$gold200, 1px, arrow.$arrowDirectionBottom);
        }
      }
    }

    &[aria-expanded="true"] {
      .p-categorySearch__list-link {
        &::after {
          @include arrow.arrow(variable.$gold200, 1px, arrow.$arrowDirectionTop);
        }
      }
    }
  }

  .p-categorySearch__list-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 14px;
  }

  .p-categorySearch__icon {
    content: "";
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    margin-right: 8px;
  }

  .p-categorySearch__list-label {
    flex: 1;
    font-weight: 600;
    padding: 10px 1rem;
  }

  .p-categorySearch__children {
    background: variable.$color-gray6;

    @include screen.screen("tablet-and-pc") {
      margin: 0 1rem 1.5rem;
      padding: 1rem;
      border-radius: 20px;
    }

    &[aria-hidden="true"] {
      display: none;
    }
    &[aria-hidden="false"] {
      display: block;
    }
  }

  .p-categorySearch__children-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1px;
    margin: 0;
  }

  .p-categorySearch__children-list-item {
    display: flex;
    min-height: 44px;
    background-color: variable.$color-gray6;
  }

  .p-categorySearch__children-list-link {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 14px;

    &::after {
      @include arrow.arrow(variable.$gold200, 1px, arrow.$arrowDirectionRight);
    }
  }

  .p-categorySearch__children-list-label {
    flex: 1;
    font-size: 14px;
    font-weight: 700;
  }
}

/* stylelint-enable declaration-property-value-allowed-list */
/* stylelint-enable selector-class-pattern */
