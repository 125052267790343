@use "../../Foundation/mixin/typography";
@use "../../Foundation/mixin/line-clamp";
@use "../../Foundation/variables/font";

.p-post {
  background: $color-gray0;
  max-width: 100%;
  min-height: 200px;
  padding: 20px 16px;
  display: grid;
  gap: 16px;

  &.p-post--modal {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-gray4;
    }
  }

  /* Header */
  .p-post__header {
    display: grid;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray5;
  }

  .p-post__from {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .p-post__fromImg {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    aspect-ratio: $aspect-square;
    object-fit: cover;
  }

  .p-post__posterInfomation {
    flex: 1;
  }

  .p-post__posterDetail {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .p-post__posterHouseHold {
    font-size: font.$font-size9;
    line-height: 1;
  }

  .p-post__fromName {
    @include typography.subTitle-small;

    > :not(i) {
      // HACK: Safari だけ他のブラウザと違ってなぜか outline が有効になってしまう（モーダルオープン時）。
      //       これはアクセシビリティを損なう可能性があるが、デザイン上の判断で今は無効にする。
      @include screen("smartphone") {
        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .p-post__to {
    display: flex;
    align-items: center;
    gap: 8px;

    &::before {
      @include arrow($color-primary2, 1px, $arrowDirectionRight);
    }
  }

  .p-post__toImg {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    aspect-ratio: $aspect-square;
    object-fit: cover;
  }

  .p-post__toName {
    @include typography.subTitle-small;
  }

  .p-post__type {
    @include typography.label-bold;
    background: $color-gray5;
    border-radius: 16px;
    display: flex;
    align-items: center;
    max-width: 12ch;
    height: fit-content;
    padding: 8px 12px;
    margin: 0 0 auto auto;
  }

  .p-post__time {
    @include typography.caption;
    color: $color-gray2;
  }

  .p-post__title {
    @include typography.subTitle;
  }

  /* Content */
  .p-post__content {
    display: grid;
    gap: 4px;
  }

  .p-post__contentBody {
    @include typography.body;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    position: relative;
    overflow: hidden;
  }

  .p-post__contentButton {
    background: linear-gradient(90deg, rgba($color-gray0, 0) 0%, $color-gray0 20%, $color-gray0 100%);
    color: $color-primary1;
    @include typography.button-small;
    padding: 8px 0 8px 40px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  /* Footer */
  .p-post__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include typography.caption;
    padding-top: 16px;
    border-top: 1px solid $color-gray5;

    .p-post__footerIconText {
      display: flex;
      align-items: center;
      gap: 8px;
      background: none;
      color: $color-gray2 !important; // HACK: デフォルトのスタイルを上書きできないので `!important` を使わざるを得ない。
      font-size: inherit;

      svg {
        fill: $color-gray3;
      }
    }
  }

  /* Like */
  .p-post__likeButton {
    font-family: inherit;
    padding: 0;

    svg.is-liked {
      fill: $color-favorite;
    }
  }

  /* Reply List */
  .p-post__replyList {
    padding-top: 16px;
    border-top: 1px solid $color-gray5;
  }

  /* Reply */
  .p-post__reply {
    .p-post__replyAuthor {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 4px 12px;
      grid-template-areas:
        "image name"
        "image time";
    }

    .p-post__replyImg {
      grid-area: image;
      width: 32px;
      height: 32px;
      aspect-ratio: $aspect-square;
      border-radius: 50%;
      object-fit: cover;
    }

    .p-post__replyName {
      @include typography.subTitle-small;
    }

    .p-post__replyTime {
      @include typography.caption;
      color: $color-gray2;
    }

    .p-post__replyContainer {
      background: $color-gray5;
      border-radius: 20px;
      padding: $base-padding-4;
      position: relative;
      margin-top: 12px;

      &::before {
        content: "";
        background: $color-gray5;
        height: 16px;
        width: 16px;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
        display: block;
        position: absolute;
        top: -12px;
        left: 12px;
      }
    }

    .p-post__replyContent {
      @include typography.body-small;
      overflow: hidden;
      position: relative;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
    }

    .p-post__replyContentButton {
      background: linear-gradient(90deg, rgba($color-gray5, 0) 0%, $color-gray5 20%, $color-gray5 100%);
      color: $color-primary1;
      @include typography.button-small;
      padding: 4px 0 4px 40px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .p-post__replyFooter {
      @include typography.caption;
      color: $color-gray2;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .p-post__replyLike {
      background: none;
      color: inherit;
      font-family: inherit;
      display: inline-flex;
      align-items: center;
      margin-left: auto;

      svg {
        margin-right: $base-margin;
      }
    }

    .p-post__replyDelete {
      background: none;
      color: inherit;
      font-family: inherit;
      padding: 0;
    }
  }

  /* Action Buttons */
  .p-post__actionButtons {
    display: flex;
    gap: $base-margin-3;
    justify-content: flex-end;

    .p-post__actionButton {
      font-size: $font-size7;
      margin: 0;
      padding: $base-padding-2;
      width: auto;

      svg {
        fill: var(--_font-color);
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.1em;
      }
    }
  }

  /* Reply Form */
  .p-post__replyForm {
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid $color-gray5;
    padding-top: 16px;

    textarea {
      flex: 1;
      background: $color-gray5;
      border: none;
      border-radius: 18px;
      padding: 8px 12px;
      resize: none;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .p-post__replyButton {
      width: auto;
    }

    .p-post__replyFormIcon {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
    }
  }

  /* Message */
  .p-post__none {
    margin: 10px;
    font-weight: $font-weight-bold;
    text-align: center;
  }
}
