@use "../../Foundation/mixin/screen";

.postItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 16px;

  .postItem__rating {
    grid-column: 1 / 3;
  }

  .postItem__images {
    grid-column: 1 / 3;
  }

  .postItem__ratingLink {
    grid-column: 1 / 3;
  }
}
