@use "../../Foundation/mixin/line-clamp";
@use "../../Foundation/mixin/typography";

.product-card {
  letter-spacing: 0;
  transition: 300ms;
  background: $color-gray0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  @include screen("smartphone") {
    border-radius: 4px;
  }

  &.productCard--color {
    @include screen("smartphone") {
      background: $color-primary0;
    }
  }

  a {
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }

  .product-card-product-link {
    .img-product {
      overflow: hidden;
      position: relative;
      width: 100%;
      padding-top: 70%;
      border-radius: 12px;
      @include screen("smartphone") {
        border-radius: 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    } /* img-product */

    .info-block-product {
      padding: 8px;
      word-break: break-word;

      .product-name {
        width: 100%;
        margin-bottom: 8px;
        height: 80px;
        @include screen("smartphone") {
          height: 60px;
        }

        h4 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-height: 1.5;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          @include screen("smartphone") {
            font-size: 14px;
          }
        } /* h4 */

        &.productName--sp {
          @include screen("smartphone") {
            height: 40px;
          }
          > h4 {
            @include screen("smartphone") {
              -webkit-line-clamp: 2;
            }
          }
        }
      }

      .list-tag {
        overflow: hidden;
        margin-bottom: 8px;
        line-height: 1;
        height: 54px;

        @include screen("smartphone") {
          height: 50px;
        }

        li {
          position: relative;
          display: inline-block;
          margin: 0 0 5px;
          padding: 6px 8px 6px 19px;
          font-size: 10px;
          font-weight: bold;
          background: $color-primary4;
          border-radius: 3px;

          @include screen("smartphone") {
            padding: 5px 6px 5px 14px;
            font-weight: normal;
          }

          @include max-breakpoint($dimen-sp-mini) {
            padding: 5px 6px 5px 6px;
            font-weight: normal;
          }

          @include min-breakpoint($dimen-sp-mini) {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 7px / 2);
              left: 7px;
              width: 7px;
              height: 7px;
              background: $color-gray0;
              border-radius: 50%;

              @include screen("smartphone") {
                left: 4px;
              }
            }
          }
        }
      } /* list-tag */

      .tags {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        min-height: 19px;
      }

      .listTag {
        display: flex;
        overflow: hidden;
        margin-right: 4px;

        li {
          padding: 2px 6px;
          font-size: $font-size9;
          font-weight: $font-weight-semibold;
          background: $color-primary4;
          border-radius: 16px;
        }
      }

      .reviewTag {
        $font-size: $font-size7;
        font-size: $font-size;
        font-weight: $font-weight-semibold;
        display: flex;
        white-space: nowrap;

        > :first-child {
          --rating-size: #{$font-size4};
        }

        > :last-child {
          color: $color-primary2;
        }
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include typography.price;

        &--small {
          font-size: 16px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include screen("smartphone") {
            font-size: 16px;
          }
        }
      } /* price */
    } /* info-block-product */

    @mixin flag($color, $background) {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 80px;
      height: 40px;
      transform: rotate(-45deg);
      position: absolute;
      top: -6px;
      left: -26px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0;
      line-height: 1.8;
      color: $color;
      background-color: $background;
    }

    &.flag-few {
      &:after {
        @include flag(#212121, #ffeb3b);
        content: "\6B8B\308A\308F\305A\304B"; // 残りわずか
      }
    }

    &.flag-soldout {
      &:after {
        @include flag($color-gray0, $color-gray3);
        content: "SOLD";
      }
    }

    &.flag-free-shipping {
      .price {
        &::before {
          content: "\9001\6599\7121\6599"; // 送料無料
          color: $color-gray0;
          padding: 4px 4px;
          margin-right: 4px;
          font-size: 12px;
          line-height: 1;
          background: $color-order2;
          border-radius: 2px;
          @include max-breakpoint($dimen-sp-mini) {
            padding: 4px 3px;
            margin-right: 4px;
            font-size: 10px;
          }
        }

        &--small {
          &::before {
            content: "\9001\6599\7121\6599"; // 送料無料
            color: $color-gray0;
            padding: 4px 4px;
            margin-right: 4px;
            font-size: 11px;
            line-height: 1;
            background: $color-order2;
            border-radius: 2px;
            @include max-breakpoint($dimen-sp-mini) {
              padding: 4px 3px;
              margin-right: 4px;
              font-size: 10px;
            }
          }
        }
      }
    } /* flag-free-shipping */

    &.flag-bundle {
      .img-product {
        &::after {
          content: "\3042\308F\305B\8CB7\3044\5BFE\8C61"; // あわせ買い対象
          background: $color-primary2;
          color: $color-gray0;
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 12px;
          font-weight: bold;
          line-height: 1;
          padding: 4px 8px;
          border-radius: 8px 0 0 0;
        }
      }
    } /* flag-bundle */
  }

  .productCard__producerLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid #f2f2f2;
    position: relative;
    @include screen("smartphone") {
      background-color: $color-primary4;
    }
  }

  .product-card-producer-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #fff;
    border-top: 1px solid #f2f2f2;
    position: relative;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 0 7px;
      border-color: #f2f2f2 transparent transparent transparent;
      position: absolute;
      top: -1px;
      left: 12%;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      top: -1px;
      left: calc(12% + 1px);
    }
  }

  .producer-img {
    width: 25%;
    max-width: 50px;
    @include screen("smartphone") {
      width: 25%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .producer-info {
    width: 75%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .area {
      overflow: hidden;
      width: 100%;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 11px;
        @include screen("smartphone") {
          font-size: 10px;
        }
      }
    }

    .name {
      overflow: hidden;
      width: 100%;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 13px;
        font-weight: bold;
        @include screen("smartphone") {
          font-size: 12px;
        }
      }
    }
  }

  .btn-product-fav-wrap {
    width: 22%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    .btn-product-fav {
      position: relative;
      margin: 0;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 14%;
      padding-top: 22%;

      .icon {
        margin: 0;
        width: 80%;
      }
    }
  }
}
