.p-notificationFruitsSelect {
  display: block;

  &.p-notificationFruitsSelect--progress {
    background: $color-progress;
  }

  // MEMO : .slim-wrap というクラスに合わせる時に使います
  &.p-notificationFruitsSelect--slimWrap {
    .p-notificationFruitsSelect__inner {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .p-notificationFruitsSelect__inner {
    font-size: $font-size5;
    font-weight: $font-weight-semibold;
    line-height: 1.4;
    padding: 8px 16px;
  }

  .p-notificationFruitsSelect__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: "";
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
      border-top: 3px solid $color-gray1;
      border-right: 3px solid $color-gray1;
      border-radius: 2px;
    }
    & + .p-notificationFruitsSelect__item {
      margin-top: 8px;
    }
  }

  .p-notificationFruitsSelect__link {
    width: 100%;
  }
}
