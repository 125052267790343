@use "../../Foundation/variable";
@use "../../Foundation/mixin/typography";

.p-userPreferenceProductList {
  @include screen("smartphone") {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 8px solid variable.$color-primary4;
  }

  .p-userPreferenceProductList__inner {
    margin-bottom: 28px;
    @include screen("smartphone") {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid variable.$color-gray4;
    }

    &.p-userPreferenceProductList__inner--last {
      @include screen("smartphone") {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .p-userPreferenceProductList__title {
    font-size: variable.$font-size2;
    font-weight: variable.$font-weight-bold;
    color: variable.$color-gray1;
    text-align: left;
    padding: 4px 8px 4px 12px;
    margin-bottom: 16px;
    border-left: 6px solid variable.$color-primary2;
    line-height: 22.4px;

    @include screen("smartphone") {
      font-size: variable.$font-size4;
    }
  }

  .p-userPreferenceProductList__registration {
    margin-top: 16px;
  }

  .p-userPreferenceProductList__registrationSupplement {
    text-align: center;
    margin-bottom: 8px;
    @include typography.caption;
  }
}
