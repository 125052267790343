@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/mixin/typography";

.c-definitionList {
  border: 1px solid $color-gray5;

  .c-definitionList__title {
    padding: 8px;
    color: color.$color-gray2;
    background-color: color.$color-gray5;

    @include typography.subTitle-small;
  }

  .c-definitionList__content {
    padding: 8px;
    color: color.$color-gray1;
    background-color: color.$color-gray0;

    @include typography.body-small;
  }
}
