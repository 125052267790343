.order-shopping-cart {
  h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 20px 0;
    padding-bottom: 6px;
    border-bottom: 2px solid #f2f2f2;
  }
}

.shipping-item {
  overflow: hidden;
  border: 1px solid $color-gray5;
  margin-bottom: 20px;
  border-radius: 10px;

  .order-group {
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid $color-gray5;
    padding: 16px;
  }

  .order-cart-items-wrapper {
    padding: 16px;

    .alert-area {
      ul.alert-list {
        width: 100%;
        margin: 16px 0;
        padding: 10px;
        border: 1px solid #f00;
      }
    }
  }

  .order-cart-shipment-wrapper {
    padding: 16px 0;
    border-top: 1px solid $color-gray5;

    select {
      font-size: 12px;
    }

    .discount-original-price {
      text-decoration: line-through;
    }

    .discount-price {
      color: #dc0800;

      .discount-label {
        margin: 3px;
        display: inline-block;
        border-radius: 1em;
        background-color: #dc0800;
        color: #fff;
        padding: 3px 8px 2px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .cart-item-list-summary {
    padding-top: 16px;
    border-top: 1px solid $color-gray5;

    dl {
      dt {
        float: left;
      }

      dd {
        text-align: right;
        margin-bottom: 8px;
      }
    }

    .small-attention {
      font-size: 11px;
    }

    .discount-original-price {
      text-decoration: line-through;
    }

    .discount-price {
      .discount-label {
        margin: 3px;
        display: inline-block;
        border-radius: 1em;
        background-color: #dc0800;
        color: #fff;
        padding: 3px 8px 2px;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .discount-note {
      font-size: 11px;
    }
  }

  .cool-type-label {
    position: relative;
    display: inline-block;
    margin: 0 0 5px;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: bold;
    border: 3px solid $color-primary4;
    border-radius: 20px;
  }
}
