.media {
  display: flex;
  align-items: center;

  .media-left {
    display: flex;

    img {
      border-radius: 50%;
      object-fit: cover;
      width: 48px;
      height: 48px;
    }

    .credit-card {
      border-radius: 6px;
      object-fit: cover;
      width: 54px;
      height: 40px;
    }
  }

  .media-content {
    color: $text-default;
    margin-left: $base-padding * 3;
    flex-grow: 1;
  }
}
