.p-mypage-wide-wrapper {
  @include screen("smartphone") {
    padding: 2rem 1rem 3rem;
    margin: 0 -1rem;
  }

  &.-background-gray {
    @include screen("smartphone") {
      background-color: $color-gray5;
    }
  }

  &.-margin-bottom {
    margin-bottom: 1rem;
    @include screen("tablet-and-pc") {
      margin-bottom: 2rem;
    }
  }
}
