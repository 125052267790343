.c-formInputRadioButton {
  display: flex;

  &[aria-disabled="true"] {
    .c-formInputRadioButton__label {
      color: $color-gray3;
      background-color: $color-gray5;
      &::before {
        border: 2px solid $color-gray4;
        background-color: $color-gray4;
      }
    }
  }

  & + .c-formInputRadioButton {
    margin-top: 8px;
  }

  .c-formInputRadioButton__input {
    // MEMO : display: none; にすると input 要素のフォーカスの操作ができなくなるので、見せないようにしています。
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    border: 0;

    &[type="radio"]:checked + .c-formInputRadioButton__label {
      background-color: $color-primary3;
      border: 1px solid $color-primary2;
      border-radius: 4px;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: #bda35a;
        border-radius: 50%;
        grid-area: 1/1;
        margin: 0 auto;
      }
    }
  }

  .c-formInputRadioButton__label {
    width: 100%;

    display: grid;
    grid-template-columns: 18px 1fr;
    gap: 8px;
    align-items: center;

    background-color: $color-gray0;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    padding: 16px;

    &::before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border: 2px solid #bda35a;
      background-color: $color-gray0;
      border-radius: 50%;
      grid-area: 1/1;
      box-sizing: border-box;
    }
  }
}
