@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/line-clamp";
@use "../../../Foundation/variables/font";
@use "../../../Foundation/variables/color";

.p-titleShowBody {
  --_sectionColumns: 1;
  --_section-gap: 16px;
  --_section-list-gap: 0px 8px;
  --_section-list-columns: 2;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  margin-top: 24px;

  @include screen.screen("tablet-and-pc") {
    --_sectionColumns: 3;
    --_section-gap: 8px;
    --_section-list-gap: 0;
    --_section-list-columns: 1;
  }

  .p-titleShowBody__title {
    display: flex;
    padding: 4px 8px 4px 16px;
    align-items: flex-end;
    gap: 8px;

    border-left: 6px solid color.$color-primary2;

    @include typography.subTitle;
  }

  .p-titleShowBody__titleCount {
    font-size: font.$font-size7;
    font-weight: font.$font-weight-normal;
    line-height: 1.6;
    letter-spacing: 0.2px;
  }

  .p-titleShowBody__sections {
    display: grid;
    grid-template-columns: repeat(var(--_sectionColumns), 1fr);
    gap: var(--_section-gap);
  }

  .p-titleShowBody__sectionTitle {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;

    @include typography.caption-bold;

    background-color: color.$color-gray6;
  }

  .p-titleShowBody__sectionTitleCount {
    font-size: font.$font-size7;
    font-weight: font.$font-weight-normal;
    line-height: 1.6;
    letter-spacing: 0.2px;
  }

  .p-titleShowBody__sectionList {
    display: grid;
    grid-template-columns: repeat(var(--_section-list-columns), 1fr);
    gap: var(--_section-list-gap);

    margin-top: 8px;
  }

  .p-titleShowBody__sectionListItem {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 8px 0px;
  }

  .p-titleShowBody__sectionListItemLink {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .p-titleShowBody__sectionListItemThumbnail {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .p-titleShowBody__sectionListItemInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .p-titleShowBody__sectionListItemName {
    @include line-clamp.line-clamp(1);
    @include typography.subTitle-small;
  }

  .p-titleShowBody__sectionListItemDetail {
    font-size: font.$font-size9;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
}
