/*
.c-formDivRadioButton

## 注意事項

`Deprecated`
- この Class は `非推奨` です。.c-formInputRadioButton を使ってください
- コンシェルジュのフォームで使われている input 要素のようで違う UI があり、改めて実装し直しています。input要素は input要素を使うようにしましょう

## アクセシビリティ

- role で radio を設定するときは、一緒に radiogroup の使用も検討してください

*/

.c-formDivRadioButton {
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: 18px 1fr;
  gap: 8px;
  align-items: center;

  border: 1px solid $color-gray4;
  border-radius: 4px;
  background-color: $color-gray0;

  padding: 16px;

  &::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid #bda35a;
    background-color: $color-gray0;
    border-radius: 50%;
    grid-area: 1/1;
    box-sizing: border-box;
  }

  &[aria-checked="true"] {
    background-color: $color-primary3;
    border: 1px solid $color-primary2;
    border-radius: 4px;

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background: #bda35a;
      border-radius: 50%;
      grid-area: 1/1;
      margin: 0 auto;
    }
  }

  &[aria-disabled="true"] {
    color: $color-gray3;
    background-color: $color-gray5;
    &::before {
      border: 2px solid $color-gray4;
      background-color: $color-gray4;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  & + .c-formDivRadioButton {
    margin-top: 8px;
  }
}
