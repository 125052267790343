@use "../../Foundation/mixin/typography";
@use "../../Foundation/variables/color";
@use "../../Foundation/variables/font";

.p-productListCardWrap {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.p-productListCard {
  border-radius: 8px;
  background: color.$color-primary0;

  .p-productListCard__productImages {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(3, 1fr);

    &.p-productListCard__productImages--soldOut {
      opacity: 0.2;
    }
  }

  .p-productListCard__productImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .p-productListCard__productImage1 {
    border-radius: 8px 0 0 0;
  }

  .p-productListCard__productImage3 {
    border-radius: 0 8px 0 0;
  }

  .p-productListCard__productInfo {
    padding: 12px;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .p-productListCard__productName {
    @include typography.subTitle;
    margin-bottom: 0;
    -webkit-line-clamp: 2;
  }

  .p-productListCard__goodsQuantitityBlock {
    display: grid;
    grid-template-columns: 1fr 9fr;
    gap: 4px;
  }

  .p-productListCard__goodsQuantitityLabel {
    display: flex;
    width: 44px;
    padding-top: 2px;
  }

  .p-productListCard__goodsQuantities {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .p-productListCard__goodsQuantity {
    padding: 2px 8px;
    border-radius: 16px;
    border: 1px solid color.$color-gray4;
    background: color.$color-gray0;
    font-size: font.$font-size9;
    font-weight: font.$font-weight-semibold;
    display: flex;
    align-items: center;

    &.p-productListCard__goodsQuantity--active {
      color: $color-primary1;
      background: color.$color-primary3;
      border: none;
    }
  }

  .p-productListCard__features {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .p-productListCard__featureLabel {
    padding: 2px 8px;
    color: color.$color-gray1;
    font-size: font.$font-size9;
    font-weight: font.$font-weight-semibold;
    border-radius: 4px;
    border: 1px solid color.$color-gray4;
    background: color.$color-gray0;
  }

  .p-productListCard__review {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $font-size7;
    white-space: nowrap;
    height: 18px;

    i {
      --rating-size: #{font.$font-size2};
    }
  }

  .p-productListCard__reviewNum {
    color: color.$color-primary2;
    font-weight: font.$font-weight-semibold;
  }

  .p-productListCard__shipping {
    display: flex;
  }

  .p-productListCard__shippingPrice {
    color: color.$color-gray2;
    font-size: font.$font-size7;
  }

  .p-productListCard__shippingDate {
    color: color.$color-gray2;
    font-size: font.$font-size7;
    border-left: 1px solid color.$color-gray4;
    padding-left: 8px;
    margin-left: 8px;
  }

  .p-productListCard__priceBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .p-productListCard__priceRow {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .p-productListCard__municipalityCampaignLabel {
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    background: $color-primary4;
    padding: 4px 6px;
    border-radius: 20px;
    width: fit-content;
    margin: 0 4px 4px 0;
    position: relative;
  }

  .p-productListCard__price {
    @include typography.price;

    &.p-productListCard__price--soldOut {
      color: color.$color-gray4;
    }
  }

  .p-productListCard__priceLabel {
    padding: 4px 8px;
    border-radius: 16px;
    font-size: font.$font-size9;
    font-weight: font.$font-weight-semibold;

    &.p-productListCard__priceLabel--soldOut {
      background: color.$color-gray2;
      color: color.$color-gray0;
    }

    &.p-productListCard__priceLabel--freeShipping {
      background: color.$color-order2;
      color: color.$color-gray0;
    }

    &.p-productListCard__priceLabel--fewStock {
      background: color.$color-progress;
      color: color.$color-gray1;
    }

    &.p-productListCard__priceLabel--reservation {
      background: color.$color-gray4;
      color: color.$color-gray1;
    }

    &.p-productListCard__priceLabel--municipalityCampaign {
      background: $color-order2;
      color: $color-primary0;

      &::before {
        content: "\00A5"; // ¥
      }

      &::after {
        content: "\5F15\304D"; // 引き
      }
    }
  }

  .p-productListCard__producerBlock {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-top: 1px solid color.$color-gray5;
    position: relative;
    border-radius: 0 0 8px 8px;
    gap: 8px;
    background: color.$color-primary4;
  }

  .p-productListCard__producerImg {
    width: 20%;
    max-width: 48px;

    > img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .p-productListCard__producerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }

  .p-productListCard__producerArea {
    overflow: hidden;
    width: 100%;
    font-size: $font-size9;
  }

  .p-productListCard__producerName {
    overflow: hidden;
    width: 100%;
    font-size: $font-size5;
    font-weight: $font-weight-semibold;
    margin-top: 0;
  }

  // TODO: コーディング規約に合わせる
  .btn-product-fav-wrap {
    width: 12%;
    background: transparent;
    position: absolute;
    right: 12px;
    cursor: pointer;

    .btn-product-fav {
      position: relative;
      margin: 0;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 14%;
      padding-top: 22%;

      .icon {
        margin: 0;
        width: 80%;
      }
    }
  }
}
