@use "../Foundation/mixin/typography";

@import "../Layout/mypage/l-mypageContainer";
@import "../Layout/mypage/l-mypageMain";

@import "../Object/Component/mypage/c-mypageHeadTitle";
@import "../Object/Component/mypage/notifications/c-notificationItem";
@import "../Object/Component/mypage/notifications/c-notificationsTitle";
@import "../Object/Component/mypage/producer_promotion_subscriptions/p-producerPromotion";
@import "../Object/Component/mypage/producer_promotion_subscriptions/p-producerPromotions";

@import "../Object/Project/mypage/p-regularPurchasesToOrders";
@import "../Object/Project/mypage/p-regularPurchase";
@import "../Object/Project/mypage/p-mypageProfie";

@import "../Object/Project/mypage/p-regularPurchasesToSubscriptions";
@import "../Object/Project/mypage/notifications/p-notificationsTitle";
@import "../Object/Project/mypage/orders/p-ordersToRegularPurchases";

@import "./mypage/p-mypage-wide-wrapper";
@import "./mypage/subscriptions_contract_card";
@import "./mypage/deliveryDateCalendar";

.mypage {
  /* stylelint-disable selector-class-pattern */
  .top-list {
    background-color: #f5f1eb;
    padding: 28px 16px;
    @include screen("tablet-and-pc") {
      margin-bottom: 28px;
    }
    @include screen("smartphone") {
      margin: 0 -1rem 28px;
    }

    .top-list-inner {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      .top-list-navi {
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        width: 100%;
        padding: 20px 4px;

        @include typography.button;
        text-align: center;

        > img {
          width: 24px;
          height: 24px;
        }

        & {
          background-color: $color-primary2;
          color: $color-gray0;
        }

        &.top-list-navi--white {
          border: 2px solid $color-primary2;
          background-color: $color-gray0;
          color: $color-gray1;
        }
      }
    }

    .top-list-container {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      .footer-menu {
        font-size: $font-size4;
        font-weight: $font-weight-semibold;
        margin-top: 8px;
        text-align: right;
        color: $color-primary1;
      }
    }
  }

  /* stylelint-enable selector-class-pattern */

  .mid-list {
    display: grid;
    gap: 8px;
    margin-bottom: 28px;
    @include screen("tablet-and-pc") {
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    > li {
      position: relative;
      width: 100%;

      > a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px;
        @include typography.button;
        border-radius: 8px;
        border: 2px solid $color-primary2;

        img {
          width: 25px;
          height: 25px;
          object-fit: contain;
          margin-right: 8px;
        }
      }
    }
  }

  .bottom-list {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $color-gray5;
    border-radius: 8px;
    background: $color-gray0;
    margin-top: 28px;

    > li {
      position: relative;
      width: 100%;
      @include screen("tablet-and-pc") {
        width: 50%;
      }

      > a {
        display: block;
        width: 100%;
        @include typography.button;
        padding: 20px 16px;
      }

      &:not(:nth-child(2n)) {
        @include screen("tablet-and-pc") {
          border-right: 1px solid $color-gray5;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-gray5;
      }

      &::before {
        @include arrow($color-gray3, 1px, $arrowDirectionRight);
        position: absolute;
        right: 1rem;
        top: calc(50% - 4px);
      }
    }
  }
}

/* mypage */

.mypage-coupon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin: 28px 0;
}

.badge-wrap {
  position: relative;

  .badge {
    right: 0;
    top: 0;
  }
}

.subscriptions__linkWapper {
  margin-top: 16px;
}
