@use "../../Foundation/mixin/typography";

.p-productsHeader__title {
  padding-bottom: 20px;
  @include typography.title-1;

  @include screen("tablet-and-pc") {
    padding: 28px 0;
  }
}

.p-productsHeader__description {
  padding: 12px;
  background-color: $color-gray6;
  border-radius: 4px;
  font-size: $font-size4;
  font-weight: $font-weight-light;
  margin-bottom: 20px;

  @include screen("tablet-and-pc") {
    padding: 16px;
  }
}

.p-productsHeader__producerDescription {
  margin-bottom: $base-margin-5;
}

.p-productsHeaderItem__title {
  font-size: $font-size4;
  font-weight: $font-weight-bold;
  padding-left: 12px;
  border-left: 6px solid $color-primary2;
  margin-bottom: 16px;
}

.p-productsHeaderItem__titleNote {
  font-size: $font-size5;
  font-weight: $font-weight-normal;
}

.p-productsHeaderItem__body {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  @include screen("tablet-and-pc") {
    grid-template-columns: repeat(4, 1fr);
  }
}

.p-productsHeaderPeakSeason {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid $color-primary4;
  background: $color-primary0;
}

.p-productsHeaderPeakSeason__title {
  padding-left: 20px;
  border-left: 6px solid $color-primary2;
  @include typography.title-2;
}

.p-productsHeaderPeakSeason__body {
  @include typography.body;
}

.p-productsHeaderPeakSeason__bodyPeakSeason {
  color: $color-primary1;
  @include typography.body-bold;
}
