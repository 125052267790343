@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/aspect-ratio";

.c-titleLevelSymbol {
  --_icon: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M7 16L0.0717964 12L0.0717964 4L7 0L13.9282 4L13.9282 12L7 16Z"/></svg>');
  --_background-color: #{color.$color-bronze};

  display: inline-block;
  mask-image: var(--_icon);
  inline-size: 1em;
  aspect-ratio: aspect-ratio.$aspect-square;
  background-color: var(--_background-color);

  &.c-titleLevelSymbol--gold {
    --_background-color: #{color.$color-gold};
  }

  &.c-titleLevelSymbol--silver {
    --_background-color: #{color.$color-silver};
  }

  &.c-titleLevelSymbol--bronze {
    --_background-color: #{color.$color-bronze};
  }
}
